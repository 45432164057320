import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useHistory, useParams, useRouteMatch } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import getDateNow from "util/getDateNow";
//
import "feature/mypage/mypage.css";
import $ from "jquery";
import "firebase/functions";
import PostSearch from "feature/postcode/PostSearch";

const MemberWorksheets = () => {
  const match = useRouteMatch();
  // const db = useMemo(() => firebase.firestore(), []);
  const [worksheet, setWorksheet] = useState(null);

  const [email, setEmail] = useState("");
  const [passwd, setPasswd] = useState("");
  const [confirmPasswd, setConfirmPasswd] = useState("");
  const [selectUserType, setSelectUserType] = useState("기업회원");
  const [userData, setUserData] = useState({});
  const [pictureFile, setPictureFile] = useState();
  const [displayStartup, setDisplayStartup] = useState("none");
  const [mentoData, setMentoData] = useState();
  const [subMentos, setSubMentos] = useState();
  //
  const userId = match.params.userId;
  const history = useHistory();
  const db = useRef(firebase.firestore()).current;
  const auth = useRef(firebase.auth()).current;
  const storage = useMemo(() => firebase.storage().ref(), []);
  const functions = useMemo(() => firebase.functions(), []);

  const renderedMentos = Array.isArray(subMentos) ? (
    subMentos.map((m, idx) => (
      <tr key={m.email}>
        <td>{m.name}</td>
        <td>
          <Link to={`/admin/member/${m.userId}/worksheets/`}>{m.email}</Link>
        </td>
        <td>
          {m.companyName ? m.companyName : "없음"}
          {m.job ? m.job : "없음"}
        </td>
        <td>{m.phonenumber}</td>
        <td className={"worksheetCount_" + idx}>
          {m.agree ? "승인" : "미승인"}
        </td>
        <td>{m.grade || "등급없음"}</td>
        <td>
          {m.registDate ? m.registDate.split("T")[0] : null}
          <br />
          {m.registDate
            ? m.registDate.split("T")[1].split("Z")[0].split(".")[0]
            : null}
        </td>
        <td>
          <span>
            <Link
              className="reviseButton reviseMemberButton mb-0"
              to={`/admin/member/${m.id}/worksheets/`}
            >
              선택
            </Link>
          </span>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td>후임 멘토가 없습니다.</td>
    </tr>
  );

  useEffect(() => {
    if (userId == undefined) return;
    async function f() {
      const doc = await db.collection("mento").doc(userId).get();
      console.log("exists", doc.exists, "id", userId);
      if (!doc.exists) return;
      const subIdList = doc.data().subMentos;

      const promises = [];
      Array.isArray(subIdList) &&
        subIdList.forEach((e) => {
          async function f() {
            const mentoData = await db.collection("mento").doc(e).get();
            const userData = await db.collection("users").doc(e).get();
            if (mentoData.exists && userData.exists) {
              return {
                ...mentoData.data(),
                ...userData.data(),
                id: e,
              };
            }
          }
          promises.push(f());
        });
      const subDataList = await Promise.all(promises);

      console.log("subDataList", subDataList);

      setSubMentos(subDataList);
    }
    f();
  }, [userId]);

  useEffect(() => {
    console.log("user_id: ", userId);
    if (userId != undefined) {
      db.collection("users")
        .doc(userId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            // console.log(doc.data())
            // setDatas(doc.data())
            setUserData(doc.data());
            if (doc.data().startupCount > 0) {
              $("#reg_mb_5_1").prop("checked", true);
              setDisplayStartup("inline");
              $("input[name=mb_6]").each(function (index, item) {
                if ($(item).val() == doc.data().startupYears) {
                  $(item).prop("checked", true);
                }
              });
            }
            // setEmail(doc.data().email)
            db.collection("mentee")
              .doc(userId)
              .get()
              .then((menteeDoc) => {
                if (menteeDoc.exists) {
                  setSelectUserType("기업회원");
                  // console.log(doc.data())
                  // setDatas(doc.data())
                  // setEmail(doc.data().email)
                }
              });
            db.collection("mento")
              .doc(userId)
              .get()
              .then((mentoDoc) => {
                if (mentoDoc.exists) {
                  setSelectUserType("멘토회원");
                  setUserData({ ...doc.data(), ...mentoDoc.data() });
                  $("#kind2").prop("checked", true);
                  console.log(mentoDoc.data());
                  $(".select1:first")
                    .val(mentoDoc.data().startupFields[0]?.category)
                    .attr("selected", "selected");
                  $(".select2:first")
                    .val(mentoDoc.data().startupFields[0]?.subCategory)
                    .attr("selected", "selected");
                  $(".select1:last")
                    .val(mentoDoc.data().startupFields[1]?.category)
                    .attr("selected", "selected");
                  $(".select2:last")
                    .val(mentoDoc.data().startupFields[1]?.subCategory)
                    .attr("selected", "selected");

                  setMentoData(mentoDoc.data());
                }
              });
          }
        });
    }
  }, [match.params.id, setEmail]);

  useEffect(() => {
    if (!userId || userId == "") return;
    db.collection("worksheets")
      .get()
      .then((querySnapshot) => {
        let worksheetArray = [];

        let proms = querySnapshot.docs.map(
          (doc) =>
            new Promise((res, err) => {
              let data = doc.data();
              data.id = doc.id;
              if (data.mentee == userId && data.mento && data.mento !== "") {
                db.collection("users")
                  .doc(data.mento)
                  .get()
                  .then((doc) => {
                    data.mentoName = doc.data().name;
                    return res(data);
                  })
                  .catch((e) => err(e));
              } else if (data.mentee == userId) {
                return res(data);
              } else {
                return res();
              }
            })
        );
        Promise.all(proms).then((arr) => {
          for (const v of arr) {
            if (v) worksheetArray.push(v);
          }
          setWorksheet(worksheetArray);
        });
      });
  }, [db, userId]);

  useEffect(() => {
    // 멘토가 연결된 워크시트
    console.log(selectUserType);
    // console.log(userId)
    db.collection("mento")
      .doc(userId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          let worksheetArray = [];
          db.collection("worksheets")
            .get()
            .then((querySnapshot) => {
              querySnapshot.docs.map((doc) => {
                // console.log(doc.data())
                if (doc.data().mento == userId) {
                  worksheetArray.push(doc.data());
                }
              });
              setWorksheet(worksheetArray);
            });
        }
      });
  }, [db, userId, selectUserType, setSelectUserType]);
  //
  const WorksheetList = ({ selectUserType }) => {
    return worksheet.map((item, index) => {
      console.log(item);
      return (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{item.menteeName}</td>
          <td>{item.companyName}</td>
          <td>
            <div className="detailView white">
              <Link
                to={`/worksheets/${item.id || "new"}/worksheet${item.page}/`}
                target="_blank"
              >
                {item.title}
                {/* <div className="btn-xs squear-btn-basic">진행중</div> */}
              </Link>
            </div>
          </td>
          <td>
            {selectUserType == "기업회원" ? (
              // item.finalSubmit &&
              !item.mento || item.mento == "" || !item.mentoAccept ? (
                <>
                  <Link
                    to={`/admin/member/${userId}/worksheets/${item.id}/apply`}
                  >
                    <div className="btn-xs squear-btn-basic">멘토신청</div>
                  </Link>
                </>
              ) : (
                <>
                  {item.mentoName}
                  {"  "}
                  <Link
                    to={`/admin/member/${userId}/worksheets/${item.id}/apply`}
                  >
                    <div className="btn-xs squear-btn-basic d-inline-flex">
                      멘토변경
                    </div>
                  </Link>
                </>
              )
            ) : (
              item.menteeName
            )}
            {/* {item.finalSubmit &&
              (!item.mento || item.mento == "" || !item.mentoAccept) ? (
                <Link to={`/admin/member/${userId}/worksheets/${item.id}/apply`}>
                  <div className="btn-xs squear-btn-basic">멘토신청</div>
                </Link>
              ) : item.finalSubmit && item.mentoAccept === "accept" ? (
                item.mentoName
              ) : item.mentoAccept != undefined ? (
                "상태:" + item.mentoAccept
              ) : (
                ""
              )} */}
          </td>
          <td>
            {item.finalSubmit ? (
              item.date?.split("T")[0]
            ) : (
              <Link
                to={`/worksheets/${item.id || "new"}/worksheet${item.page}/`}
                target="_blank"
              >
                <div className="btn-xs squear-btn-basic">진행중</div>
              </Link>
            )}
          </td>
          <td>
            <div className="btn-xs squear-btn-basic">수정</div>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <PostSearch
        onComplete={(data) => onComplete(data, userData, setUserData)}
      />
      <div
        style={{
          marginLeft: 290,
          marginTop: 70,
          marginBottom: 70,
          marginRight: 70,
        }}
      >
        <div className="float-left mt-3">개인정보</div>
        <div className="btn squear-btn-basic float-right">
          <Link
            to={
              "/admin/member?selectType=" +
              (selectUserType === "멘토회원" ? "mento" : "mentee")
            }
            className="detailView white"
          >
            {" "}
            목록으로
          </Link>
        </div>
      </div>
      <div
        style={{
          marginLeft: 290,
          marginTop: 120,
          marginBottom: 70,
          marginRight: 70,
        }}
      >
        {/* {userId} */}
        {/*  */}
        <div className="register">
          <form
            id="fregisterform"
            name="fregisterform"
            action
            method
            autoComplete
            onSubmit={(e) => {
              e.preventDefault();
              // auth.signInWithEmailAndPassword(email, passwd)
              // 유저 키를 가져옴
              // 신규 비밀번호가 있으면 비밀번호 변경
              let newPw = "";
              let picUrl;
              if (userData.newPw != "") {
                if (userData.newPw === confirmPasswd) {
                  newPw = userData.newPw;
                  console.log(userId);
                  //
                  const changeUserPassword = functions.httpsCallable(
                    "changeUserPassword"
                  );
                  let data = { userId: userId, newPw: newPw };
                  changeUserPassword(data).then((result) => {
                    db.collection("users").doc(userId).update({
                      pw: newPw,
                    });
                  });
                  //
                  // return

                  // firebase.auth().currentUser.updatePassword(newPw).then(()=>{
                  //   console.log('비밀번호 변경 성공')
                  // }).catch((err)=>{console.log(err); alert('비밀번호 변경 오류 코드: '+err)})
                }
              }
              db.collection("users")
                .doc(userId)
                .update({
                  id: userData.id || "",
                  pw: newPw == "" ? userData.pw || "" : newPw,
                  name: userData.name || "",
                  email: userData.email || "",
                  phonenumber: userData.phonenumber || "",
                  postnumber: userData.postnumber || "",
                  addressMain: userData.addressMain || "",
                  addressSub: userData.addressSub || "",
                  birthday: userData.birthday || "",
                  companyName: userData.companyName || "",
                  job: userData.job || "",
                  jobType: userData.jobType || "",
                  jobPosition: userData.jobPosition || "",
                  startupCount: userData.startupCount || 0,
                  startupYears: userData.startupYears || "",
                  // registDate: getDateNow(),
                })
                .then(() => {
                  if (!pictureFile || !auth.currentUser?.uid) return;
                  const pictureRef = storage.child(
                    "image/user/" + auth.currentUser.uid
                  );
                  return pictureRef
                    .put(pictureFile)
                    .then(() =>
                      pictureRef.getDownloadURL().then((url) => {
                        picUrl = url;
                      })
                    )
                    .catch((err) => console.log(err));
                })
                .then(() => {
                  if (selectUserType == "기업회원") {
                    alert("수정되었습니다.");
                    window.location.reload();
                  } else {
                    db.collection("mento")
                      .doc(userId)
                      .update({
                        picture: picUrl || "",
                        account: userData.account || "",
                        techType: userData.techType || "",
                        fourTechType: userData.fourTechType || "",
                        startupFields: userData.startupFields || "",
                        status: 1 || "",
                        // agree:false,
                      })
                      .then(() => {
                        alert("수정되었습니다.");
                        window.location.reload();
                      })
                      .catch((err) => console.log(err));
                  }
                })
                .catch((err) => console.log(err));

              return;
            }}
          >
            <div id="register_form" className="form_01">
              <input
                type="radio"
                name="kind"
                id="kind1"
                className="memberkind d-none"
                defaultValue="기업회원"
                data={1}
                defaultChecked
                onChange={(e) => {
                  setSelectUserType("기업회원");
                }}
              />
              <input
                type="radio"
                name="kind"
                id="kind2"
                className="memberkind d-none"
                defaultValue="멘토회원"
                data={2}
                onChange={(e) => {
                  setSelectUserType("멘토회원");
                }}
              />
              <div className="register_form_inner">
                <ul>
                  <li className="half_input left_input margin_input">
                    <label htmlFor="reg_mb_id">
                      아이디(이메일)<strong className="sound_only">필수</strong>
                      {/* <button type="button" className="tooltip_icon d-none">
                            <i
                              className="fa fa-question-circle-o"
                              aria-hidden="true"
                            />
                            <span className="sound_only">설명보기</span>
                          </button> */}
                      {/* <span className="tooltip">
                            영문자, 숫자, _ 만 입력 가능. 최소 3자이상
                            입력하세요.
                          </span> */}
                    </label>
                    {/* <input
                          type="text"
                          name="mb_id"
                          id="reg_mb_id"
                          required
                          className="frm_input full_input required "
                          minLength={3}
                          maxLength={20}
                          placeholder="아이디"
                          value={datas == null ? datas:datas.id}
                          onChange={(e) => {
                            setUserData({ ...userData, id: e.target.value });
                          }}
                        /> */}
                    <input
                      type="text"
                      name="mb_email"
                      id="reg_mb_email"
                      required
                      className="frm_input email full_input required"
                      size={70}
                      maxLength={100}
                      placeholder="아이디(이메일)"
                      onChange={(e) => {
                        if (match.params.id == undefined) {
                          setEmail(e.target.value);
                          setUserData({ ...userData, email: e.target.value });
                        }
                      }}
                      value={userData == null ? userData : userData.email}
                    />
                    {/* <span id="msg_mb_id" /> */}
                  </li>
                  <li className="half_input left_input">
                    <label htmlFor="reg_mb_name">
                      이름<strong className="sound_only">필수</strong>
                    </label>
                    <input
                      type="text"
                      id="reg_mb_name"
                      name="mb_name"
                      required
                      className="frm_input full_input required "
                      size={10}
                      placeholder="이름"
                      value={userData == null ? userData : userData.name}
                      onChange={(e) => {
                        setUserData({ ...userData, name: e.target.value });
                      }}
                    />
                  </li>
                  <li className="half_input left_input margin_input">
                    <label htmlFor="reg_mb_password">
                      비밀번호<strong className="sound_only">필수</strong>
                    </label>
                    <input
                      type="password"
                      name="mb_password"
                      id="reg_mb_password"
                      required
                      className="frm_input full_input required"
                      minLength={6}
                      maxLength={20}
                      placeholder="비밀번호"
                      value={userData == null ? userData : userData.pw}
                      onChange={(e) => {
                        setPasswd(e.target.value);
                        setUserData({ ...userData, pw: e.target.value });
                      }}
                    />
                  </li>
                  {userId ? (
                    <li className="half_input left_input">
                      <div style={{ opacity: 0 }}>
                        <label htmlFor="reg_mb_password_re">
                          비밀번호 확인
                          <strong className="sound_only">필수</strong>
                        </label>
                        <input
                          type="password"
                          name="mb_password_re"
                          id="reg_mb_password_re"
                          required
                          className="frm_input full_input required"
                          minLength={6}
                          maxLength={30}
                          placeholder="비밀번호 확인"
                          value={userData == null ? userData : userData.pw}
                          onChange={(e) => {
                            setConfirmPasswd(userData.pw);
                          }}
                        />
                      </div>
                    </li>
                  ) : (
                    <li className="half_input left_input">
                      <label htmlFor="reg_mb_password_re">
                        비밀번호 확인
                        <strong className="sound_only">필수</strong>
                      </label>
                      <input
                        type="password"
                        name="mb_password_re"
                        id="reg_mb_password_re"
                        required
                        className="frm_input full_input required"
                        minLength={6}
                        maxLength={30}
                        placeholder="비밀번호 확인"
                        onChange={(e) => {
                          setConfirmPasswd(e.target.value);
                        }}
                      />
                    </li>
                  )}
                  {userId ? (
                    <>
                      <li className="half_input left_input margin_input">
                        <label htmlFor="reg_mb_password_re">
                          비밀번호 변경
                          {/* <strong className="sound_only">필수</strong> */}
                        </label>
                        <input
                          type="password"
                          name="mb_password_re"
                          id="reg_mb_password_re"
                          // required
                          className="frm_input full_input"
                          minLength={6}
                          maxLength={30}
                          placeholder="비밀번호 확인"
                          onChange={(e) => {
                            setPasswd(e.target.value);
                            setUserData({ ...userData, newPw: e.target.value });
                          }}
                        />
                      </li>
                      <li className="half_input left_input">
                        <label htmlFor="reg_mb_password_re">
                          비밀번호 변경확인
                          {/* <strong className="sound_only">필수</strong> */}
                        </label>
                        <input
                          type="password"
                          name="mb_password_re"
                          id="reg_mb_password_re"
                          // required
                          className="frm_input full_input"
                          minLength={6}
                          maxLength={30}
                          placeholder="비밀번호 확인"
                          onChange={(e) => {
                            setConfirmPasswd(e.target.value);
                          }}
                        />
                      </li>
                    </>
                  ) : null}
                  {/* <li className="half_input left_input">
                        <label htmlFor="reg_mb_password_re">
                          비밀번호 확인
                          <strong className="sound_only">필수</strong>
                        </label>
                        <input
                          type="password"
                          name="mb_password_re"
                          id="reg_mb_password_re"
                          required
                          className="frm_input full_input required"
                          minLength={3}
                          maxLength={20}
                          placeholder="비밀번호 확인"
                          onChange={(e) => {
                            setConfirmPasswd(e.target.value);
                          }}
                        />
                      </li> */}
                  {/* <li className="half_input left_input margin_input">
                        <label htmlFor="reg_mb_email">
                          E-mail<strong className="sound_only">필수</strong>
                        </label>
                        <input type="hidden" name="old_email" />
                        <input
                          type="text"
                          name="mb_email"
                          id="reg_mb_email"
                          required
                          className="frm_input email full_input required"
                          size={70}
                          maxLength={100}
                          placeholder="E-mail"
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setUserData({ ...userData, email: e.target.value });
                          }}
                          value={datas == null ? datas:datas.email}
                        />
                      </li> */}
                  <li className="half_input left_input margin_input">
                    <label htmlFor="reg_mb_10">생년월일</label>
                    {/* <input type="hidden" name="mb_10_default" /> */}
                    <input
                      type="text"
                      name="mb_10"
                      id="reg_mb_10"
                      className="frm_input nospace full_input"
                      size={10}
                      maxLength={20}
                      placeholder="YYMMDD"
                      value={userData == null ? userData : userData.birthday}
                      onChange={(e) => {
                        setUserData({
                          ...userData,
                          birthday: e.target.value,
                        });
                      }}
                    />
                    {/* <span id="msg_mb_10" /> */}
                  </li>
                  <li className="half_input left_input">
                    <label htmlFor="reg_mb_hp">
                      휴대폰번호<strong className="sound_only">필수</strong>
                    </label>
                    <input
                      type="text"
                      name="mb_hp"
                      id="reg_mb_hp"
                      required
                      className="frm_input full_input required"
                      maxLength={20}
                      placeholder="휴대폰번호"
                      value={userData == null ? userData : userData.phonenumber}
                      onChange={(e) => {
                        setUserData({
                          ...userData,
                          phonenumber: e.target.value,
                        });
                      }}
                    />
                  </li>
                  <li>
                    <label>주소</label>
                    <label htmlFor="reg_mb_zip" className="sound_only">
                      우편번호
                    </label>
                    <input
                      type="text"
                      name="mb_zip"
                      id="reg_mb_zip"
                      className="frm_input twopart_input"
                      size={5}
                      maxLength={6}
                      placeholder="우편번호"
                      value={userData == null ? userData : userData.postnumber}
                      onChange={(e) => {
                        setUserData({
                          ...userData,
                          postnumber: e.target.value,
                        });
                      }}
                    />
                    <button
                      type="button"
                      className="btn_frmline"
                      onClick={() => {
                        stateChangePostModal();
                      }}
                    >
                      주소 검색
                    </button>
                    <br />
                    <input
                      type="text"
                      name="mb_addr1"
                      id="reg_mb_addr1"
                      className="frm_input frm_address full_input"
                      size={50}
                      placeholder="기본주소"
                      value={userData == null ? userData : userData.addressMain}
                      onChange={(e) => {
                        setUserData({
                          ...userData,
                          addressMain: e.target.value,
                        });
                      }}
                    />
                    <label htmlFor="reg_mb_addr1" className="sound_only">
                      기본주소<strong>필수</strong>
                    </label>
                    <br />
                    <input
                      type="text"
                      name="mb_addr2"
                      id="reg_mb_addr2"
                      className="frm_input frm_address full_input"
                      size={50}
                      placeholder="상세주소"
                      value={userData == null ? userData : userData.addressSub}
                      onChange={(e) => {
                        setUserData({
                          ...userData,
                          addressSub: e.target.value,
                        });
                      }}
                    />
                    <label htmlFor="reg_mb_addr2" className="sound_only">
                      상세주소
                    </label>
                    <br />
                    {/* <input
                          type="text"
                          name="mb_addr3"
                          id="reg_mb_addr3"
                          className="frm_input frm_address full_input"
                          size={50}
                          readOnly="readonly"
                          placeholder="참고항목"
                        />
                        <label htmlFor="reg_mb_addr3" className="sound_only">
                          참고항목
                        </label>
                        <input
                          type="hidden"
                          name="mb_addr_jibeon"
                          onChange={(e) => {
                            setUserData({
                              ...userData,
                              addition: e.target.value,
                            });
                          }}
                        /> */}
                  </li>
                  {/* <li>
                        <label htmlFor>
                          분류<strong className="sound_only">필수</strong>
                        </label>
                        <div className="d-flex resi_btn_Wrap mb-0 pb-0">
                          <input
                            type="text"
                            name="mb_2"
                            id="mb_2"
                            className="d-none"
                          />
                          <label
                            htmlFor="kind1"
                            className="btn btn-sm resi_btn col-2 kind1"
                          >
                            기업회원
                          </label>
                          <label
                            htmlFor="kind2"
                            className="btn btn-sm resi_btn col-2 kind2"
                          >
                            멘토회원
                          </label>
                        </div>
                      </li> */}
                  <li className="half_input left_input margin_input">
                    <label htmlFor="reg_mb_1">
                      현재소속<strong className="sound_only">필수</strong>
                    </label>
                    <input
                      type="text"
                      name="mb_1"
                      id="reg_mb_1"
                      className="frm_input full_input "
                      minLength={3}
                      maxLength={20}
                      placeholder="현재소속"
                      value={userData == null ? userData : userData.companyName}
                      onChange={(e) => {
                        setUserData({
                          ...userData,
                          companyName: e.target.value,
                        });
                      }}
                    />
                  </li>
                  <li className="half_input left_input">
                    <label htmlFor="reg_mb_">현재직업 </label>
                    <select
                      name="mb_"
                      id="reg_mb_"
                      className="frm_input full_input"
                      value={userData == null ? userData : userData.job}
                      onChange={(e) => {
                        setUserData({
                          ...userData,
                          job: e.target.value,
                        });
                      }}
                    >
                      <option>선택</option>
                      <option value="중소기업(소상공인 포함)">
                        중소기업(소상공인 포함)
                      </option>
                      <option value="중견기업">중견기업</option>
                      <option value="대기업">대기업</option>
                      <option value="공공(연구)기관">공공(연구)기관</option>
                      <option value="초,중,고 학교">초,중,고 학교</option>
                      <option value="대학,대학교">대학, 대학교</option>
                      <option value="정부, 지자체">정부, 지자체</option>
                      <option value="일반협회, 단체">일반협회, 단체</option>
                      <option value="프리랜서">프리랜서</option>
                      <option value="학생">학생</option>
                      <option value="전업주부">전업주부</option>
                      <option value="무직,퇴직 등 기타">
                        무직,퇴직 등 기타
                      </option>
                    </select>
                  </li>
                  <li className="half_input left_input margin_input">
                    <label htmlFor="reg_mb_3">
                      근무직종<strong className="sound_only">필수</strong>
                    </label>
                    <select
                      name="mb_3"
                      id="reg_mb_3"
                      className="frm_input full_input "
                      value={userData == null ? userData : userData.jobType}
                      onChange={(e) => {
                        setUserData({
                          ...userData,
                          jobType: e.target.value,
                        });
                      }}
                    >
                      <option>선택</option>
                      <option value="경영,관리직">
                        경영,관리직(경영자, 임원 등)
                      </option>
                      <option value="일반사무직">
                        일반사무직(일반사무원, 교사 등)
                      </option>
                      <option value="영업,판매직">
                        영업,판매직(영업,판매원 등)
                      </option>
                      <option value="연구,기술직">
                        연구,기술직(연구원, 기술자, 교수 등)
                      </option>
                      <option value="기능, 생산직">
                        기능, 생산직(기능원, 기계조작원 등)
                      </option>
                      <option value="단순노무직">단순노무직</option>
                    </select>
                  </li>
                  <li className="half_input left_input">
                    <label htmlFor="reg_mb4">
                      현재직위<strong className="sound_only">필수</strong>
                    </label>
                    <input
                      type="text"
                      name="mb_4"
                      id="reg_mb_4"
                      className="frm_input full_input "
                      // minLength={1}
                      // maxLength={20}
                      placeholder="현재직위"
                      value={userData == null ? userData : userData.jobPosition}
                      onChange={(e) => {
                        setUserData({
                          ...userData,
                          jobPosition: e.target.value,
                        });
                      }}
                    />
                  </li>
                  <li className="half_input left_input margin_input">
                    <label htmlFor="reg_mb_5_title">
                      창업경험<strong className="sound_only">필수</strong>
                    </label>
                    <div className=" resi_btn_Wrap d-flex align-items-center justify-content-start">
                      <input
                        type="radio"
                        name="mb_5"
                        id="reg_mb_5_1"
                        data={1}
                        hidden
                        onClick={(e) => {
                          setDisplayStartup("inline");
                        }}
                      />
                      <label
                        htmlFor="reg_mb_5_1"
                        className="btn btn-sm resi_btn col-3 reg_mb_5_1"
                      >
                        {" "}
                        있다
                      </label>
                      <input
                        type="radio"
                        name="mb_5"
                        id="reg_mb_5_2"
                        data={2}
                        hidden
                        defaultChecked
                        onClick={(e) => {
                          setDisplayStartup("none");
                        }}
                      />
                      <label
                        htmlFor="reg_mb_5_2"
                        className="btn btn-sm resi_btn col-3 reg_mb_5_2 mr-2"
                      >
                        {" "}
                        없다
                      </label>
                      <input
                        className="mb_5_number frm_input business"
                        style={{ display: displayStartup }}
                        type="text"
                        placeholder="횟수를 입력해주세요"
                        value={
                          userData == null ? userData : userData.startupCount
                        }
                        onChange={(e) => {
                          setUserData({
                            ...userData,
                            startupCount: e.target.value || 0,
                          });
                        }}
                      />
                    </div>
                  </li>
                  <li
                    className="half_input left_input business"
                    style={{ display: displayStartup }}
                  >
                    <label htmlFor="reg_mb_6_title">
                      업력<strong className="sound_only">필수</strong>
                    </label>
                    <div className="resi_btn_Wrap d-flex align-items-center justify-content-start">
                      <input
                        type="radio"
                        name="mb_6"
                        value="예비창업자"
                        id="reg_mb_6_1"
                        hidden
                        onChange={(e) => {
                          setUserData({
                            ...userData,
                            startupYears: e.target.value,
                          });
                        }}
                      />
                      <label
                        htmlFor="reg_mb_6_1"
                        className="reg_mb_6_1 btn btn-sm resi_btn col-3"
                      >
                        {" "}
                        예비창업자
                      </label>
                      <input
                        type="radio"
                        name="mb_6"
                        value="창업 3년미만"
                        id="reg_mb_6_2"
                        hidden
                        onChange={(e) => {
                          setUserData({
                            ...userData,
                            startupYears: e.target.value,
                          });
                        }}
                      />
                      <label
                        htmlFor="reg_mb_6_2"
                        className="reg_mb_6_2 btn btn-sm resi_btn col-3"
                      >
                        {" "}
                        창업 3년미만
                      </label>
                      <input
                        type="radio"
                        name="mb_6"
                        value="창업 7년미만"
                        id="reg_mb_6_3"
                        hidden
                        onChange={(e) => {
                          setUserData({
                            ...userData,
                            startupYears: e.target.value,
                          });
                        }}
                      />
                      <label
                        htmlFor="reg_mb_6_3"
                        className="reg_mb_6_3 btn btn-sm resi_btn col-3"
                      >
                        {" "}
                        창업 7년미만
                      </label>
                      <input
                        type="radio"
                        name="mb_6"
                        value="창업 7년초과"
                        id="reg_mb_6_4"
                        hidden
                        onChange={(e) => {
                          setUserData({
                            ...userData,
                            startupYears: e.target.value,
                          });
                        }}
                      />
                      <label
                        htmlFor="reg_mb_6_4"
                        className="reg_mb_6_4 btn btn-sm resi_btn col-3"
                      >
                        {" "}
                        창업 7년초과
                      </label>
                    </div>
                  </li>
                  {/* <li>
                                  <label for="">학력<strong class="sound_only">필수</strong></label>
                                  <div class="d-flex resi_btn_Wrap mb-0 pb-0">
                                      <input type="radio" name="education" id="education1" class="d-none" value="고등학교졸업"  checked>
                                      <input type="radio" name="education" id="education2" class="d-none" value="학사">
                                      <input type="radio" name="education" id="education3" class="d-none" value="석사">
                                      <input type="radio" name="education" id="education4" class="d-none" value="박사">
                                      <label for="education1" class="btn btn-sm resi_btn col-3 education1">고등학교 졸업</label>
                                      <label for="education2" class="btn btn-sm resi_btn col-3 education2">학사</label>
                                      <label for="education3" class="btn btn-sm resi_btn col-3 education3">석사</label>
                                      <label for="education4" class="btn btn-sm resi_btn col-3 education4">박사</label>
                                  </div>
                              </li>
                              <li>
                                  <label for="">주전공<strong class="sound_only">필수</strong></label>
                                  <div class="d-flex resi_btn_Wrap mb-0 pb-0">
                                      <input type="radio" name="major" id="major1" class="d-none" value="공학"  checked>
                                      <input type="radio" name="major" id="major2" class="d-none" value="이학">
                                      <input type="radio" name="major" id="major3" class="d-none" value="사회과학">
                                      <input type="radio" name="major" id="major4" class="d-none" value="인문학">
                                      <input type="radio" name="major" id="major5" class="d-none" value="의학">
                                      <input type="radio" name="major" id="major6" class="d-none" value="예체능">
                                      <label for="major1" class="btn btn-sm resi_btn col-2 major1">공학</label>
                                      <label for="major2" class="btn btn-sm resi_btn col-2 major2">이학</label>
                                      <label for="major3" class="btn btn-sm resi_btn col-2 major3">사회과학</label>
                                      <label for="major4" class="btn btn-sm resi_btn col-2 major4">인문학</label>
                                      <label for="major5" class="btn btn-sm resi_btn col-2 major5">의학</label>
                                      <label for="major6" class="btn btn-sm resi_btn col-2 major6">예체능</label>
                                  </div>
                              </li>
                              <li>
                                  <div class="d-flex justify-content-between mb-0 pd-0 align-items-center">
                                      <label for="">경력 (*최대 5개 등록가능합니다)<strong class="sound_only">필수</strong></label>
                                      <div id="addCareer" class=" float-right btn btn-sm squear-btn-border-basic text-center bgnone" >추가</div>
                                  </div>
                                  <div class="d-flex mb-0 pb-0 career">
                                      <div class="col-3 grid">
                                          <input type="text" id="company1" class="frm_input full_input" placeholder="조직명">
                                      </div>
                                      <div class="col-3 grid">
                                          <input type="text" id="date1" class="frm_input full_input" placeholder="yyyymmdd-yyyymmdd">
                                      </div>
                                      <div class="col grid pr-0">
                                          <input type="text" id="work1" class="frm_input full_input" placeholder="업무">
                                      </div>
                                  </div>
                                  <div class="addBox">
                                  </div>
                              </li> */}
                </ul>
              </div>

              <div className="tbl_frm01 tbl_wrap register_form_inner mento_form">
                <h2>멘토회원 추가입력</h2>
                <ul>
                  <li className="reg_mb_img_file">
                    <label
                      htmlFor="reg_mb_img"
                      className="frm_label"
                      style={{ width: 300 }}
                    >
                      {userData == null ? null : (
                        <img
                          style={{
                            width: 300,
                            height: 300,
                            objectFit: "cover",
                          }}
                          src={userData?.picture}
                          alt="멘토 사진"
                        />
                      )}
                      멘토 사진 (300 x 300)
                      <button type="button" className="tooltip_icon d-none">
                        <i
                          className="fa fa-question-circle-o"
                          aria-hidden="true"
                        />
                        <span className="sound_only">설명보기</span>
                      </button>
                      <span className="tooltip">
                        이미지 크기는 가로 300픽셀, 세로 300픽셀 이하로
                        해주세요.
                        <br />
                        gif, jpg, png파일만 가능하며 용량 50,000바이트 이하만
                        등록됩니다.
                      </span>
                    </label>
                    <input
                      type="file"
                      name="mb_img"
                      id="reg_mb_img"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        setPictureFile(file);
                      }}
                    />
                  </li>
                  <li>
                    <label htmlFor="mb_">계좌 정보</label>
                    <input
                      type="text"
                      name="mb_"
                      id="reg_mb_"
                      className="frm_input full_input "
                      minLength={3}
                      maxLength={20}
                      placeholder="우리은행, 000-000000-00000"
                      value={userData == null ? userData : userData.account}
                      onChange={(e) => {
                        const clone = { ...userData };
                        clone.account = e.target.value;
                        setUserData(clone);
                      }}
                    />
                  </li>
                  <li>
                    <label htmlFor>산업기술분야</label>
                    <div className="row container pl-0">
                      <div className="w-50 pl-0  mb-0">
                        <div className="d-flex align-items-center justify-content-start pl-0 mb-0">
                          <span>기술 &gt; </span>
                          <select
                            name
                            id
                            className="ml-2 frm_input"
                            value={
                              userData == null ? userData : userData.techType
                            }
                            onChange={(e) => {
                              const clone = { ...userData };
                              clone.techType = e.target.value;
                              setUserData(clone);
                            }}
                          >
                            <option value="">분야선택</option>
                            <option value={"정보/통신"}>정보/통신</option>
                            <option value={"기계/소재"}>기계/소재</option>
                            <option value={"전기/전자"}>전기/전자</option>
                            <option value={"에너지/자원"}>에너지/자원</option>
                            <option value={"바이오/의료"}>바이오/의료</option>
                            <option value={"화공/섬유"}>화공/섬유</option>
                            <option value={"공예/디자인"}>공예/디자인</option>
                          </select>
                        </div>
                      </div>
                      <div className="w-50 pl-0 mb-0">
                        <div className="d-flex align-items-center justify-content-start mb-0">
                          <span>4치 혁명기술 &gt; </span>
                          <select
                            name
                            id
                            className="ml-2 frm_input "
                            value={
                              userData == null
                                ? userData
                                : userData.fourTechType
                            }
                            onChange={(e) => {
                              const clone = { ...userData };
                              clone.fourTechType = e.target.value;
                              setUserData(clone);
                            }}
                          >
                            <option value="">분야선택</option>
                            <option value={"인공지능"}>인공지능</option>
                            <option value={"클라우드"}>클라우드</option>
                            <option value={"사물 인터넷"}>사물 인터넷</option>
                            <option value={"5G"}>5G</option>
                            <option value={"3D 프린팅"}>3D 프린팅</option>
                            <option value={"블록체인"}>블록체인</option>
                            <option value={"지능형 반도체"}>
                              지능형 반도체
                            </option>
                            <option value={"첨단소재"}>첨단소재</option>
                            <option value={"스마트 헬스케어"}>
                              스마트 헬스케어
                            </option>
                            <option value={"AR/VR"}>AR/VR</option>
                            <option value={"드론"}>드론</option>
                            <option value={"스마트 공장"}>스마트 공장</option>
                            <option value={"스마트 팜"}>스마트 팜</option>
                            <option value={"지능형 로봇"}>지능형 로봇</option>
                            <option value={"스마트 공장"}>자율 주행차</option>
                            <option value={"020"}>O2O</option>
                            <option value={"신재생 에너지"}>
                              신재생 에너지
                            </option>
                            <option value={"스마트 시트"}>스마트 시트</option>
                            <option value={"핀테크"}>핀테크</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <label htmlFor="mb_8_text">창업경영분야</label>
                    <div className="row container pl-0">
                      <div className="w-50 pl-0 ">
                        <div className="d-flex align-items-center justify-content-start pl-0 mb-0">
                          <span>선택1 </span>
                          <select
                            name
                            id
                            className="select1 ml-2 frm_input"
                            // value={userData == null ? userData:userData.startupFields}
                            onChange={(e) => {
                              const clone = { ...userData };
                              clone.startupFields = clone.startupFields || [];
                              clone.startupFields[0] = {
                                ...clone.startupFields[0],
                                category: e.target.value,
                              };
                              setUserData(clone);
                              changeNextSelect(e);
                            }}
                          >
                            <option value data={0}>
                              1차카테고리
                            </option>
                            <option value="창업">창업</option>
                            <option value="기획">기획</option>
                            <option value="마케팅">마케팅</option>
                            <option value="조직/인사">조직/인사</option>
                            <option value="연구개발">연구개발</option>
                          </select>
                          <select
                            name
                            id
                            className="select2 ml-2 frm_input "
                            value={null}
                            onChange={(e) => {
                              const clone = { ...userData };
                              clone.startupFields = clone.startupFields || [];
                              clone.startupFields[0] = {
                                ...clone.startupFields[0],
                                subCategory: e.target.value,
                              };
                              setUserData(clone);
                            }}
                          >
                            <option value data={0}>
                              1차카테고리를 선택해주세요
                            </option>
                            <option
                              value="사업계휙"
                              data={10}
                              style={{ display: "none" }}
                            >
                              사업계획
                            </option>
                            <option
                              value="비즈니스모델"
                              data={10}
                              style={{ display: "none" }}
                            >
                              비즈니스모델
                            </option>
                            <option
                              value="아이템 검증"
                              data={10}
                              style={{ display: "none" }}
                            >
                              아이템 검증
                            </option>
                            <option
                              value="팀구성"
                              data={10}
                              style={{ display: "none" }}
                            >
                              팀구성
                            </option>
                            <option
                              value="사업자등록"
                              data={10}
                              style={{ display: "none" }}
                            >
                              사업자등록
                            </option>
                            <option
                              value="법인설립"
                              data={10}
                              style={{ display: "none" }}
                            >
                              법인설립
                            </option>
                            <option
                              value="정부지원사업"
                              data={10}
                              style={{ display: "none" }}
                            >
                              정부지원사업
                            </option>
                            <option
                              value="제품/서비스기휙"
                              data={20}
                              style={{ display: "none" }}
                            >
                              제품/서비스기획
                            </option>
                            <option
                              value="신상품 기휙"
                              data={20}
                              style={{ display: "none" }}
                            >
                              신상품 기획
                            </option>
                            <option
                              value="시장성 분석"
                              data={20}
                              style={{ display: "none" }}
                            >
                              시장성 분석
                            </option>
                            <option
                              value="경쟁 분석"
                              data={20}
                              style={{ display: "none" }}
                            >
                              경쟁 분석
                            </option>
                            <option
                              value="고객조사/분석"
                              data={30}
                              style={{ display: "none" }}
                            >
                              고객조사/분석
                            </option>
                            <option
                              value="국내 마케팅"
                              data={30}
                              style={{ display: "none" }}
                            >
                              국내 마케팅
                            </option>
                            <option
                              value="해외 마케팅"
                              data={30}
                              style={{ display: "none" }}
                            >
                              해외 마케팅
                            </option>
                            <option
                              value="온라인 마케팅"
                              data={30}
                              style={{ display: "none" }}
                            >
                              온라인 마케팅
                            </option>
                            <option
                              value="전시회/박람회"
                              data={30}
                              style={{ display: "none" }}
                            >
                              전시회/박람회
                            </option>
                            <option
                              value="국내외 인증"
                              data={30}
                              style={{ display: "none" }}
                            >
                              국내외 인증
                            </option>
                            <option
                              value="임금/보상"
                              data={40}
                              style={{ display: "none" }}
                            >
                              임금/보상
                            </option>
                            <option
                              value="인력채용"
                              data={40}
                              style={{ display: "none" }}
                            >
                              인력채용
                            </option>
                            <option
                              value="조직구조"
                              data={40}
                              style={{ display: "none" }}
                            >
                              조직구조
                            </option>
                            <option
                              value="사내규정"
                              data={40}
                              style={{ display: "none" }}
                            >
                              사내규정
                            </option>
                            <option
                              value="직무"
                              data={40}
                              style={{ display: "none" }}
                            >
                              직무
                            </option>
                            <option
                              value="복리후생"
                              data={40}
                              style={{ display: "none" }}
                            >
                              복리후생
                            </option>
                            <option
                              value="설계"
                              data={50}
                              style={{ display: "none" }}
                            >
                              설계
                            </option>
                            <option
                              value="디자인"
                              data={50}
                              style={{ display: "none" }}
                            >
                              디자인
                            </option>
                            <option
                              value="시제품 개발"
                              data={50}
                              style={{ display: "none" }}
                            >
                              시제품 개발
                            </option>
                            <option
                              value="제품시험"
                              data={50}
                              style={{ display: "none" }}
                            >
                              제품시험
                            </option>
                            <option
                              value="기술개발/분석"
                              data={50}
                              style={{ display: "none" }}
                            >
                              기술개발/분석
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="w-50 pl-0">
                        <div className="d-flex align-items-center justify-content-start mb-0">
                          <span>선택2 </span>
                          <select
                            name
                            id
                            className="select1 ml-2 frm_input "
                            value={null}
                            onChange={(e) => {
                              const clone = { ...userData };
                              clone.startupFields = clone.startupFields || [];
                              clone.startupFields[1] = {
                                ...clone.startupFields[1],
                                category: e.target.value,
                              };
                              setUserData(clone);
                              changeNextSelect(e);
                            }}
                          >
                            <option value data={0}>
                              1차카테고리
                            </option>
                            <option value="창업">창업</option>
                            <option value="기획">기획</option>
                            <option value="마케팅">마케팅</option>
                            <option value="조직/인사">조직/인사</option>
                            <option value="연구개발">연구개발</option>
                          </select>
                          <select
                            name
                            id
                            className="select2 ml-2 frm_input"
                            value={null}
                            onChange={(e) => {
                              const clone = { ...userData };
                              clone.startupFields = clone.startupFields || [];
                              clone.startupFields[1] = {
                                ...clone.startupFields[1],
                                subCategory: e.target.value,
                              };
                              setUserData(clone);
                            }}
                          >
                            <option value data={0}>
                              1차카테고리를 선택해주세요
                            </option>
                            <option
                              value="사업계휙"
                              data={10}
                              style={{ display: "none" }}
                            >
                              사업계획
                            </option>
                            <option
                              value="비즈니스모델"
                              data={10}
                              style={{ display: "none" }}
                            >
                              비즈니스모델
                            </option>
                            <option
                              value="아이템 검증"
                              data={10}
                              style={{ display: "none" }}
                            >
                              아이템 검증
                            </option>
                            <option
                              value="팀구성"
                              data={10}
                              style={{ display: "none" }}
                            >
                              팀구성
                            </option>
                            <option
                              value="사업자등록"
                              data={10}
                              style={{ display: "none" }}
                            >
                              사업자등록
                            </option>
                            <option
                              value="법인설립"
                              data={10}
                              style={{ display: "none" }}
                            >
                              법인설립
                            </option>
                            <option
                              value="정부지원사업"
                              data={10}
                              style={{ display: "none" }}
                            >
                              정부지원사업
                            </option>
                            <option
                              value="제품/서비스기휙"
                              data={20}
                              style={{ display: "none" }}
                            >
                              제품/서비스기획
                            </option>
                            <option
                              value="신상품 기휙"
                              data={20}
                              style={{ display: "none" }}
                            >
                              신상품 기획
                            </option>
                            <option
                              value="시장성 분석"
                              data={20}
                              style={{ display: "none" }}
                            >
                              시장성 분석
                            </option>
                            <option
                              value="경쟁 분석"
                              data={20}
                              style={{ display: "none" }}
                            >
                              경쟁 분석
                            </option>
                            <option
                              value="고객조사/분석"
                              data={30}
                              style={{ display: "none" }}
                            >
                              고객조사/분석
                            </option>
                            <option
                              value="국내 마케팅"
                              data={30}
                              style={{ display: "none" }}
                            >
                              국내 마케팅
                            </option>
                            <option
                              value="해외 마케팅"
                              data={30}
                              style={{ display: "none" }}
                            >
                              해외 마케팅
                            </option>
                            <option
                              value="온라인 마케팅"
                              data={30}
                              style={{ display: "none" }}
                            >
                              온라인 마케팅
                            </option>
                            <option
                              value="전시회/박람회"
                              data={30}
                              style={{ display: "none" }}
                            >
                              전시회/박람회
                            </option>
                            <option
                              value="국내외 인증"
                              data={30}
                              style={{ display: "none" }}
                            >
                              국내외 인증
                            </option>
                            <option
                              value="임금/보상"
                              data={40}
                              style={{ display: "none" }}
                            >
                              임금/보상
                            </option>
                            <option
                              value="인력채용"
                              data={40}
                              style={{ display: "none" }}
                            >
                              인력채용
                            </option>
                            <option
                              value="조직구조"
                              data={40}
                              style={{ display: "none" }}
                            >
                              조직구조
                            </option>
                            <option
                              value="사내규정"
                              data={40}
                              style={{ display: "none" }}
                            >
                              사내규정
                            </option>
                            <option
                              value="직무"
                              data={40}
                              style={{ display: "none" }}
                            >
                              직무
                            </option>
                            <option
                              value="복리후생"
                              data={40}
                              style={{ display: "none" }}
                            >
                              복리후생
                            </option>
                            <option
                              value="설계"
                              data={50}
                              style={{ display: "none" }}
                            >
                              설계
                            </option>
                            <option
                              value="디자인"
                              data={50}
                              style={{ display: "none" }}
                            >
                              디자인
                            </option>
                            <option
                              value="시제품 개발"
                              data={50}
                              style={{ display: "none" }}
                            >
                              시제품 개발
                            </option>
                            <option
                              value="제품시험"
                              data={50}
                              style={{ display: "none" }}
                            >
                              제품시험
                            </option>
                            <option
                              value="기술개발/분석"
                              data={50}
                              style={{ display: "none" }}
                            >
                              기술개발/분석
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {/* {match.params.id == undefined ? (
                  <div id="register_form" className="form_01">
                    <div className="register_form_inner  px-4 pb-4">
                      <section
                        id="fregister_term"
                        className="position-relative"
                      >
                        <h2 className="pl-0">회원가입약관</h2>
                        <textarea readOnly defaultValue={"{ 이용약관 }"} />
                        <fieldset className="fregister_agree">
                          <input
                            type="checkbox"
                            name="agree"
                            defaultValue={1}
                            id="agree11"
                            className="selec_chk d-none"
                          />
                          <label htmlFor="agree11">
                            <span />
                            <b className="sound_only">
                              회원가입약관의 내용에 동의합니다.
                            </b>
                          </label>
                        </fieldset>
                      </section>
                      <section
                        id="fregister_private"
                        className="position-relative"
                      >
                        <h2 className="pl-0">개인정보처리방침안내</h2>
                        <textarea
                          readOnly
                          defaultValue={"{ 개인정보처리방침안내 }"}
                        />
                        <fieldset className="fregister_agree">
                          <input
                            type="checkbox"
                            name="agree2"
                            defaultValue={1}
                            id="agree21"
                            className="selec_chk d-none"
                          />
                          <label htmlFor="agree21">
                            <span />
                            <b className="sound_only">
                              개인정보처리방침안내의 내용에 동의합니다.
                            </b>
                          </label>
                        </fieldset>
                      </section>
                    </div>
                  </div>
                ) : null} */}

            <div className="btn_confirm mt-5">
              {/* <a
                    href="/public/index.html"
                    id="notLogin"
                    className="btn btn-sm btn_close squear-btn-border-basic "
                  >
                    메인으로
                  </a> */}
              <button
                type="submit"
                id="btn_submit"
                className="btn btn-sm btn_submit squear-btn-basic "
              >
                수정
              </button>
            </div>
          </form>
        </div>

        {mentoData && mentoData?.grade === "선임멘토" && (
          <>
            {/* 선임멘토의 멘토관리 */}
            <div style={{ height: 40 }}>
              <p>멘토관리</p>
              <div className="btn squear-btn-basic mt40 float-right">
                <Link
                  to={`/admin/member/${userId}/subMento/apply`}
                  className="detailView white"
                >
                  후임멘토 신청
                </Link>
              </div>
            </div>
            <div className="tbl_head01 tbl_wrap">
              <table className="table stepTable tableStyle text-center bgTable">
                <caption className="sound-only d-none">회원관리 목록</caption>
                <colgroup>
                  <col width="100px" />
                  <col width="150px" />
                  <col width="150px" />
                  <col width="200px" />
                  <col width="100px" />
                  <col width="100px" />
                  <col width="120px" />
                  <col width="80px" />
                </colgroup>
                <thead>
                  <tr>
                    <th className="bg">이름</th>
                    <th className="bg">이메일</th>
                    <th className="bg">소속(직업군)</th>
                    <th className="bg">번호</th>
                    <th className="bg">현황</th>
                    <th className="bg">상태</th>
                    <th className="bg">가입날짜</th>
                    <th className="bg">관리</th>
                  </tr>
                </thead>
                <tbody>{renderedMentos}</tbody>
              </table>
            </div>
          </>
        )}

        {/* 워크시트 */}
        <div style={{ height: 40 }}>
          <p>워크시트</p>
        </div>
        {selectUserType == "기업회원" ? (
          worksheet == null ? null : worksheet.length < 1 ? (
            <div className="worksheet_list">
              <div
                style={{
                  flex: 1,
                  // alignItems: "right",
                  // justifyContent: "flex-end",
                }}
              >
                {/* <div
                className="btn squear-btn-basic mb40"
                style={{ position: "relative", right: 0 }}
              >
              </div> */}
              </div>
              <table className="table text-center table-theme3">
                <colgroup>
                  <col width="60px" />
                  <col width="120px" />
                  <col width="120px" />
                  <col />
                  <col width="120px" />
                  <col width="120px" />
                  <col width="100px" />
                </colgroup>
                <thead className="thead-blue">
                  <tr>
                    <th className="font-weight-normal">no</th>
                    <th className="font-weight-normal">이름</th>
                    <th className="font-weight-normal">기업명</th>
                    <th className="font-weight-normal">제목</th>
                    <th className="font-weight-normal">멘토</th>
                    <th className="font-weight-normal">제출일</th>
                  </tr>
                </thead>
                {/* {console.log(worksheet)} */}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>작성한 워크시트가 없습니다.</td>
                </tr>
              </table>
            </div>
          ) : (
            <div className="worksheet_list">
              <div
                style={{
                  flex: 1,
                  // alignItems: "right",
                  // justifyContent: "flex-end",
                }}
              >
                {/* <div
                className="btn squear-btn-basic mb40"
                style={{ position: "relative", right: 0 }}
              >
              </div> */}
              </div>
              <table className="table text-center table-theme3">
                <colgroup>
                  <col width="60px" />
                  <col width="120px" />
                  <col width="120px" />
                  <col />
                  <col width="150px" />
                  <col width="120px" />
                  <col width="100px" />
                </colgroup>
                <thead className="thead-blue">
                  <tr>
                    <th className="font-weight-normal">no</th>
                    <th className="font-weight-normal">이름</th>
                    <th className="font-weight-normal">기업명</th>
                    <th className="font-weight-normal">제목</th>
                    <th className="font-weight-normal">멘토</th>
                    <th className="font-weight-normal">제출일</th>
                  </tr>
                </thead>
                {/* {console.log(worksheet)} */}
                <WorksheetList selectUserType={selectUserType} />
              </table>
            </div>
          )
        ) : worksheet == null ? null : worksheet.length < 1 ? (
          <div className="worksheet_list">
            <div
              style={{
                flex: 1,
                alignItems: "right",
                justifyContent: "flex-end",
              }}
            >
              {/* <div
                  className="btn squear-btn-basic mb40"
                  style={{ position: "relative", right: 0 }}
                >
                </div> */}
            </div>
            <table className="table text-center table-theme3">
              <colgroup>
                <col width="60px" />
                <col width="120px" />
                <col width="120px" />
                <col />
                <col width="120px" />
                <col width="120px" />
                <col width="100px" />
              </colgroup>
              <thead className="thead-blue">
                <tr>
                  <th className="font-weight-normal">no</th>
                  <th className="font-weight-normal">이름</th>
                  <th className="font-weight-normal">기업명</th>
                  <th className="font-weight-normal">제목</th>
                  <th className="font-weight-normal">멘티</th>
                  <th className="font-weight-normal">제출일</th>
                </tr>
              </thead>
              {/* {console.log(worksheet)} */}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>연동된 워크시트가 없습니다.</td>
              </tr>
            </table>
          </div>
        ) : (
          <div className="worksheet_list">
            <div
              style={{
                alignItems: "right",
                justifyContent: "flex-end",
              }}
            >
              {/* <div
                  className="btn squear-btn-basic mb40"
                  style={{ position: "relative", right: 0 }}
                >
                </div> */}
            </div>
            <table className="table text-center table-theme3">
              <colgroup>
                <col width="60px" />
                <col width="120px" />
                <col width="120px" />
                <col />
                <col width="120px" />
                <col width="120px" />
                <col width="100px" />
              </colgroup>
              <thead className="thead-blue">
                <tr>
                  <th className="font-weight-normal">no</th>
                  <th className="font-weight-normal">이름</th>
                  <th className="font-weight-normal">기업명</th>
                  <th className="font-weight-normal">제목</th>
                  <th className="font-weight-normal">멘티</th>
                  <th className="font-weight-normal">제출일</th>
                </tr>
              </thead>
              {/* {console.log(worksheet)} */}
              <WorksheetList selectUserType={selectUserType} />
            </table>
          </div>
        )}
      </div>
    </>
  );
};

const stateChangePostModal = () => {
  console.log($(".postModal").css("display"));
  let disp = $(".postModal").css("display");
  if (disp == "block") {
    $(".postModal").css("display", "none");
  } else {
    $(".postModal").css("display", "block");
  }
  //
};

const onComplete = (data, userData, setUserData) => {
  let postCode = "";
  let address = "";
  postCode = data.zonecode;
  address = data.address + " (" + data.buildingName + ")";
  console.log(postCode);
  console.log(address);
  setUserData({
    ...userData,
    postnumber: postCode,
    addressMain: address,
  });
  $(".postModal").css("display", "none");
};

const changeNextSelect = (e) => {
  let val = e.target.value;
  console.log();
  let select = $(e.target).siblings(".select2");
  if (val == "창업") {
    select.find("option[data=10]").show();
  } else if (val == "기획") {
    select.find("option[data=20]").show();
  } else if (val == "마케팅") {
    select.find("option[data=30]").show();
  } else if (val == "조직/인사") {
    select.find("option[data=40]").show();
  } else if (val == "연구개발") {
    select.find("option[data=50]").show();
  }
};

export default MemberWorksheets;
