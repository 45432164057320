import { Link, useRouteMatch } from "react-router-dom";
import "feature/notice/notice.css";
import { useSelector } from "react-redux";
// import image1200x450 from 'https://via.placeholder.com/1200x450?text=notice_photo'

const View = () => {
  const match = useRouteMatch();
  const notice = useSelector(
    (state) =>
      state.notice.noticeList.filter((e) => e.id === match.params.id)[0] || {}
  );

  const renderedPictures = notice.pictures?.map((url, index) => {
    return (
      <div id="bo_v_img" key={index.toString()}>
        <img
          src={url}
          alt
          width={400}
        />
      </div>
    );
  });

  return (
    <div>
      <div className="minwidth">
        {/* 상단 시작 { */}
        {/* <header include-html="/public/header.html" /> */}
        {/* } 상단 끝 */}
        {/*  팝업시작 { */}
        {/* <div include-html="/public/popup.html" /> */}
        {/*  } 팝업시작  */}
        {/* 시장정보 확인하기 Modal { */}
        {/* <div include-html="/public/modal.html" /> */}
        {/* } 시장정보 확인하기 Modal */}
        <hr />
        {/* 콘텐츠 시작 { */}
        <div className="resizeWrap">
          <div className="basicWrap">
            {/* basicWrap */}
            <div
              id="topNav"
              className="d-flex justify-content-start align-items-end pb-2 mb40"
            >
              <h1 className="h1 m-0 font-weight-bold">공지사항</h1>
              <ul id="topNavList" className="d-flex">
                <li className="active">
                  <Link to="/notice/list/">공지사항</Link>
                </li>
                <li className>
                  <Link to="/qna/write/">문의사항</Link>
                </li>
              </ul>
            </div>
            {/* 게시물 읽기 시작 { */}
            <article id="bo_v" style={{ width: "100%" }}>
              <header>
                <h1 className="font-weight-bold h1 title">{notice.title}</h1>
              </header>
              <section id="bo_v_info">
                <h2>페이지 정보</h2>
                <div className="profile_info">
                  <div className="profile_info_ct">
                    <span className="sound_only">작성자</span>
                    <span className="sv_member">{notice.author || ""}</span>
                    <span>
                      <span className="sound_only">작성일</span>
                      {notice.date}
                    </span>
                  </div>
                </div>
              </section>
              <section id="bo_v_atc">
                <h2 id="bo_v_atc_title">본문</h2>
                <div id="bo_v_share" className="d-none" />
                {/* <div id="bo_v_img">
                  <img
                    src="https://via.placeholder.com/1200x450?text=notice_photo"
                    alt
                    width={400}
                  />
                </div>
                <div id="bo_v_img">
                  <img
                    src="https://via.placeholder.com/1200x450?text=notice_photo"
                    alt
                    width={400}
                  />
                </div> */}
                {renderedPictures}
                {/* 본문 내용 시작 { */}
                <div id="bo_v_con">{notice.content}</div>
                {/* } 본문 내용 끝 */}
              </section>
              {/* 게시물 버튼 시작 { */}
              <div id="bo_v_top">
                <ul className="btn_bo_user bo_v_com">
                  <li>
                    <Link
                      to="/notice/list/"
                      className="btn btn-sm squear-btn-border-basic"
                      title="목록"
                    >
                      목록
                    </Link>
                  </li>
                  {/* 관리자 삭제/수정 
                            <li><a href="" class="btn btn-sm squear-btn-border-basic" title="수정">수정</a></li>
                            <li><a href="" class="btn btn-sm squear-btn-border-basic" title="삭제">삭제</a></li>
                            */}
                </ul>
              </div>
              {/* } 게시물 버튼 끝 */}
            </article>
            {/* } 게시판 읽기 끝 */}
          </div>
          {/* container_wr / basicWrap */}
        </div>
        {/* } 콘텐츠 끝 */}
        <hr />
        {/* 하단 시작 { */}
        {/* <footer include-html="/public/footer.html" /> */}
        {/* } 하단 시작  */}
      </div>
    </div>
  );
};

export default View;
