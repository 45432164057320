import { Link, useHistory, useRouteMatch } from "react-router-dom";
import "../Worksheet.css";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { useEffect, useRef, useState } from "react";
//
import * as docx from "docx";
import { saveAs } from "file-saver";
import questions from "../questions.json";
import createAnswerDocxRows from "util/docx/createAnswerDocxRows";
import extractPdf from "util/extractPdf";
import CommentCheck from "util/CommentCheck";

const Worksheet2 = ({ listeners, userType }) => {
  const [answers, setAnswers] = useState({});
  const db = useRef(firebase.firestore()).current;
  const auth = useRef(firebase.auth()).current;
  const [commentVisible, setCommentVisible] = useState(true);
  const match = useRouteMatch();
  const [worksheetId, setWorksheetId] = useState();
  const htmlRef = useRef();

  const [initialAnswers, setInitialAnswers] = useState({});
  const [prevent, setPrevent] = useState(false);

  const history = useHistory();

  const radioDefaultChecked = listeners.radioDefaultChecked(
    answers,
    setAnswers
  );
  const radioChecked = listeners.radioChecked(answers);
  const radioListener = listeners.radioListener(answers, setAnswers);
  const checkboxChecked = listeners.checkboxChecked(answers);
  const checkboxListener = listeners.checkboxListener(answers, setAnswers);
  const textareaValue = listeners.textareaValue(answers);
  const textareaListener = listeners.textareaListener(answers, setAnswers);
  const commentValue = listeners.commentValue(answers);
  const commentListener = listeners.commentListener(answers, setAnswers);
  const fileValue = listeners.fileValue(answers);
  const fileListener = listeners.fileListener(answers, setAnswers);
  const fileDeleteListener = listeners.fileDeleteListener(answers, setAnswers);

  useEffect(() => {
    //
    setWorksheetId(match.params.id);

    db.collection("worksheets")
      .doc(match.params.id)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          return;
        }
        setInitialAnswers(doc.data().questionAnswers);
        setAnswers(doc.data().questionAnswers);
      });
  }, []);

  useEffect(() => {
    console.log(userType);
    // if (userType == "mento") {
      // setCommentVisible(true);
    // }
  }, [userType]);

  // const saveWorksheet = (file = false, inAnswers = answers) => {
  const saveWorksheet = (e) => {
    if (userType === "admin") {
      alert("어드민은 워크시트를 저장할 수 없습니다!");
      return;
    }
    let finalSubmit = false;
    let date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, -1);

    if (worksheetId && worksheetId != "new") {
      const accountInfo =
        userType == "mento"
          ? {
              mento: auth.currentUser.uid,
            }
          : {
              mentee: auth.currentUser.uid,
            };

      db.collection("worksheets")
        .doc(worksheetId)
        .update({
          ...accountInfo,
          questionAnswers: answers,
          page: 2,
          finalSubmit,
          date,
        })
        .then(() => {
          alert("저장 성공!");
          listeners.storeAnswersListener(answers);
        })
        .catch((e) => alert(e));
      return;
    }
    console.log("\nanswers", {
      questionAnswers: {
        ...answers,
      },
      mentee: auth.currentUser.uid,
      mento: "",
    });
    db.collection("worksheets")
      .add({
        questionAnswers: answers,
        mentee: auth.currentUser.uid,
        page:2,
        finalSubmit,
        date
      })
      .then((doc) => {
        setWorksheetId(doc.id);
        alert("저장 성공!");
        listeners.storeAnswersListener(answers);
        history.replace(`/worksheets/${doc.id || "new"}/worksheet4/`);
      })
      .catch((e) => alert(e));
  };
  //
  const extractDocx = () => {
    const doc = new docx.Document();

    const marginOption = {
      margins: {
        left: 70,
      },
    };
    const centerOption = {
      verticalAlign: docx.VerticalAlign.CENTER,
    };
    //
    let dataArray = [];
    //
    console.log(questions[0]);
    Object.keys(questions[1]).map((item, index) => {
      if (item.includes("a_2_5") || item.includes("a_2_6")) {
        dataArray.push(
          createAnswerDocxRows(
            item.toUpperCase() + ". " + questions[1][item],
            answers?.[item] ? answers[item] : "",
            1
          )[0]
        );
        dataArray.push(
          createAnswerDocxRows(
            item.toUpperCase() + ". " + questions[1][item],
            answers?.[item] ? answers[item] : "",
            1,
            "radio"
          )[1]
        );
      } else {
        dataArray.push(
          createAnswerDocxRows(
            item.toUpperCase() + ". " + questions[1][item],
            answers?.[item] ? answers[item] : "",
            1
          )[0]
        );
        dataArray.push(
          createAnswerDocxRows(
            item.toUpperCase() + ". " + questions[1][item],
            answers?.[item] ? answers[item] : "",
            1,
            "textarea"
          )[1]
        );
      }
    });
    console.log(dataArray);
    const table = new docx.Table({
      width: {
        size: 100,
        type: docx.WidthType.PERCENTAGE,
      },
      rows: dataArray,
      // .reduce((acc, cur) => acc.concat(cur), []),
    });

    doc.addSection({
      children: [table],
    });
    docx.Packer.toBlob(doc).then((blob) => {
      saveAs(blob);
    });
  };

  return (
    <div>
      <div className="minwidth">
        {/*  콘텐츠 시작 { */}
        <div
          className="modal fade main_modal pb-4 memtoListPopup"
          id="exampleModal1"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header" style={{ border: 0 }}>
                <h5 className="modal-title" id="exampleModalLabel" />
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row text-center">
                  <div className="col-12">
                    <h4 className="h4">
                      저장해야 다음으로 넘어갈 수 있습니다.
                    </h4>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {/* <button
                type="button"
                className="btn btn-sm squear-btn-border-basic"
                data-dismiss="modal"
              >
                취소
              </button> */}
                <button
                  type="button"
                  className="btn btn-sm squear-btn-basic"
                  id="applyFinish"
                  data-dismiss="modal"
                  onClick={() => {
                    let modal = document.getElementById("exampleModal1");
                    function removeClass(element, className) {
                      var check = new RegExp("(\\s|^)" + className + "(\\s|$)");
                      element.className = element.className
                        .replace(check, " ")
                        .trim();
                    }
                    removeClass(modal, "show");
                  }}
                >
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
        <div id="worksheet">
          {/* 상단 시작 { */}
          {/* } nav 끝 */}
          <div className="basicWrap">
            <section className="d-flex justify-content-between youtube align-items-center px-5">
              <h2 className="h2 font-weight-normal">
                작성하는 법을 잘 모르겠다면? youtube 시청 후 작성하기
              </h2>
              <a
                className="btn btn-sm font-weight-normal squear-btn-basic radius pr-4 pl-4"
                href
              >
                과정설명 영상 보러가기
              </a>
            </section>
            <div style={{ marginTop: 22, borderBottom: "1px solid #aaa" }}>
              <span style={{ fontSize: 12, color: "#aaa", marginTop: 22 }}>
                STEP1. 아이디어 설명 {">"} 아이디어 설명
              </span>
            </div>
            <form action className="dataForm mt40" ref={htmlRef}>
              {/* a_2_5 */}
              <input
                type="radio"
                name="a_2_5"
                id="a_2_5_1"
                className="d-none"
                checked={radioDefaultChecked("a_2_5", "1.자체생산")}
                onChange={radioListener("a_2_5", "1.자체생산")}
              />
              <input
                type="radio"
                name="a_2_5"
                id="a_2_5_2"
                className="d-none"
                checked={radioChecked("a_2_5", "2.위탁생산")}
                onChange={radioListener("a_2_5", "2.위탁생산")}
              />
              <input
                type="radio"
                name="a_2_5"
                id="a_2_5_3"
                className="d-none"
                checked={radioChecked("a_2_5", "3.자체생산+위탁생산")}
                onChange={radioListener("a_2_5", "3.자체생산+위탁생산")}
              />
              {/* a_2_6 */}
              <input
                type="radio"
                name="a_2_6"
                id="a_2_6_1"
                className="d-none"
                checked={radioDefaultChecked("a_2_6", "1.자체판매")}
                onChange={radioListener("a_2_6", "1.자체판매")}
              />
              <input
                type="radio"
                name="a_2_6"
                id="a_2_6_2"
                className="d-none"
                checked={radioChecked("a_2_6", "2.위탁판매")}
                onChange={radioListener("a_2_6", "2.위탁판매")}
              />
              <input
                type="radio"
                name="a_2_6"
                id="a_2_6_3"
                className="d-none"
                checked={radioChecked("a_2_6", "3.자체판매+위탁판매")}
                onChange={radioListener("a_2_6", "3.자체판매+위탁판매")}
              />
              <section className="section">
                <div className="question">
                  A-2-1. 제품/서비스 사양 및 형태를 간략하게 설명해주세요.
                  <span className="text-danger">*</span>
                </div>
                <div className="answer d-flex">
                  <div className="w-25 d-flex align-items-center disabled px-1">
                    {answers?.["a_1_6"] ? answers?.["a_1_6"] : "연동A_1_6"}
                  </div>
                  {/* a_2_1 */}
                  <textarea
                    name="a_2_1"
                    id="a_2_1"
                    className="col textareaForm"
                    value={textareaValue("a_2_1", 1)}
                    onChange={textareaListener("a_2_1", 1)}
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto"
                      e.currentTarget.style.height = e.currentTarget.scrollHeight + "px";
                    }}
                  />
                </div>
                <div className="d-flex">
                  <input
                    type="file"
                    accept=".jpeg,.png"
                    id="file"
                    className="d-none"
                    onChange={fileListener("a_2_1_file", 1)}
                  />
                  <label htmlFor="file" className="w-25 text-center fileupload">
                    + 이미지 업로드
                  </label>
                  <div style={{ alignItems: "center", display: "flex" }}>
                    {/* &nbsp;&nbsp; */}
                    {fileValue("a_2_1_file", 1) ? (
                      <>
                        <img
                          height={100}
                          width={100}
                          src={fileValue("a_2_1_file", 1)}
                          alt={"no image"}
                        ></img>
                        <div
                          onClick={fileDeleteListener("a_2_1_file", 1)}
                          className="btn btn-sm squear-btn-basic pr-2 pl-2 p-1"
                        >
                          삭제
                        </div>
                      </>
                    ) : (
                      <div>이미지가 없습니다</div>
                    )}
                  </div>
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("a_2_1", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="a_2_1_comment"
                    id="a_2_1_comment"
                    className="textareaForm"
                    disabled={userType == 'mento' ? false:true}
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    value={commentValue("a_2_1", 1)}
                    onChange={commentListener("a_2_1", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question">
                  A-2-2. 예상 고객은 누구입니까?
                  <span className="text-danger">*</span>
                </div>
                <div className="answer d-flex">
                  <div className="w-25 d-flex align-items-center disabled px-1">
                    {answers?.["a_1_2"] ? answers?.["a_1_2"] : "연동A_1_2"}
                  </div>
                  {/* a_2_2 */}
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="a_2_2"
                    id="a_2_2"
                    className="col textareaForm"
                    value={textareaValue("a_2_2", 1)}
                    onChange={textareaListener("a_2_2", 1)}
                  />
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("a_2_2", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="a_2_2_comment"
                    id="a_2_2_comment"
                    className="textareaForm"
                    disabled={userType == 'mento' ? false:true}
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    value={commentValue("a_2_2", 1)}
                    onChange={commentListener("a_2_2", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question">
                  A-2-3. 이 제품/서비스를 사용하는 이유는 무엇입니까?
                  <span className="text-danger">*</span>{" "}
                </div>
                <div className="answer d-flex">
                  <div className="w-25 d-flex align-items-center disabled px-1">
                    {answers?.["a_1_3"] ? answers?.["a_1_3"] : "연동A_1_3"}
                  </div>
                  {/* a_2_3 */}
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="a_2_3"
                    id="a_2_3"
                    className="col textareaForm"
                    value={textareaValue("a_2_3", 1)}
                    onChange={textareaListener("a_2_3", 1)}
                  />
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("a_2_3", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="a_2_3_comment"
                    id="a_2_3_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == 'mento' ? false:true}
                    value={commentValue("a_2_3", 1)}
                    onChange={commentListener("a_2_3", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question">
                  A-2-4. 이 제품/서비스 사용시 예상 고객은 어떤 혜택이 있습니까?
                  <span className="text-danger">*</span>
                </div>
                <div className="answer">
                  {/* a_2_4 */}
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="a_2_4"
                    id="a_2_4"
                    className="textareaForm"
                    value={textareaValue("a_2_4", 1)}
                    onChange={textareaListener("a_2_4", 1)}
                  />
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("a_2_4", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="a_2_4_comment"
                    id="a_2_4_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == 'mento' ? false:true}
                    value={commentValue("a_2_4", 1)}
                    onChange={commentListener("a_2_4", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question">
                  A-2-5. 이 제품/서비스를 생산하는 방식은 무엇입니까?
                  <span className="text-danger">*</span>
                </div>
                <div className="answer">
                  <ul className="row">
                    <li className="col-2">
                      <label htmlFor="a_2_5_1" className="a_2_5_1 radio">
                        <span />
                        1.자체생산
                      </label>
                    </li>
                    <li className="col-2">
                      <label htmlFor="a_2_5_2" className="a_2_5_2 radio">
                        <span />
                        2.위탁생산
                      </label>
                    </li>
                    <li className="col-2">
                      <label htmlFor="a_2_5_3" className="a_2_5_3 radio">
                        <span />
                        3.자체생산+위탁생산
                      </label>
                    </li>
                  </ul>
                </div>
              </section>
              <section className="section">
                <div className="question">
                  A-2-6. 이 제품/서비스를 판매(유통)하는 방식은 무엇입니까?
                  <span className="text-danger">*</span>
                </div>
                <div className="answer">
                  <ul className="row">
                    <li className="col-2">
                      <label htmlFor="a_2_6_1" className="a_2_6_1 radio">
                        <span />
                        1.자체판매
                      </label>
                    </li>
                    <li className="col-2">
                      <label htmlFor="a_2_6_2" className="a_2_6_2 radio">
                        <span />
                        2.위탁판매
                      </label>
                    </li>
                    <li className="col-2">
                      <label htmlFor="a_2_6_3" className="a_2_6_3 radio">
                        <span />
                        3.자체판매+위탁판매
                      </label>
                    </li>
                  </ul>
                </div>
              </section>
              <section className="section">
                <div className="question">
                  A-2-7. 이 제품/서비스의 핵심기능은 무엇입니까?(1순위)
                  <span className="text-danger">*</span>
                </div>
                <div className="answer">
                  {/* a_2_7 */}
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="a_2_7"
                    id="a_2_7"
                    className="textareaForm"
                    value={textareaValue("a_2_7", 1)}
                    onChange={textareaListener("a_2_7", 1)}
                  />
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("a_2_7", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="a_2_7_comment"
                    id="a_2_7_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == 'mento' ? false:true}
                    value={commentValue("a_2_7", 1)}
                    onChange={commentListener("a_2_7", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question">
                  A-2-8. 이 제품/서비스의 핵심기능은 무엇입니까?(2순위)
                  {/* <span className="text-danger">*</span> */}
                </div>
                <div className="answer">
                  {/* a_2_8 */}
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="a_2_8"
                    id="a_2_8"
                    className="textareaForm"
                    value={textareaValue("a_2_8", 1)}
                    onChange={textareaListener("a_2_8", 1)}
                  />
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("a_2_8", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="a_2_8_comment"
                    id="a_2_8_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == 'mento' ? false:true}
                    value={commentValue("a_2_8", 1)}
                    onChange={commentListener("a_2_8", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question">
                  A-2-9. 이 제품/서비스의 핵심기능은 무엇입니까?(3순위)
                  {/* <span className="text-danger">*</span> */}
                </div>
                <div className="answer">
                  {/* a_2_9 */}
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="a_2_9"
                    id="a_2_9"
                    className="textareaForm"
                    value={textareaValue("a_2_9", 1)}
                    onChange={textareaListener("a_2_9", 1)}
                  />
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("a_2_9", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="a_2_9_comment"
                    id="a_2_9_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == 'mento' ? false:true}
                    value={commentValue("a_2_9", 1)}
                    onChange={commentListener("a_2_9", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question">
                  A-2-10. 이 제품/서비스의 예상 가격은 얼마입니까?
                  <span className="text-danger">*</span>
                </div>
                <div className="answer">
                  {/* a_2_10 */}
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="a_2_10"
                    id="a_2_10"
                    className="textareaForm"
                    value={textareaValue("a_2_10", 1)}
                    onChange={textareaListener("a_2_10", 1)}
                  />
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("a_2_10", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="a_2_10_comment"
                    id="a_2_10_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == 'mento' ? false:true}
                    value={commentValue("a_2_10", 1)}
                    onChange={commentListener("a_2_10", 1)}
                  />
                </div>
              </section>
            </form>
            <section className="btnArea d-flex align-items-center justify-content-end">
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={() => extractPdf(htmlRef.current)}
              >
                PDF
              </button>
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={extractDocx}
              >
                다운로드
              </button>
              <div className="btn btn-sm squear-btn-border-basic mr-2">
                <Link to={`/worksheets/${worksheetId || "new"}/worksheet1/`}>
                  이전으로
                </Link>
              </div>
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={saveWorksheet}
              >
                저장하기
              </button>
              {/* <div className="btn btn-sm squear-btn-basic">
                <Link to={`/worksheets/${(worksheetId || "new")}/worksheet3/`}>
                  다음으로
                </Link>
              </div> */}
              <div
                className="btn btn-sm squear-btn-basic "
                onClick={() => {
                  let keyArray = [
                    "a_2_1",
                    // "a_2_1_file1",
                    "a_2_2",
                    "a_2_3",
                    "a_2_4",
                    "a_2_5",
                    "a_2_6",
                    "a_2_7",
                    // "a_2_8",
                    // "a_2_9",
                    "a_2_10",
                  ];
                  listeners.checkValue(keyArray, answers, worksheetId, 3);
                }}
              >
                <Link>다음으로</Link>
              </div>
            </section>
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default Worksheet2;
