import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

// 홈 배너 이미지 업로드 함수
export const updateBanner = (banner) => (dispatch, getState) => {
    const storage = firebase.storage();
    const db = firebase.firestore()
    // 
    storage
      .ref(
        "admin/banner/banner"
      )
      .put(banner)
      .then((snapshot) => {
        return snapshot.ref.getDownloadURL();
      })
      .then((url) => {
        if(url){
          alert('저장되었습니다.')
        }
      })
      .catch((e) => console.log("err", e));
}

export const imagePreview = (e = false) => (dispatch, getState) => {
    const db = firebase.firestore()
    if(e){
        let reader = new FileReader()
        reader.readAsDataURL(e.target.files[0])
        reader.onloadend = () => {
            document.getElementById('banner_file').style.backgroundImage='url("'+reader.result+'")';
        }
    }else{
        db.collection("banner").doc('1').get()
        .then((doc) => {
            if(doc.exists){
                let banner = doc.data()
                document.getElementById('banner_file').style.backgroundImage='url("'+banner.url+'")';
            }
        })
    }
}

