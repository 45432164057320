import { Link, useHistory, useRouteMatch } from "react-router-dom";
import "../Worksheet.css";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
//
import * as docx from "docx";
import { saveAs } from "file-saver";
import questions from "../questions.json";
import createAnswerDocxRows from "util/docx/createAnswerDocxRows";
import extractPdf from "util/extractPdf";

const Worksheet1 = ({ userType, listeners }) => {
  const [answers, setAnswers] = useState({});
  const db = useRef(firebase.firestore()).current;
  let match = useRouteMatch();
  const [worksheetId, setWorksheetId] = useState();
  const auth = useRef(firebase.auth()).current;
  const [commentVisible, setCommentVisible] = useState(false);
  const history = useHistory();
  const [worksheetTitle, setWorksheetTitle] = useState("");
  const htmlRef = useRef();
  //
  const radioDefaultChecked = listeners.radioDefaultChecked(
    answers,
    setAnswers
  );
  const radioChecked = listeners.radioChecked(answers);
  const radioListener = listeners.radioListener(answers, setAnswers);
  const checkboxChecked = listeners.checkboxChecked(answers);
  const checkboxListener = listeners.checkboxListener(answers, setAnswers);
  const textareaValue = listeners.textareaValue(answers);
  const textareaListener = listeners.textareaListener(answers, setAnswers);
  const commentValue = listeners.commentValue(answers);
  const commentListener = listeners.commentListener(answers, setAnswers);

  useEffect(() => {
    console.log(userType);
    if (userType == "mento") {
      setCommentVisible(true);
    }
  }, [userType]);

  //
  useEffect(() => {
    setWorksheetId(match.params.id);

    db.collection("worksheets")
      .doc(match.params.id)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          return;
        }
        console.log(doc.data());
        setWorksheetTitle(doc.data().title);
        listeners.storeAnswersListener(doc.data().questionAnswers);
        setAnswers(doc.data().questionAnswers);
      });
  }, []);

  const saveWorksheet = (e) => {
    if (userType === "admin") {
      alert("어드민은 워크시트를 저장할 수 없습니다!");
      return;
    }
    let date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, -1);
    if (worksheetId && worksheetId != "new") {
      const accountInfo =
        userType == "mento"
          ? {
              mento: auth.currentUser.uid,
            }
          : {
              mentee: auth.currentUser.uid,
            };
      console.log(accountInfo);
      if (userType == "mentee") {
        db.collection("users")
          .doc(accountInfo.mentee)
          .get()
          .then((doc) => {
            if (doc.exists) {
              db.collection("worksheets")
                .doc(worksheetId)
                .update({
                  ...accountInfo,
                  questionAnswers: answers,
                  page: 1,
                  title: worksheetTitle,
                  menteeName: doc.data().name,
                  companyName: doc.data().companyName,
                  date,
                })
                .then(() => {
                  alert("저장 성공!");
                  listeners.storeAnswersListener(answers);
                })
                .catch((e) => alert(e));
            }
          });
      } else {
        db.collection("worksheets")
          .doc(worksheetId)
          .update({
            ...accountInfo,
            questionAnswers: answers,
            page: 1,
            title: worksheetTitle,
            date,
          })
          .then(() => {
            alert("저장 성공!");
            listeners.storeAnswersListener(answers);
          })
          .catch((e) => alert(e));
      }

      return;
    }
    console.log("\nanswers", {
      questionAnswers: {
        ...answers,
      },
      mentee: auth.currentUser.uid,
      mento: "",
      page: 1,
      endPage: 1,
    });
    if (userType == "mentee") {
      db.collection("users")
        .doc(auth.currentUser.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            db.collection("worksheets")
              .add({
                questionAnswers: answers,
                mentee: auth.currentUser.uid,
                page: 1,
                title: worksheetTitle,
                menteeName: doc.data().name,
                finalSubmit: false,
                date,
              })
              .then((doc) => {
                setWorksheetId(doc.id);
                alert("저장 성공!");
                listeners.storeAnswersListener(answers);
                // setInitialAnswers(answers)
                history.replace(`/worksheets/${doc.id || "new"}/worksheet1/`);
              })
              .catch((e) => alert(e));
          }
        });
    } else {
      db.collection("worksheets")
        .add({
          questionAnswers: answers,
          mentee: auth.currentUser.uid,
          page: 1,
          title: worksheetTitle,
          finalSubmit: false,
          date,
        })
        .then((doc) => {
          setWorksheetId(doc.id);
          alert("저장 성공!");
          listeners.storeAnswersListener(answers);
          // setInitialAnswers(answers)
          history.replace(`/worksheets/${doc.id || "new"}/worksheet1/`);
        })
        .catch((e) => alert(e));
    }
    // db.collection("worksheets")
    //   .add({
    //     questionAnswers: answers,
    //     mentee: auth.currentUser.uid,
    //     page: 1,
    //     title: worksheetTitle,
    //   })
    //   .then((doc) => {
    //     setWorksheetId(doc.id);
    //     alert("저장 성공!");
    //     listeners.storeAnswersListener(answers);
    //     // setInitialAnswers(answers)
    //     history.replace(`/worksheets/${doc.id || "new"}/worksheet1/`);
    //   })
    //   .catch((e) => alert(e));
  };

  const extractDocx = () => {
    const doc = new docx.Document();

    const marginOption = {
      margins: {
        left: 70,
      },
    };
    const centerOption = {
      verticalAlign: docx.VerticalAlign.CENTER,
    };
    //
    let dataArray = [
      new docx.TableRow({
        height: {
          height: 500,
          rule: docx.HeightRule.ATLEAST,
        },
        children: [
          new docx.TableCell({
            ...marginOption,
            ...centerOption,
            children: [new docx.Paragraph(listeners.jsonTestQ["a_1_1_a"])],
          }),
        ],
      }),
      new docx.TableRow({
        children: [
          new docx.TableCell({
            ...marginOption,
            ...centerOption,
            columnSpan: 3,
            children: [
              new docx.Paragraph(answers["a_1_1_a"]),
              answers["a_1_1_b"]
                ? new docx.Paragraph(
                    " " +
                      answers["a_1_1_b"] +
                      " " +
                      (answers["a_1_1_c"] ? ": " + answers["a_1_1_c"] : "")
                  )
                : "",
            ],
          }),
        ],
      }),
    ];
    //
    console.log(questions[0]);
    Object.keys(questions[0]).map((item, index) => {
      if (item.includes("a_1_1")) return;
      //
      dataArray.push(
        createAnswerDocxRows(
          item.toUpperCase() + ". " + questions[0][item],
          answers?.[item] ? answers[item] : "",
          3
        )[0]
      );
      dataArray.push(
        createAnswerDocxRows(
          item.toUpperCase() + ". " + questions[0][item],
          answers?.[item] ? answers[item] : "",
          3
        )[1]
      );
    });
    console.log(dataArray);
    const table = new docx.Table({
      width: {
        size: 100,
        type: docx.WidthType.PERCENTAGE,
      },
      rows: dataArray,
      // .reduce((acc, cur) => acc.concat(cur), []),
    });

    doc.addSection({
      properties: {},
      children: [table],
    });
    docx.Packer.toBlob(doc).then((blob) => {
      saveAs(blob);
    });
  };

  return (
    <div>
      <div
        className="modal fade main_modal pb-4 memtoListPopup"
        id="exampleModal1"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header" style={{ border: 0 }}>
              <h5 className="modal-title" id="exampleModalLabel" />
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row text-center">
                <div className="col-12">
                  <h4 className="h4">저장해야 다음으로 넘어갈 수 있습니다.</h4>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {/* <button
                type="button"
                className="btn btn-sm squear-btn-border-basic"
                data-dismiss="modal"
              >
                취소
              </button> */}
              <button
                type="button"
                className="btn btn-sm squear-btn-basic"
                id="applyFinish"
                data-dismiss="modal"
                onClick={() => {
                  let modal = document.getElementById("exampleModal1");
                  function removeClass(element, className) {
                    var check = new RegExp("(\\s|^)" + className + "(\\s|$)");
                    element.className = element.className
                      .replace(check, " ")
                      .trim();
                  }
                  removeClass(modal, "show");
                }}
              >
                확인
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="minwidth">
        {/*  콘텐츠 시작 { */}
        {/* } nav 끝 */}
        <div className="basicWrap">
          <section className="d-flex justify-content-between youtube align-items-center px-5">
            <h2 className="h2 font-weight-normal">
              작성하는 법을 잘 모르겠다면? youtube 시청 후 작성하기
            </h2>
            <Link
              className="btn btn-sm font-weight-normal squear-btn-basic radius pr-4 pl-4"
              to=""
            >
              과정설명 영상 보러가기
            </Link>
          </section>
          <div style={{ marginTop: 22, borderBottom: "1px solid #aaa" }}>
            <span style={{ fontSize: 12, color: "#aaa", marginTop: 22 }}>
              STEP1. 아이디어 설명 {">"} 의도워크시트
            </span>
          </div>
          <form action className="dataForm mt40" ref={htmlRef}>
            {/* A-1-1 */}
            <input
              type="radio"
              name="a_1_1_a"
              id="a_1_1_1"
              className="d-none"
              checked={radioDefaultChecked("a_1_1_a", "1.아이디어 도출화")}
              onChange={radioListener("a_1_1_a", "1.아이디어 도출화")}
            />
            <input
              type="radio"
              name="a_1_1_a"
              id="a_1_1_2"
              className="d-none"
              checked={radioChecked("a_1_1_a", "2.아이디어 고도화")}
              onChange={radioListener("a_1_1_a", "2.아이디어 고도화")}
            />
            <input
              type="radio"
              name="a_1_1_a"
              id="a_1_1_3"
              className="d-none"
              checked={radioChecked("a_1_1_a", "3.사업계획서 작성")}
              onChange={radioListener("a_1_1_a", "3.사업계획서 작성")}
            />
            <input
              type="radio"
              name="a_1_1_a"
              id="a_1_1_4"
              className="d-none"
              checked={radioChecked("a_1_1_a", "4.BM 모델 수립")}
              onChange={radioListener("a_1_1_a", "4.BM 모델 수립")}
            />
            <input
              type="radio"
              name="a_1_1_a"
              id="a_1_1_5"
              className="d-none"
              checked={radioChecked("a_1_1_a", "5.시제품제작")}
              onChange={radioListener("a_1_1_a", "5.시제품제작")}
            />
            <input
              type="checkbox"
              name="a_1_1_b"
              id="a_1_1_6"
              className="d-none"
              checked={radioChecked("a_1_1_b", "6.기타")}
              onChange={radioListener("a_1_1_b", "6.기타")}
            />

            {/* A-1-2 */}
            <input
              type="radio"
              name="a_1_2"
              id="a_1_2_1"
              className="d-none"
              checked={radioDefaultChecked(
                "a_1_2",
                "1.B2B(기업과 기업간 거래)"
              )}
              onChange={radioListener("a_1_2", "1.B2B(기업과 기업간 거래)")}
            />
            <input
              type="radio"
              name="a_1_2"
              id="a_1_2_2"
              className="d-none"
              checked={radioChecked("a_1_2", "2.B2C(기업과 소비자간 거래)")}
              onChange={radioListener("a_1_2", "2.B2C(기업과 소비자간 거래)")}
            />
            <input
              type="radio"
              name="a_1_2"
              id="a_1_2_3"
              className="d-none"
              checked={radioChecked("a_1_2", "3.B2G(기업과 정부간 거래)")}
              onChange={radioListener("a_1_2", "3.B2G(기업과 정부간 거래)")}
            />
            {/* A-1-3 */}
            <input
              type="radio"
              name="a_1_3"
              id="a_1_3_1"
              className="d-none"
              checked={radioDefaultChecked("a_1_3", "1.불편점 해결")}
              onChange={radioListener("a_1_3", "1.불편점 해결")}
            />
            <input
              type="radio"
              name="a_1_3"
              id="a_1_3_2"
              className="d-none"
              checked={radioChecked("a_1_3", "2.새로운 욕구 충족")}
              onChange={radioListener("a_1_3", "2.새로운 욕구 충족")}
            />
            {/* A-1-5 */}
            <input
              type="radio"
              name="a_1_6"
              id="a_1_6_1"
              className="d-none"
              checked={radioDefaultChecked("a_1_6", "1.제품")}
              onChange={radioListener("a_1_6", "1.제품")}
            />
            <input
              type="radio"
              name="a_1_6"
              id="a_1_6_2"
              className="d-none"
              checked={radioChecked("a_1_6", "2.서비스")}
              onChange={radioListener("a_1_6", "2.서비스")}
            />
            <input
              type="radio"
              name="a_1_6"
              id="a_1_6_3"
              className="d-none"
              checked={radioChecked("a_1_6", "3.제품+서비스 혼합")}
              onChange={radioListener("a_1_6", "3.제품+서비스 혼합")}
            />
            {/* 5 */}
            <input
              type="checkbox"
              name="a_1_8"
              id="a_1_8_1"
              className="d-none"
              checked={checkboxChecked("a_1_8", "1.기술")}
              onChange={checkboxListener("a_1_8", "1.기술")}
            />
            <input
              type="checkbox"
              name="a_1_8"
              id="a_1_8_2"
              className="d-none"
              checked={checkboxChecked("a_1_8", "2.자금")}
              onChange={checkboxListener("a_1_8", "2.자금")}
            />
            <input
              type="checkbox"
              name="a_1_8"
              id="a_1_8_3"
              className="d-none"
              checked={checkboxChecked("a_1_8", "3.인적자원")}
              onChange={checkboxListener("a_1_8", "3.인적자원")}
            />
            <input
              type="checkbox"
              name="a_1_8"
              id="a_1_8_4"
              className="d-none"
              checked={checkboxChecked("a_1_8", "4.생산")}
              onChange={checkboxListener("a_1_8", "4.생산")}
            />
            <input
              type="checkbox"
              name="a_1_8"
              id="a_1_8_5"
              className="d-none"
              checked={checkboxChecked("a_1_8", "5.마케팅")}
              onChange={checkboxListener("a_1_8", "5.마케팅")}
            />
            <input
              type="checkbox"
              name="a_1_8"
              id="a_1_8_6"
              className="d-none"
              checked={checkboxChecked("a_1_8", "6.유통")}
              onChange={checkboxListener("a_1_8", "6.유통")}
            />
            {/* 6 */}
            <input
              type="checkbox"
              name="a_1_9"
              id="a_1_9_1"
              className="d-none"
              checked={checkboxChecked("a_1_9", "1.기술")}
              onChange={checkboxListener("a_1_9", "1.기술")}
            />
            <input
              type="checkbox"
              name="a_1_9"
              id="a_1_9_2"
              className="d-none"
              checked={checkboxChecked("a_1_9", "2.자금")}
              onChange={checkboxListener("a_1_9", "2.자금")}
            />
            <input
              type="checkbox"
              name="a_1_9"
              id="a_1_9_3"
              className="d-none"
              checked={checkboxChecked("a_1_9", "3.인적자원")}
              onChange={checkboxListener("a_1_9", "3.인적자원")}
            />
            <input
              type="checkbox"
              name="a_1_9"
              id="a_1_9_4"
              className="d-none"
              checked={checkboxChecked("a_1_9", "4.생산")}
              onChange={checkboxListener("a_1_9", "4.생산")}
            />
            <input
              type="checkbox"
              name="a_1_9"
              id="a_1_9_5"
              className="d-none"
              checked={checkboxChecked("a_1_9", "5.마케팅")}
              onChange={checkboxListener("a_1_9", "5.마케팅")}
            />
            <input
              type="checkbox"
              name="a_1_9"
              id="a_1_9_6"
              className="d-none"
              checked={checkboxChecked("a_1_9", "6.유통")}
              onChange={checkboxListener("a_1_9", "6.유통")}
            />
            <section className="section">
              <div className="question">
                워크시트 제목
                <span className="text-danger">*</span>
              </div>
              <div className="answer">
                {/* a_2_4 */}
                <textarea
                  onKeyUp={(e) => {
                    e.currentTarget.style.height = "auto";
                    e.currentTarget.style.height =
                      e.currentTarget.scrollHeight + 2 + "px";
                  }}
                  name="title"
                  id="title"
                  className="textareaForm"
                  value={worksheetTitle}
                  onChange={(e) => setWorksheetTitle(e.target.value)}
                />
              </div>
            </section>
            <section className="section">
              <div className="question">
                A-1-1. 본 과정을 통해 하고자 하는 것은 무엇인가?
                <span className="text-danger">*</span>
              </div>
              <div className="answer">
                <ul className="row">
                  <li className="col-2">
                    <label htmlFor="a_1_1_1" className="a_1_1_1 radio">
                      <span />
                      1.아이디어 도출화
                    </label>
                  </li>
                  <li className="col-2">
                    <label htmlFor="a_1_1_2" className="a_1_1_2 radio">
                      <span />
                      2.아이디어 고도화
                    </label>
                  </li>
                  <li className="col-2">
                    <label htmlFor="a_1_1_3" className="a_1_1_3 radio">
                      <span />
                      3.사업계획서 작성
                    </label>
                  </li>
                  <li className="col-2">
                    <label htmlFor="a_1_1_4" className="a_1_1_4 radio">
                      <span />
                      4.BM 모델 수립
                    </label>
                  </li>
                  <li className="col-2">
                    <label htmlFor="a_1_1_5" className="a_1_1_5 radio">
                      <span />
                      5.시제품제작
                    </label>
                  </li>
                  <li className="col-2">
                    <label
                      htmlFor="a_1_1_6"
                      className="a_1_1_6 checkbox createText"
                      data-name="a_1_1_6"
                      data={1}
                    >
                      <span />
                      6.기타
                    </label>
                  </li>
                  <li className="col-12 h-0 a_1_1_6_text">
                    <input
                      type="text"
                      className="inputTextForm"
                      name="a_1_1_c"
                      id="a_1_1"
                      placeholder="기타사항을 적어주세요."
                      value={textareaValue("a_1_1_c", "a_1_1_c")}
                      onChange={textareaListener("a_1_1_c", "a_1_1_c")}
                    />
                  </li>
                </ul>
              </div>
            </section>
            <section className="section">
              <div className="question">
                A-1-2. 어떤 시장에 접근하고자 하십니까?
                <span className="text-danger">*</span>{" "}
              </div>
              <div className="answer">
                <ul className="row">
                  <li className="col-3">
                    <label htmlFor="a_1_2_1" className="a_1_2_1 radio">
                      <span />
                      1.B2B(기업과 기업간 거래)
                    </label>
                  </li>
                  <li className="col-3">
                    <label htmlFor="a_1_2_2" className="a_1_2_2 radio">
                      <span />
                      2.B2C(기업과 소비자간 거래)
                    </label>
                  </li>
                  <li className="col-3">
                    <label htmlFor="a_1_2_3" className="a_1_2_3 radio">
                      <span />
                      3.B2G(기업과 정부간 거래)
                    </label>
                  </li>
                </ul>
              </div>
            </section>
            <section className="section">
              <div className="question">
                A-1-3. 예상 고객이 원하는 것은 무엇이라고 생각하십니까?
                <span className="text-danger">*</span>{" "}
              </div>
              <div className="answer">
                <ul className="row">
                  <li className="col-2">
                    <label htmlFor="a_1_3_1" className="a_1_3_1 radio">
                      <span />
                      1.불편점 해결
                    </label>
                  </li>
                  <li className="col-2">
                    <label htmlFor="a_1_3_2" className="a_1_3_2 radio">
                      <span />
                      2.새로운 욕구 충족
                    </label>
                  </li>
                </ul>
              </div>
            </section>
            <section className="section">
              <div className="question">
                A-1-6. 어떤 형태로 예상고객에게 제품/서비스를 제공할 것입니까?
                <span className="text-danger">*</span>
              </div>
              <div className="answer">
                <ul className="row">
                  <li className="col-2">
                    <label htmlFor="a_1_6_1" className="a_1_6_1 radio">
                      <span />
                      1.제품
                    </label>
                  </li>
                  <li className="col-2">
                    <label htmlFor="a_1_6_2" className="a_1_6_2 radio">
                      <span />
                      2.서비스
                    </label>
                  </li>
                  <li className="col-2">
                    <label htmlFor="a_1_6_3" className="a_1_6_3 radio">
                      <span />
                      3.제품+서비스 혼합
                    </label>
                  </li>
                </ul>
              </div>
            </section>
            <section className="section">
              <div className="question">
                A-1-9. 어떤 자원을 보유하고 계십니까?
                <span className="text-danger">*</span>
              </div>
              <div className="answer">
                <ul className="row">
                  <li className="col-2">
                    <label htmlFor="a_1_9_1" className="a_1_9_1 checkbox">
                      <span />
                      1.기술
                    </label>
                  </li>
                  <li className="col-2">
                    <label htmlFor="a_1_9_2" className="a_1_9_2 checkbox">
                      <span />
                      2.자금
                    </label>
                  </li>
                  <li className="col-2">
                    <label htmlFor="a_1_9_3" className="a_1_9_3 checkbox">
                      <span />
                      3.인적자원
                    </label>
                  </li>
                  <li className="col-2">
                    <label htmlFor="a_1_9_4" className="a_1_9_4 checkbox">
                      <span />
                      4.생산
                    </label>
                  </li>
                  <li className="col-2">
                    <label htmlFor="a_1_9_5" className="a_1_9_5 checkbox">
                      <span />
                      5.마케팅
                    </label>
                  </li>
                  <li className="col-2">
                    <label htmlFor="a_1_9_6" className="a_1_9_6 checkbox">
                      <span />
                      6.유통
                    </label>
                  </li>
                </ul>
              </div>
            </section>
            <section className="section">
              <div className="question">
                A-1-8. 어떤 자원을 필요로 하십니까?
                <span className="text-danger">*</span>
              </div>
              <div className="answer">
                <ul className="row">
                  <li className="col-2">
                    <label htmlFor="a_1_8_1" className="a_1_8_1 checkbox">
                      <span />
                      1.기술
                    </label>
                  </li>
                  <li className="col-2">
                    <label htmlFor="a_1_8_2" className="a_1_8_2 checkbox">
                      <span />
                      2.자금
                    </label>
                  </li>
                  <li className="col-2">
                    <label htmlFor="a_1_8_3" className="a_1_8_3 checkbox">
                      <span />
                      3.인적자원
                    </label>
                  </li>
                  <li className="col-2">
                    <label htmlFor="a_1_8_4" className="a_1_8_4 checkbox">
                      <span />
                      4.생산
                    </label>
                  </li>
                  <li className="col-2">
                    <label htmlFor="a_1_8_5" className="a_1_8_5 checkbox">
                      <span />
                      5.마케팅
                    </label>
                  </li>
                  <li className="col-2">
                    <label htmlFor="a_1_8_6" className="a_1_8_6 checkbox">
                      <span />
                      6.유통
                    </label>
                  </li>
                </ul>
              </div>
            </section>
          </form>
          <section className="btnArea d-flex align-items-center justify-content-end">
            <button
              className="btn btn-sm squear-btn-border-basic mr-2"
              onClick={() => extractPdf(htmlRef.current)}
            >
              PDF
            </button>
            <button
              className="btn btn-sm squear-btn-border-basic mr-2"
              onClick={extractDocx}
            >
              다운로드
            </button>
            <button
              className="btn btn-sm squear-btn-border-basic mr-2"
              onClick={saveWorksheet}
            >
              저장하기
            </button>
            {/* <div className="btn btn-sm squear-btn-basic">
              <Link to={`/worksheets/${worksheetId || "new"}/worksheet2/`}>
                다음으로
              </Link>
            </div> */}
            <div
              className="btn btn-sm squear-btn-basic "
              // data-toggle="modal"
              // data-target="#exampleModal1"
              onClick={() => {
                let keyArray = [
                  "a_1_1_a",
                  "a_1_2",
                  "a_1_3",
                  "a_1_6",
                  "a_1_9",
                  "a_1_8",
                ];
                listeners.checkValue(keyArray, answers, worksheetId, 2);
                // checkValue()
                // if(JSON.stringify(initialAnswers) === JSON.stringify(answers)){
                //   history.push(`/worksheets/${worksheetId || "new"}/worksheet2/`)
                //   console.log('같음')
                // }else{
                //   console.log('다름')
                //   let modal = document.getElementById('exampleModal1')
                //   function addClass(element, className) { element.className += " " + className; };
                //   addClass(modal, "show")
                // }
              }}
            >
              <Link>다음으로</Link>
            </div>
          </section>
        </div>
      </div>
      {/* } 콘텐츠 끝 */}
      <hr />
      {/* 하단 시작 { */}
      {/* <footer include-html="/public/worksheets/worksheet_footer.html" /> */}
      {/* } 하단 시작  */}
    </div>
  );
};

export default Worksheet1;
