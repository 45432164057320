import { combineReducers, configureStore } from "@reduxjs/toolkit";
import logger from 'redux-logger'
import notice from 'feature/notice/sliceNotice';
import home from 'feature/sliceHome';
import adminNotice from "feature/admin/notice/sliceAdminNotice";
import sliceAdminPopup from "feature/admin/popup/sliceAdminPopup";

const reducer = combineReducers({
  home: home,
  notice: notice,
  admin: combineReducers({
    notice: adminNotice,
    popup: sliceAdminPopup.reducer,
  }),
});

const preloadedState = {
  // 초기상태
};

export default configureStore({
  reducer,
  preloadedState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  devTools: process.env.NODE_ENV !== "production",
});
