import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
//
import "../mypage.css";

const Mento = ({ user }) => {
  const [mentoStatus, setMentoStatus] = useState(null);
  const [worksheets, setWorksheets] = useState([]);
  const [selectWorksheet, setSelectWorksheet] = useState();
  const db = useMemo(() => firebase.firestore(), []);

  useEffect(() => {
    updateStateFromDB();
  }, []);

  const updateStateFromDB = () => {
    if (!user) return;
    db.collection("mento")
      .doc(user.uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log(doc.data().status);
          setMentoStatus(doc.data().status);
        }
      });
    firebase.auth().onAuthStateChanged((user) => {
      db.collection("worksheets")
        .where("mento", "==", user.uid)
        .get()
        .then(async (querySnapshot) => {
          let worksheetArray = [];
          for (const doc of querySnapshot.docs) {
            let data = doc.data();
            data.id = doc.id;
            const menteeUser = await db
              .collection("users")
              .doc(data.mentee)
              .get();

            worksheetArray.push({
              ...data,
              menteeName: menteeUser.data().name,
              menteeEmail: menteeUser.data().email,
            });
          }
          console.log(worksheetArray);
          setWorksheets(worksheetArray);
        });
    });
  };

  const PendingList = () =>
    worksheets
      .filter((v) => v.mentoAccept && v.mentoAccept == "pending")
      .map((v, i) => (
        <tr key={i}>
          <td>{v.menteeName}</td>
          <td>멘토신청합니다.</td>
          <td>
            <div className="btnWrap d-flex justify-content-center">
              <div
                className="btn btn-sm btn-xs squear-btn-border-basic radius mr-2"
                onClick={() => {
                  if (!v.id) return;
                  db.collection("worksheets")
                    .doc(v.id)
                    .update({
                      mentoAccept: "deny",
                    })
                    .then(() => updateStateFromDB());
                }}
              >
                거절
              </div>
              <div
                className="btn btn-sm btn-xs squear-btn-basic radius accept"
                data-toggle="modal"
                data-target="#exampleModal2"
                onClick={() => {
                  if (!v.id) return;
                  setSelectWorksheet(v);
                }}
              >
                수락
              </div>
            </div>
          </td>
          <td>{v.pendingDate?.split("T")[0]}</td>
        </tr>
      ));

  const AcceptList = () =>
    worksheets
      .filter((item) => item.mentoAccept == "accept")
      .map((item, index) => {
        return (
          <tr key={index}>
            <th>{index + 1}</th>
            <td>{item.menteeName}</td>
            <td>
              <Link
                to={`/worksheets/${item.id || "new"}/worksheet${item.page}`}
                className="detailView white"
                target="_blank"
              >
                {item.title}
              </Link>
            </td>
            <td>승인</td>
            <td>{item.date?.split("T")[0]}</td>
          </tr>
        );
      });

  return (
    <div>
      <div className="minwidth">
        <div id="mymain" className="main ">
          <div
            id="topNav"
            className="d-flex justify-content-start align-items-end pb-2 "
          >
            <h1 className="h1 m-0 font-weight-bold">마이페이지</h1>
            <ul id="topNavList" className="d-flex">
              <li className="active  position-relative">
                <Link to="/mypage">멘티관리</Link>
                {/* <span className="position-absolute feedback">99</span> */}
              </li>
              <li className="nav-item">
                {/* <Link to='/mypage/detail'>내정보</Link> */}
                <Link to={"/mypage/info/" + user?.uid}>내정보</Link>
              </li>
            </ul>
          </div>
          <div id="mypage" className="subWrap">
            {" "}
            {/* #sub */}
            <div className="basicWrap pt40">
              {mentoStatus == null ? null : mentoStatus ? (
                <div className="">
                  <div className="btnWrap d-flex justify-content-center mb60">
                    {/* <div className="btn btn-sm squear-btn-basic mr-2">
                                    <Link to="/mypage/mento/mypage/">멘토 신청 목록</Link>
                                </div>
                                <div className="btn btn-sm squear-btn-border-basic ">
                                    <Link to="/mypage/mento/mentee_worksheet/">
                                    멘티 워크시트 진행사항
                                    </Link>
                                </div> */}
                  </div>
                  <h3 className="h3 sound_only">멘토 신청 목록</h3>
                  <p>신청자 목록</p>
                  <table className="table table-vertical text-center mb60">
                    <colgroup>
                      <col width="10%" />
                      <col />
                      <col width="15%" />
                      <col width="15%" />
                    </colgroup>
                    <tbody>
                      <tr className=" table-border-top">
                        <th>이름</th>
                        <th>내용</th>
                        <th>승인여부</th>
                        <th>신청날짜</th>
                      </tr>
                      <PendingList />
                    </tbody>
                  </table>
                  <div style={{ height: 80 }}></div>
                  <p>멘티목록</p>
                  <table className="table table-vertical text-center mb60">
                    <colgroup>
                      <col width="60px" />
                      <col width="80px" />
                      <col />
                      <col width="120px" />
                      <col width="120px" />
                    </colgroup>
                    <tbody>
                      <tr className=" table-border-top">
                        <th>no</th>
                        <th>이름</th>
                        <th>제목</th>
                        <th>승인여부</th>
                        <th>작성날짜</th>
                      </tr>
                      <AcceptList />
                    </tbody>
                  </table>
                  {/* <div class="d-flex justify-content-end">
                                        <button id="moreList" class="btn btn-sm btn-xs squear-btn-border-basic">더보기</button>
                                    </div> */}
                  {/* Modal */}
                  <div
                    className="modal fade main_modal pb-4"
                    id="exampleModal2"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header" style={{ border: 0 }}>
                          <h5 className="modal-title" id="exampleModalLabel" />
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="row text-center">
                            <div className="col-12">
                              <h4 className="h4">
                                멘토링요청을 수락하시겠습니까?
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-sm squear-btn-border-basic"
                            data-dismiss="modal"
                          >
                            취소
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm squear-btn-basic"
                            id="meetoApplyFinish"
                            data-dismiss="modal"
                            onClick={() => {
                              db.collection("worksheets")
                                .doc(selectWorksheet.id)
                                .update({
                                  mentoAccept: "accept",
                                })
                                .then(() => {
                                  updateStateFromDB();
                                })
                                .catch((e) => alert(e));
                            }}
                          >
                            수락하기
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <span className="sound_only">마이페이지</span>
                  <div className="d-none d-block ">
                    <h1 className="h1">
                      관리자의 멘토승인을 기다리고 있습니다 :)
                    </h1>
                  </div>
                </>
              )}

              {/*  */}
            </div>
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default Mento;
