import "./Popup.css";
import React from "react";
import { useDispatch } from "react-redux";
import { blockPopupByCookie, removePopup } from "./sliceHome";

const Popup = ({ popup, top = 100, left = 200 }) => {
  const dispatch = useDispatch();
  return (
    <div
      id="hd_pop"
      style={{
        zIndex: 10,
      }}
    >
      <h2>팝업레이어 알림</h2>
      <span className="sound_only">팝업레이어 알림이 없습니다.</span>
      <div id="hd_pops_2" className="hd_pops" style={{ top: top, left: left }}>
        <div className="hd_pops_con" style={{ width: 300, height: 225 }}>
          <p>
            <a href={popup.linkUrl}>
              <img src={popup.imageUrl} title="popup" alt="popup" />
              <br style={{ clear: "both" }} />
              &nbsp;
            </a>
          </p>
        </div>
        <div className="hd_pops_footer">
          <button
            className="hd_pops_reject hd_pops_2 24"
            name="notice"
            value="close"
            onClick={() => dispatch(blockPopupByCookie(popup.id))}
          >
            24시간동안 보지 않기
          </button>
          <button
            className="hd_pops_close hd_pops_2"
            onClick={() => dispatch(removePopup(popup.id))}
          >
            닫기 <i className="fa fa-times" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
