import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import icon01 from "../image/main/icon01.jpg";
import icon02 from "../image/main/icon02.jpg";
import icon03 from "../image/main/icon03.jpg";

import "./Home.css";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import Popup from "./Popup";
import { fetchBanner, reqGetPopup } from "./sliceHome";

const Home = () => {
  const dispatch = useDispatch();
  const popup1 = useSelector((state) => state.home.popups.filter(e => e.id == "1")[0]);
  const popup2 = useSelector((state) => state.home.popups.filter(e => e.id == "2")[0]);

  useEffect(() => {
    dispatch(fetchBanner())
    dispatch(reqGetPopup());
  }, [])

  return (
    <div className="minwidth">
      {/* 상단 시작 { */}
      {/* } 상단 끝 */}
      {/*  팝업시작 { */}
      {popup1?.id ? <Popup popup={popup1}/> : null}
      {popup2?.id ? <Popup popup={popup2} left={600}/> : null}

      {/*  } 팝업시작  */}
      {/* } 시장정보 확인하기 Modal */}
      <hr />
      {/* 콘텐츠 시작 { */}
      <div id="wrapper" className="resizeWrap">
        {/* resizeWrap */}
        <div id="container_wr" className="resize">
          {/* container_wr */}
          {/* 메인이미지 시작 { */}
          <div
            id="main_banner"
            className="d-flex justify-content-center flex-column"
          >
            <h2 className="h2 font-weight-bold">(주)에이치이노베이션</h2>
            <h1 className="h1 font-weight-bold">TEC교육프로그램 안내</h1>
            <h6 className="h6 pt-2 font-weight-bold">
              TEC알고리즘 디지털화 H-TEC
            </h6>
            <div className="btnWrap pt-4">
              {!firebase.auth().currentUser ? (
                <Link className="btn btn-sm squear-btn-basic mr-2" to="/login">
                  로그인 하러가기
                </Link>
              ) : null}

              <Link className="btn btn-sm squear-btn-border-basic" to="/intro">
                회사소개 바로가기
              </Link>
            </div>
          </div>
          {/* } 메인이미지 끝 */}
          <div id="main_contents" className="d-flex justify-content-between">
            <div className="text-center">
              <div className="mainImage">
                {/* <img src={icon01} alt="tec" /> */}
              </div>
              <p>
                TEC은 세계적으로 검증된
                <br /> 기술사업화 교육 프로그램 입니다.
              </p>
            </div>
            <div className="text-center">
              <div className="mainImage">
                <img src={icon02} alt="tec" />
              </div>
              <p>
                TEC을 경험하면 사업과 시장을
                <br /> 보는 관점이 달라집니다.
              </p>
            </div>
            <div className="text-center">
              <div className="mainImage">
                <img src={icon03} alt="tec" />
              </div>
              <p>
                (주)에이치이노베이션은 TEC을 국내에
                <br />
                독점 공급하는 최고 전문가 그룹 입니다.
              </p>
            </div>
          </div>
        </div>
        {/* container_wr / basicWrap */}
      </div>
      {/* resizeWrap */}
      {/* } 콘텐츠 끝 */}
      <hr />
      {/* 하단 시작 { */}
      {/* <footer include-html="/public/footer.html" /> */}
      {/* } 하단 시작  */}
    </div>
  );
};

export default Home;
