import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "../../mypage.css";
import $ from 'jquery';
import UserForm from "feature/test/UserForm";

const InfoModifyMentee = ({ user }) => {
  const db = useRef(firebase.firestore()).current;
  
  useEffect(() => {
  }, []);
  console.log(user)
  return (
    <div>
        <div className="minwidth">
            <hr />
            <div id="mymain" className="main ">
                <div
                    id="topNav"
                    className="d-flex justify-content-start align-items-end pb-2 "
                >
                    <h1 className="h1 m-0 font-weight-bold">마이페이지</h1>
                    <ul id="topNavList" className="d-flex">
                    <li className="position-relative">
                        <Link to="/mypage/mentee/mypage">워크시트</Link>
                    </li>
                    <li className="nav-item active">
                        <Link to={"/register/mentee/" + user?.uid}>내정보</Link>
                    </li>
                    </ul>
                </div>
                {/*  */}
                {/* <div id="mypage" className="subWrap">
                    <div className="basicWrap pt40"> */}
                        {/*  */}
                        <UserForm id={user?.uid} />
                        {/*  */}
                    {/* </div>
                </div> */}
                
            </div>
        </div>
    </div>
  );
};


export default InfoModifyMentee;
