import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestGetNotices } from "./sliceAdminNotice";

const { Link, NavLink } = require("react-router-dom");

const AdminNotice = () => {
  const notice = useSelector((state) => state.admin.notice);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestGetNotices());
  }, []);

  const renderedNoticeList = notice.noticeList.map((notice, index) => {
    return (
      <tr key={index}>
        <td>
          <input type="checkbox" className="listCheckbox" data={index} />
        </td>
        <td>{index + 1}</td>
        <td>
          <p className="title">{notice.title}</p>
        </td>
        <td>{notice.date}</td>
        <td>
          <Link to={`/admin/notice/${notice.id}/modify/`}>
            <span className="reviseButton reviseNoticeButton mb-0">수정</span>
          </Link>
        </td>
      </tr>
    );
  });

  return (
    <>
      <div id="wrapper">
        <div id="container">
          <h1 id="container_title">
            게시판관리
            <div className="float-right">
              {/* <div
                id="delectBtn"
                className="btn btn-sm squear-basic-btn gray mt-0"
              >
                삭제
              </div> */}
              <Link to={`/admin/notice/write`}>
                <div
                  id="noticeWrite"
                  className="btn btn-sm squear-basic-btn mt-0"
                >
                  글쓰기
                </div>
              </Link>
              {/* <div
                id="noticeWrite"
                className="btn btn-sm squear-basic-btn mt-0"
              >
                글쓰기
              </div> */}
            </div>
          </h1>
          <div className="container_wr container">
            <div className="searchArea d-flex justify-content-center align-items-center mb-4">
              {/* <input type="text" placeholder="검색어를 입력해주세요." />
              <button className="submit">검색</button> */}
            </div>
            <div className="tbl_head01 tbl_wrap board">
              <table className="table stepTable tableStyle text-center bgTable">
                <caption className="sound-only d-none">게시판 목록</caption>
                <colgroup>
                  <col width="50px" />
                  <col width="50px" />
                  <col />
                  <col width="200px" />
                  <col width="100px" />
                </colgroup>
                <thead>
                  <tr>
                    <th className="bg" />
                    <th className="bg">번호</th>
                    <th className="bg">제목</th>
                    <th className="bg">작성날짜</th>
                    <th className="bg">관리</th>
                  </tr>
                </thead>
                <tbody>
                  {renderedNoticeList}
                  {/* <tr>
                    <td>
                      <input
                        type="checkbox"
                        className="listCheckbox"
                        data={2}
                      />
                    </td>
                    <td>2</td>
                    <td>
                      <p className="title">제목을 입력해주세요~000000</p>
                    </td>
                    <td>2020-10-10</td>
                    <td>
                      <span className="reviseButton reviseNoticeButton mb-0">
                        수정
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        className="listCheckbox"
                        data={1}
                      />
                    </td>
                    <td>1</td>
                    <td>
                      <p className="title">제목을 입력해주세요~000000</p>
                    </td>
                    <td>2020-10-10</td>
                    <td>
                      <span className="reviseButton reviseNoticeButton mb-0">
                        수정
                      </span>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminNotice;
