import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  Redirect,
} from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "feature/admin/Admin.css";

import AdminHeader from "feature/admin/AdminHeader";
import AdminHome from "feature/admin/home/Home";
import MemberList from "feature/admin/member/MemberList";
import MemberWorksheets from "./member/MemberWorksheets";
import MemberApply from "feature/admin/member/MemberApply";
import Banner from "./banner/Banner";
import AdminNotice from "./notice/AdminNotice";
import AdminNoticeWrite from "./notice/AdminNoticeWrite";
import AdminNoticeModify from "./notice/AdminNoticeModify";
import AdminPopup from "./popup/AdminPopup";
import SubMentoApply from "./member/SubMentoApply";

const Admin = () => {
  const match = useRouteMatch();
  return (
    <div id="admin">
      <AdminHeader />
      <Switch>
        <Route path={`${match.path}/popup`}>
          <AdminPopup />
        </Route>
        <Route path={`${match.path}/banner`}>
          <Banner/>
        </Route>
        <Route path={`${match.path}/notice/:id/modify/`}>
          <AdminNoticeModify />
        </Route>
        <Route path={`${match.path}/notice/write`}>
          <AdminNoticeWrite />
        </Route>
        <Route path={`${match.path}/notice`}>
          <AdminNotice />
        </Route>
        <Route path={"/admin/member/:userId/subMento/apply"}>
          <SubMentoApply />
        </Route>
        <Route path={"/admin/member/:userId/worksheets/:worksheetId/apply"}>
          <MemberApply />
        </Route>
        <Route path={"/admin/member/:userId/worksheets/"}>
          <MemberWorksheets />
        </Route>
        <Route path="/admin/member">
          <MemberList />
        </Route>
        <Route path="/admin/home">
          <AdminHome />
        </Route>
        <Route>
          <Redirect to="/admin/home" />
        </Route>
      </Switch>
    </div>
  );
};

export default Admin;
