import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import "../Worksheet.css";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
//
import * as docx from "docx";
import { saveAs } from "file-saver";
import questions from "../questions.json";
import createAnswerDocxRows from "util/docx/createAnswerDocxRows";
import extractPdf from "util/extractPdf";
import CommentCheck from "util/CommentCheck";

import classifi_json from "./market_classification";

const Worksheet7 = ({ listeners, userType }) => {
  const [answers, setAnswers] = useState({});
  const db = useRef(firebase.firestore()).current;
  const auth = useRef(firebase.auth()).current;
  const match = useRouteMatch();
  const history = useHistory();
  const [worksheetId, setWorksheetId] = useState();
  const [commentVisible, setCommentVisible] = useState(true);
  const [initialAnswers, setInitialAnswers] = useState({});
  const htmlRef = useRef();

  const radioChecked = listeners.radioChecked(answers);
  const radioListener = listeners.radioListener(answers, setAnswers);
  const checkboxChecked = listeners.checkboxChecked(answers);
  const checkboxListener = listeners.checkboxListener(answers, setAnswers);
  const textareaValue = listeners.textareaValue(answers);
  const textareaListener = listeners.textareaListener(answers, setAnswers);
  const commentValue = listeners.commentValue(answers);
  const commentListener = listeners.commentListener(answers, setAnswers);

  const tamList = useMemo(() =>
    classifi_json.filter((e) => e.code.toString().length === 3)
  );
  const samList = useMemo(() =>
    classifi_json.filter((e) => e.code.toString().length === 4)
  );
  const somList = useMemo(() =>
    classifi_json.filter((e) => e.code.toString().length === 5)
  );

  // const formRef = useRef();

  // test code
  // useEffect(() => {
  //     const handler = setInterval(() => {
  //     const data = new FormData(formRef.current);
  //     for (const entry of data) {
  //         console.log(entry[0] + "=" + entry[1] + "\n");
  //     }
  //     }, 1000);

  //     return () => {
  //     clearInterval(handler);
  //     };
  // }, []);
  //
  const extractDocx = () => {
    const doc = new docx.Document();

    const marginOption = {
      margins: {
        left: 70,
      },
    };
    const centerOption = {
      verticalAlign: docx.VerticalAlign.CENTER,
    };
    //
    let dataArray = [];
    //
    console.log(questions[6]);
    Object.keys(questions[6]).map((item, index) => {
      if (
        item.includes("b_4_6") ||
        item.includes("b_4_7") ||
        item.includes("b_4_9") ||
        item.includes("b_4_11")
      ) {
        return;
      }

      dataArray.push(
        createAnswerDocxRows(
          item.toUpperCase() + ". " + questions[6][item],
          answers?.[item] ? answers[item] : "",
          3
        )[0]
      );
      dataArray.push(
        createAnswerDocxRows(
          item.toUpperCase() + ". " + questions[6][item],
          answers?.[item] ? answers[item] : "",
          3,
          "textarea"
        )[1]
      );
    });

    //  표 만들기
    dataArray.push(
      new docx.TableRow({
        children: [
          new docx.TableCell({
            columnSpan: 1,
            children: [new docx.Paragraph("구분")],
          }),
          new docx.TableCell({
            columnSpan: 1,
            children: [new docx.Paragraph("분류내용")],
          }),
          new docx.TableCell({
            columnSpan: 1,
            children: [new docx.Paragraph("규모")],
          }),
        ],
      }),
      //
      new docx.TableRow({
        children: [
          new docx.TableCell({
            columnSpan: 1,
            children: [new docx.Paragraph("시장1(전체시장)TAM")],
          }),
          new docx.TableCell({
            columnSpan: 1,
            children: [new docx.Paragraph("분류내용")],
          }),
          new docx.TableCell({
            columnSpan: 1,
            children: [
              new docx.Paragraph(
                "" + (answers?.["b_4_7"] ? answers["b_4_7"] : "")
              ),
            ],
          }),
        ],
      }),
      new docx.TableRow({
        children: [
          new docx.TableCell({
            columnSpan: 1,
            children: [new docx.Paragraph("시장2(목표시장)SAM")],
          }),
          new docx.TableCell({
            columnSpan: 1,
            children: [new docx.Paragraph("분류내용")],
          }),
          new docx.TableCell({
            columnSpan: 1,
            children: [
              new docx.Paragraph(
                "" + (answers?.["b_4_9"] ? answers["b_4_9"] : "")
              ),
            ],
          }),
        ],
      }),
      new docx.TableRow({
        children: [
          new docx.TableCell({
            columnSpan: 1,
            children: [new docx.Paragraph("시장3(목표시장)SOM")],
          }),
          new docx.TableCell({
            columnSpan: 1,
            children: [new docx.Paragraph("분류내용")],
          }),
          new docx.TableCell({
            columnSpan: 1,
            children: [
              new docx.Paragraph(
                "" + (answers?.["b_4_11"] ? answers["b_4_11"] : "")
              ),
            ],
          }),
        ],
      })
    );

    //

    const table = new docx.Table({
      width: {
        size: 100,
        type: docx.WidthType.PERCENTAGE,
      },
      rows: dataArray,
    });

    doc.addSection({
      children: [table],
    });
    docx.Packer.toBlob(doc).then((blob) => {
      saveAs(blob);
    });
  };
  //
  const saveWorksheet = (e) => {
    if (userType === "admin") {
      alert("어드민은 워크시트를 저장할 수 없습니다!");
      return;
    }

    let finalSubmit = false;
    let date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, -1);

    if (worksheetId && worksheetId != "new") {
      const accountInfo =
        userType == "mento"
          ? {
              mento: auth.currentUser.uid,
            }
          : {
              mentee: auth.currentUser.uid,
            };

      db.collection("worksheets")
        .doc(worksheetId)
        .update({
          questionAnswers: answers,
          page: 7,
          ...accountInfo,
          finalSubmit,
          date,
        })
        .then(() => {
          alert("저장 성공!");
          listeners.storeAnswersListener(answers);
        })
        .catch((e) => alert(e));
      return;
    }
    console.log("\nanswers", {
      questionAnswers: {
        ...answers,
      },
      mentee: auth.currentUser.uid,
      mento: "",
    });
    db.collection("worksheets")
      .add({
        questionAnswers: answers,
        mentee: auth.currentUser.uid,
        page:7,
        finalSubmit,
        date,
      })
      .then((doc) => {
        setWorksheetId(doc.id);
        alert("저장 성공!");
        listeners.storeAnswersListener(answers);
        history.replace(`/worksheets/${doc.id || "new"}/worksheet7/`);
      })
      .catch((e) => alert(e));
  };

  useEffect(() => {
    console.log(userType);
    // if (userType == "mento") {
    //   setCommentVisible(true);
    // }
  }, [userType]);

  useEffect(() => {
    //
    setWorksheetId(match.params.id);

    db.collection("worksheets")
      .doc(match.params.id)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          return;
        }
        listeners.storeAnswersListener(doc.data().questionAnswers);
        setAnswers(doc.data().questionAnswers);
      });
  }, []);

  // b_4_6 8 10
  const renderedTamOptions = tamList.map((element) => (
    <option key={element.code} value={element.code + ". " + element.name}>
      {element.name}
    </option>
  ));

  const renderedSamOptions = samList
    .filter((element) => {
      const code = textareaValue("b_4_6").split(". ")[0];
      const regex = new RegExp("^" + code + ".+");
      return regex.test(element.code);
    })
    .map((element) => (
      <option key={element.code} value={element.code + ". " + element.name}>
        {element.name}
      </option>
    ));

  const renderedSomOptions = somList
    .filter((element) => {
      const code = textareaValue("b_4_8").split(". ")[0];
      const regex = new RegExp("^" + code + ".+");
      return regex.test(element.code);
    })
    .map((element) => (
      <option key={element.code} value={element.code + ". " + element.name}>
        {element.name}
      </option>
    ));

  return (
    <div>
      <div className="minwidth">
        <div id="worksheet">
          <div className="basicWrap">
            <section className="d-flex justify-content-between youtube align-items-center px-5">
              <h2 className="h2 font-weight-normal">
                작성하는 법을 잘 모르겠다면? youtube 시청 후 작성하기
              </h2>
              <a
                className="btn btn-sm font-weight-normal squear-btn-basic radius pr-4 pl-4"
                href
              >
                과정설명 영상 보러가기
              </a>
            </section>
            <div style={{ marginTop: 22, borderBottom: "1px solid #aaa" }}>
              <span style={{ fontSize: 12, color: "#aaa", marginTop: 22 }}>
                STEP2. 가치제안서 {">"} 지속적인 고객 니즈 워크시트
              </span>
            </div>
            <form action className="dataForm mt40 " ref={htmlRef}>
              <section className="section">
                <div className="question">
                  B_4_1. 고객의 요구를 제공하는 제품/서비스로 설명하시오.
                  <span className="text-danger">*</span>
                </div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_4_1"
                    id
                    className="textareaForm "
                    value={textareaValue("b_4_1", 1)}
                    onChange={textareaListener("b_4_1", 1)}
                  />
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_4_1", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_4_1_comment"
                    id="b_4_1_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == 'mento' ? false:true}
                    value={commentValue("b_4_1", 1)}
                    onChange={commentListener("b_4_1", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question">
                  B_4_2. 고객의 핵심 요구사항과 문제점은 무엇인가?
                  <span className="text-danger">*</span>
                </div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_4_2"
                    id
                    className="textareaForm "
                    value={textareaValue("b_4_2", 1)}
                    onChange={textareaListener("b_4_2", 1)}
                  />
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_4_2", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_4_2_comment"
                    id="b_4_2_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == 'mento' ? false:true}
                    value={commentValue("b_4_2", 1)}
                    onChange={commentListener("b_4_2", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question">
                  B_4_4. 고객 니즈는 장기적인 관점에서 시장이나 기술 트렌드와
                  일관되게 움직이는가?<span className="text-danger">*</span>
                </div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_4_4"
                    id
                    className="textareaForm "
                    value={textareaValue("b_4_4", 1)}
                    onChange={textareaListener("b_4_4", 1)}
                  />
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_4_4", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_4_4_comment"
                    id="b_4_4_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == 'mento' ? false:true}
                    value={commentValue("b_4_4", 1)}
                    onChange={commentListener("b_4_4", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question">
                  B_4_5. 경쟁자들도 이 시장에 투자를 하고 있는가?{" "}
                </div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_4_5"
                    id
                    className="textareaForm "
                    value={textareaValue("b_4_5", 1)}
                    onChange={textareaListener("b_4_5", 1)}
                  />
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_4_5", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_4_5_comment"
                    id="b_4_5_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == 'mento' ? false:true}
                    value={commentValue("b_4_5", 1)}
                    onChange={commentListener("b_4_5", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question d-flex justify-content-between">
                  <p>
                    B_4_6. 고객 요구사항/문제점의 강도와 고객의 수로 볼때
                    잠재력이 큰 시장인가?<span className="text-danger">*</span>
                  </p>
                  <span className>+ 산업분류표 자세히보기</span>
                </div>
                <div className="answer">
                  <table className="table stepTable mb-0">
                    <colgroup>
                      <col width="33%" />
                      <col width="33%" />
                      <col />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td className="center middle bg">구분</td>
                        <td className="center middle bg">분류내용</td>
                        <td className="center middle bg">규모</td>
                      </tr>
                      <tr>
                        <td className="bg center middle">
                          TAM
                          <br />
                          (Total Addressable Market)
                        </td>
                        <td className="bd-right center middle">
                          <select
                            name="b_4_6"
                            value={textareaValue("b_4_6", 1)}
                            onChange={textareaListener("b_4_6", 1)}
                          >
                            <option selected>선택</option>
                            {renderedTamOptions}
                          </select>
                        </td>
                        <td>
                          <input
                            className="inputForm"
                            type="text"
                            name="b_4_7"
                            placeholder
                            value={textareaValue("b_4_7", 1)}
                            onChange={textareaListener("b_4_7", 1)}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="bg center middle">
                          SAM
                          <br />
                          (Service Available Market)
                        </td>
                        <td className="bd-right center middle">
                          <select
                            name="b_4_8"
                            value={textareaValue("b_4_8", 1)}
                            onChange={textareaListener("b_4_8", 1)}
                          >
                            <option selected>선택</option>
                            {renderedSamOptions}
                          </select>
                        </td>
                        <td>
                          <input
                            className="inputForm"
                            type="text"
                            name="b_4_9"
                            placeholder
                            value={textareaValue("b_4_9", 1)}
                            onChange={textareaListener("b_4_9", 1)}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="bg center middle">
                          SOM
                          <br />
                          (Serviceable Obtainable Market)
                        </td>
                        <td className="bd-right center middle">
                          <select
                            name="b_4_10"
                            value={textareaValue("b_4_10", 1)}
                            onChange={textareaListener("b_4_10", 1)}
                          >
                            <option selected>선택</option>
                            {renderedSomOptions}
                          </select>
                        </td>
                        <td>
                          <input
                            className="inputForm"
                            type="text"
                            name="b_4_11"
                            placeholder
                            value={textareaValue("b_4_11", 1)}
                            onChange={textareaListener("b_4_11", 1)}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_4_6", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_4_6_comment"
                    id="b_4_6_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == 'mento' ? false:true}
                    value={commentValue("b_4_6", 1)}
                    onChange={commentListener("b_4_6", 1)}
                  />
                </div>
              </section>
            </form>
            <section className="btnArea d-flex align-items-center justify-content-end">
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={() => extractPdf(htmlRef.current)}
              >
                PDF
              </button>
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={extractDocx}
              >
                다운로드
              </button>
              <div className="btn btn-sm squear-btn-border-basic mr-2">
                <Link to={`/worksheets/${worksheetId || "new"}/worksheet6/`}>
                  이전으로
                </Link>
              </div>
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={saveWorksheet}
              >
                저장하기
              </button>
              <div
                className="btn btn-sm squear-btn-basic "
                onClick={() => {
                  let keyArray = [
                    "b_4_1",
                    "b_4_2",
                    "b_4_4",
                    "b_4_5",
                    "b_4_7",
                    "b_4_9",
                    "b_4_11",
                  ];
                  listeners.checkValue(keyArray, answers, worksheetId, 8);
                }}
              >
                <Link>다음으로</Link>
              </div>
            </section>
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default Worksheet7;
