import { Link, useHistory, useRouteMatch } from "react-router-dom";
import "../Worksheet.css";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { useEffect, useRef, useState } from "react";
//
import * as docx from "docx";
import { saveAs } from "file-saver";
import extractPdf from "util/extractPdf";
import CommentCheck from "util/CommentCheck";

const pointQuestion = (answers, subValArray, idx) => {
  console.log('b_1_'+
  (parseInt(subValArray[idx][0].split('_')[3]/3) == 0?1:parseInt(subValArray[idx][0].split('_')[3]/3))
  +'_'+(subValArray[idx][0].split('_')[3]%3 == 0? 6:(subValArray[idx][0].split('_')[3]%3+3)))
  
  console.log(subValArray[idx][0].split('_')[3]/3)
  return answers['b_1_'+Math.ceil(subValArray[idx][0].split('_')[3]/3)+'_'+(subValArray[idx][0].split('_')[3]%3 == 0? 6:(subValArray[idx][0].split('_')[3]%3+3))]
}
const Worksheet6 = ({ listeners, userType }) => {
  const [answers, setAnswers] = useState({});
  const [subValArray, setSubValArray] = useState([]);
  const db = useRef(firebase.firestore()).current;
  const auth = useRef(firebase.auth()).current;
  const match = useRouteMatch();
  const history = useHistory();
  const [worksheetId, setWorksheetId] = useState();
  const [commentVisible, setCommentVisible] = useState(true);
  const [initialAnswers, setInitialAnswers] = useState({});
  const [saveCheck, setSaveCheck] = useState(false);
  const htmlRef = useRef();
  //
  const [compareData, setCompareData] = useState([]);
  //
  const radioChecked = listeners.radioChecked(answers);
  const radioListener = listeners.radioListener(answers, setAnswers);
  const checkboxChecked = listeners.checkboxChecked(answers);
  const checkboxListener = listeners.checkboxListener(answers, setAnswers);
  const textareaValue = listeners.textareaValue(answers);
  const textareaListener = listeners.textareaListener(answers, setAnswers);
  const commentValue = listeners.commentValue(answers);
  const commentListener = listeners.commentListener(answers, setAnswers);
  const fileValue = listeners.fileValue(answers);
  const fileListener = listeners.fileListener(answers, setAnswers);
  const fileDeleteListener = listeners.fileDeleteListener(answers, setAnswers);

  //
  const extractDocx = () => {
    const doc = new docx.Document();

    const marginOption = {
      margins: {
        left: 70,
      },
    };
    const centerOption = {
      verticalAlign: docx.VerticalAlign.CENTER,
    };
    //
    let dataArray = [];
    //
    console.log(compareData);
    //
    //answers?.["b_1_"+(item[0].split('_')[2])+'_1'] ? answers?.["b_1_"+(item[0].split('_')[2])+'_1']: 'b_2_8에서 총업이 가장 높은 시장의 명칭 이말은 b_1_1 호출'
    // new docx.TableRow({
    //   children: [
    //     new docx.TableCell({
    //       columnSpan: 1,
    //       children: [new docx.Paragraph('')],
    //     }),
    //   ],
    // }),
    compareData.map((item, index) => {
      dataArray.push(
        new docx.TableRow({
          children: [
            new docx.TableCell({
              columnSpan: 3,
              children: [new docx.Paragraph(index + 1 + "위 비교")],
            }),
          ],
        }),
        new docx.TableRow({
          children: [
            new docx.TableCell({
              columnSpan: 1,
              children: [
                new docx.Paragraph(
                  answers?.["b_1_" + item[0].split("_")[2] + "_1"]
                    ? answers?.["b_1_" + item[0].split("_")[2] + "_1"]
                    : "b_2_8에서 총업이 가장 높은 시장의 명칭 이말은 b_1_1 호출"
                ),
              ],
            }),
            new docx.TableCell({
              columnSpan: 1,
              children: [
                new docx.Paragraph(
                  answers?.["b_3_" + (index + 1) + "_2"]
                    ? answers["b_3_" + (index + 1) + "_2"]
                    : ""
                ),
              ],
            }),
            new docx.TableCell({
              columnSpan: 1,
              children: [
                new docx.Paragraph(
                  answers?.["b_3_" + (index + 1) + "_3"]
                    ? answers["b_3_" + (index + 1) + "_3"]
                    : ""
                ),
              ],
            }),
          ],
        }),
        //
        new docx.TableRow({
          children: [
            new docx.TableCell({
              columnSpan: 3,
              children: [new docx.Paragraph("제품/서비스 특성")],
            }),
          ],
        }),
        //
        new docx.TableRow({
          children: [
            new docx.TableCell({
              columnSpan: 1,
              children: [new docx.Paragraph("문제점")],
            }),
            new docx.TableCell({
              columnSpan: 1,
              children: [new docx.Paragraph("핵심기능")],
            }),
            new docx.TableCell({
              columnSpan: 1,
              children: [new docx.Paragraph("기능설명")],
            }),
          ],
        }),
        //
        new docx.TableRow({
          children: [
            new docx.TableCell({
              columnSpan: 1,
              children: [
                new docx.Paragraph(
                  answers?.["b_1_" + item[0].split("_")[2] + "_4"]
                    ? answers?.["b_1_" + item[0].split("_")[2] + "_4"]
                    : "b_2_8에서 총업이 가장 높은 시장의 문제점1"
                ),
              ],
            }),
            new docx.TableCell({
              columnSpan: 1,
              children: [
                new docx.Paragraph(
                  answers?.["b_3_" + (index + 1) + "_4"]
                    ? answers["b_3_" + (index + 1) + "_4"]
                    : ""
                ),
              ],
            }),
            new docx.TableCell({
              columnSpan: 1,
              children: [
                new docx.Paragraph(
                  answers?.["b_3_" + (index + 1) + "_5"]
                    ? answers["b_3_" + (index + 1) + "_5"]
                    : ""
                ),
              ],
            }),
          ],
        }),
        new docx.TableRow({
          children: [
            new docx.TableCell({
              columnSpan: 1,
              children: [
                new docx.Paragraph(
                  answers?.["b_1_" + item[0].split("_")[2] + "_5"]
                    ? answers?.["b_1_" + item[0].split("_")[2] + "_5"]
                    : "b_2_8에서 총업이 가장 높은 시장의 문제점2"
                ),
              ],
            }),
            new docx.TableCell({
              columnSpan: 1,
              children: [
                new docx.Paragraph(
                  answers?.["b_3_" + (index + 1) + "_6"]
                    ? answers["b_3_" + (index + 1) + "_6"]
                    : ""
                ),
              ],
            }),
            new docx.TableCell({
              columnSpan: 1,
              children: [
                new docx.Paragraph(
                  answers?.["b_3_" + (index + 1) + "_7"]
                    ? answers["b_3_" + (index + 1) + "_7"]
                    : ""
                ),
              ],
            }),
          ],
        }),
        new docx.TableRow({
          children: [
            new docx.TableCell({
              columnSpan: 1,
              children: [
                new docx.Paragraph(
                  answers?.["b_1_" + item[0].split("_")[2] + "_6"]
                    ? answers?.["b_1_" + item[0].split("_")[2] + "_6"]
                    : "b_2_8에서 총업이 가장 높은 시장의 문제점3"
                ),
              ],
            }),
            new docx.TableCell({
              columnSpan: 1,
              children: [
                new docx.Paragraph(
                  answers?.["b_3_" + (index + 1) + "_8"]
                    ? answers["b_3_" + (index + 1) + "_8"]
                    : ""
                ),
              ],
            }),
            new docx.TableCell({
              columnSpan: 1,
              children: [
                new docx.Paragraph(
                  answers?.["b_3_" + (index + 1) + "_9"]
                    ? answers["b_3_" + (index + 1) + "_9"]
                    : ""
                ),
              ],
            }),
          ],
        }),
        //
        new docx.TableRow({
          children: [
            new docx.TableCell({
              columnSpan: 1,
              children: [new docx.Paragraph("시장점유율")],
            }),
            new docx.TableCell({
              columnSpan: 1,
              children: [new docx.Paragraph("사용고객수")],
            }),
            new docx.TableCell({
              columnSpan: 1,
              children: [new docx.Paragraph("사진")],
            }),
          ],
        }),
        //
        new docx.TableRow({
          children: [
            new docx.TableCell({
              columnSpan: 1,
              children: [
                new docx.Paragraph(
                  answers?.["b_3_" + (index + 1) + "_10"]
                    ? answers["b_3_" + (index + 1) + "_10"]
                    : ""
                ),
              ],
            }),
            new docx.TableCell({
              columnSpan: 1,
              children: [
                new docx.Paragraph(
                  answers?.["b_3_" + (index + 1) + "_11"]
                    ? answers["b_3_" + (index + 1) + "_11"]
                    : ""
                ),
              ],
            }),
            new docx.TableCell({
              columnSpan: 1,
              children: [new docx.Paragraph("사진")],
            }),
          ],
        })
      );
    });
    console.log(dataArray);
    const table = new docx.Table({
      width: {
        size: 100,
        type: docx.WidthType.PERCENTAGE,
      },
      rows: dataArray,
    });

    doc.addSection({
      children: [table],
    });
    docx.Packer.toBlob(doc).then((blob) => {
      saveAs(blob);
    });
  };
  //
  const saveWorksheet = (e) => {
    if (userType === "admin") {
      alert("어드민은 워크시트를 저장할 수 없습니다!");
      return;
    }
    let finalSubmit = false;
    let date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, -1);

    setSaveCheck(true);
    if (worksheetId && worksheetId != "new") {
      const accountInfo =
        userType == "mento"
          ? {
              mento: auth.currentUser.uid,
            }
          : {
              mentee: auth.currentUser.uid,
            };

      db.collection("worksheets")
        .doc(worksheetId)
        .update({
          questionAnswers: answers,
          page: 6,
          ...accountInfo,
          finalSubmit,
          date,
        })
        .then(() => {
          alert("저장 성공!");
          listeners.storeAnswersListener(answers);
        })
        .catch((e) => alert(e));
      return;
    }
    console.log("\nanswers", {
      questionAnswers: {
        ...answers,
      },
      mentee: auth.currentUser.uid,
      mento: "",
    });
    db.collection("worksheets")
      .add({
        questionAnswers: answers,
        mentee: auth.currentUser.uid,
        finalSubmit,
        date,
      })
      .then((doc) => {
        setWorksheetId(doc.id);
        alert("저장 성공!");
        listeners.storeAnswersListener(answers);
        history.replace(`/worksheets/${doc.id || "new"}/worksheet6/`);
      })
      .catch((e) => alert(e));
  };

  useEffect(() => {
    // console.log(userType);
    // if (userType == "mento") {
    //   setCommentVisible(true);
    // }
  }, [userType]);

  useEffect(() => {
    //
    setWorksheetId(match.params.id);

    db.collection("worksheets")
      .doc(match.params.id)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          return;
        }
        listeners.storeAnswersListener(doc.data().questionAnswers);
        setAnswers(doc.data().questionAnswers);
      });
    //
  }, []);

  useEffect(() => {
    if(Object.keys(answers).length>0){
      console.log("총점이 높은거에서 하위 점수가 높은거 순으로 처리 필요...");
      //
      console.log(Object.keys(answers));
      //
      let scoreArray = [];
      let newTestArray = [];
      // Object.keys(answers).map((item)=>{
      let fiveArray = [1, 2, 3, 4, 5];
      fiveArray.map((key) => {
        if (answers?.["b_2_" + key] != undefined) {
          newTestArray.push(["b_2_" + key, answers["b_2_" + key]]);
        }
      });
      // })
      console.log('// 점수가 높은 순으로 전체 나열');
      newTestArray.sort((a,b)=>{
        return b[1]-a[1]
      })
      console.log(newTestArray[0])
      // 
      console.log('//'+newTestArray[0][0]+'의 모든값 나열하기')
      // Object.keys(answers)에서 위값포함시 나열
      // console.log(Object.keys(answers).indexOf(newTestArray[0][0]))
      let subArray = []
      Object.keys(answers).map(item=>{
        if(item.indexOf(newTestArray[0][0]) > -1 && item != newTestArray[0][0]){
          subArray.push([item, parseFloat(answers[item])])
          // console.log(item+': '+ parseFloat(answers[item]))
        }
      })
      subArray.sort((a,b)=>{
        return b[1]-a[1]
      })
      setSubValArray(subArray.slice(0,3))
      // console.log(subArray.slice(0,3))
      // 
      setCompareData(newTestArray)
    }
    
  }, [answers]);

  return (
    <div>
      <div className="minwidth">
        <div
          className="modal fade main_modal pb-4 memtoListPopup"
          id="exampleModal1"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header" style={{ border: 0 }}>
                <h5 className="modal-title" id="exampleModalLabel" />
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row text-center">
                  <div className="col-12">
                    <h4 className="h4">
                      저장해야 다음으로 넘어갈 수 있습니다.
                    </h4>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {/* <button
                  type="button"
                  className="btn btn-sm squear-btn-border-basic"
                  data-dismiss="modal"
                >
                  취소
                </button> */}
                <button
                  type="button"
                  className="btn btn-sm squear-btn-basic"
                  id="applyFinish"
                  data-dismiss="modal"
                  onClick={() => {
                    let modal = document.getElementById("exampleModal1");
                    function removeClass(element, className) {
                      var check = new RegExp("(\\s|^)" + className + "(\\s|$)");
                      element.className = element.className
                        .replace(check, " ")
                        .trim();
                    }
                    removeClass(modal, "show");
                  }}
                >
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div id="worksheet">
          <div className="basicWrap">
            <section className="d-flex justify-content-between youtube align-items-center px-5">
              <h2 className="h2 font-weight-normal">
                작성하는 법을 잘 모르겠다면? youtube 시청 후 작성하기
              </h2>
              <a
                className="btn btn-sm font-weight-normal squear-btn-basic radius pr-4 pl-4"
                href
              >
                과정설명 영상 보러가기
              </a>
            </section>
            <div style={{ marginTop: 22, borderBottom: "1px solid #aaa" }}>
              <span style={{ fontSize: 12, color: "#aaa", marginTop: 22 }}>
                STEP2. 가치제안서 {">"} 경쟁 제품/서비스 비교 워크시트
              </span>
            </div>
            <form action className="dataForm mt40 " ref={htmlRef}>
              {/*  */}
              {
                compareData.length > 0 ? 
                [1,2,3].map((item, index) => {
                  console.log(index)
                  return(
                    <div key={'compare'+(index+1)}>
                      <div className="d-flex justify-content-between mb-1">
                        <div>{index+1}위 비교</div>
                      </div>
                      <table className="table stepTable mb60">
                        <colgroup>
                          <col width="33%" />
                          <col width="33%" />
                          <col />
                        </colgroup>
                        <tbody>
                          <tr>
                            <td className="bd-right">
                              <input
                                className={"inputForm required b_3_" + (index + 1) + "_1"}
                                type="text"
                                placeholder="경쟁자명"
                                value={textareaValue(
                                  "b_3_" + (index + 1) + "_1",
                                  1
                                )}
                                onChange={textareaListener(
                                  "b_3_" + (index + 1) + "_1",
                                  1
                                )}
                              />
                            </td>
                            <td className="bd-right">
                              <input
                                className={"inputForm required b_3_" + (index + 1) + "_2"}
                                type="text"
                                placeholder="제품.서비스명"
                                value={textareaValue(
                                  "b_3_" + (index + 1) + "_2",
                                  1
                                )}
                                onChange={textareaListener(
                                  "b_3_" + (index + 1) + "_2",
                                  1
                                )}
                              />
                            </td>
                            <td>
                              <input
                                className={"inputForm required b_3_" + (index + 1) + "_3"}
                                type="text"
                                placeholder="가격"
                                value={textareaValue(
                                  "b_3_" + (index + 1) + "_3",
                                  1
                                )}
                                onChange={textareaListener(
                                  "b_3_" + (index + 1) + "_3",
                                  1
                                )}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={3} className="bg center middle">
                              제품/서비스 특성
                            </td>
                          </tr>
                          <tr>
                            <td className="bg center middle">문제점</td>
                            <td className="bg center middle">핵심기능</td>
                            <td className="bg center middle">기능설명</td>
                          </tr>
                          {
                            [1,2,3].map((initem,idx)=>{
                              return(
                                <tr key={'inner'+idx}>
                            <td className="disabled center middle">
                              {
                                pointQuestion(answers, subValArray, idx)
                              }
                            </td>
                            <td>
                              <textarea
                                onKeyUp={(e) => {
                                  e.currentTarget.style.height = "auto";
                                  e.currentTarget.style.height =
                                    e.currentTarget.scrollHeight + 2 + "px";
                                }}
                                className="textareaForm required"
                                name={"b_3_" + (index + 1) + "_"+(idx+4)}
                                id
                                cols={30}
                                rows={3}
                                placeholder="핵심기능"
                                value={textareaValue(
                                  "b_3_" + (index + 1) + "_"+(idx+4),
                                  1
                                )}
                                onChange={textareaListener(
                                  "b_3_" + (index + 1) + "_"+(idx+4),
                                  1
                                )}
                              />
                            </td>
                            <td>
                              <textarea
                                onKeyUp={(e) => {
                                  e.currentTarget.style.height = "auto";
                                  e.currentTarget.style.height =
                                    e.currentTarget.scrollHeight + 2 + "px";
                                }}
                                className="textareaForm required"
                                name={"b_3_" + (index + 1) + "_"+(idx+7)}
                                id
                                cols={30}
                                rows={3}
                                placeholder="기능설명"
                                // value={"b_3_" + (index + 1) + "_"+(idx+7)}
                                value={textareaValue(
                                  "b_3_" + (index + 1) + "_"+(idx+7),
                                  1
                                )}
                                onChange={textareaListener(
                                  "b_3_" + (index + 1) + "_"+(idx+7),
                                  1
                                )}
                              />
                            </td>
                          </tr>
                              )
                            })
                          }
   
                          <tr>
                            <td className="bg center middle">시장점유율</td>
                            <td className="bg center middle">사용고객수</td>
                            <td className="bg center middle">사진</td>
                          </tr>
                          <tr>
                            <td>
                              <textarea
                                onKeyUp={(e) => {
                                  e.currentTarget.style.height = "auto";
                                  e.currentTarget.style.height =
                                    e.currentTarget.scrollHeight + 2 + "px";
                                }}
                                className={"textareaForm b_3_" + (index + 1) + "_10"}
                                id
                                cols={30}
                                rows={3}
                                placeholder="시장점유율"
                                value={textareaValue(
                                  "b_3_" + (index + 1) + "_10",
                                  1
                                )}
                                onChange={textareaListener(
                                  "b_3_" + (index + 1) + "_10",
                                  1
                                )}
                              />
                            </td>
                            <td className="center middle ">
                                <input
                                  type="text"
                                  className={"inputForm required w-25 worksheet border b_3_" + (index + 1) + "_11"}
                                  placeholder={'00명'}
                                  value={textareaValue(
                                    "b_3_" + (index + 1) + "_11",
                                    1
                                  )}
                                  onChange={textareaListener(
                                    "b_3_" + (index + 1) + "_11",
                                    1
                                  )}
                                />
                            </td>
                            <td className="center middle">
                              <input
                                type="file"
                                id={"b_3_" + (index + 1) + "_12"}
                                className={"d-none b_3_" + (index + 1) + "_12"}
                                onChange={fileListener(
                                  "b_3_" + (index + 1) + "_12",
                                  1
                                )}
                              />
                              <label
                                htmlFor={"b_3_" + (index + 1) + "_12"}
                                name={"b_3_" + (index + 1) + "_12"}
                                className="w-25 text-center fileupload"
                              >
                                + 파일업로드
                              </label>
                              <div
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                &nbsp;&nbsp;
                                {fileValue(
                                  "b_3_" + (index + 1) + "_12",
                                  1
                                ) ? (
                                  <>
                                    <a
                                      href={fileValue(
                                        "b_3_" + (index + 1) + "_12",
                                        1
                                      )}
                                    >
                                      첨부파일
                                    </a>
                                    &nbsp;
                                    <div
                                      onClick={fileDeleteListener(
                                        "b_3_" + (index + 1) + "_12",
                                        1
                                      )}
                                      className="btn btn-sm squear-btn-basic pr-2 pl-2 p-1"
                                    >
                                      삭제
                                    </div>
                                  </>
                                ) : (
                                  <div>파일이 없습니다</div>
                                )}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <section
                        className="section comment"
                        style={CommentCheck(userType, commentValue("b_3_" + (index + 1), 1))}
                      >
                        <div className="question font-weight-bold">
                          Comment
                        </div>
                        <div className="answer">
                          <textarea
                            onKeyUp={(e) => {
                              e.currentTarget.style.height = "auto";
                              e.currentTarget.style.height =
                                e.currentTarget.scrollHeight + 2 + "px";
                            }}
                            name={"b_3_" + (index + 1) + "_comment"}
                            id={"b_3_" + (index + 1) + "_comment"}
                            className="textareaForm"
                            placeholder="멘토만 작성할 수 있는 구역입니다."
                            disabled={userType == 'mento' ? false:true}
                            value={commentValue("b_3_" + (index + 1), 1)}
                            onChange={commentListener(
                              "b_3_" + (index + 1),
                              1
                            )}
                          />
                        </div>
                      </section>
                    </div>
                  )
                }):null
              }
              
              
              <div id="create" className="mb40" />
            </form>
            <section className="btnArea d-flex align-items-center justify-content-end">
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={() => extractPdf(htmlRef.current)}
              >
                PDF
              </button>
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={extractDocx}
              >
                다운로드
              </button>
              <div className="btn btn-sm squear-btn-border-basic mr-2">
                <Link to={`/worksheets/${worksheetId || "new"}/worksheet5/`}>
                  이전으로
                </Link>
              </div>
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={saveWorksheet}
              >
                저장하기
              </button>
              {/* <div className="btn btn-sm squear-btn-basic">
                <Link to={`/worksheets/${(worksheetId || "new")}/worksheet7/`}>
                  다음으로
                </Link>
              </div> */}
              <div
                className="btn btn-sm squear-btn-basic "
                onClick={() => {
                  console.log(compareData);

                  let tt = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
                  let keyArray = [];
                  compareData.map((item, index) => {
                    tt.map((inItem) => {
                      keyArray.push("b_3_" + (index + 1) + "_" + inItem);
                    });
                  });

                  console.log(keyArray);
                  listeners.checkValue(keyArray, answers, worksheetId, 7);
                }}
              >
                <Link>다음으로</Link>
              </div>
            </section>
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default Worksheet6;
