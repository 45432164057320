import { useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { imagePreview, updateBanner } from "./sliceBanner"

const Banner = () => {
    const dispatch = useDispatch()
    const [bannerImage, setBannerImage] = useState(null)

    useEffect((e)=>{
        // console.log(banner)
        dispatch(imagePreview())
    },[])

    return(
        <div>
            <div id="wrapper">
                <div id="container">
                <h1 id="container_title">
                    배너관리
                    <div className="float-right">
                    <div className="btn btn-sm squear-basic-btn" onClick={updateBanner(bannerImage)}>저장</div>
                    </div>
                </h1>
                <div className="container_wr container">
                    <table className="table stepTable tableStyle">
                    <colgroup>
                        <col width="10%" />
                        <col width="40%" />
                        <col width="10%" />
                        <col />
                    </colgroup>
                    <tbody>
                        <tr> 
                        <th className="bg">이미지</th>
                        <td colSpan={3}>
                            <span className="frm_info mb-2 d-block">
                            이미지 사이즈는 1200*600 입니다. 크기가 다를시 배너가 일그러질수 있습니다.
                            </span>
                            <input type="file" id="banner_image_file" onChange={(e) => {
                                setBannerImage(e.target.files[0]);
                                dispatch(imagePreview(e))
                            }} />
                            <br />
                            <div id="banner_file" className="banner_file" />
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>

    )
}

export default Banner