import { Link, useRouteMatch } from "react-router-dom";
import "./Worksheet.css";

const WorksheetRightNav = () => {
  const match = useRouteMatch();
  
  return (
    <nav
      className="d-flex flex-column position-fixed fixed-right column col-xs-3"
      id="worksheetNav"
    >
      <ul>
        <li>
          <Link
            className="align-items-center d-flex justify-content-center"
            to={`${match.url}/pamm`}
          >
            PAMM
          </Link>
        </li>
        <li>
          <Link
            className="align-items-center d-flex justify-content-center"
            to={`${match.url}/pmc`}
          >
            PMC
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default WorksheetRightNav;
