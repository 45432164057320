import { Link, useRouteMatch } from "react-router-dom";
import "./Worksheet.css";

const WorksheetNav = () => {
  const match = useRouteMatch();
  
  return (
    <nav
      className="d-flex flex-column position-fixed fixed-left d-flex flex-column position-fixed fixed-left"
      id="worksheetNav"
    >
      <ul>
        <li>
          <Link
            className="align-items-center d-flex justify-content-center"
            to={`${match.url}/forces`}
          >
            5Forces
          </Link>
        </li>
        <li>
          <Link
            className="align-items-center d-flex justify-content-center"
            to={`${match.url}/swot`}
          >
            SWOT
          </Link>
        </li>
        <li>
          <Link
            className="align-items-center d-flex justify-content-center"
            to={`${match.url}/industrymap`}
          >
            industry Map
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default WorksheetNav;
