import { Link, useHistory, useRouteMatch } from "react-router-dom";
import "../Worksheet.css";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { useEffect, useRef, useState } from "react";

import * as docx from "docx";
import { saveAs } from "file-saver";
import createAnswerDocxRows from "util/docx/createAnswerDocxRows";
import questions from "feature/worksheets/questions.json";
import extractPdf from "util/extractPdf";
import CommentCheck from "util/CommentCheck";

const Worksheet12 = ({ listeners, userType }) => {
  const [answers, setAnswers] = useState({});
  const db = useRef(firebase.firestore()).current;
  const auth = useRef(firebase.auth()).current;
  const [commentVisible, setCommentVisible] = useState(true);
  const match = useRouteMatch();
  const [worksheetId, setWorksheetId] = useState();
  const history = useHistory();
  const [initialAnswers, setInitialAnswers] = useState({});
  const htmlRef = useRef();

  const radioChecked = listeners.radioChecked(answers);
  const radioListener = listeners.radioListener(answers, setAnswers);
  const checkboxChecked = listeners.checkboxChecked(answers);
  const checkboxListener = listeners.checkboxListener(answers, setAnswers);
  const textareaValue = listeners.textareaValue(answers);
  const textareaListener = listeners.textareaListener(answers, setAnswers);
  const commentValue = listeners.commentValue(answers);
  const commentListener = listeners.commentListener(answers, setAnswers);

  useEffect(() => {
    //
    setWorksheetId(match.params.id);

    db.collection("worksheets")
      .doc(match.params.id)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          return;
        }
        setAnswers(doc.data().questionAnswers);
        listeners.storeAnswersListener(doc.data().questionAnswers);
      });
  }, []);

  useEffect(() => {
    console.log(userType);
    // if (userType == "mento") {
    //   setCommentVisible(true);
    // }
  }, [userType]);

  const saveWorksheet = (isFinal) => (e) => {
    if (userType === "admin") {
      alert("어드민은 워크시트를 저장할 수 없습니다!");
      return;
    }

    let finalSubmit = false;
    let date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, -1);

    if (isFinal) {
      console.log(auth.currentUser)
      if(userType=='mento'){
        finalSubmit = true;
      }
      // return
    }
    if (worksheetId && worksheetId != "new") {
      const accountInfo =
        userType == "mento"
          ? {
              mento: auth.currentUser.uid,
            }
          : {
              mentee: auth.currentUser.uid,
            };
      db.collection("worksheets")
        .doc(worksheetId)
        .update({
          questionAnswers: answers,
          page: 12,
          finalSubmit,
          date,
          ...accountInfo,
        })
        .then(() => {
          if (finalSubmit) {
            alert("제출 성공!");
          } else {
            alert("저장 성공!");
          }

          listeners.storeAnswersListener(answers);
        })
        .catch((e) => alert(e));
      return;
    }
    console.log("\nanswers", {
      questionAnswers: {
        ...answers,
      },
      mentee: auth.currentUser.uid,
      mento: "",
    });
    db.collection("worksheets")
      .add({
        questionAnswers: answers,
        mentee: auth.currentUser.uid,
        page: 12,
        finalSubmit,
        date,
      })
      .then((doc) => {
        setWorksheetId(doc.id);
        if (finalSubmit) {
          alert("제출 성공!");
        } else {
          alert("저장 성공!");
        }
        listeners.storeAnswersListener(answers);
        history.replace(`/worksheets/${doc.id || "new"}/worksheet12/`);
      })
      .catch((e) => alert(e));
  };

  const extractDocx = () => {
    const targetData = Object.keys(answers).map((item, index) => {
      const Q = [
        item.toString() + " " + questions?.[11]?.[item]?.toString(),
        answers?.[item],
      ];
      return Q;
    });
    let tabledTargetData = targetData
      .map((data) => {
        if (!(data[0] && data[1])) return null;
        return createAnswerDocxRows(data[0], data[1], 3);
      })
      .reduce((acc, cur) => acc.concat(cur), [])
      .filter((e) => e);

    tabledTargetData =
      tabledTargetData?.length > 0
        ? tabledTargetData
        : createAnswerDocxRows("데이터가 없습니다", "")
            .reduce((acc, cur) => acc.concat(cur), [])
            .filter((e) => e);

    const doc = new docx.Document();
    const table = new docx.Table({
      width: {
        size: 100,
        type: docx.WidthType.PERCENTAGE,
      },
      rows: tabledTargetData,
    });

    doc.addSection({
      properties: {},
      children: [table],
    });
    docx.Packer.toBlob(doc).then((blob) => {
      saveAs(blob);
    });
  };

  return (
    <div>
      <div className="minwidth">
        <div id="worksheet">
          <div className="basicWrap">
            <section className="d-flex justify-content-between youtube align-items-center px-5">
              <h2 className="h2 font-weight-normal">
                작성하는 법을 잘 모르겠다면? youtube 시청 후 작성하기
              </h2>
              <a
                className="btn btn-sm font-weight-normal squear-btn-basic radius pr-4 pl-4"
                href
              >
                과정설명 영상 보러가기
              </a>
            </section>
            <div style={{ marginTop: 22, borderBottom: "1px solid #aaa" }}>
              <span style={{ fontSize: 12, color: "#aaa", marginTop: 22 }}>
                STEP2. 가치제안서 {">"} 가치제안서 워크시트
              </span>
            </div>
            <form action className="dataForm mt40 " ref={htmlRef}>
              <section className="section">
                <div className="question">
                  B_9_1. 우리의 목표고객은 누구인가?
                  <span className="text-danger">*</span>
                </div>
                <div className="answer d-flex">
                  <div className="w-25 d-flex align-items-center disabled px-1">
                    {answers?.["b_8_2"] ? answers["b_8_2"] : "연동B_8_2"}
                  </div>
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_9_1"
                    id="b_9_1"
                    className="col textareaForm"
                    value={textareaValue("b_9_1", 1)}
                    onChange={textareaListener("b_9_1", 1)}
                  />
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_9_1", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_9_1_comment"
                    id="b_9_1_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == 'mento' ? false:true}
                    value={commentValue("b_9_1", 1)}
                    onChange={commentListener("b_9_1", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question">
                  B_9_2. 목표고객의 요구사항(문제는) 무엇인가?
                  <span className="text-danger">*</span>
                </div>
                <div className="answer d-flex">
                  <div className="w-25 d-flex align-items-center disabled px-1">
                    {answers?.["b_8_5"] ? answers["b_8_5"] : "연동B_8_5"}
                  </div>
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_9_2"
                    id="b_9_2"
                    className="col textareaForm"
                    value={textareaValue("b_9_2", 1)}
                    onChange={textareaListener("b_9_2", 1)}
                  />
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_9_2", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_9_2_comment"
                    id="b_9_2_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == 'mento' ? false:true}
                    value={commentValue("b_9_2", 1)}
                    onChange={commentListener("b_9_2", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question">
                  B_9_3.제공할 제품/서비스는 무엇인가?
                  <span className="text-danger">*</span>
                </div>
                <div className="answer d-flex">
                  <div className="w-25 d-flex align-items-center disabled px-1">
                    {answers?.["b_8_1"] ? answers["b_8_1"] : "연동B_8_1"}
                  </div>
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_9_3"
                    id="b_9_3"
                    className="col textareaForm"
                    value={textareaValue("b_9_3", 1)}
                    onChange={textareaListener("b_9_3", 1)}
                  />
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_9_3", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_9_3_comment"
                    id="b_9_3_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == 'mento' ? false:true}
                    value={commentValue("b_9_3", 1)}
                    onChange={commentListener("b_9_3", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question">
                  B_9_4. 제공할 제품/서비스는 어떻게 요구사항(문제)를 해결하고,
                  어떤 혜택을 제공하는가?<span className="text-danger">*</span>
                </div>
                <div className="answer d-flex">
                  <div className="w-25 d-flex align-items-center disabled px-1">
                    {answers?.["b_8_3"] ? answers["b_8_3"] : "연동B_8_3"}
                  </div>
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_9_4"
                    id="b_9_4"
                    className="col textareaForm"
                    value={textareaValue("b_9_4", 1)}
                    onChange={textareaListener("b_9_4", 1)}
                  />
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_9_4", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_9_4_comment"
                    id="b_9_4_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == 'mento' ? false:true}
                    value={commentValue("b_9_4", 1)}
                    onChange={commentListener("b_9_4", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question">
                  B_9_5. 기존의 고객의 해결방안(경재자의 제품/서비스)은 무엇이며
                  <span className="text-danger">*</span>
                </div>
                <div className="answer d-flex">
                  <div className="w-25 align-items-center disabled px-1">
                    <div>
                      {answers?.["b_3_1_1"] ? answers["b_3_1_1"] : "b_3_1_1"}
                    </div>
                    <div>
                      {answers?.["b_3_1_2"] ? answers["b_3_1_2"] : "b_3_1_2"}
                    </div>
                  </div>
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_9_5"
                    id="b_9_5"
                    className="col textareaForm"
                    value={textareaValue("b_9_5", 1)}
                    onChange={textareaListener("b_9_5", 1)}
                  />
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_9_5", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_9_5_comment"
                    id="b_9_5_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == 'mento' ? false:true}
                    value={commentValue("b_9_5", 1)}
                    onChange={commentListener("b_9_5", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question">
                  B_9_6. 기존 경쟁자는 어떻게 문제를 해결했는가?, 무엇을
                  만족시키지 못하였는가?<span className="text-danger">*</span>
                </div>
                <div className="answer d-flex">
                  <div className="w-25 align-items-center disabled px-1">
                    <div>
                      {answers?.["b_3_1_4"] ? answers["b_3_1_4"] : "b_3_1_4"}
                    </div>
                    <div>
                      {answers?.["b_3_1_5"] ? answers?.["b_3_1_5"] : "b_3_1_5"}
                    </div>
                    <div>
                      {answers?.["b_3_1_6"] ? answers?.["b_3_1_6"] : "b_3_1_6"}
                    </div>
                  </div>
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_9_6"
                    id="b_9_6"
                    className="col textareaForm"
                    value={textareaValue("b_9_6", 1)}
                    onChange={textareaListener("b_9_6", 1)}
                  />
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_9_6", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_9_6_comment"
                    id="b_9_6_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == 'mento' ? false:true}
                    value={commentValue("b_9_6", 1)}
                    onChange={commentListener("b_9_6", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question">
                  B_9_7. 경쟁자의 제품/서비스의 부족점을 우리 제품/서비스는
                  어떻게 해결하는가?<span className="text-danger">*</span>
                </div>
                <div className="answer d-flex">
                  <div className="w-25 align-items-center disabled px-1">
                    <div>{answers?.["a_2_7"] ? answers["a_2_7"] : "a_2_7"}</div>
                    <div>{answers?.["a_2_8"] ? answers["a_2_8"] : "a_2_8"}</div>
                    <div>{answers?.["a_2_9"] ? answers["a_2_9"] : "a_2_9"}</div>
                  </div>
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_9_7"
                    id="b_9_7"
                    className="col textareaForm"
                    value={textareaValue("b_9_7", 1)}
                    onChange={textareaListener("b_9_7", 1)}
                  />
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_9_7", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_9_7_comment"
                    id="b_9_7_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == 'mento' ? false:true}
                    value={commentValue("b_9_7", 1)}
                    onChange={commentListener("b_9_7", 1)}
                  />
                </div>
              </section>
            </form>
            <section className="btnArea d-flex align-items-center justify-content-end">
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={() => extractPdf(htmlRef.current)}
              >
                PDF
              </button>
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={extractDocx}
              >
                다운로드
              </button>
              <div className="btn btn-sm squear-btn-border-basic mr-2">
                <Link to={`/worksheets/${worksheetId || "new"}/worksheet11/`}>
                  이전으로
                </Link>
              </div>
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={saveWorksheet(true)}
              >
                제출
              </button>
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={saveWorksheet(false)}
              >
                저장하기
              </button>
              <div
                className="btn btn-sm squear-btn-basic "
                onClick={() => {
                  let keyArray = [
                    "b_9_1",
                    "b_9_2",
                    "b_9_3",
                    "b_9_4",
                    "b_9_5",
                    "b_9_6",
                    "b_9_7",
                  ];
                  listeners.checkValue(keyArray, answers, worksheetId, 13);
                }}
              >
                <Link>다음으로</Link>
              </div>
            </section>
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default Worksheet12;
