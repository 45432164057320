import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminPopupRow from "./AdminPopupRow";
import { reqGetPopups, reqPostPopups, selectPopup } from "./sliceAdminPopup";

const AdminPopup = () => {
  const popups = useSelector((state) => state.admin.popup.popups.slice().sort((a,b) => a.id.localeCompare(b.id)));
  const dispatch = useDispatch();
  const [popupCount, setPopupCount] = useState(0);

  useEffect(() => {
    dispatch(reqGetPopups());
  }, []);

  useEffect(() => {
    setPopupCount(popups.length);
  }, [popups.length]);

  const renderedInputRow = new Array(popupCount).fill(1).map((_, index) => {
    return <AdminPopupRow key={index + 1} idx={index + 1} />;
  });

  return (
    <div id="wrapper">
      <div id="container">
        <h1 id="container_title">
          팝업관리
          <div className="float-right">
            {popupCount < 2 && (
              <button
                className="btn btn-sm squear-basic-btn"
                onClick={(e) => {
                  setPopupCount(Math.min(popupCount + 1, 2));
                }}
              >
                추가
              </button>
            )}{" "}
          </div>
        </h1>

        <div className="container_wr row">
          <div className="col-12">
            <div className="float-right">
              {" "}
              팝업 추가는 2개까지 가능합니다.&nbsp;&nbsp;
            </div>
            <table className="table stepTable tableStyle">
              <colgroup>
                <col width="5%" /> {/*번호*/}
                <col /> {/*링크*/}
                <col width="60%" />
                <col width="15%" /> {/*관리*/}
              </colgroup>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>링크</th>
                  <th>이미지</th>
                  <th>관리</th>
                </tr>
              </thead>
              <tbody>{renderedInputRow}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPopup;
