import "./Worksheet.css";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import $ from "jquery";

const WorksheetInfo = () => {
  const auth = useRef(firebase.auth()).current;
  const db = useRef(firebase.firestore()).current;
  const [worksheet, setWorksheet] = useState(null);
  //
  useEffect(() => {
    console.log(auth.currentUser);
    if (auth.currentUser != null) {
      db.collection("worksheets")
        .get()
        .then((querySnapshot) => {
          let worksheetArray = [];

          let proms = querySnapshot.docs.map(
            (doc) =>
              new Promise((res, err) => {
                let data = doc.data();
                data.id = doc.id;
                console.log(data, auth.currentUser);
                if (
                  data.mentee == auth.currentUser.uid &&
                  data.mento &&
                  data.mento !== ""
                ) {
                  db.collection("users")
                    .doc(data.mento)
                    .get()
                    .then((doc) => {
                      data.mentoName = doc.data().name;
                      return res(data);
                    })
                    .catch((e) => err(e));
                } else if (data.mentee == auth.currentUser.uid) {
                  return res(data);
                } else {
                  return res();
                }
              })
          );
          Promise.all(proms).then((arr) => {
            for (const v of arr) {
              if (v) worksheetArray.push(v);
            }
            setWorksheet(worksheetArray);
          });
        });
    }
  }, [auth]);

  return (
    <div>
      <div className="minwidth">
        <hr />
        <div id="mymain" className="main ">
          <div
            id="topNav"
            className="d-flex justify-content-start align-items-end pb-2 "
          >
            <h1 className="h1 m-0 font-weight-bold">교육신청소개 </h1>
            <ul id="topNavList" className="d-flex">
              <li className="active">
                <Link to="/worksheet_info/">교육신청소개</Link>
              </li>
            </ul>
          </div>
          <div id="education" className="subWrap">
            {/* #sub */}
            <div className="basicWrap pt40">
              <span className="sound_only">교육신청소개</span>
              <div>
                {/* <img
                  src="https://via.placeholder.com/1200x450?text=education+intro+banner"
                  alt
                /> */}
              </div>
              <section className="box1 mt40">
                <div className="row">
                  <p className="col">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    <br />
                    Alias excepturi dolor perferendis nesciunt maiores quasi
                    sunt quas facere quaerat debitis vero magni sint minus nam
                    tempora esse, minima, voluptates quidem.
                  </p>
                  <div className="col-2 d-flex justify-content-end align-items-center">
                    {/* 워크시트가 존재하면 스타트 눌렀을시 마이페이지로 이동하게 수정하기 */}
                    {auth.currentUser ? (
                      <Link
                        onClick={() => {
                          //
                          db.collection("mentee")
                            .doc(auth.currentUser.uid)
                            .get()
                            .then((doc) => {
                              if (doc.exists) {
                                if (!doc.data().agree) {
                                  alert(
                                    "승인되지 않은 멘티입니다. 관리자에게 문의해주세요."
                                  );
                                  return;
                                } else {
                                  let submitStatus = true;
                                  worksheet.map((item, index) => {
                                    console.log(item);
                                    console.log(item.finalSubmit);
                                    if (!item.finalSubmit) {
                                      console.log(
                                        "멘토가 없는게 한개라도 있음..."
                                      );
                                      submitStatus = false;
                                    }
                                  });
                                  // 제출안된게있을때
                                  if (!submitStatus) {
                                    // alert('진행중인 워크시트가 있습니다. 마이페이지에서 확인해주세요.')
                                    $(".modal_mentee_mypage").css(
                                      "display",
                                      "block"
                                    );
                                  } else {
                                    const win = window.open(
                                      "/worksheets/new/worksheet1/",
                                       "_blank"
                                       );
                                    win.focus();
                                  }
                                }
                              }
                            });
                        }}
                      >
                        <button
                          className="btn btn-sm squear-btn-basic"
                          id="startBtn"
                        >
                          START
                        </button>
                      </Link>
                    ) : (
                      <Link to="/login">
                        <button
                          className="btn btn-sm squear-btn-basic"
                          id="startBtn"
                        >
                          START
                        </button>
                      </Link>
                    )}
                  </div>
                </div>
              </section>
            </div>
          </div>{" "}
          {/* #sub */}
          {/* 회원레벨 / 2이상이면 회원 */}
          <input type="hidden" id="level" defaultValue={1} />
        </div>
        {/* } 콘텐츠 끝 */}
        <hr />
      </div>
      {Modal(db)}
    </div>
  );
};

const Modal = (db) => {
  return (
    <div className="modal_mentee_mypage">
      <div>
        <div className="content">
          진행중인 워크시트가 있습니다. 새로운 워크시트를 작성하시겠습니까?
        </div>
        <div className="btns">
          <div
            className="btn confirm"
            onClick={() => {
              modalConfirm(db);
            }}
          >
            확인
          </div>
          <div
            className="btn cancel"
            onClick={() => {
              modalCancel();
            }}
          >
            <Link to="/mypage">취소</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const modalConfirm = (db) => {
  $(".modal_mentee_mypage").css("display", "none");
  const win = window.open(
    "/worksheets/new/worksheet1/",
     "_blank"
     );
  win.focus();
};

const modalCancel = () => {
  $(".modal_mentee_mypage").css("display", "none");
};
export default WorksheetInfo;
