import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

import WorksheetHeader from "./WorksheetHeader";
import WorksheetNav from "./WorksheetNav";
import WorksheetRightNav from "./WorksheetRightNav";
import WorksheetFooter from "./WorksheetFooter";

import Worksheet1 from "./worksheet1/Worksheet1";
import Worksheet2 from "./worksheet2/Worksheet2";
import Worksheet3 from "./worksheet3/Worksheet3";
import Worksheet4 from "./worksheet4/Worksheet4";
import Worksheet5 from "./worksheet5/Worksheet5";
import Worksheet6 from "./worksheet6/Worksheet6";
import Worksheet7 from "./worksheet7/Worksheet7";
import Worksheet8 from "./worksheet8/Worksheet8";
import Worksheet9 from "./worksheet9/Worksheet9";
import Worksheet10 from "./worksheet10/Worksheet10";
import Worksheet11 from "./worksheet11/Worksheet11";
import Worksheet12 from "./worksheet12/Worksheet12";
import Worksheet14 from "./worksheet14/Worksheet14";
import WorksheetSend from "./worksheet15/Worksheet15";
import { useEffect, useMemo, useRef, useState } from "react";
import FiveForces from "./FiveForces";
import IndustryMap from "./IndustryMap";
import Swot from "./Swot";
import Report from "./report/Report";

import Q from "./questions.json";
import Pamm from "./Pamm";
import Pmc from "./Pmc";

//
const WorksheetHome = ({ worksheetId, userType }) => {
  const auth = useRef(firebase.auth()).current;
  const db = useRef(firebase.firestore()).current;
  const storage = useMemo(() => firebase.storage(), []);
  const [isNew, setIsNew] = useState(false);
  const match = useRouteMatch();
  const [storeAnswers, setStoreAnswers] = useState({});
  const history = useHistory();
  const [authState, setAuthState] = useState("init");

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      console.log("=================", user);
      console.log("-----------------", auth.currentUser);
      // if (user) {
      setAuthState("login");
      // } else {
      //   setAuthState("logout");
      // }
    });
  });

  useEffect(() => {
    if (!worksheetId) return;
    db.collection("worksheets")
      .doc(worksheetId)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          setIsNew(true);
          return;
        }
      });
  }, [db, worksheetId]);

  /**
   * 1: 문제넘버
   * 2: 항목넘버
   */
  // const radioDefaultChecked = (answers, setAnswers) => (qId, r) => {
  //   if (!answers[qId]?.singleAnswer) {
  //     const clone = { ...answers };
  //     clone[qId] = clone[qId] || {};
  //     clone[qId].singleAnswer = r;
  //     setAnswers(clone);
  //   }
  //   return radioChecked(answers)(qId, r);
  // };
  // const radioChecked = (answers) => (qId, r) => {
  //   if (!answers?.[qId]?.singleAnswer) {
  //     return;
  //   }
  //   return answers?.[qId]?.singleAnswer == r ? true : false;
  // };
  // const radioListener = (answers, setAnswers) => (qId, r) => (e) => {
  //   if(userType == 'mento'){
  //     return
  //   }
  //   const clone = { ...answers };
  //   clone[qId] = clone[qId] || {};
  //   clone[qId].singleAnswer = r;
  //   setAnswers(clone);
  // };
  const radioDefaultChecked = (answers, setAnswers) => (qId, r) => {
    if (!answers[qId]) {
      const clone = { ...answers };
      clone[qId] = clone[qId] || {};
      clone[qId] = r;
      setAnswers(clone);
    }
    return radioChecked(answers)(qId, r);
  };
  const radioChecked = (answers) => (qId, r) => {
    if (!answers?.[qId]) {
      return;
    }
    return answers?.[qId] == r ? true : false;
  };
  const radioListener = (answers, setAnswers) => (qId, r) => (e) => {
    if (userType == "mento") {
      return;
    }
    const clone = { ...answers };
    clone[qId] = clone[qId] || {};
    clone[qId] = r;
    setAnswers(clone);
  };
  //

  // const checkboxChecked = (answers) => (qId, n) => {
  //   return answers?.[qId]?.multiAnswers?.[n] ? true : false;
  // };
  // const checkboxListener = (answers, setAnswers) => (qId, n) => (e) => {
  //   if(userType == 'mento'){
  //     return
  //   }
  //   const clone = { ...answers };

  //   clone[qId] = clone[qId] || {};
  //   clone[qId].multiAnswers = clone[qId].multiAnswers || {};

  //   clone[qId].multiAnswers[n] = e.target.checked;
  //   setAnswers(clone);
  // };
  const checkboxChecked = (answers) => (qId, n) => {
    return answers?.[qId]?.includes(n);
  };
  const checkboxListener = (answers, setAnswers) => (qId, n) => (e) => {
    if (userType == "mento") {
      return;
    }
    const clone = { ...answers };

    if (clone?.[qId] == undefined) {
      clone[qId] = [];
    }

    if (clone[qId].includes(n)) {
      const idx = clone[qId].indexOf(n);
      if (idx > -1) clone[qId].splice(idx, 1);
    } else {
      clone[qId].push(n);
    }
    setAnswers(clone);
  };
  const fileValue = (answers) => (qId, n) => {
    console.log("fileValue", qId + "_file" + n, answers?.[qId + "_file" + n]);
    return answers?.[qId + "_file" + n];
  };
  // const fileListener = (answers, setAnswers) => (qId, n, del = false, save = null) => (e) => {
  const fileListener = (answers, setAnswers) => (qId, n) => (e) => {
    console.log("fileListener", qId, n, e.target.files);
    // if(del){
    //   console.log('이미지가 있고 삭제를 눌렀을때?')
    //   //
    //   const fileUrl = answers?.[qId + "_file" + n]
    //     ? answers?.[qId + "_file" + n]
    //     : null;
    //   storage.refFromURL(fileUrl).delete().then(()=>{
    //     console.log('삭제 성공')
    //     // answer도 지워야됨
    //     const clone = { ...answers };

    //     delete clone[qId + "_file" + n]
    //     setAnswers(clone);
    //     if(save != null)
    //       save(true, clone)
    //   }).catch((e=>{
    //     console.log(e)
    //     console.log('삭제 실패')
    //   }))
    //   //
    //   return
    // }
    try {
      if (e.target.files[0].size == undefined) {
        alert("파일이 잘못되었습니다");
        return;
      }
      if (e.target.files[0].size >= 2097152) {
        alert("파일 사이즈를 10mb이하로 줄여주세요");
        return;
      }

      const fileUrl = answers?.[qId + "_file" + n]
        ? answers?.[qId + "_file" + n]
        : null;

      // if (fileUrl) {
      //   const fileRef = storage.refFromURL(fileUrl);
      //   fileRef.delete();
      // }

      storage
        .ref(
          "user/" +
            auth.currentUser.uid +
            "/worksheets/worksheet3/" +
            qId +
            "_file"
        )
        .put(e.target.files[0])
        .then((snapshot) => {
          return snapshot.ref.getDownloadURL();
        })
        .then((url) => {
          const clone = { ...answers };

          clone[qId + "_file" + n] = url;
          setAnswers(clone);
        })
        .catch((e) => console.log("err", e));
    } catch (e) {
      console.log("err", e);
    }
  };
  //
  const fileDeleteListener = (answers, setAnswers) => (qId, n) => (e) => {
    // console.log("qId_file", answers?.[qId + "_file" + n]);
    const fileUrl = answers?.[qId + "_file" + n]
      ? answers?.[qId + "_file" + n]
      : null;
    if (fileUrl) {
      const fileRef = storage.refFromURL(fileUrl);
      fileRef.delete();
    }
    const cloneAnswers = { ...answers };
    delete cloneAnswers[qId + "_file" + n];
    setAnswers(cloneAnswers);
  };

  // const textareaValue = (answers) => (qId, n) => {
  //   return answers?.[qId]?.multiAnswers?.[n] || "";
  // };
  // const textareaListener = (answers, setAnswers) => (qId, n) => (e) => {
  //   if(userType == 'mento'){
  //     return
  //   }
  //   console.log(answers, setAnswers, qId, n, e.target.value);
  //   const clone = { ...answers };

  //   clone[qId] = clone[qId] || {};
  //   clone[qId].multiAnswers = clone[qId].multiAnswers || {};

  //   clone[qId].multiAnswers[n] = e.target.value;
  //   setAnswers(clone);
  // };

  const textareaValue = (answers) => (qId) => {
    return answers?.[qId] || "";
  };
  const textareaListener = (answers, setAnswers) => (qId) => (e) => {
    if (userType == "mento") {
      return;
    }
    // console.log(answers, setAnswers, qId, n, e.target.value);
    const clone = { ...answers };

    clone[qId] = clone[qId] || {};

    clone[qId] = e.target.value;
    setAnswers(clone);
  };
  //
  const urlValue = (answers) => (qId, n) => (fileIdx) => {
    return answers?.[qId]?.multiAnswers?.[n]?.[fileIdx] || "";
  };

  //
  const commentValue = (answers) => (qId, n) => {
    return answers?.[qId + "_comment"] || "";
  };
  const commentListener = (answers, setAnswers) => (qId, n) => (e) => {
    // console.log(answers, setAnswers, qId, n, e.target.value);
    const clone = { ...answers };

    clone[qId + "_comment"] = clone[qId + "_comment"] || {};

    clone[qId + "_comment"] = e.target.value;
    setAnswers(clone);
  };
  //
  const storeAnswersValue = () => {
    return storeAnswers;
  };

  const storeAnswersListener = (data) => {
    setStoreAnswers(data);
  };
  const checkValue = (keyArray, answers, worksheetId, page) => {
    let msg = "";
    keyArray.map((item, index) => {
      if (answers?.[item] == undefined || answers?.[item]?.length == 0) {
        msg = "빈값";
      } else {
      }
    });
    if (msg == "빈값") {
      alert("빈값이 있습니다. 작성하고 저장해주세요.");
    } else if (userType === "admin") {
      // 어드민은 다음으로 넘어감
      history.push(`/worksheets/${worksheetId}/worksheet` + page + `/`);
    } else if (JSON.stringify(storeAnswers) === JSON.stringify(answers)) {
      // 다음으로 넘어가도됨;
      history.push(`/worksheets/${worksheetId}/worksheet` + page + `/`);
    } else {
      // console.log(
      //   "변경된 값이 있습니다. 저장한뒤에 다음으로 넘어갈수 있습니다."
      // );
      alert("저장을 해야 다음으로 넘어갈 수 있습니다.");
    }
  };
  //
  const extractExcel = (page) => (e) => {
    alert("준비 중 입니다.");
    // let data = Object.entries(storeAnswers)
    //   .filter(([key, value]) => {
    //     return Object.keys(Q[page]).includes(key);
    //   })
    //   .map(([key, value]) => {
    //     let v = value;
    //     if (Array.isArray(v)) {
    //       v = v.reduce((acc, cur) => {
    //         return acc + (acc && cur ? " " : "") + cur.toString();
    //       }, "");
    //     }
    //     return [key, v];
    //   });
    // data = [["문제", "답안"]].concat(data);
    // console.log("answers:", data, Q);

    // const book = XLSX.utils.book_new();
    // const sheet = XLSX.utils.aoa_to_sheet(data);

    // XLSX.utils.book_append_sheet(book, sheet, "SheetJS");
    // XLSX.writeFile(book, `worksheet${page}.xlsx`);
  };

  const WorksheetHandlers = {
    radioDefaultChecked,
    radioChecked,
    radioListener,
    checkboxChecked,
    checkboxListener,
    textareaValue,
    textareaListener,
    commentValue,
    commentListener,
    fileValue,
    fileListener,
    fileDeleteListener,
    storeAnswersValue,
    storeAnswersListener,
    checkValue,
    // jsonTestQ,
    // jsonTestP,
    extractExcel,
  };

  return (
    <div id="worksheet">
      <WorksheetHeader />
      <WorksheetNav />
      <WorksheetRightNav />
      <Switch>
        {/* 유저 정보 없으면 worksheet 접근 못함 */}
        {authState === "logout" ? (
          <Route>
            <Redirect to="/" />
          </Route>
        ) : null}
        <Route path={`${match.path}/pamm`}>
          <Pamm userType={userType} listeners={WorksheetHandlers} />
        </Route>
        <Route path={`${match.path}/pmc`}>
          <Pmc userType={userType} listeners={WorksheetHandlers} />
        </Route>
        <Route path={`${match.path}/report`}>
          <Report userType={userType} listeners={WorksheetHandlers} />
        </Route>
        <Route path={`${match.path}/worksheet-send`}>
          <WorksheetSend userType={userType} listeners={WorksheetHandlers} />
        </Route>
        <Route path={`${match.path}/worksheet14`}>
          <Worksheet14 userType={userType} listeners={WorksheetHandlers} />
        </Route>
        <Route path={`${match.path}/worksheet12`}>
          <Worksheet12 userType={userType} listeners={WorksheetHandlers} />
        </Route>
        <Route path={`${match.path}/worksheet11`}>
          <Worksheet11 userType={userType} listeners={WorksheetHandlers} />
        </Route>
        <Route path={`${match.path}/worksheet10`}>
          <Worksheet10 userType={userType} listeners={WorksheetHandlers} />
        </Route>
        <Route path={`${match.path}/worksheet9`}>
          <Worksheet9 userType={userType} listeners={WorksheetHandlers} />
        </Route>
        <Route path={`${match.path}/worksheet8`}>
          <Worksheet8 userType={userType} listeners={WorksheetHandlers} />
        </Route>
        <Route path={`${match.path}/worksheet7`}>
          <Worksheet7 userType={userType} listeners={WorksheetHandlers} />
        </Route>
        <Route path={`${match.path}/worksheet6`}>
          <Worksheet6 userType={userType} listeners={WorksheetHandlers} />
        </Route>
        <Route path={`${match.path}/worksheet5`}>
          <Worksheet5 userType={userType} listeners={WorksheetHandlers} />
        </Route>
        <Route path={`${match.path}/worksheet4`}>
          <Worksheet4 userType={userType} listeners={WorksheetHandlers} />
        </Route>
        <Route path={`${match.path}/worksheet3`}>
          <Worksheet3 userType={userType} listeners={WorksheetHandlers} />
        </Route>
        <Route path={`${match.path}/worksheet2`}>
          <Worksheet2 userType={userType} listeners={WorksheetHandlers} />
        </Route>
        <Route path={`${match.path}/worksheet1`}>
          <Worksheet1 userType={userType} listeners={WorksheetHandlers} />
        </Route>
        <Route path={`${match.path}/forces`}>
          <FiveForces userType={userType} listeners={WorksheetHandlers} />
        </Route>
        <Route path={`${match.path}/industrymap`}>
          <IndustryMap userType={userType} listeners={WorksheetHandlers} />
        </Route>
        <Route path={`${match.path}/swot`}>
          <Swot userType={userType} listeners={WorksheetHandlers} />
        </Route>
        <Route>
          <Redirect to="/worksheet_info" />
        </Route>
      </Switch>
      <WorksheetFooter />
    </div>
  );
};

export default WorksheetHome;
