import { Link } from 'react-router-dom';
import '../../mypage.css'

// 명칭 변경하자...
const WorksheetListDeatil = () => {
    return(
        <div>
            <div className="minwidth">
                <hr />
                <div id="mymain" className="main ">
                <div
                    id="topNav"
                    className="d-flex justify-content-start align-items-end pb-2 "
                >
                    <h1 className="h1 m-0 font-weight-bold">마이페이지</h1>
                    <ul id="topNavList" className="d-flex">
                    
                    <li className="active position-relative">
                        <Link to="/mypage/mentee/mypage">워크시트</Link>
                        <span className="position-absolute feedback">99</span>
                    </li>
                    <li className="nav-item">
                        {/* <Link to=''>내정보</Link> */}
                        내정보
                    </li>
                    </ul>
                </div>
                <div id="mypage" className="subWrap">
                    <div className="basicWrap pt40">
                        <div className="worksheet_list">
                            <table className="table text-center table-theme3">
                                <colgroup>
                                    <col width="60px" />
                                    <col />
                                    <col width="120px" />
                                    <col width="120px" />
                                </colgroup>
                                <thead className="thead-blue">
                                    <tr>
                                    <th className="font-weight-normal">no</th>
                                    <th className="font-weight-normal">제목</th>
                                    <th className="font-weight-normal">코멘트</th>
                                    <th className="font-weight-normal">수정일</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td>1</td>
                                    <td>워크시트 이름</td>
                                    <td>홍길동</td>
                                    <td>2020.01.01</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                    <td>2</td>
                                    <td onClick={()=>{alert('준비중입니다.')}}>워크시트 이름</td>
                                    <td><div className="btn-xs squear-btn-basic">멘토신청</div></td>
                                    <td>2020.01.01</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                    <td>3</td>
                                    <td onClick={()=>{alert('준비중입니다.')}}>워크시트 이름</td>
                                    <td></td>
                                    <td><div className="btn-xs squear-btn-basic">진행중</div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                </div>
                <hr />
            </div>
        </div>
    )
}

export default WorksheetListDeatil;