import { useEffect, useMemo, useRef, useState } from "react";
import "./PostSearch.css";
import $ from 'jquery';

import DaumPostcode from "react-daum-postcode";

const PostSearch = ({onComplete}) => {
    // console.log(onComplete)
    return(
        <div className="postModal" onClick={()=>{
            stateChangePostModal()
        }}>
            <div className="post">
                <DaumPostcode onComplete={onComplete}/>
            </div>
        </div>
    )
};

const stateChangePostModal = () => {
    console.log($('.postModal').css('display'))
    let disp = $('.postModal').css('display')
    if(disp=='block'){
      $('.postModal').css('display','none')
    }else{
      $('.postModal').css('display','block')
    }
    // 
  }

export default PostSearch;