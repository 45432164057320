import "./Worksheet.css";
import logo from "../../image/main/logo.svg";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { useState } from "react";

const WorksheetHeader = () => {
  const [url, setUrl] = useState("");
  const match = useRouteMatch();
  const history = useHistory();

  const warnAlert = (url) => {
    console.log(
      "해당 페이지를 나가면 데이터가 유실될수있습니다. 그래도 나가시겠습니까?"
    );
    // 예, 아니오
    let modal = document.getElementById("warnAlertModal");
    function addClass(element, className) {
      element.className += " " + className;
    }
    addClass(modal, "show");
    console.log(url);
    setUrl(url);
  };

  return (
    <header className="header">
      <nav className="basicWrap d-flex justify-content-between">
        <ul className="d-flex align-items-center">
          <li className="w-25 logo">
            <a
              onClick={() => {
                warnAlert("/");
              }}
            >
              <img src={logo} alt="logo" />
            </a>
          </li>
        </ul>
        <ul className="d-flex align-items-center nav">
          <li>
            <a>STEP1. 아이디어 설명</a>
            <ul className="sub-nav">
              <li>
                <Link
                  onClick={() => {
                    warnAlert(match.url + "/worksheet1");
                  }}
                >
                  의도워크시트
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    warnAlert(match.url + "/worksheet2");
                  }}
                >
                  아이디어설명
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    warnAlert(match.url + "/worksheet3");
                  }}
                >
                  믿을만한 워크시트
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <a>STEP2. 가치제안서</a>
            <ul className="sub-nav">
              <li>
                <Link
                  onClick={() => {
                    warnAlert(match.url + "/worksheet4");
                  }}
                >
                  고객조사 워크시트
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    warnAlert(match.url + "/worksheet5");
                  }}
                >
                  세분시장별 고객문제 워크시트
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    warnAlert(match.url + "/worksheet6");
                  }}
                >
                  경쟁 제품/서비스 비교 워크시트
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    warnAlert(match.url + "/worksheet7");
                  }}
                >
                  지속적인 고객 니즈 워크시트
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    warnAlert(match.url + "/worksheet8");
                  }}
                >
                  기술(역량)명세 워크시트
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    warnAlert(match.url + "/worksheet9");
                  }}
                >
                  기능분석 워크시트
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    warnAlert(match.url + "/worksheet10");
                  }}
                >
                  전달 역량 워크시트
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    warnAlert(match.url + "/worksheet11");
                  }}
                >
                  기회개발 워크시트
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    warnAlert(match.url + "/worksheet12");
                  }}
                >
                  가치제안서 워크시트
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <a>STEP3. 비즈니스 모델 캔버스</a>
            <ul className="sub-nav">
              <li>
                <Link
                  onClick={() => {
                    warnAlert(match.url + "/worksheet13");
                  }}
                >
                  채널요소
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    warnAlert(match.url + "/worksheet14");
                  }}
                >
                  비즈니스 모델 캔버스
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    warnAlert(match.url + "/report");
                  }}
                >
                  사업계획서 작성
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      {/*  */}
      <div
        className="modal fade main_modal pb-4 memtoListPopup"
        id="warnAlertModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header" style={{ border: 0 }}>
              <h5 className="modal-title" id="exampleModalLabel" />
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  let modal = document.getElementById("warnAlertModal");
                  function removeClass(element, className) {
                    var check = new RegExp("(\\s|^)" + className + "(\\s|$)");
                    element.className = element.className
                      .replace(check, " ")
                      .trim();
                  }
                  removeClass(modal, "show");
                  // 기능 추가
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row text-center">
                <div className="col-12">
                  <h4 className="h4" style={{
                    margin:16
                  }}>
                    해당 페이지를 나가면 데이터가 유실될수있습니다.<br/>
                    그래도 나가시겠습니까?
                  </h4>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm squear-btn-basic"
                id="applyFinish"
                data-dismiss="modal"
                onClick={() => {
                  let modal = document.getElementById("warnAlertModal");
                  function removeClass(element, className) {
                    var check = new RegExp("(\\s|^)" + className + "(\\s|$)");
                    element.className = element.className
                      .replace(check, " ")
                      .trim();
                  }
                  removeClass(modal, "show");
                  // 기능 추가
                  history.push(url);
                }}
              >
                예
              </button>
              <button
                type="button"
                className="btn btn-sm squear-btn-border-basic"
                data-dismiss="modal"
                onClick={() => {
                  let modal = document.getElementById("warnAlertModal");
                  function removeClass(element, className) {
                    var check = new RegExp("(\\s|^)" + className + "(\\s|$)");
                    element.className = element.className
                      .replace(check, " ")
                      .trim();
                  }
                  removeClass(modal, "show");
                  // 기능 추가
                }}
              >
                아니오
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
    </header>
  );
};

export default WorksheetHeader;
