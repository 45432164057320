import { Link, useHistory, useRouteMatch } from "react-router-dom";
import "../Worksheet.css";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import * as docx from "docx";
import { saveAs } from "file-saver";
import createAnswerDocxRows from "util/docx/createAnswerDocxRows";

const Report = ({ userType, listeners }) => {
  const [answers, setAnswers] = useState({});
  const db = useRef(firebase.firestore()).current;
  let match = useRouteMatch();
  const [worksheetId, setWorksheetId] = useState();
  const auth = useRef(firebase.auth()).current;
  const [commentVisible, setCommentVisible] = useState(false);
  const history = useHistory();

  useEffect(() => {
    console.log(userType);
    if (userType == "mento") {
      setCommentVisible(true);
    }
  }, [userType]);

  //
  useEffect(() => {
    setWorksheetId(match.params.id);

    db.collection("worksheets")
      .doc(match.params.id)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          return;
        }
        listeners.storeAnswersListener(doc.data().questionAnswers);
        setAnswers(doc.data().questionAnswers);
      });
  }, []);

  const saveWorksheet = (e) => {
    if (worksheetId && worksheetId != "new") {
      const accountInfo =
        userType == "mento"
          ? {
              mento: auth.currentUser.uid,
            }
          : {
              mentee: auth.currentUser.uid,
            };

      db.collection("worksheets")
        .doc(worksheetId)
        .update({
          ...accountInfo,
          questionAnswers: answers,
          page: 1,
        })
        .then(() => {
          alert("저장 성공!");
          listeners.storeAnswersListener(answers);
        })
        .catch((e) => alert(e));
      return;
    }
    console.log("\nanswers", {
      questionAnswers: {
        ...answers,
      },
      mentee: auth.currentUser.uid,
      mento: "",
      page: 1,
      endPage: 1,
    });
    db.collection("worksheets")
      .add({
        questionAnswers: answers,
        mentee: auth.currentUser.uid,
        page: 1,
      })
      .then((doc) => {
        setWorksheetId(doc.id);
        alert("저장 성공!");
        listeners.storeAnswersListener(answers);
        // setInitialAnswers(answers)
        history.replace(`/worksheets/${doc.id || "new"}/worksheet1/`);
      })
      .catch((e) => alert(e));
  };
  const extractDocx = () => {
    const targetData = Object.keys(listeners.jsonTestQ).map((item, index) => {
      const Q = [
        item.toString() + listeners.jsonTestQ?.[item]?.toString(),
        answers?.[item],
      ];
      return Q;

      // return(
      //   <>
      //   <tr key={item}>
      //   {answers?.[item] ? <td>{answers?.[item] ? item.toUpperCase() + ': ' + listeners.jsonTestQ[item]:null}</td>:null}
      //     {/* <td>{answers?.[item] ? item.toUpperCase() + ': ' + listeners.jsonTestQ[item]:null}</td> */}
      //   </tr>
      //   <tr>

      //     {/* <td> */}
      //       {console.log(answers[item])}
      //       {
      //           answers?.[item] ?
      //         <td>{answers[item]}</td>: null
      //       }

      //     {/* </td> */}
      //   </tr>
      //   </>
      // )
    });

    const marginOption = {
      margins: {
        left: 70,
      },
    };
    const centerOption = {
      verticalAlign: docx.VerticalAlign.CENTER,
    };

    let tabledTargetData = targetData
      .map((data) => {
        if (!(data[0] && data[1])) return null;
        return createAnswerDocxRows(data[0], data[1], 3, {
          ...marginOption,
          ...centerOption,
        });
      })
      .reduce((acc, cur) => acc.concat(cur), [])
      .filter((e) => e);

    tabledTargetData =
      tabledTargetData?.length > 0
        ? tabledTargetData
        : createAnswerDocxRows("데이터가 없습니다", "")
            .reduce((acc, cur) => acc.concat(cur), [])
            .filter((e) => e);

    const doc = new docx.Document();
    const table = new docx.Table({
      width: {
        size: 100,
        type: docx.WidthType.PERCENTAGE,
      },
      rows: tabledTargetData
    });

    doc.addSection({
      properties: {},
      children: [table],
    });
    docx.Packer.toBlob(doc).then((blob) => {
      saveAs(blob);
    });
  };
  //
  return (
    <>
      <div className="minwidth">
        {/*  콘텐츠 시작 { */}
        <div id="worksheet">
          {/* 상단 시작 { */}
          {/* <header className="header" include-html="/public/worksheets/worksheet_header.html" /> */}
          {/* } 상단 끝 */}
          {/* nav 시작 { */}
          <nav
            className="d-flex flex-column position-fixed fixed-left"
            id="worksheetNav"
            include-html="/public/worksheets/worksheet_nav.html"
          />
          {/* } nav 끝 */}
          <div className="basicWrap">
            <section className="d-flex justify-content-between youtube align-items-center px-5">
              <h2 className="h2 font-weight-normal">
                작성하는 법을 잘 모르겠다면? youtube 시청 후 작성하기
              </h2>
              <a
                className="btn btn-sm font-weight-normal squear-btn-basic radius pr-4 pl-4"
                href
              >
                과정설명 영상 보러가기
              </a>
            </section>
            <form action className="dataForm mt40">
              <table id="reporttable" className="table report">
                {/* {console.log(listeners.jsonTestQ)} */}
                <tbody>
                  {/* {console.log(Object.keys(listeners.jsonTestQ))} */}
                  {/* {Object.keys(listeners.jsonTestQ).map((item, index) => {
                    console.log(
                      item.toUpperCase() + ": " + listeners.jsonTestQ[item]
                    );
                    return (
                      <>
                        <tr key={item}>
                          {answers?.[item] ? (
                            <td>
                              {answers?.[item]
                                ? item.toUpperCase() +
                                  ": " +
                                  listeners.jsonTestQ[item]
                                : null}
                            </td>
                          ) : null}
                        </tr>
                        <tr>
                          {console.log(answers[item])}
                          {answers?.[item] ? <td>{answers[item]}</td> : null}
                        </tr>
                      </>
                    );
                  })} */}
                </tbody>
              </table>
            </form>
            <section className="btnArea d-flex align-items-center justify-content-end">
              <button
                id="click"
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={() => {
                  // alert("준비중입니다");
                  extractDocx();
                  // TableToExcel.convert(document.getElementById("reporttable"), {
                  //   name: "reporttable.xlsx",
                  //   sheet: {
                  //     name: "테스트 시트"
                  //   }
                  // });
                }}
              >
                다운로드
              </button>
              <button className="btn btn-sm squear-btn-border-basic mr-2">
                저장하기
              </button>
              <div className="btn btn-sm squear-btn-basic">
                <a href="/public/worksheets/worksheet2/worksheet2.html">
                  다음으로
                </a>
              </div>
            </section>
          </div>
        </div>
        {/* } 콘텐츠 끝 */}
        <hr />
        {/* 하단 시작 { */}
        {/* <footer include-html="/public/worksheets/worksheet_footer.html" /> */}
        {/* } 하단 시작  */}
      </div>
    </>
  );
};

export default Report;
