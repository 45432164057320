const CommentCheck = (userType, val) => {
    let result = null;
    if(userType != 'mento'){
        if(!val){
            result = {display: 'none'}
        }
    }
    return result
}

export default CommentCheck;