import { Link } from "react-router-dom";
import "feature/notice/notice.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotices, setNotices } from "./sliceNotice";

const List = () => {
  const dispatch = useDispatch();

  // 사용하는 데이터만 뽑아오기
  // 모든 데이터를 뽑아오면 그만큼 리렌더링 발생
  const noticeList = useSelector((state) => state.notice.noticeList);

  useEffect(() => {
    dispatch(fetchNotices());
  }, []);

  const renderedNotices = noticeList.map((e, idx) => {
    return (
      <tr key={idx.toString()}>
        <td className="td_num2">{idx + 1}</td>
        <td className="td_subject" style={{ paddingLeft: 0 }}>
          <div className="bo_tit">
            <Link to={"/notice/" + e.id}>{e.title}</Link>
          </div>
        </td>
        <td className="td_name sv_use text-center">
          <span className="sv_member">{e.author}</span>
        </td>
        <td className="td_datetime text-center">{e.date}</td>
      </tr>
    );
  });

  return (
    <div>
      <div className="minwidth">
        {/* 상단 시작 { */}
        {/* <header include-html="/public/header.html" /> */}
        {/* } 상단 끝 */}
        {/*  팝업시작 { */}
        {/* <div include-html="/public/popup.html" /> */}
        {/*  } 팝업시작  */}
        {/* 시장정보 확인하기 Modal { */}
        {/* <div include-html="/public/modal.html" /> */}
        {/* } 시장정보 확인하기 Modal */}
        <hr />
        {/* 콘텐츠 시작 { */}
        <div className="resizeWrap">
          <div className="basicWrap">
            {/* basicWrap */}
            <div
              id="topNav"
              className="d-flex justify-content-start align-items-end pb-2 mb40"
            >
              <h1 className="h1 m-0 font-weight-bold">공지사항 </h1>
              <ul id="topNavList" className="d-flex">
                <li className="active">
                  <Link to="/notice/list/">공지사항</Link>
                </li>
                <li className>
                  <Link to="/qna/write/">문의사항</Link>
                </li>
              </ul>
            </div>
            {/* 게시판 목록 시작 { */}
            <div id="bo_list" style={{ width: "100%" }}>
              {/* 게시판 카테고리 시작 { */}
              {/* } 게시판 카테고리 끝 */}
              <form name="fboardlist" id="fboardlist" action onSubmit method>
                <input type="hidden" name="bo_table" defaultValue="notice" />
                <input type="hidden" name="sfl" defaultValue />
                <input type="hidden" name="stx" defaultValue />
                <input type="hidden" name="spt" defaultValue />
                <input type="hidden" name="sca" defaultValue />
                <input
                  type="hidden"
                  name="sst"
                  defaultValue="wr_num, wr_reply"
                />
                <input type="hidden" name="sod" defaultValue />
                <input type="hidden" name="page" defaultValue={1} />
                <input type="hidden" name="sw" defaultValue />
                {/* 게시판 페이지 정보 및 버튼 시작 { */}
                <div id="bo_btn_top">
                  <ul className="btn_bo_user d-flex justify-content-end">
                    <li>
                      <button
                        type="button"
                        className="btn_bo_sch btn_b01 btn"
                        title="게시판 검색"
                      >
                        <i className="fa fa-search" aria-hidden="true" />
                        <span className="sound_only">게시판 검색</span>
                      </button>
                    </li>
                  </ul>
                </div>
                {/* } 게시판 페이지 정보 및 버튼 끝 */}
                <div className="tbl_head01 tbl_wrap">
                  <table>
                    <caption>공지사항 목록</caption>
                    <thead className="d-none">
                      <tr>
                        <th scope="col">번호</th>
                        <th scope="col">제목</th>
                        <th scope="col">글쓴이</th>
                        <th scope="col">날짜</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderedNotices}
                      {/* <tr className="even">
                                <td className="td_num2">2</td>
                                <td className="td_subject" style={{ paddingLeft: 0 }}>
                                <div className="bo_tit">
                                    <Link to='/notice/view/'>
                                    공지공지공지공지공지공지공지공지공지공지{" "}
                                    </Link>
                                </div>
                                </td>
                                <td className="td_name sv_use text-center">
                                <span className="sv_member">최고관리자</span>
                                </td>
                                <td className="td_datetime text-center">10-16</td>
                            </tr>
                            <tr className>
                                <td className="td_num2">1</td>
                                <td className="td_subject" style={{ paddingLeft: 0 }}>
                                <div className="bo_tit">
                                    <Link to="/notice/view/">
                                    공지사항입니다공지사항입니다공지사항입니다공지사항입니다공지사항입니다공지사항입니다{" "}
                                    </Link>
                                </div>
                                </td>
                                <td className="td_name sv_use text-center">
                                <span className="sv_member">test</span>
                                </td>
                                <td className="td_datetime text-center">10-16</td>
                            </tr> */}
                    </tbody>
                  </table>
                </div>
                {/* 페이지 */}
                {/* 페이지 */}
              </form>
              {/* 게시판 검색 시작 { */}
              <div className="bo_sch_wrap">
                <fieldset className="bo_sch">
                  <h3>검색</h3>
                  <form name="fsearch" method="get">
                    <input
                      type="hidden"
                      name="bo_table"
                      defaultValue="notice"
                    />
                    <input type="hidden" name="sca" defaultValue />
                    <input type="hidden" name="sop" defaultValue="and" />
                    <label htmlFor="sfl" className="sound_only">
                      검색대상
                    </label>
                    <select name="sfl" id="sfl">
                      <option value="wr_subject">제목</option>
                      <option value="wr_content">내용</option>
                      <option value="wr_name,1">글쓴이</option>
                    </select>
                    <label htmlFor="stx" className="sound_only">
                      검색어<strong className="sound_only"> 필수</strong>
                    </label>
                    <div className="sch_bar">
                      <input
                        type="text"
                        name="stx"
                        defaultValue
                        required
                        id="stx"
                        className="sch_input"
                        size={25}
                        maxLength={20}
                        placeholder=" 검색어를 입력해주세요"
                      />
                      <button type="submit" value="검색" className="sch_btn">
                        <i className="fa fa-search" aria-hidden="true" />
                        <span className="sound_only">검색</span>
                      </button>
                    </div>
                    <button type="button" className="bo_sch_cls" title="닫기">
                      <i className="fa fa-times" aria-hidden="true" />
                      <span className="sound_only">닫기</span>
                    </button>
                  </form>
                </fieldset>
                <div className="bo_sch_bg" />
              </div>
              {/* } 게시판 검색 끝 */}
            </div>
            {/* } 게시판 목록 끝 */}
          </div>
          {/* container_wr / basicWrap */}
        </div>
        {/* } 콘텐츠 끝 */}
        <hr />
        {/* 하단 시작 { */}
        {/* <footer include-html="/public/footer.html" /> */}
        {/* } 하단 시작  */}
      </div>
    </div>
  );
};

export default List;
