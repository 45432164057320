import { Field, Form, Formik } from "formik";
import { useEffect, useState, useRef } from "react";

import { Link, useHistory, useRouteMatch } from "react-router-dom";
import firebase from "firebase";

const FiveForces = ({ userType, listeners }) => {
  const db = useRef(firebase.firestore()).current;
  let match = useRouteMatch();
  const [worksheetId, setWorksheetId] = useState();
  const auth = useRef(firebase.auth()).current;
  const [commentVisible, setCommentVisible] = useState(false);
  const history = useHistory();

  useEffect(() => {
    console.log(userType);
    if (userType == "mento") {
      setCommentVisible(true);
    }
  }, [userType]);

  //
  useEffect(() => {
    setWorksheetId(match.params.id);

    db.collection("worksheets")
      .doc(match.params.id)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          return;
        }
        listeners.storeAnswersListener(doc.data().questionAnswers);
      });
  }, []);

  const saveWorksheet = (value) => {
    const resultAnswers = {
      ...(listeners.storeAnswersValue() || {}),
      ...value,
    };
    const accountInfo =
      userType == "mento"
        ? {
            mento: auth.currentUser.uid,
          }
        : {
            mentee: auth.currentUser.uid,
          };
    if (worksheetId && worksheetId != "new") {
      db.collection("worksheets")
        .doc(worksheetId)
        .update({
          ...accountInfo,
          questionAnswers: resultAnswers,
        })
        .then(() => {
          alert("저장 성공!");
          listeners.storeAnswersListener(resultAnswers);
        })
        .catch((e) => alert(e));
      return;
    }
    console.log("\nanswers", {
      questionAnswers: {
        ...resultAnswers,
      },
      mentee: auth.currentUser.uid,
      mento: "",
      page: 1,
      endPage: 1,
    });
    db.collection("worksheets")
      .add({
        questionAnswers: resultAnswers,
        mentee: auth.currentUser.uid,
        page: 1,
      })
      .then((doc) => {
        setWorksheetId(doc.id);
        alert("저장 성공!");
        listeners.storeAnswersListener(resultAnswers);
        history.replace(`/worksheets/${doc.id}/forces/`);
      })
      .catch((e) => alert(e));
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        force_1: listeners.storeAnswersValue().force_1,
        force_2: listeners.storeAnswersValue().force_2 || "",
        force_3: listeners.storeAnswersValue().force_3 || "",
        force_4: listeners.storeAnswersValue().force_4 || "",
        force_5: listeners.storeAnswersValue().force_5 || "",
        force_comment: listeners.storeAnswersValue().force_comment || "",
      }}
      onSubmit={(values, { setSubmitting }) => {
        saveWorksheet(values);
      }}
    >
      {(values, isSubmitting) => (
        <Form>
          <div className="minwidth">
            {/*  콘텐츠 시작 { */}
            <div id="worksheet">
              <div className="basicWrap forces otherTable">
                <table className="table">
                  <colgroup>
                    <col width="33%" /> <col width="33%" />
                  </colgroup>
                  <tbody>
                    {/* 1행 */}
                    <tr>
                      <th className="invisible">
                        <p>&nbsp;</p>
                      </th>
                      <th className="color1 text-center">
                        <p>Threat from new entrants</p>
                      </th>
                      <th className="invisible">
                        <p>&nbsp;</p>
                      </th>
                    </tr>
                    <tr>
                      <td>
                        <textarea
                          className="textareaForm invisible"
                          name="dummy"
                          cols={30}
                          rows={10}
                        />
                      </td>
                      <td>
                        <Field
                          className="textareaForm"
                          as="textarea"
                          name="force_1"
                          cols={30}
                          rows={10}
                          placeholder="Threat from new entrants"
                        />
                      </td>
                      <td>
                        <textarea
                          className="textareaForm invisible"
                          name="dummy"
                          cols={30}
                          rows={10}
                        />
                      </td>
                    </tr>
                    {/* 2행 */}
                    <tr>
                      <th className="color2">
                        <p>Bargaining Power of suppliers</p>
                      </th>
                      <th className="color5 text-center">
                        <p>Competitive Rivalry</p>
                      </th>
                      <th className="color3">
                        <p>Bargaining Power of Buyers</p>
                      </th>
                    </tr>
                    <tr>
                      <td>
                        <Field
                          className="textareaForm"
                          as="textarea"
                          name="force_4"
                          cols={30}
                          rows={10}
                          placeholder="Bargaining Power of suppliers"
                        />
                      </td>
                      <td>
                        <Field
                          className="textareaForm"
                          as="textarea"
                          name="force_2"
                          cols={30}
                          rows={10}
                          placeholder="Competitive Rivalry"
                        />
                      </td>
                      <td>
                        <Field
                          className="textareaForm"
                          as="textarea"
                          name="force_3"
                          cols={30}
                          rows={10}
                          placeholder="Bargaining Power of Buyers"
                        />
                      </td>
                    </tr>
                    {/* 3행 */}
                    <tr>
                      <th className="invisible">
                        <p>&nbsp;</p>
                      </th>
                      <th className="color4 text-center">
                        <p>Threat from Substitutions Product</p>
                      </th>
                      <th className="invisible">
                        <p>&nbsp;</p>
                      </th>
                    </tr>
                    <tr>
                      <td>
                        <textarea
                          className="textareaForm invisible"
                          name="dummy"
                          cols={30}
                          rows={10}
                        />
                      </td>
                      <td>
                        <Field
                          className="textareaForm"
                          as="textarea"
                          name="force_5"
                          cols={30}
                          rows={10}
                          placeholder="Threat from Substitutions Product"
                        />
                      </td>
                      <td>
                        <textarea
                          className="textareaForm invisible"
                          name="dummy"
                          cols={30}
                          rows={10}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="worksheetForm">
                  <section
                    className="section comment"
                    style={commentVisible ? null : { display: "none" }}
                  >
                    <div className="question font-weight-bold">Comment</div>
                    <div className="answer">
                      <Field
                        as="textarea"
                        name="force_comment"
                        id="comment"
                        className="textareaForm"
                        placeholder="멘토만 작성할 수 있는 구역입니다."
                      />
                    </div>
                  </section>
                </div>
                <section className="btnArea d-flex align-items-center justify-content-end mt-2">
                  <button className="btn btn-sm squear-btn-border-basic">
                    저장하기
                  </button>
                </section>
              </div>
            </div>
            {/* } 콘텐츠 끝 */}
            <hr />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FiveForces;
