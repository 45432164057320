import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "../mypage.css";
import $ from "jquery";

const Mentee = ({ user }) => {
  const db = useRef(firebase.firestore()).current;
  const [worksheet, setWorksheet] = useState(null);

  useEffect(() => {
    console.log(user.uid);
    db.collection("worksheets")
      .get()
      .then((querySnapshot) => {
        let worksheetArray = [];

        let proms = querySnapshot.docs.map(
          (doc) =>
            new Promise((res, err) => {
              let data = doc.data();
              data.id = doc.id;
              if (data.mentee == user.uid && data.mento && data.mento !== "") {
                db.collection("users")
                  .doc(data.mento)
                  .get()
                  .then((doc) => {
                    data.mentoName = doc.data().name;
                    return res(data);
                  })
                  .catch((e) => err(e));
              } else if (data.mentee == user.uid) {
                return res(data);
              } else {
                return res();
              }
            })
        );
        Promise.all(proms).then((arr) => {
          for (const v of arr) {
            if (v) worksheetArray.push(v);
          }
          setWorksheet(worksheetArray);
        });
      });
  }, []);

  const WorksheetList = () => {
    return worksheet.map((item, index) => {
      return (
        <tbody key={index}>
          <tr>
            {/* <td><input type="checkbox" name="worksheetCheck" value={item.id} /></td> */}
            <td>{index + 1}</td>
            <td>{item.title}</td>
            <td>
              {item.finalSubmit &&
              (!item.mento || item.mento == "" || !item.mentoAccept) ? (
                <div>-</div>
              ) : item.finalSubmit && item.mentoAccept === "accept" ? (
                item.mentoName
              ) : // 멘토 매칭되면 멘토 이름이 나와야됨 클릭시 멘토 기본정보도 볼수있어야됨
              item.mentoAccept != undefined ? (
                "제출완료"
              ) : (
                "-"
              )}
            </td>
            <td>
              {item.finalSubmit ? (
                <Link
                  to={`/worksheets/${item.id || "new"}/worksheet${
                    item.page ? item.page : "1"
                  }/`}
                  target="_blank"
                >
                  {console.log(item.page)}
                  <div className="btn-xs squear-btn-basic">진행중</div>
                </Link>
              ) : (
                <Link
                  to={`/worksheets/${item.id || "new"}/worksheet${
                    item.page ? item.page : "1"
                  }/`}
                  target="_blank"
                >
                  <div className="btn-xs squear-btn-basic">진행중</div>
                </Link>
              )}
            </td>
          </tr>
        </tbody>
      );
    });
  };

  return (
    <div>
      <div className="minwidth">
        <hr />
        <div id="mymain" className="main ">
          <div
            id="topNav"
            className="d-flex justify-content-start align-items-end pb-2 "
          >
            <h1 className="h1 m-0 font-weight-bold">마이페이지</h1>
            <ul id="topNavList" className="d-flex">
              <li className="active position-relative">
                <Link to="/mypage/mentee/mypage">워크시트</Link>
              </li>
              <li className="nav-item">
                {/* <Link to={"/register/mentee/" + user?.uid}>내정보</Link> */}
                <Link to={"/mypage/info/" + user?.uid}>내정보</Link>
              </li>
            </ul>
          </div>
          <div id="mypage" className="subWrap">
            {" "}
            <div className="basicWrap pt40">
              <span className="sound_only">마이페이지</span>

              {/* {worksheet == null ? null : worksheet.length < 1 ? (
                <div className="user_1 d-none  d-block">
                  <div>
                    <h1 className="h1">{"워크시트 작성을 완료해주세요 :)"}</h1>
                    <div className="btn squear-btn-basic mt40">
                      <Link
                        to={"/worksheets/new/worksheet1/"}
                        target="_blank"
                        className="detailView white"
                      >
                        {" "}
                        워크시트 작성하러가기
                      </Link>
                    </div>
                  </div>
                </div>
              ) : ( */}
              <div className="worksheet_list">
                <div
                  style={{
                    // flex: 1,
                    display: "inline-flex",
                    alignItems: "right",
                    justifyContent: "flex-end",
                  }}
                >
                  <div
                    className="btn squear-btn-basic mb40"
                    style={{ position: "relative", right: 0 }}
                  >
                    <Link
                      className="detailView white"
                      onClick={() => {
                        db.collection("mentee")
                          .doc(user.uid)
                          .get()
                          .then((doc) => {
                            if (doc.exists) {
                              if (!doc.data().agree) {
                                alert(
                                  "승인되지 않은 멘티입니다. 관리자에게 문의해주세요."
                                );
                                return;
                              } else {
                                let submitStatus = true;
                                worksheet.map((item, index) => {
                                  console.log(item);
                                  console.log(item.finalSubmit);
                                  if (!item.finalSubmit) {
                                    submitStatus = false;
                                  }
                                });
                                if (!submitStatus) {
                                  $(".modal_mentee_mypage .content").html(
                                    `진행중인 워크시트가 있습니다. 새로운 워크시트를 작성하시겠습니까?`
                                  );
                                  $("input[name=modalData]").val("워크시트");
                                  $(".modal_mentee_mypage").css(
                                    "display",
                                    "block"
                                  );
                                } else {
                                  const win = window.open(
                                    "/worksheets/new/worksheet1/"
                                    ,"_blank"
                                  );
                                  win.focus();
                                }
                              }
                            }
                          });
                      }}
                    >
                      {" "}
                      새로운 워크시트 작성
                    </Link>
                  </div>
                </div>
                <table className="table text-center table-theme3">
                  <colgroup>
                    <col width="60px" />
                    <col />
                    <col width="120px" />
                    <col width="120px" />
                  </colgroup>
                  <thead className="thead-blue">
                    <tr>
                      <th className="font-weight-normal">no</th>
                      <th className="font-weight-normal">제목</th>
                      <th className="font-weight-normal">멘토</th>
                      <th className="font-weight-normal">처리</th>
                    </tr>
                  </thead>
                  {console.log(worksheet)}
                  {worksheet != null && worksheet.length > 0 ? (
                    <WorksheetList />
                  ) : (
                    <tr>
                      <td></td>
                      <td>워크시트가 없습니다. 워크시트를 작성해주세요.</td>
                    </tr>
                  )}
                </table>
              </div>
              {/* )} */}
            </div>
          </div>{" "}
        </div>
        <hr />
      </div>
      {Modal(db)}
    </div>
  );
};

const Modal = (db) => {
  return (
    <div className="modal_mentee_mypage">
      <div>
        <input type="hidden" name="modalData" value="" />
        <div className="content">
          진행중인 워크시트가 있습니다. 새로운 워크시트를 작성하시겠습니까?
        </div>
        <div className="btns">
          <div
            className="btn confirm"
            onClick={() => {
              modalConfirm(db);
            }}
          >
            확인
          </div>
          <div
            className="btn cancel"
            onClick={() => {
              modalCancel();
            }}
          >
            취소
          </div>
        </div>
      </div>
    </div>
  );
};

const modalConfirm = (db) => {
  console.log($("input[name=modalData]").val());
  let val = $("input[name=modalData]").val();
  if (val == "삭제") {
    // 디비 직접 삭제
    $(".modal_mentee_mypage").css("display", "none");
    $("input[name=worksheetCheck]:checked").each(function (index, item) {
      db.collection("worksheets")
        .doc($(item).val())
        .get()
        .then((doc) => {
          if (doc.exists) {
            if (doc.data().mento != undefined) {
              // msg = '멘토가 연결되어 삭제하지 못합니다.';
            } else {
              // 여기서 삭제 필요
              console.log(doc.id);
              db.collection("worksheets")
                .doc(doc.id)
                .delete()
                .then(function () {
                  console.log("Document successfully deleted!");
                  window.location.reload();
                })
                .catch(function (error) {
                  console.error("Error removing document: ", error);
                });
            }
          }
        });
    });
  } else if (val == "워크시트") {
    $(".modal_mentee_mypage").css("display", "none");
    const win = window.open(
      "/worksheets/new/worksheet1/",
       "_blank"
       );
    win.focus();
  }
};

const modalCancel = () => {
  $(".modal_mentee_mypage").css("display", "none");
  // /mypage
  // const win = window.open("/mypage");
  // win.focus();
};

const checkedAll = (e) => {
  if ($(e.target).is(":checked")) {
    // 전체 체크
    $("input[name=worksheetCheck]").prop("checked", true);
  } else {
    // 전체 체크 해제
    $("input[name=worksheetCheck]").prop("checked", false);
  }
};

export default Mentee;
