import { useEffect, useRef, useState } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import "../Worksheet.css";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import * as docx from "docx";
import { saveAs } from "file-saver";
import createAnswerDocxRows from "util/docx/createAnswerDocxRows";
import questions from "feature/worksheets/questions.json";
import extractPdf from "util/extractPdf";
import CommentCheck from "util/CommentCheck";

const Worksheet8 = ({ listeners, userType }) => {
  const [answers, setAnswers] = useState({});
  const db = useRef(firebase.firestore()).current;
  const auth = useRef(firebase.auth()).current;
  const match = useRouteMatch();
  const history = useHistory();
  const [worksheetId, setWorksheetId] = useState();
  const [commentVisible, setCommentVisible] = useState(true);
  const [initialAnswers, setInitialAnswers] = useState({});
  const htmlRef = useRef();

  const radioDefaultChecked = listeners.radioDefaultChecked(
    answers,
    setAnswers
  );
  const radioChecked = listeners.radioChecked(answers);
  const radioListener = listeners.radioListener(answers, setAnswers);
  const checkboxChecked = listeners.checkboxChecked(answers);
  const checkboxListener = listeners.checkboxListener(answers, setAnswers);
  const textareaValue = listeners.textareaValue(answers);
  const textareaListener = listeners.textareaListener(answers, setAnswers);
  const commentValue = listeners.commentValue(answers);
  const commentListener = listeners.commentListener(answers, setAnswers);

  const saveWorksheet = (e) => {
    if (userType === "admin") {
      alert("어드민은 워크시트를 저장할 수 없습니다!");
      return;
    }

    let finalSubmit = false;
    let date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, -1);

    if (worksheetId && worksheetId != "new") {
      const accountInfo =
        userType == "mento"
          ? {
              mento: auth.currentUser.uid,
            }
          : {
              mentee: auth.currentUser.uid,
            };

      db.collection("worksheets")
        .doc(worksheetId)
        .update({
          questionAnswers: answers,
          page: 8,
          ...accountInfo,
          finalSubmit,
          date,
        })
        .then(() => {
          alert("저장 성공!");
          listeners.storeAnswersListener(answers);
        })
        .catch((e) => alert(e));
      return;
    }
    console.log("\nanswers", {
      questionAnswers: {
        ...answers,
      },
      mentee: auth.currentUser.uid,
      mento: "",
    });
    db.collection("worksheets")
      .add({
        questionAnswers: answers,
        mentee: auth.currentUser.uid,
        page:8,
        finalSubmit,
        date,
      })
      .then((doc) => {
        setWorksheetId(doc.id);
        alert("저장 성공!");
        listeners.storeAnswersListener(answers);
        history.replace(`/worksheets/${doc.id || "new"}/worksheet8/`);
      })
      .catch((e) => alert(e));
  };

  useEffect(() => {
    console.log(userType);
    // if (userType == "mento") {
    //   setCommentVisible(true);
    // }
  }, [userType]);

  useEffect(() => {
    //
    setWorksheetId(match.params.id);

    db.collection("worksheets")
      .doc(match.params.id)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          return;
        }
        setAnswers(doc.data().questionAnswers);
        listeners.storeAnswersListener(doc.data().questionAnswers);
      });
  }, []);

  const extractDocx = () => {
    const targetData = Object.keys(answers).map((item, index) => {
      const Q = [
        item.toString() + " " + questions?.[7]?.[item]?.toString(),
        answers?.[item],
      ];
      return Q;
    });
    let tabledTargetData = targetData
      .map((data) => {
        if (!(data[0] && data[1])) return null;
        return createAnswerDocxRows(data[0], data[1], 3);
      })
      .reduce((acc, cur) => acc.concat(cur), [])
      .filter((e) => e);

    tabledTargetData =
      tabledTargetData?.length > 0
        ? tabledTargetData
        : createAnswerDocxRows("데이터가 없습니다", "")
            .reduce((acc, cur) => acc.concat(cur), [])
            .filter((e) => e);

    const doc = new docx.Document();
    const table = new docx.Table({
      width: {
        size: 100,
        type: docx.WidthType.PERCENTAGE,
      },
      rows: tabledTargetData,
    });

    doc.addSection({
      properties: {},
      children: [table],
    });
    docx.Packer.toBlob(doc).then((blob) => {
      saveAs(blob);
    });
  };

  return (
    <div>
      <div className="minwidth">
        <div id="worksheet">
          <div className="basicWrap">
            <section className="d-flex justify-content-between youtube align-items-center px-5">
              <h2 className="h2 font-weight-normal">
                작성하는 법을 잘 모르겠다면? youtube 시청 후 작성하기
              </h2>
              <Link
                className="btn btn-sm font-weight-normal squear-btn-basic radius pr-4 pl-4"
                to=""
              >
                과정설명 영상 보러가기
              </Link>
            </section>
            <div style={{ marginTop: 22, borderBottom: "1px solid #aaa" }}>
              <span style={{ fontSize: 12, color: "#aaa", marginTop: 22 }}>
                STEP2. 가치제안서 {">"} 기술(역량)명세 워크시트
              </span>
            </div>
            <form action className="dataForm mt40 " ref={htmlRef}>
              <input
                type="radio"
                name="b_5_4"
                id="b_5_4_1"
                className="d-none"
                checked={radioDefaultChecked("b_5_4_a", "차이 있음")}
                onChange={radioListener("b_5_4_a", "차이 있음")}
              />
              <input
                type="radio"
                name="b_5_4"
                id="b_5_4_2"
                className="d-none"
                checked={radioChecked("b_5_4_a", "차이 없음")}
                onChange={radioListener("b_5_4_a", "차이 없음")}
              />
              <input
                type="radio"
                name="b_5_5"
                id="b_5_5_1"
                className="d-none"
                checked={radioDefaultChecked("b_5_5", "1.개념단계")}
                onChange={radioListener("b_5_5", "1.개념단계")}
              />
              <input
                type="radio"
                name="b_5_5"
                id="b_5_5_2"
                className="d-none"
                checked={radioChecked("b_5_5", "2.실험실검증단계")}
                onChange={radioListener("b_5_5", "2.실험실검증단계")}
              />
              <input
                type="radio"
                name="b_5_5"
                id="b_5_5_3"
                className="d-none"
                checked={radioChecked(
                  "b_5_5",
                  "3.생산환경 검증단계(프로토타입)"
                )}
                onChange={radioListener(
                  "b_5_5",
                  "3.생산환경 검증단계(프로토타입)"
                )}
              />
              <input
                type="radio"
                name="b_5_5"
                id="b_5_5_4"
                className="d-none"
                checked={radioChecked("b_5_5", "4.양산환경 적응단계")}
                onChange={radioListener("b_5_5", "4.양산환경 적응단계")}
              />
              <input
                type="checkbox"
                name="b_5_6"
                id="b_5_6_1"
                className="d-none"
                checked={checkboxChecked("b_5_6", "1.특허")}
                onChange={checkboxListener("b_5_6", "1.특허")}
              />
              <input
                type="checkbox"
                name="b_5_6"
                id="b_5_6_2"
                className="d-none"
                checked={checkboxChecked("b_5_6", "2.실용신안")}
                onChange={checkboxListener("b_5_6", "2.실용신안")}
              />
              <input
                type="checkbox"
                name="b_5_6"
                id="b_5_6_3"
                className="d-none"
                checked={checkboxChecked("b_5_6", "3.디자인")}
                onChange={checkboxListener("b_5_6", "3.디자인")}
              />
              <input
                type="checkbox"
                name="b_5_6"
                id="b_5_6_4"
                className="d-none"
                checked={checkboxChecked("b_5_6", "4.상표")}
                onChange={checkboxListener("b_5_6", "4.상표")}
              />
              <input
                type="checkbox"
                name="b_5_6"
                id="b_5_6_5"
                className="d-none"
                checked={checkboxChecked("b_5_6", "5.저작권")}
                onChange={checkboxListener("b_5_6", "5.저작권")}
              />
              <input
                type="checkbox"
                name="b_5_6"
                id="b_5_6_6"
                className="d-none"
                checked={checkboxChecked("b_5_6", "6.기타")}
                onChange={checkboxListener("b_5_6", "6.기타")}
              />
              <input
                type="checkbox"
                name="b_5_6"
                id="b_5_6_7"
                className="d-none"
                checked={checkboxChecked("b_5_6", "7.없음")}
                onChange={checkboxListener("b_5_6", "7.없음")}
              />
              <section className="section">
                <div className="question">
                  B_5_1. 전문가 입장에서 기술(역량)을 설명하십시오.
                  <span className="text-danger">*</span>
                </div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_5_1"
                    id
                    className="textareaForm"
                    value={textareaValue("b_5_1", 1)}
                    onChange={textareaListener("b_5_1", 1)}
                  />
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_5_1", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_5_1_comment"
                    id="b_5_1_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == 'mento' ? false:true}
                    value={commentValue("b_5_1", 1)}
                    onChange={commentListener("b_5_1", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question">
                  B_5_2. 비전문가(사용자) 입장에서 기술(역량)을 설명하십시오.
                  <span className="text-danger">*</span>
                </div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_5_2"
                    id
                    className="textareaForm"
                    value={textareaValue("b_5_2", 1)}
                    onChange={textareaListener("b_5_2", 1)}
                  />
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_5_2", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_5_2_comment"
                    id="b_5_2_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == 'mento' ? false:true}
                    value={commentValue("b_5_2", 1)}
                    onChange={commentListener("b_5_2", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question">
                  B_5_3. 전문가 입장에서 제품(서비스)의 추가 아이디어를
                  나열해보십시오.
                </div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_5_3"
                    id
                    className="textareaForm"
                    value={textareaValue("b_5_3", 1)}
                    onChange={textareaListener("b_5_3", 1)}
                  />
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_5_3", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_5_3_comment"
                    id="b_5_3_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == 'mento' ? false:true}
                    value={commentValue("b_5_3", 1)}
                    onChange={commentListener("b_5_3", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question">
                  B_5_4. 제품 핵심기능에 적용하고자 하는 기술(역량)과 기존
                  제품의 적용 기술(역량)과의 차이점을 설명하시오.
                  <span className="text-danger">*</span>
                </div>
                <div className="answer">
                  <ul className="row">
                    <li className="col-2">
                      <label
                        htmlFor="b_5_4_1"
                        className="b_5_4_1 radio createText"
                        data-name="b_5_4_1"
                        data={1}
                      >
                        <span />
                        차이 있음
                      </label>
                    </li>
                    <li className="col-2">
                      <label htmlFor="b_5_4_2" className="b_5_4_2 radio ">
                        <span />
                        차이 없음
                      </label>
                    </li>
                    <li className="col-12">
                      <input
                        type="text"
                        className="inputTextForm"
                        name="b_5_4_3"
                        id="b_5_4_3"
                        placeholder="차이점을 적어주세요."
                        value={textareaValue("b_5_4_b", 1)}
                        onChange={textareaListener("b_5_4_b", 1)}
                      />
                    </li>
                  </ul>
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_5_4", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_5_4_comment"
                    id="b_5_4_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == 'mento' ? false:true}
                    value={commentValue("b_5_4", 1)}
                    onChange={commentListener("b_5_4", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question">
                  B_5_5. 기술(역량)의 준비 수준을 설명하십시오.
                  <span className="text-danger">*</span>
                </div>
                <div className="answer">
                  <ul className="row">
                    <li className="col-2">
                      <label htmlFor="b_5_5_1" className="b_5_5_1 radio">
                        <span />
                        1.개념단계
                      </label>
                    </li>
                    <li className="col-2">
                      <label htmlFor="b_5_5_2" className="b_5_5_2 radio">
                        <span />
                        2. 실험실검증단계
                      </label>
                    </li>
                    <li className="col-2">
                      <label htmlFor="b_5_5_3" className="b_5_5_3 radio">
                        <span />
                        3. 생산환경 검증단계
                        <div className="d-block text-center">(프로토타입)</div>
                      </label>
                    </li>
                    <li className="col-2">
                      <label htmlFor="b_5_5_4" className="b_5_5_4 radio">
                        <span />
                        4. 양산환경 적응단계
                      </label>
                    </li>
                  </ul>
                </div>
              </section>
              {/* <section className="section comment"
                        style={commentVisible ? null : { display: "none" }}
                    >
                        <div className="question font-weight-bold">Comment</div>
                        <div className="answer">
                        <textarea 
onKeyUp={(e) => {
                        e.currentTarget.style.height = "auto"
                        e.currentTarget.style.height = e.currentTarget.scrollHeight + 2 + "px";
                      }}
                            name="b_5_5_comment"
                            id="b_5_5_comment"
                            className="textareaForm"
                            placeholder="멘토만 작성할 수 있는 구역입니다."
                            value={commentValue("b_5_5", 1)}
                            onChange={commentListener("b_5_5", 1)}
                        />
                        </div>
                    </section> */}
              <section className="section">
                <div className="question">
                  B_5_6. 선택한 기술(역량)의 보호 방법을 설명하십시오.
                  <span className="text-danger">*</span>
                </div>
                <div className="answer">
                  <ul className="row">
                    <li className="col-2">
                      <label htmlFor="b_5_6_1" className="b_5_6_1 checkbox">
                        <span />
                        1.특허
                      </label>
                    </li>
                    <li className="col-2">
                      <label htmlFor="b_5_6_2" className="b_5_6_2 checkbox">
                        <span />
                        2.실용신안
                      </label>
                    </li>
                    <li className="col-2">
                      <label htmlFor="b_5_6_3" className="b_5_6_3 checkbox">
                        <span />
                        3.디자인
                      </label>
                    </li>
                    <li className="col-2">
                      <label htmlFor="b_5_6_4" className="b_5_6_4 checkbox">
                        <span />
                        4.상표
                      </label>
                    </li>
                    <li className="col-2">
                      <label htmlFor="b_5_6_5" className="b_5_6_5 checkbox">
                        <span />
                        5.저작권
                      </label>
                    </li>
                    <li className="col-2">
                      <label
                        htmlFor="b_5_6_6"
                        className="b_5_6_6 checkbox"
                        data-name="b_5_6_6"
                        data={1}
                      >
                        <span />
                        6.기타
                      </label>
                    </li>
                    <li className="col-2">
                      <label htmlFor="b_5_6_7" className="b_5_6_7 checkbox">
                        <span />
                        7.없음
                      </label>
                    </li>
                    <li className="col-12 h-0" />
                  </ul>
                </div>
              </section>
              {/* <section className="section comment"
                        style={commentVisible ? null : { display: "none" }}
                    >
                        <div className="question font-weight-bold">Comment</div>
                        <div className="answer">
                        <textarea 
onKeyUp={(e) => {
                        e.currentTarget.style.height = "auto"
                        e.currentTarget.style.height = e.currentTarget.scrollHeight + 2 + "px";
                      }}
                            name="b_5_6_comment"
                            id="b_5_6_comment"
                            className="textareaForm"
                            placeholder="멘토만 작성할 수 있는 구역입니다."
                            value={commentValue("b_5_6", 1)}
                            onChange={commentListener("b_5_6", 1)}
                        />
                        </div>
                    </section> */}
            </form>
            <section className="btnArea d-flex align-items-center justify-content-end">
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={() => extractPdf(htmlRef.current)}
              >
                PDF
              </button>
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={extractDocx}
              >
                다운로드
              </button>
              <div className="btn btn-sm squear-btn-border-basic mr-2">
                <Link to={`/worksheets/${worksheetId || "new"}/worksheet7/`}>
                  이전으로
                </Link>
              </div>
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={saveWorksheet}
              >
                저장하기
              </button>
              <div
                className="btn btn-sm squear-btn-basic "
                onClick={() => {
                  let keyArray = [
                    "b_5_1",
                    "b_5_2",
                    "b_5_3",
                    "b_5_4_a",
                    "b_5_4_b",
                    "b_5_5",
                    "b_5_6",
                  ];
                  listeners.checkValue(keyArray, answers, worksheetId, 9);
                }}
              >
                <Link>다음으로</Link>
              </div>
            </section>
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default Worksheet8;
