import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import "../mypage.css";
import firebase from "firebase";

const MentoDetail = () => {
  const db = useMemo(() => firebase.firestore(), []);
  const auth = useMemo(() => firebase.auth(), []);
  const [mentoData, setMentoData] = useState({});

  useEffect(() => {
    db.collection("users")
      .doc(auth.currentUser.uid)
      .get()
      .then((doc) => {
        if (!doc.exists) return;
        return doc.data();
      })
      .then((user) => {
        console.log("users", user);
        db.collection("mento")
          .doc(auth.currentUser.uid)
          .get()
          .then((doc) => {
            if (!doc.exists) return;
            setMentoData({ ...user, ...doc.data() });
          });
      });
  }, [auth.currentUser.uid, db]);

  return (
    <div>
      <div className="minwidth">
        {/* 상단 시작 { */}
        {/* <header include-html="/public/header.html" /> */}
        {/* } 상단 끝 */}
        {/* 시장정보 확인하기 Modal { */}
        {/* <div include-html="/public/modal.html" /> */}
        {/* } 시장정보 확인하기 Modal */}
        <hr />
        {/* 콘텐츠 시작 { */}
        <div id="mymain" className="main ">
          {/* mymain */}
          <div
            id="topNav"
            className="d-flex justify-content-start align-items-end pb-2 "
          >
            <h1 className="h1 m-0 font-weight-bold">마이페이지</h1>
            <ul id="topNavList" className="d-flex">
              <li className="position-relative">
                <Link to="/mypage/mypage">멘티관리</Link>
                {/* <span className="position-absolute feedback">1</span> */}
              </li>
              <li className="active nav-item">
                <Link>내정보</Link>
              </li>
            </ul>
          </div>
          <div id="mypage_mentoDetail" className="subWrap">
            {" "}
            {/* #sub */}
            <div className="basicWrap pt40">
              <div className="mentoDetail">
                <section className="text-center">
                  <div className="mt-4 d-flex justify-content-between memtoList">
                    <div className="w-25">
                      <img
                        src={
                          mentoData.picture
                            ? mentoData.picture
                            : "https://via.placeholder.com/300x300?text=pic1"
                        }
                        height={300}
                        width={300}
                        alt="mentoPicture"
                      />
                      <h3 className="h3 mt-4">{mentoData.name}</h3>
                      {/* <div className="d-flex justify-content-center mt-4">
                        <div className="btn btn-sm squear-btn-border-basic mr-2 radius backClick">
                          목록으로
                        </div>
                        <div
                          className="btn btn-sm squear-btn-basic radius applyClickbtn"
                          data-toggle="modal"
                          data-target="#exampleModal1"
                        >
                          신청하기
                        </div>
                      </div> */}
                    </div>
                    <section className="w-75 text-left container row mentoInfo">
                      <h3 className="h3 col-12 mb-4">산업기술 분야</h3>
                      <div className="col-6 mb-2">
                        <span className="catename">현재소속</span>
                        <div className="infomation">
                          {mentoData.companyName}
                        </div>
                      </div>
                      <div className="col-6">
                        <span className="catename">현재직업</span>
                        <div className="infomation">{mentoData.job}</div>
                      </div>
                      <div className="col-6 mb-2">
                        <span className="catename">근무직종</span>
                        <div className="infomation">{mentoData.jobType}</div>
                      </div>
                      <div className="col-6">
                        <span className="catename">현재직위</span>
                        <div className="infomation">
                          {mentoData.jobPosition}
                        </div>
                      </div>
                      <h3 className="h3 col-12 mt40 mb-4">창업경영 분야</h3>
                      <div className="col-12 mb-2">
                        <span className="catename">창업경험</span>
                        <div className="infomation">
                          {!mentoData.startupCount
                            ? "NO"
                            : "YES ( " + mentoData.startupCount + "회, "}
                          {mentoData.startupYears
                            ? mentoData.startupYears + " )"
                            : " )"}
                          {/* YES ( 00회, 창업 3년 미만 ) */}
                        </div>
                      </div>
                      <div className="col-6 mb-2">
                        <span className="catename">산업기술분야</span>
                        <div className="infomation">
                          기술 &gt; {mentoData.techType}
                        </div>
                      </div>
                      <div className="col-6">
                        <span className="catename" />
                        <div className="infomation">
                          4차 혁명기술 &gt; {mentoData.fourTechType}
                        </div>
                      </div>
                      <div className="col-6 mb-2">
                        <span className="catename">창업경영분야</span>
                        <div className="infomation">
                          {mentoData.startupFields?.[0]
                            ? `${mentoData.startupFields?.[0]?.category} > ${mentoData.startupFields?.[0]?.subCategory}`
                            : " "}
                        </div>
                      </div>
                      {mentoData.startupFields?.length > 1 ? (
                        <div className="col-6">
                          <span className="catename" />
                          <div className="infomation">
                            {mentoData.startupFields[1]?.category} &gt;{" "}
                            {mentoData.startupFields[1]?.subCategory}
                          </div>
                        </div>
                      ) : null}
                    </section>
                  </div>
                </section>
                {/* Modal */}
                <div
                  className="modal fade main_modal pb-4 memtoListPopup"
                  id="exampleModal1"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header" style={{ border: 0 }}>
                        <h5 className="modal-title" id="exampleModalLabel" />
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="row text-center">
                          <div className="col-12">
                            <h4 className="h4">특허정보 사이트</h4>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-sm squear-btn-border-basic"
                          data-dismiss="modal"
                        >
                          취소
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm squear-btn-basic"
                          id="applyFinishbtn"
                          data-dismiss="modal"
                        >
                          신청하기
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* } 콘텐츠 끝 */}
        <hr />
      </div>
    </div>
  );
};

export default MentoDetail;
