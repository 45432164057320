import { Link, useHistory, useRouteMatch } from "react-router-dom";
import "../Worksheet.css";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { useEffect, useRef, useState } from "react";

import * as docx from "docx";
import { saveAs } from "file-saver";
import createAnswerDocxRows from "util/docx/createAnswerDocxRows";
import questions from "feature/worksheets/questions.json";
import extractPdf from "util/extractPdf";
import CommentCheck from "util/CommentCheck";

const Worksheet11 = ({ listeners, userType }) => {
  const [answers, setAnswers] = useState({});
  const db = useRef(firebase.firestore()).current;
  const auth = useRef(firebase.auth()).current;
  const [commentVisible, setCommentVisible] = useState(true);
  const match = useRouteMatch();
  const [worksheetId, setWorksheetId] = useState();
  const history = useHistory();
  const [initialAnswers, setInitialAnswers] = useState({});
  const htmlRef = useRef();

  const [compareData, setCompareData] = useState(null);
  const [subValArray, setSubValArray] = useState([]);

  const radioDefaultChecked = listeners.radioDefaultChecked(
    answers,
    setAnswers
  );
  const radioChecked = listeners.radioChecked(answers);
  const radioListener = listeners.radioListener(answers, setAnswers);
  const checkboxChecked = listeners.checkboxChecked(answers);
  const checkboxListener = listeners.checkboxListener(answers, setAnswers);
  const textareaValue = listeners.textareaValue(answers);
  const textareaListener = listeners.textareaListener(answers, setAnswers);
  const commentValue = listeners.commentValue(answers);
  const commentListener = listeners.commentListener(answers, setAnswers);

  useEffect(() => {
    //
    setWorksheetId(match.params.id);

    db.collection("worksheets")
      .doc(match.params.id)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          return;
        }
        setAnswers(doc.data().questionAnswers);
        listeners.storeAnswersListener(doc.data().questionAnswers);
      });
  }, []);

  useEffect(() => {
    console.log(userType);
    // if (userType == "mento") {
    //   setCommentVisible(true);
    // }
  }, [userType]);

  const saveWorksheet = (e) => {
    if (userType === "admin") {
      alert("어드민은 워크시트를 저장할 수 없습니다!");
      return;
    }

    let finalSubmit = false;
    let date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, -1);

    if (worksheetId && worksheetId != "new") {
      const accountInfo =
        userType == "mento"
          ? {
              mento: auth.currentUser.uid,
            }
          : {
              mentee: auth.currentUser.uid,
            };
      db.collection("worksheets")
        .doc(worksheetId)
        .update({
          questionAnswers: answers,
          page: 11,
          ...accountInfo,
          finalSubmit,
          date,
        })
        .then(() => {
          alert("저장 성공!");
          listeners.storeAnswersListener(answers);
        })
        .catch((e) => alert(e));
      return;
    }
    console.log("\nanswers", {
      questionAnswers: {
        ...answers,
      },
      mentee: auth.currentUser.uid,
      mento: "",
    });
    db.collection("worksheets")
      .add({
        questionAnswers: answers,
        mentee: auth.currentUser.uid,
        page: 11,
        finalSubmit,
        date,
      })
      .then((doc) => {
        setWorksheetId(doc.id);
        alert("저장 성공!");
        listeners.storeAnswersListener(answers);
        history.replace(`/worksheets/${doc.id || "new"}/worksheet11/`);
      })
      .catch((e) => alert(e));
  };

  useEffect(() => {
    if (Object.keys(answers).length > 0) {
      console.log("총점높은거 1등과 2등 가져오기 시작");
      //
      console.log("우선 총점전부 가져오기");
      console.log(Object.keys(answers));
      //
      let scoreArray = [];
      let newTestArray = [];
      let fiveArray = [1, 2, 3, 4, 5];
      fiveArray.map((key) => {
        if (answers?.["b_2_" + key] != undefined) {
          newTestArray.push(["b_2_" + key, answers["b_2_" + key]]);
        }
      });
      // let maxNum = 0;
      // let maxVal = "";
      // newTestArray.map((item, index) => {
      //   // console.log(item[0], item[1])
      //   if (maxNum < item[1]) {
      //     maxNum = item[1];
      //     maxVal = item[0];
      //   }
      // });
      console.log("// 점수가 높은 순으로 전체 나열");
      newTestArray.sort((a, b) => {
        return b[1] - a[1];
      });
      console.log("newTestArray", newTestArray);
      //
      // console.log('//'+newTestArray[0][0]+'의 모든값 나열하기')
      // console.log(maxVal, maxNum)
      // console.log(secVal, secNum)
      //   if(maxVal != ''){
      // setCompareData(maxVal.split("_")[2]);
      //     console.log([maxVal,maxNum])
      //   }
      let subArray = [];
      console.log(newTestArray);
      if (newTestArray.length > 0) {
        Object.keys(answers).map((item) => {
          if (
            item.indexOf(newTestArray[0][0]) > -1 &&
            item != newTestArray[0][0]
          ) {
            subArray.push([item, parseFloat(answers[item])]);
            // console.log(item+': '+ parseFloat(answers[item]))
          }
        });
        subArray.sort((a, b) => {
          return b[1] - a[1];
        });

        setSubValArray(subArray.slice(0, 3));
        console.log(subArray.slice(0, 3));
        //
        setCompareData(newTestArray);
      }
    }
  }, [answers]);

  const extractDocx = () => {
    const targetData = Object.keys(answers).map((item, index) => {
      const Q = [
        item.toString() + " " + questions?.[10]?.[item]?.toString(),
        answers?.[item],
      ];
      return Q;
    });
    let tabledTargetData = targetData
      .map((data) => {
        if (!(data[0] && data[1])) return null;
        return createAnswerDocxRows(data[0], data[1], 3);
      })
      .reduce((acc, cur) => acc.concat(cur), [])
      .filter((e) => e);

    tabledTargetData =
      tabledTargetData?.length > 0
        ? tabledTargetData
        : createAnswerDocxRows("데이터가 없습니다", "")
            .reduce((acc, cur) => acc.concat(cur), [])
            .filter((e) => e);

    const doc = new docx.Document();
    const table = new docx.Table({
      width: {
        size: 100,
        type: docx.WidthType.PERCENTAGE,
      },
      rows: tabledTargetData,
    });

    doc.addSection({
      properties: {},
      children: [table],
    });
    docx.Packer.toBlob(doc).then((blob) => {
      saveAs(blob);
    });
  };

  return (
    <div>
      <div className="minwidth">
        <div id="worksheet">
          <div className="basicWrap">
            <section className="d-flex justify-content-between youtube align-items-center px-5">
              <h2 className="h2 font-weight-normal">
                작성하는 법을 잘 모르겠다면? youtube 시청 후 작성하기
              </h2>
              <Link
                className="btn btn-sm font-weight-normal squear-btn-basic radius pr-4 pl-4"
                to=""
              >
                과정설명 영상 보러가기
              </Link>
            </section>
            <div style={{ marginTop: 22, borderBottom: "1px solid #aaa" }}>
              <span style={{ fontSize: 12, color: "#aaa", marginTop: 22 }}>
                STEP2. 가치제안서 {">"} 기회개발 워크시트
              </span>
            </div>
            <form action className="dataForm mt40 " ref={htmlRef}>
              <section className="section">
                <div className="question">
                  B_8_1. 아이디어 설명<span className="text-danger">*</span>
                </div>
                <div className="answer d-flex">
                  <div className="w-25 d-flex align-items-center disabled px-1">
                    {answers?.["a_2_1"] ? answers["a_2_1"] : "연동A_2_1"}
                  </div>
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_8_1"
                    id="b_8_1"
                    className="col textareaForm"
                    value={textareaValue("b_8_1", 1)}
                    onChange={textareaListener("b_8_1", 1)}
                  />
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_8_1", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_8_1_comment"
                    id="b_8_1_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == "mento" ? false : true}
                    value={commentValue("b_8_1", 1)}
                    onChange={commentListener("b_8_1", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question">
                  B_8_2. 목표고객<span className="text-danger">*</span>
                </div>
                <div className="answer d-flex">
                  <div className="w-25 d-flex align-items-center disabled px-1">
                    <div>
                      {/* 총점:{" "}
                      {compareData ? compareData[0][1]:''} */}
                      {subValArray.length > 0 &&
                        answers[
                          "b_1_" +
                            Math.ceil(subValArray[0][0].split("_")[3] / 3) +
                            "_" +
                            (subValArray[0][0].split("_")[3] % 3 == 0
                              ? 6
                              : (subValArray[0][0].split("_")[3] % 3) + 3)
                        ]}
                    </div>
                  </div>
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_8_2"
                    id="b_8_2"
                    className="col textareaForm"
                    value={textareaValue("b_8_2", 1)}
                    onChange={textareaListener("b_8_2", 1)}
                  />
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_8_2", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_8_2_comment"
                    id="b_8_2_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == "mento" ? false : true}
                    value={commentValue("b_8_2", 1)}
                    onChange={commentListener("b_8_2", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question">
                  B_8_3. 제품(서비스)이 고객에게 제공하는 (새로운)핵심가치는
                  무엇입니까?
                  <span className="text-danger">*</span>
                </div>
                <div className="answer d-flex">
                  <div className="w-25 align-items-center disabled px-1">
                    {subValArray.length > 0
                      ? [1, 2, 3].map((item, idx) => {
                          return (
                            <div key={idx}>
                              {answers[
                                "b_1_" +
                                  Math.ceil(
                                    subValArray[idx][0].split("_")[3] / 3
                                  ) +
                                  "_" +
                                  (subValArray[idx][0].split("_")[3] % 3 == 0
                                    ? 6
                                    : (subValArray[idx][0].split("_")[3] % 3) +
                                      3)
                              ] +
                                ": " +
                                (answers?.[subValArray[idx][0]]
                                  ? answers[subValArray[idx][0]]
                                  : "")}
                            </div>
                          );
                        })
                      : ""}
                  </div>
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_8_3"
                    id="b_8_3"
                    className="col textareaForm"
                    value={textareaValue("b_8_3", 1)}
                    onChange={textareaListener("b_8_3", 1)}
                  />
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_8_3", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_8_3_comment"
                    id="b_8_3_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == "mento" ? false : true}
                    value={commentValue("b_8_3", 1)}
                    onChange={commentListener("b_8_3", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question">
                  B_8_5. 제품(서비스)가 해결가능한 고객의 요구사항(문제점)은
                  무엇입니까?
                  <span className="text-danger">*</span>
                </div>
                <div className="answer d-flex">
                  <div className="w-25 align-items-center disabled px-1">
                    {/*  */}
                    {compareData
                      ? [4, 5, 6].map((item) => {
                          return (
                            <div key={item}>
                              {answers?.[
                                "b_1_" +
                                  compareData[0][0].split("_")[2] +
                                  "_" +
                                  item
                              ]
                                ? answers[
                                    "b_1_" +
                                      compareData[0][0].split("_")[2] +
                                      "_" +
                                      item
                                  ]
                                : ""}
                            </div>
                          );
                        })
                      : ""}
                    {/* {answers?.["b_1_" + compareData[0][0].split('_')[2] + "_4"] ? answers["b_1_" + compareData[0][0].split('_')[2] + "_4"]:''}
                    {answers?.["b_1_" + compareData[0][0].split('_')[2] + "_5"] ? answers["b_1_" + compareData[0][0].split('_')[2] + "_5"]:''}
                    {answers?.["b_1_" + compareData[0][0].split('_')[2] + "_6"] ? answers["b_1_" + compareData[0][0].split('_')[2] + "_6"]:''} */}
                  </div>
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_8_5"
                    id="b_8_5"
                    className="col textareaForm"
                    value={textareaValue("b_8_5", 1)}
                    onChange={textareaListener("b_8_5", 1)}
                  />
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_8_5", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_8_5_comment"
                    id="b_8_5_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == "mento" ? false : true}
                    value={commentValue("b_8_5", 1)}
                    onChange={commentListener("b_8_5", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question">
                  B_8_8. 고객의 요구사항(문제점)을 충족시키기 위해 보유한 역량은
                  무엇입니까?<span className="text-danger">*</span>
                </div>
                <div className="answer d-flex">
                  <div className="w-25 align-items-center disabled px-1">
                    {B88(answers)}
                  </div>
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_8_8"
                    id="b_8_8"
                    className="col textareaForm"
                    value={textareaValue("b_8_8", 1)}
                    onChange={textareaListener("b_8_8", 1)}
                  />
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_8_8", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_8_8_comment"
                    id="b_8_8_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == "mento" ? false : true}
                    value={commentValue("b_8_8", 1)}
                    onChange={commentListener("b_8_8", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question">
                  B_8_9. 고객의 요구사항(문제점)을 충족시키기 위해 필요한 역량은
                  무엇입니까?<span className="text-danger">*</span>
                </div>
                <div className="answer d-flex">
                  {/* <div className="w-25 align-items-center disabled px-1">
                            <div>
                                {answers?.["b_7_3"]?.singleAnswer ? answers?.["b_7_3"]?.singleAnswer+': '+answers?.["b_7_3"]?.multiAnswers[1]: null}
                            </div>
                        </div> */}
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_8_9"
                    id="b_8_9"
                    className="col textareaForm"
                    value={textareaValue("b_8_9", 1)}
                    onChange={textareaListener("b_8_9", 1)}
                  />
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_8_9", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_8_9_comment"
                    id="b_8_9_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == "mento" ? false : true}
                    value={commentValue("b_8_9", 1)}
                    onChange={commentListener("b_8_9", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question">
                  B_8_11. 제품(서비스)가 해결할 예상 고객의 수를 제시하시오.
                  <span className="text-danger">*</span>
                </div>
                <div className="answer d-flex">
                  <div className="w-25 align-items-center disabled px-1">
                    {compareData
                      ? answers?.[
                          "b_1_" + compareData[0][0].split("_")[2] + "_2"
                        ]
                        ? answers[
                            "b_1_" + compareData[0][0].split("_")[2] + "_2"
                          ]
                        : ""
                      : ""}
                    {/* <div>
                                {answers?.["b_7_2"]?.multiAnswers?.[1] == true ? '1.기술: '+answers?.["b_7_2_1"]?.multiAnswers[1]: null}
                            </div>
                            <div>
                                {answers?.["b_7_2"]?.multiAnswers?.[2] == true ? '2.자금: '+answers?.["b_7_2_1"]?.multiAnswers[2]: null}
                            </div>
                            <div>
                                {answers?.["b_7_2"]?.multiAnswers?.[3] == true ? '3.인력: '+answers?.["b_7_2_1"]?.multiAnswers[3]: null}
                            </div>
                            <div>
                                {answers?.["b_7_2"]?.multiAnswers?.[4] == true ? '4.파트너: '+answers?.["b_7_2_1"]?.multiAnswers[4]: null}
                            </div>
                            <div>
                                {answers?.["b_7_2"]?.multiAnswers?.[5] == true ? '5.기타: '+answers?.["b_7_2_1"]?.multiAnswers[5]: null}
                            </div> */}
                  </div>
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_8_11"
                    id="b_8_11"
                    className="col textareaForm"
                    value={textareaValue("b_8_11", 1)}
                    onChange={textareaListener("b_8_11", 1)}
                  />
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_8_11", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_8_11_comment"
                    id="b_8_11_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == "mento" ? false : true}
                    value={commentValue("b_8_11", 1)}
                    onChange={commentListener("b_8_11", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question">
                  B_8_12. 고객 요구사항에 맞춘 제품(서비스)를 전달할 수 있는
                  역량 있음을 설명하시오.<span className="text-danger">*</span>{" "}
                </div>
                <div className="answer d-flex">
                  <div className="w-25 align-items-center disabled px-1">
                    <div>
                      {answers?.["b_7_4_a"]
                        ? "자사 브랜드로 시장접근이 " +
                          (answers["b_7_4_a"] == "있다"
                            ? "가능하다"
                            : "불가능하다") +
                          ": " +
                          (answers?.["b_7_4_b"] ? answers?.["b_7_4_b"] : "")
                        : "b_7_4_b"}
                    </div>
                    <div>
                      {answers?.["b_7_5_a"]
                        ? "제품(서비스)의 우수성을 지속적으로 유지할 수 " +
                          answers?.["b_7_5_a"] +
                          ": " +
                          (answers?.["b_7_5_b"] ? answers?.["b_7_5_b"] : "")
                        : "b_7_5_b"}
                    </div>
                  </div>
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_8_12"
                    id="b_8_12"
                    className="col textareaForm"
                    value={textareaValue("b_8_12", 1)}
                    onChange={textareaListener("b_8_12", 1)}
                  />
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_8_12", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_8_12_comment"
                    id="b_8_12_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == "mento" ? false : true}
                    value={commentValue("b_8_12", 1)}
                    onChange={commentListener("b_8_12", 1)}
                  />
                </div>
              </section>
            </form>
            <section className="btnArea d-flex align-items-center justify-content-end">
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={() => extractPdf(htmlRef.current)}
              >
                PDF
              </button>
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={extractDocx}
              >
                다운로드
              </button>
              <div className="btn btn-sm squear-btn-border-basic mr-2">
                <Link to={`/worksheets/${worksheetId || "new"}/worksheet10/`}>
                  이전으로
                </Link>
              </div>
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={saveWorksheet}
              >
                저장하기
              </button>
              <div
                className="btn btn-sm squear-btn-basic "
                onClick={() => {
                  let keyArray = [
                    "b_8_1",
                    "b_8_2",
                    "b_8_3",
                    "b_8_5",
                    "b_8_8",
                    "b_8_9",
                    "b_8_11",
                    "b_8_12",
                  ];
                  listeners.checkValue(keyArray, answers, worksheetId, 12);
                }}
              >
                <Link>다음으로</Link>
              </div>
            </section>
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
};

const B88 = (answers) => {
  // b_8_8에 연동부분
  console.log(answers["b_7_2_a"]);

  const result = (item) => {
    let rs = "ff";
    switch (item) {
      case "1":
        return (rs = "b_7_2_b");
      case "2":
        return (rs = "b_7_2_c");
      case "3":
        return (rs = "b_7_2_d");
      case "4":
        return (rs = "b_7_2_e");
      case "5":
        return (rs = "b_7_2_f");
      default:
        break;
    }
    return rs;
  };
  if (answers?.["b_7_2_a"])
    return answers["b_7_2_a"].map((item, index) => {
      return (
        <div key={item}>
          {item}:{" "}
          {
            // (item.split('.')[0])
            answers[result(item.split(".")[0])]
          }
        </div>
      );
    });
};
export default Worksheet11;
