import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const extractPdf = (htmlElement) => {

  let pdf = new jsPDF('p', 'mm', 'a4')
    let canvas = pdf.canvas
    const pageWidth = 210 //캔버스 너비 mm
    const pageHeight = 295 //캔버스 높이 mm
    canvas.width = pageWidth

    let width = htmlElement.offsetWidth // 셀렉트한 요소의 px 너비
    let height = htmlElement.offsetHeight // 셀렉트한 요소의 px 높이
    let imgHeight = pageWidth * height/width // 이미지 높이값 px to mm 변환
    console.log("width height imgHeight", width, height, imgHeight)

  html2canvas(htmlElement, {scrollY: -window.scrollY}).then((canvas) => {
    let position = 0;
    const imgData = canvas.toDataURL("image/png");
    pdf.addImage(
      imgData,
      "png",
      10,
      position + 10,
      pageWidth - 17,
      imgHeight - 17,
      undefined,
      "slow"
    );

    //Paging 처리
    let heightLeft = imgHeight; //페이징 처리를 위해 남은 페이지 높이 세팅.
    heightLeft -= pageHeight;
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      console.log("position", position)
      pdf.addPage();
      pdf.addImage(imgData, "png", 10, position + 10, pageWidth - 17, imgHeight - 17);
      heightLeft -= pageHeight;
    }

    pdf.save("download.pdf");
  });
};

export default extractPdf;