import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CookieUtil from "util/CookieUtil";

export const slice = createSlice({
  name: "home",
  initialState: {
    // banner: {},
    /*{
        id: null,
        linkUrl: null,
        imageUrl: null,
      },*/
    popups: [],
  },
  reducers: {
    setBanner: (state, action) => {
      state.banner = action.payload;
    },
    setPopup: (state, action) => {
      state.popups = action.payload;
    },
    removePopup: (state, action) => {
      state.popups = state.popups.filter(e => e.id != action.payload);
    },
    setPopupByIdx: (state, action) => {
      state.popups[action.payload.idx] = action.payload.popup;
    },
  },
});
export const { setBanner, setPopup, removePopup } = slice.actions;

// Thunk Function
export const fetchBanner = () => (dispatch, getState) => {
  const storage = firebase.storage();
  const db = firebase.firestore();
  // 서버에서 데이터를 가져왔다고 가정 json 변수
  db.collection("banner")
    .doc("1")
    .get()
    .then((doc) => {
      if (doc.exists) {
        let banner = doc.data();
        console.log("banner", banner);
        document.getElementById("main_banner").style.backgroundImage =
          'url("' + banner.url + '")';
      }
    });
  //const banner = {
  //  url: "/static/media/banner.466981f4.png",
  //};
  //console.log("url('" + banner.url + "')");
  // javascript로 배너 이미지 변경
  //document.getElementById("main_banner").style.backgroundImage =
  //  'url("' + banner.url + '")';
  // 가져온 값 디스패치
  // dispatch(setBanner(banner));
};

export const reqGetPopup = () => async (dispatch, getState) => {
  const db = firebase.firestore();

  // const popup1 = await (
  //   await db.collection("popup").doc("popup1").get()
  // ).data();

  // const popup2 = await (
  //   await db.collection("popup").doc("popup2").get()
  // ).data();

  const popups = [];
  for (const popupDoc of (await db.collection("popup").get()).docs) {
    if (CookieUtil.getCookie(popupDoc.id) !== null) continue;
    popups.push({
      ...popupDoc.data(),
      id: popupDoc.id,
    });
  }

  dispatch(setPopup(popups));
};

export const blockPopupByCookie = (id, blockHours = 24) => async (
  dispatch,
  getState
) => {
  let untilDate = new Date();
  untilDate.setHours(untilDate.getHours() + blockHours);

  CookieUtil.setCookie(id, true, untilDate);
  dispatch(removePopup(id));
};

export default slice.reducer;
