import { Field, Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { sliceAdminNotice, noticeSubmit, requestCreateNotice } from "./sliceAdminNotice";

const AdminNoticeWrite = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        title: "",
        content: "",
        bf_file1: null,
        bf_file2: null,
      }}
      onSubmit={(values, { setSubmitting }) => {
        dispatch(requestCreateNotice(values, history))
      }}
    >
      <Form> 
        <div id="wrapper">
          <div id="container">
            <h1 id="container_title">
              게시판 글쓰기
              <div className="float-right">
                <Link to="/admin/notice" className="btn btn-sm squear-basic-btn gray mt-0 backClick">
                  목록
                </Link>
                <button type="submit" className="btn btn-sm squear-basic-btn mt-0">작성하기</button>
              </div>
            </h1>
            <div className="container_wr container d-flex align-items-center">
              <div className="tbl_head01 tbl_wrap">
                <table className="table stepTable tableStyle text-left">
                  <colgroup>
                    <col width="200px" />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th className="bg">제목</th>
                      <td>
                        <Field
                          type="text"
                          name="title"
                          id="wr_subject"
                          required
                          className="frm_input full_input required"
                          size={50}
                          maxLength={255}
                          placeholder="제목"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th className="bg">내용</th>
                      <td>
                        <Field
                          as="textarea"
                          id="content"
                          name="content"
                          className
                          maxLength={65536}
                          style={{ width: "100%", height: 300 }}
                        />
                      </td>
                    </tr>
                    {/* <tr>
                      <th className="bg">파일첨부1</th>
                      <td>
                        <Field
                          type="file"
                          name="bf_file1"
                          id="bf_file_1"
                          title="파일첨부 1"
                          className="frm_file "
                        />
                      </td>
                    </tr>
                    <tr>
                      <th className="bg">파일첨부2</th>
                      <td>
                        <Field
                          type="file"
                          name="bf_file2"
                          id="bf_file_2"
                          title="파일첨부 2"
                          className="frm_file "
                        />
                      </td>
                    </tr> */}
                  </tbody>
                </table>
                {/* <span className="text-muted">
                  *파일은 내용 윗부분에 나타납니다.
                </span> */}
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default AdminNoticeWrite;
