import "./Header.css";
import React, { useRef } from "react";
import logo from "../image/main/logo.svg";
import { Link, useHistory } from "react-router-dom";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const Header = ({ user, userType }) => {
  console.log(userType)
  const history = useHistory();
  return (
    <div id="hd" style={{ zIndex: 1000 }}>
      <h1 id="hd_h1">WOKRSHEET</h1>
      {/* leftmenu { */}
      <div id="mySidenav" className="sidenav">
        <div id="logo">
          <Link to="/">
            <img src={logo} alt="logo" title="logo" />
          </Link>
        </div>
        <nav id="nav">
          <ul id="left_menu">
            <li className="left_menu_li left_menu_li_1">
              <Link className="left_menu_a_1" to="/intro">
                회사소개
                <span className="open">
                  <i className="fa fa-sort-down" />
                </span>
                <span className="cl">
                  <i className="fa fa-sort-up" />
                </span>
              </Link>
              <ul className="left_menu_2">
                <li className="left_menu_li left_menu_li_2">
                  <Link to="/intro">회사소개</Link>
                </li>
                <li className="left_menu_li left_menu_li_2">
                  <Link to="/map">오시는길</Link>
                </li>
              </ul>
            </li>
            {userType == "mento" ? null : (
              <li className="left_menu_li left_menu_li_1">
                <Link className="left_menu_a_1" to="/worksheet_info/">
                  교육신청
                </Link>
                <ul className="left_menu_2">
                  <li className="left_menu_li left_menu_li_2">
                    <Link to="/worksheets/">교육신청설명</Link>
                  </li>
                </ul>
              </li>
            )}

            <li className="left_menu_li left_menu_li_1">
              <Link className="left_menu_a_1" to="/notice/list">
                커뮤니티
                <span className="open">
                  <i className="fa fa-sort-down" />
                </span>
                <span className="cl">
                  <i className="fa fa-sort-up" />
                </span>
              </Link>
              <ul className="left_menu_2">
                <li className="left_menu_li left_menu_li_2">
                  <Link to="/notice/list">공지사항</Link>
                </li>
                <li className="left_menu_li left_menu_li_2">
                  <Link to="/qna/write">문의하기</Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
        <nav id="sNav" className="mt-4 nav2 d-flex flex-column">
          <ul className="d-flex flex-column navbar-right">
            {user ? (
              <>
                {userType ? (
                  userType == "admin" ? (
                    <li className="nav-item">
                      <Link to="/admin">어드민</Link>
                    </li>
                  ) : (
                    <li className="nav-item">
                      <Link to="/mypage">마이페이지</Link>
                    </li>
                  )
                ) : (
                  <li className="nav-item"></li>
                )}

                <li className="nav-item">
                  <Link
                    to=""
                    onClick={() => {
                      firebase.auth().signOut();
                      history.go(1);
                      history.push("/");
                    }}
                  >
                    로그아웃
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item">
                  <Link to="/join">회원가입</Link>
                </li>
                <li className="nav-item">
                  <Link to="/login">로그인</Link>
                </li>
              </>
            )}
            <li className="nav-item d-none">
              <span className="sound_only">전체메뉴열기</span>
              {/* <a href="javascript:void(0);" id="openNav">
                MENU
              </a> */}
              <button type="button" className="gnb_menu_btn" title="전체메뉴">
                <i className="fa fa-bars" aria-hidden="true" />
              </button>
            </li>
          </ul>
          <div className="d-flex justify-content-center chkbox mt40">
            {/* Button trigger modal */}
            <button
              type="button"
              className="btn btn-sm squear-btn-border-basic bgnone"
              data-toggle="modal"
              data-target="#exampleModal"
            >
              시장정보 확인하기
            </button>
          </div>
        </nav>
      </div>
      {/* } leftmenu */}
    </div>
  );
};

export default Header;
