import { useEffect, useMemo, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/firestore";
import { useLocation } from "react-router-dom";
import $ from "jquery";

const MemberList = () => {
  const query = useQuery();
  const match = useRouteMatch();

  const db = useMemo(() => firebase.firestore(), []);
  const selectType = useMemo(() => query.get("selectType"), [query]);
  const sucType = useMemo(() => query.get("sucType"), [query]);
  // const selectType = null;
  const [users, setUsers] = useState([]);

  useEffect(() => {
    db.collection("users")
      .where("useOrNot", "==", true)
      .get()
      .then(async (userDocs) => {
        let result = [];
        let reqProms = [];

        for (const userDoc of userDocs.docs) {
          switch (selectType) {
            case "mentee": {
              let menteeProm = db
                .collection("mentee")
                .doc(userDoc.id)
                .get()
                .then((menteeDoc) => {
                  if (!userDoc.exists || !menteeDoc.exists) return;
                  result.push({
                    ...userDoc.data(),
                    ...menteeDoc.data(),
                    userId: userDoc.id,
                    userType: "mentee",
                  });
                });
              reqProms.push(menteeProm);
              break;
            }
            case "mento": {
              let mentoProm = db
                .collection("mento")
                .doc(userDoc.id)
                .get()
                .then((mentoDoc) => {
                  if (!userDoc.exists || !mentoDoc.exists) return;
                  result.push({
                    ...userDoc.data(),
                    ...mentoDoc.data(),
                    userId: userDoc.id,
                    userType: "mento",
                  });
                });
              reqProms.push(mentoProm);
              break;
            }
            default: {
              if (!userDoc.exists) return;
              // mentee
              let menteeProm = db
                .collection("mentee")
                .doc(userDoc.id)
                .get()
                .then((menteeDoc) => {
                  if (!userDoc.exists || !menteeDoc.exists) return;
                  result.push({
                    ...userDoc.data(),
                    ...menteeDoc.data(),
                    userId: userDoc.id,
                    userType: "mentee",
                  });
                });
              reqProms.push(menteeProm);

              // mento
              let mentoProm = db
                .collection("mento")
                .doc(userDoc.id)
                .get()
                .then((mentoDoc) => {
                  if (!userDoc.exists || !mentoDoc.exists) return;
                  result.push({
                    ...userDoc.data(),
                    ...mentoDoc.data(),
                    userId: userDoc.id,
                    userType: "mento",
                  });
                });
              reqProms.push(mentoProm);
            }
          }

          // FIXME: 옛날 버전 유저데이터 요청로직
          // for (const userDoc of userDocs.docs) {
          //   switch (selectType) {
          //     case "mentee": {
          //       let menteeDoc = await db
          //         .collection("mentee")
          //         .doc(userDoc.id)
          //         .get();
          //       if (!userDoc.exists || !menteeDoc.exists) return;
          //       result.push({
          //         ...userDoc.data(),
          //         ...menteeDoc.data(),
          //         userId: userDoc.id,
          //         userType: "mentee",
          //       });
          //       break;
          //     }
          //     case "mento": {
          //       let mentoDoc = await db.collection("mento").doc(userDoc.id).get();
          //       if (!userDoc.exists || !mentoDoc.exists) return;
          //       result.push({
          //         ...userDoc.data(),
          //         ...mentoDoc.data(),
          //         userId: userDoc.id,
          //         userType: "mento",
          //       });
          //       break;
          //     }
          //     default: {
          //       if (!userDoc.exists) return;
          //       let menteeDoc = await db
          //         .collection("mentee")
          //         .doc(userDoc.id)
          //         .get();
          //       if (menteeDoc.exists) {
          //         result.push({
          //           ...userDoc.data(),
          //           ...menteeDoc.data(),
          //           userId: userDoc.id,
          //           userType: "mentee",
          //         });
          //       }
          //       let mentoDoc = await db.collection("mento").doc(userDoc.id).get();
          //       if (mentoDoc.exists) {
          //         result.push({
          //           ...userDoc.data(),
          //           ...mentoDoc.data(),
          //           userId: userDoc.id,
          //           userType: "mento",
          //         });
          //       }
          //     }
          //   }
          // }
          // setUsers(result);
          // FIXME:
        }
        Promise.all(reqProms).then(() => {
          console.log(selectType);
          // if(selectType == 'mentee'){
          let resultArray = [];
          result.map((item, index) => {
            db.collection("worksheets")
              .where("mentee", "==", item.userId)
              .orderBy("date", "desc")
              .get()
              .then((sheetDoc) => {
                let sheetArray = [];
                if (!sheetDoc.empty) {
                  // console.log(sheetDoc.docs)
                  let inArr = [];
                  sheetDoc.forEach((ddd) => {
                    // console.log(ddd.data())
                    if (ddd.exists) {
                      inArr.push(ddd.data());
                    }
                  });
                  sheetArray.push(inArr);
                }
                item["sheets"] = sheetArray;
                resultArray.push(item);
                if (resultArray.length == result.length) setUsers(resultArray);
                //
              });
          });

          // }else{
          //   setUsers(result)
          // }
        });
      });
  }, [db, selectType, setUsers]);

  const renderedUsers = users
    .filter((user) => {
      if (!selectType) return true;
      return user.userType === selectType;
    })
    .map((user, idx) => {
      let typeEx = "없음";
      if (user.userType == "mento") {
        typeEx = "멘토";
      } else if (user.userType == "mentee") {
        typeEx = "멘티";
      }
      let sheetEndUser = false;
      let end = [];
      let all = [];
      if (user.sheets != undefined && user.sheets.length > 0) {
        user.sheets[0].map((sheet, index) => {
          if (sheet.finalSubmit) {
            end.push(sheet);
          }
          all.push(sheet);
        });
        if (end.length == all.length) {
          sheetEndUser = true;
        }
      }
      if (sheetEndUser && sucType == "ing") {
        return;
      }
      if (!sheetEndUser && sucType == "end") {
        return;
      }
      return (
        <tr key={user.email}>
          <td>
            <input
              type="checkbox"
              value={user.userId}
              name="cur_check"
              className="table_checkbox"
            />
          </td>
          <td>{user.name}</td>
          {selectType == "mentee" ? (
            <td>
              <Link
                className=""
                to={`/admin/member/${user.userId}/worksheets/`}
              >
                {user.email}
              </Link>
            </td>
          ) : (
            <td>
              <Link
                className=""
                to={`/admin/member/${user.userId}/worksheets/`}
              >
                {user.email}
              </Link>
            </td>
          )}
          <td>
            {user.companyName ? user.companyName : "없음"}
            {user.job ? user.job : "없음"}
          </td>
          <td>{user.phonenumber}</td>
          <td>
            {typeEx}
            {user.agree ? "(승인)" : "(미승인)"}
          </td>
          <td className={"worksheetCount_" + idx}>
            {user.userType == "mentee"
              ? //
                // testFunction(user, idx)
                lastSave(user, idx)
              : //
              user.agree
              ? "승인"
              : "미승인"}
          </td>
          <td>
            {user.userType == "mentee"
              ? lastMentoring(user, idx)
              : user.grade
              ? user.grade
              : "등급없음"}
          </td>
          <td>
            {user.registDate ? user.registDate.split("T")[0] : null}
            <br />
            {user.registDate
              ? user.registDate.split("T")[1].split("Z")[0].split(".")[0]
              : null}
          </td>
          <td>
            {user.userType === "mentee" ? (
              <span>
                <Link
                  className="reviseButton reviseMemberButton mb-0"
                  to={`/admin/member/${user.userId}/worksheets/`}
                >
                  선택
                </Link>
              </span>
            ) : (
              <span>
                <Link
                  className="reviseButton reviseMemberButton mb-0"
                  to={`/admin/member/${user.userId}/worksheets/`}
                >
                  선택
                </Link>
              </span>
            )}
          </td>
        </tr>
      );
    });

  return (
    <div>
      <div id="wrapper">
        <div id="container">
          <h1 id="container_title">
            회원관리{" "}
            {selectType == null
              ? ""
              : selectType == "mentee"
              ? "(멘티)"
              : "(멘토)"}
            <div className="float-right">
              {modiBtn(selectType)}
              <div
                className="btn btn-sm squear-basic-btn delete"
                onClick={() => {
                  deleteUser(db);
                }}
              >
                삭제
              </div>
              <div className="btn btn-sm squear-basic-btn">
                <Link to="/register/" style={{ color: "#fff" }}>
                  등록
                </Link>
              </div>
            </div>
          </h1>
          {selectType == null ? null : selectType == "mentee" ? (
            <div className="d-flex float-right">
              <div className="admBtn matching" onClick={() => mentoChange(db)}>
                멘토로 변경하기
              </div>
              <div className="admBtn approving" onClick={menteeApprove}>
                멘티 승인하기
              </div>
              <div className="admBtn returning" onClick={menteeReturn}>
                멘티 반려하기
              </div>
            </div>
          ) : (
            <div className="d-flex float-right">
              <div
                className="admBtnSmall"
                onClick={() => {
                  mentoGrade("코치", db);
                }}
              >
                코치
              </div>
              <div
                className="admBtnSmall"
                onClick={() => {
                  mentoGrade("멘토", db);
                }}
              >
                멘토
              </div>
              <div
                className="admBtnSmall"
                onClick={() => {
                  mentoGrade("선임멘토", db);
                }}
              >
                선임멘토
              </div>
              <div className="admBtn approving" onClick={mentoApprove}>
                멘토 승인하기
              </div>
              <div className="admBtn returning" onClick={mentoReturn}>
                멘토 반려하기
              </div>
            </div>
          )}
          <div className="container_wr">
            <div className="searchArea d-flex justify-content-center align-items-center mb-4"></div>
            <div className="tbl_head01 tbl_wrap">
              <table className="table stepTable tableStyle text-center bgTable">
                <caption className="sound-only d-none">회원관리 목록</caption>
                <colgroup>
                  <col width="80px" />
                  <col width="100px" />
                  <col width="150px" />
                  <col width="150px" />
                  <col width="200px" />
                  <col width="120px" />
                  <col width="100px" />
                  <col width="100px" />
                  <col width="120px" />
                  <col width="80px" />
                </colgroup>
                <thead>
                  <tr>
                    <th className="bg">
                      <input type="checkbox" onChange={(e) => checkAll(e)} />
                    </th>
                    <th className="bg">이름</th>
                    <th className="bg">이메일</th>
                    <th className="bg">소속(직업군)</th>
                    <th className="bg">번호</th>
                    <th className="bg">분류</th>
                    <th className="bg">현황</th>
                    <th className="bg">상태</th>
                    <th className="bg">가입날짜</th>
                    <th className="bg">관리</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {console.log("users", users, "select", selectType)} */}
                  {renderedUsers}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {Modal(db)}
    </div>
  );
};

const Modal = (db) => {
  return (
    <div className="modal_adm">
      <div>
        <div className="content">test</div>
        <div className="btns">
          <div
            className="btn confirm"
            onClick={() => {
              modalConfirm(db);
            }}
          >
            확인
          </div>
          <div
            className="btn cancel"
            onClick={() => {
              $(".modal_adm").css("display", "none");
            }}
          >
            취소
          </div>
        </div>
      </div>
    </div>
  );
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const modiBtn = (selectType) => {
  // if(selectType == 'mento'){
  //   return <div className="btn btn-sm squear-basic-btn modify">수정</div>
  // }
};

const modalConfirm = (db) => {
  //
  console.log($(".modal_adm .content").text());
  if ($(".modal_adm .content").text() == "멘토회원을 승인하시겠습니까?") {
    // 승인시 처리
    $("input[name=cur_check]:checked").each(function () {
      console.log($(this).val());
      db.collection("mento")
        .doc($(this).val())
        .update({
          agree: true,
          grade: "코치",
        })
        .then(() => {
          window.location.reload();
        })
        .catch((e) => alert(e));
    });
  } else if (
    $(".modal_adm .content").text() == "멘티회원을 승인하시겠습니까?"
  ) {
    // 반려시 처리
    $("input[name=cur_check]:checked").each(function () {
      console.log($(this).val());
      db.collection("mentee")
        .doc($(this).val())
        .update({
          agree: true,
        })
        .then(() => {
          window.location.reload();
        })
        .catch((e) => alert(e));
    });
  } else if (
    $(".modal_adm .content").text() == "멘토회원을 반려하시겠습니까?"
  ) {
    // 반려시 처리
    $("input[name=cur_check]:checked").each(function () {
      console.log($(this).val());
      db.collection("mento")
        .doc($(this).val())
        .update({
          agree: false,
          grade: null,
        })
        .then(() => {
          window.location.reload();
        })
        .catch((e) => alert(e));
    });
  } else if (
    $(".modal_adm .content").text() == "멘티회원을 반려하시겠습니까?"
  ) {
    // 반려시 처리
    $("input[name=cur_check]:checked").each(function () {
      console.log($(this).val());
      db.collection("mentee")
        .doc($(this).val())
        .update({
          agree: false,
        })
        .then(() => {
          window.location.reload();
        })
        .catch((e) => alert(e));
    });
  } else if ($(".modal_adm .content").text() == "삭제하시겠습니까?") {
    // 반려시 처리
    $("input[name=cur_check]:checked").each(function () {
      console.log($(this).val());
      db.collection("users")
        .doc($(this).val())
        .update({
          useOrNot: false,
        })
        .then(() => {
          // 나중에 리로드 말고 다른걸로 수정 필요
          window.location.reload();
        });
    });
  } else if ($(".modal_adm .content").text() == "멘토로 변경하시겠습니까?") {
    $("input[name=cur_check]:checked").each(function () {
      console.log($(this).val());
      db.collection("mentee")
        .doc($(this).val())
        .delete()
        .then(() => {
          db.collection("mento")
            .doc($(this).val())
            .set({
              picture: "",
              account: "",
              techType: "",
              fourTechType: "",
              startupFields: "",
              status: 1 || "",
              agree: true,
            })
            .then(() => {
              // 나중에 리로드 말고 다른걸로 수정 필요
              window.location.reload();
            })
            .catch((err) => alert(err));
        })
        .catch((err) => alert(err));
    });
  }

  $(".modal_adm").css("display", "none");
};
const mentoApprove = () => {
  console.log("멘토 승인하는것");
  console.log("우선 채크된 채크박스의 멘토 키값 가져와야됨");
  $(".modal_adm .content").text("멘토회원을 승인하시겠습니까?");
  $(".modal_adm").css("display", "block");
};

const menteeApprove = () => {
  console.log("멘티 승인하는것");
  console.log("우선 채크된 채크박스의 멘토 키값 가져와야됨");
  $(".modal_adm .content").text("멘티회원을 승인하시겠습니까?");
  $(".modal_adm").css("display", "block");
};

const mentoReturn = () => {
  console.log("멘토 반려하는것");
  console.log("우선 채크된 채크박스의 멘토 키값 가져와야됨");
  $(".modal_adm .content").text("멘토회원을 반려하시겠습니까?");
  $(".modal_adm").css("display", "block");
};

const menteeReturn = () => {
  console.log("멘티 반려하는것");
  console.log("우선 채크된 채크박스의 멘토 키값 가져와야됨");
  $(".modal_adm .content").text("멘티회원을 반려하시겠습니까?");
  $(".modal_adm").css("display", "block");
};

const mentoChange = (db) => {
  console.log("멘토 변경하는것");
  console.log("우선 채크된 채크박스의 멘토 키값 가져와야됨");
  // 알림
  $(".modal_adm .content").text("멘토로 변경하시겠습니까?");
  $(".modal_adm").css("display", "block");
};

const checkAll = (e) => {
  if ($(e.target).is(":checked")) {
    $(".table_checkbox").prop("checked", true);
  } else {
    $(".table_checkbox").prop("checked", false);
  }
};

const mentoGrade = (grade, db) => {
  console.log(grade, db);
  $("input[name=cur_check]:checked").each(function () {
    console.log($(this).val());
    db.collection("mento")
      .doc($(this).val())
      .get()
      .then((mentoDoc) => {
        if (mentoDoc.exists) {
          if (mentoDoc.data().agree) {
            db.collection("mento")
              .doc($(this).val())
              .update({
                grade: grade,
              })
              .then(() => {
                window.location.reload();
              })
              .catch((e) => alert(e));
          } else {
            alert("멘토 승인이 안된 멘토가 있습니다.");
          }
        }
      });
  });
};

const lastSave = (user, idx) => {
  let pageCtrl = {
    1: "A-1",
    2: "A-2",
    3: "A-3",
    4: "B-1",
    5: "B-2",
    6: "B-3",
    7: "B-4",
    8: "B-5",
    9: "B-6",
    10: "B-7",
    11: "B-8",
    12: "B-9",
    13: "C-1",
    14: "C-2",
    15: "C-3",
  };
  let result = "X";
  if (user.sheets != undefined && user.sheets.length > 0) {
    result = pageCtrl[user.sheets[0][0].page];
    // 밑에는 제목도 추가한 값임 지우지말기..
    // result = user.sheets[0][0].title+'('+pageCtrl[user.sheets[0][0].page]+')'
  }
  return result;
};

const lastMentoring = (user, idx) => {
  let result = "X";
  if (user.sheets != undefined && user.sheets.length > 0) {
    if (user.sheets[0][0].mento) {
      if (user.sheets[0][0].finalSubmit) {
        result = "멘토링 완료";
      } else {
        result = "멘토링 진행중";
      }
    } else {
      result = "멘토 매칭 예정";
    }
  }
  return result;
};

const deleteUser = (db) => {
  console.log("삭제");
  $("input[name=cur_check]:checked").each(function () {
    console.log($(this).val());
    //
    $(".modal_adm .content").text("삭제하시겠습니까?");
    $(".modal_adm").css("display", "block");

    // db.collection('users')
    // .doc($(this).val())
    // .update({
    //   useOrNot:false
    // })
    // // 나중에 리로드 말고 다른걸로 수정 필요
    // window.location.reload()
  });
};

export default MemberList;
