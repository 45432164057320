import { Link, useHistory, useRouteMatch } from "react-router-dom";
import "../Worksheet.css";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { useEffect, useRef, useState } from "react";

import * as docx from "docx";
import { saveAs } from "file-saver";
import createAnswerDocxRows from "util/docx/createAnswerDocxRows";
import questions from "feature/worksheets/questions.json";
import extractPdf from "util/extractPdf";
import CommentCheck from "util/CommentCheck";

const Worksheet10 = ({ listeners, userType }) => {
  const [answers, setAnswers] = useState({});
  const db = useRef(firebase.firestore()).current;
  const auth = useRef(firebase.auth()).current;
  const [commentVisible, setCommentVisible] = useState(true);
  const match = useRouteMatch();
  const [worksheetId, setWorksheetId] = useState();
  const history = useHistory();
  const [initialAnswers, setInitialAnswers] = useState({});
  const htmlRef = useRef();

  const radioDefaultChecked = listeners.radioDefaultChecked(
    answers,
    setAnswers
  );
  const radioChecked = listeners.radioChecked(answers);
  const radioListener = listeners.radioListener(answers, setAnswers);
  const checkboxChecked = listeners.checkboxChecked(answers);
  const checkboxListener = listeners.checkboxListener(answers, setAnswers);
  const textareaValue = listeners.textareaValue(answers);
  const textareaListener = listeners.textareaListener(answers, setAnswers);
  const commentValue = listeners.commentValue(answers);
  const commentListener = listeners.commentListener(answers, setAnswers);

  useEffect(() => {
    //
    setWorksheetId(match.params.id);

    db.collection("worksheets")
      .doc(match.params.id)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          return;
        }
        setAnswers(doc.data().questionAnswers);
        listeners.storeAnswersListener(doc.data().questionAnswers);
      });
  }, []);

  useEffect(() => {
    console.log(userType);
    // if (userType == "mento") {
    //   setCommentVisible(true);
    // }
  }, [userType]);

  const saveWorksheet = (e) => {
    if (userType === "admin") {
      alert("어드민은 워크시트를 저장할 수 없습니다!");
      return;
    }

    let finalSubmit = false;
    let date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, -1);

    if (worksheetId && worksheetId != "new") {
      const accountInfo =
        userType == "mento"
          ? {
              mento: auth.currentUser.uid,
            }
          : {
              mentee: auth.currentUser.uid,
            };

      db.collection("worksheets")
        .doc(worksheetId)
        .update({
          questionAnswers: answers,
          page: 10,
          ...accountInfo,
          finalSubmit,
          date,
        })
        .then(() => {
          alert("저장 성공!");
          listeners.storeAnswersListener(answers);
        })
        .catch((e) => alert(e));
      return;
    }
    console.log("\nanswers", {
      questionAnswers: {
        ...answers,
      },
      mentee: auth.currentUser.uid,
      mento: "",
    });
    db.collection("worksheets")
      .add({
        questionAnswers: answers,
        mentee: auth.currentUser.uid,
        page: 10,
        finalSubmit,
        date,
      })
      .then((doc) => {
        setWorksheetId(doc.id);
        alert("저장 성공!");
        listeners.storeAnswersListener(answers);
        history.replace(`/worksheets/${doc.id || "new"}/worksheet10/`);
      })
      .catch((e) => alert(e));
  };

  const extractDocx = () => {
    const targetData = Object.keys(answers).map((item, index) => {
      const Q = [
        item.toString() + " " + questions?.[9]?.[item]?.toString(),
        answers?.[item],
      ];
      return Q;
    });
    let tabledTargetData = targetData
      .map((data) => {
        if (!(data[0] && data[1])) return null;
        return createAnswerDocxRows(data[0], data[1], 3);
      })
      .reduce((acc, cur) => acc.concat(cur), [])
      .filter((e) => e);

    tabledTargetData =
      tabledTargetData?.length > 0
        ? tabledTargetData
        : createAnswerDocxRows("데이터가 없습니다", "")
            .reduce((acc, cur) => acc.concat(cur), [])
            .filter((e) => e);

    const doc = new docx.Document();
    const table = new docx.Table({
      width: {
        size: 100,
        type: docx.WidthType.PERCENTAGE,
      },
      rows: tabledTargetData,
    });

    doc.addSection({
      properties: {},
      children: [table],
    });
    docx.Packer.toBlob(doc).then((blob) => {
      saveAs(blob);
    });
  };

  return (
    <div>
      <div className="minwidth">
        <div id="worksheet">
          <div className="basicWrap">
            <section className="d-flex justify-content-between youtube align-items-center px-5">
              <h2 className="h2 font-weight-normal">
                작성하는 법을 잘 모르겠다면? youtube 시청 후 작성하기
              </h2>
              <Link
                className="btn btn-sm font-weight-normal squear-btn-basic radius pr-4 pl-4"
                to
              >
                과정설명 영상 보러가기
              </Link>
            </section>
            <div style={{ marginTop: 22, borderBottom: "1px solid #aaa" }}>
              <span style={{ fontSize: 12, color: "#aaa", marginTop: 22 }}>
                STEP2. 가치제안서 {">"} 전달 역량 워크시트
              </span>
            </div>
            <form action className="dataForm mt40 " ref={htmlRef}>
              <input
                type="checkbox"
                name="b_7_2"
                id="b_7_2_1"
                className="d-none"
                checked={checkboxChecked("b_7_2_a", "1.기술")}
                onChange={checkboxListener("b_7_2_a", "1.기술")}
              />
              <input
                type="checkbox"
                name="b_7_2"
                id="b_7_2_2"
                className="d-none"
                checked={checkboxChecked("b_7_2_a", "2.자금")}
                onChange={checkboxListener("b_7_2_a", "2.자금")}
              />
              <input
                type="checkbox"
                name="b_7_2"
                id="b_7_2_3"
                className="d-none"
                checked={checkboxChecked("b_7_2_a", "3.인력")}
                onChange={checkboxListener("b_7_2_a", "3.인력")}
              />
              <input
                type="checkbox"
                name="b_7_2"
                id="b_7_2_4"
                className="d-none"
                checked={checkboxChecked("b_7_2_a", "4.파트너")}
                onChange={checkboxListener("b_7_2_a", "4.파트너")}
              />
              <input
                type="checkbox"
                name="b_7_2"
                id="b_7_2_5"
                className="d-none"
                checked={checkboxChecked("b_7_2_a", "5.기타")}
                onChange={checkboxListener("b_7_2_a", "5.기타")}
              />

              <input
                type="radio"
                name="b_7_4"
                id="b_7_4_1"
                className="d-none"
                checked={radioChecked("b_7_4_a", "있다")}
                onChange={radioListener("b_7_4_a", "있다")}
              />
              <input
                type="radio"
                name="b_7_4"
                id="b_7_4_2"
                className="d-none"
                checked={radioDefaultChecked("b_7_4_a", "없다")}
                onChange={radioListener("b_7_4_a", "없다")}
              />

              <input
                type="radio"
                name="b_7_5"
                id="b_7_5_1"
                className="d-none"
                checked={radioChecked("b_7_5_a", "있다")}
                onChange={radioListener("b_7_5_a", "있다")}
              />
              <input
                type="radio"
                name="b_7_5"
                id="b_7_5_2"
                className="d-none"
                checked={radioDefaultChecked("b_7_5_a", "없다")}
                onChange={radioListener("b_7_5_a", "없다")}
              />

              <section className="section">
                <div className="question">
                  B_7_1. 기능명세 워크시트와 기능분석 워크시트에서 설계한
                  제품(서비스)은 경쟁자보다 우수한가?
                  <span className="text-danger">*</span>
                </div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_7_1"
                    id
                    className="col textareaForm"
                    value={textareaValue("b_7_1", 1)}
                    onChange={textareaListener("b_7_1", 1)}
                  />
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_7_1", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_7_1_comment"
                    id="b_7_1_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == "mento" ? false : true}
                    value={commentValue("b_7_1", 1)}
                    onChange={commentListener("b_7_1", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question">
                  B_7_2. 기능명세 워크시트와 기능분석 워크시트에서 설계한
                  제품(서비스)을 완성하기 위한 기술(역량)과 자원들을 갖고
                  있는가?
                  <span className="text-danger">*</span>
                </div>
                <div className="answer">
                  <ul className="row">
                    <li className="col-2">
                      <label htmlFor="b_7_2_1" className="b_7_2_1 checkbox">
                        <span />
                        1.기술
                      </label>
                    </li>
                    <li className="col-2">
                      <label htmlFor="b_7_2_2" className="b_7_2_2 checkbox">
                        <span />
                        2.자금
                      </label>
                    </li>
                    <li className="col-2">
                      <label htmlFor="b_7_2_3" className="b_7_2_3 checkbox">
                        <span />
                        3.인력
                      </label>
                    </li>
                    <li className="col-2">
                      <label htmlFor="b_7_2_4" className="b_7_2_4 checkbox">
                        <span />
                        4.파트너
                      </label>
                    </li>
                    <li className="col-2">
                      <label htmlFor="b_7_2_5" className="b_7_2_5 checkbox">
                        <span />
                        5.기타
                      </label>
                    </li>
                    {/*  */}
                    <li className="col-12 d-none b_7_2_1_txt">
                      <input
                        type="text"
                        className="inputTextForm"
                        name="b_7_2_1_Text"
                        id="b_7_2_1_Text"
                        placeholder="1.기술에 대한 설명을 적어주세요"
                        value={textareaValue("b_7_2_b", 1)}
                        onChange={textareaListener("b_7_2_b", 1)}
                      />
                    </li>
                    <li className="col-12 d-none b_7_2_2_txt">
                      <input
                        type="text"
                        className="inputTextForm"
                        name="b_7_2_2_Text"
                        id="b_7_2_2_Text"
                        placeholder="2.자금에 대한 설명을 적어주세요"
                        value={textareaValue("b_7_2_c", 2)}
                        onChange={textareaListener("b_7_2_c", 2)}
                      />
                    </li>
                    <li className="col-12 d-none b_7_2_3_txt">
                      <input
                        type="text"
                        className="inputTextForm"
                        name="b_7_2_3_Text"
                        id="b_7_2_3_Text"
                        placeholder="3.인력에 대한 설명을 적어주세요"
                        value={textareaValue("b_7_2_d", 3)}
                        onChange={textareaListener("b_7_2_d", 3)}
                      />
                    </li>
                    <li className="col-12 d-none b_7_2_4_txt">
                      <input
                        type="text"
                        className="inputTextForm"
                        name="b_7_2_4_Text"
                        id="b_7_2_4_Text"
                        placeholder="4.파트너에 대한 설명을 적어주세요"
                        value={textareaValue("b_7_2_e", 4)}
                        onChange={textareaListener("b_7_2_e", 4)}
                      />
                    </li>
                    <li className="col-12 d-none b_7_2_5_txt">
                      <input
                        type="text"
                        className="inputTextForm"
                        name="b_7_2_5_Text"
                        id="b_7_2_5_Text"
                        placeholder="5.기타에 대한 설명을 적어주세요"
                        value={textareaValue("b_7_2_f", 5)}
                        onChange={textareaListener("b_7_2_f", 5)}
                      />
                    </li>
                  </ul>
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_7_2", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_7_2_comment"
                    id="b_7_2_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == "mento" ? false : true}
                    value={commentValue("b_7_2", 1)}
                    onChange={commentListener("b_7_2", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question">
                  B_7_4. 자사 브랜드로 시장에 접근이 가능한가?
                  <span className="text-danger">*</span>
                </div>
                <div className="answer">
                  <ul className="row">
                    <li className="col-2">
                      <label htmlFor="b_7_4_1" className="b_7_4_1 radio">
                        <span />
                        있다
                      </label>
                    </li>
                    <li className="col-2">
                      <label htmlFor="b_7_4_2" className="b_7_4_2 radio">
                        <span />
                        없다
                      </label>
                    </li>
                    {answers?.["b_7_4_a"] === "있다" && (
                      <li className="col-12">
                        <input
                          type="text"
                          className="inputTextForm"
                          name="b_7_4"
                          id="b_7_4Text"
                          placeholder="이유를 적어주세요."
                          value={textareaValue("b_7_4_b", 1)}
                          onChange={textareaListener("b_7_4_b", 1)}
                        />
                      </li>
                    )}
                  </ul>
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_7_4", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_7_4_comment"
                    id="b_7_4_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == "mento" ? false : true}
                    value={commentValue("b_7_4", 1)}
                    onChange={commentListener("b_7_4", 1)}
                  />
                </div>
              </section>
              <section className="section">
                <div className="question">
                  B_7_5. 제품(서비스)의 우수성을 지속적으로 유지할 수 있는가?
                  <span className="text-danger">*</span>
                </div>
                <div className="answer">
                  <ul className="row">
                    <li className="col-2">
                      <label htmlFor="b_7_5_1" className="b_7_5_1 radio">
                        <span />
                        있다
                      </label>
                    </li>
                    <li className="col-2">
                      <label htmlFor="b_7_5_2" className="b_7_5_2 radio">
                        <span />
                        없다
                      </label>
                    </li>
                    {answers?.["b_7_5_a"] === "있다" && (
                      <li className="col-12">
                        <input
                          type="text"
                          className="inputTextForm"
                          name="b_7_5"
                          id="b_7_5Text"
                          placeholder="이유를 적어주세요."
                          value={textareaValue("b_7_5_b", 1)}
                          onChange={textareaListener("b_7_5_b", 1)}
                        />
                      </li>
                    )}
                  </ul>
                </div>
              </section>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_7_5", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_7_5_comment"
                    id="b_7_5_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == "mento" ? false : true}
                    value={commentValue("b_7_5", 1)}
                    onChange={commentListener("b_7_5", 1)}
                  />
                </div>
              </section>
            </form>
            <section className="btnArea d-flex align-items-center justify-content-end">
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={() => extractPdf(htmlRef.current)}
              >
                PDF
              </button>
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={extractDocx}
              >
                다운로드
              </button>
              <div className="btn btn-sm squear-btn-border-basic mr-2">
                <Link to={`/worksheets/${worksheetId || "new"}/worksheet9/`}>
                  이전으로
                </Link>
              </div>
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={saveWorksheet}
              >
                저장하기
              </button>
              <div
                className="btn btn-sm squear-btn-basic "
                onClick={() => {
                  let keyArray = [
                    "b_7_1",
                    "b_7_2_a",
                    "b_7_4_a",
                    "b_7_5_a",
                  ];
                  // 'b_7_2_b', 'b_7_2_c', 'b_7_2_d', 'b_7_2_e', 'b_7_2_f',
                  if (answers?.["b_7_2_a"]?.includes("1.기술")) {
                    keyArray.push("b_7_2_b");
                  }
                  if (answers?.["b_7_2_a"]?.includes("2.자금")) {
                    keyArray.push("b_7_2_c");
                  }
                  if (answers?.["b_7_2_a"]?.includes("3.인력")) {
                    keyArray.push("b_7_2_d");
                  }
                  if (answers?.["b_7_2_a"]?.includes("4.파트너")) {
                    keyArray.push("b_7_2_e");
                  }
                  if (answers?.["b_7_2_a"]?.includes("5.기타")) {
                    keyArray.push("b_7_2_f");
                  }
                  if (answers?.["b_7_4_a"] === '있음') {
                    keyArray.push("b_7_4_b");
                  }
                  if (answers?.["b_7_5_a"] === '있음') {
                    keyArray.push("b_7_5_b");
                  }

                  listeners.checkValue(keyArray, answers, worksheetId, 11);
                }}
              >
                <Link>다음으로</Link>
              </div>
            </section>
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default Worksheet10;
