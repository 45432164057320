import { Link } from "react-router-dom";


const AdminHome = () => {
    return(
        <div>
            <div id="wrapper">
                <div id="container" className="adminMain">
                <h1 id="container_title">관리자메인</h1>
                <div className="container_wr container d-flex flex-column justify-content-center">
                    <ul className="d-flex justify-content-between list1">
                    <li className="bn">
                        <Link to="/admin/banner">
                        <i className="fa fa-window-maximize" aria-hidden="true" />
                        <h2>배너관리</h2>
                        </Link>
                    </li>
                    <li className="pop">
                        <Link to="/admin/popup">
                        <i className="fa fa-window-restore" aria-hidden="true" />
                        <h2>팝업관리</h2>
                        </Link>
                    </li>
                    <li className="mem">
                        <Link to="/admin/member">
                        <i className="fa fa-user-circle-o" aria-hidden="true" />
                        <h2>회원관리</h2>
                        </Link>
                    </li>
                    </ul>
                    <ul className="d-flex justify-content-between list2 mt-4">
                    <li className="not">
                        <Link to="/admin/notice">
                        <i className="fa fa-cog" aria-hidden="true" />
                        <h2>공지사항</h2>
                        </Link>
                    </li>
                    <li className="qa">
                        <a>
                        <i className="fa fa-envelope-open-o" aria-hidden="true" />
                        <h2>QnA</h2>
                        </a>
                    </li>
                    <li className="bank">
                        <a>
                        <i className="fa fa-database" aria-hidden="true" />
                        <h2>계좌관리</h2>
                        </a>
                    </li>
                    </ul>
                </div>
                </div>
            </div>
            </div>
    )
}

export default AdminHome;