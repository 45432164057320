import { Link } from "react-router-dom"
import icon01 from "../../image/main/icon01.jpg";
import intro01_01 from "../../image/sub/intro01_01.png";
import intro01_02 from "../../image/sub/intro01_02.png";
import icon02 from "../../image/main/icon02.jpg";
import intro02_01 from "../../image/sub/intro02_01.jpg";
import intro02_02 from "../../image/sub/intro02_02.jpg";
import intro02_03 from "../../image/sub/intro02_03.jpg";
import icon03 from "../../image/main/icon03.jpg";
import intro03_01 from "../../image/sub/intro03_01.jpg";
import intro03_02 from "../../image/sub/intro03_02.png";
import intro03_03 from "../../image/sub/intro03_03.png";
import intro03_04 from "../../image/sub/intro03_04.png";
import intro03_05 from "../../image/sub/intro03_05.png";

const Intro = () =>{
    return(
        <div>
            {/* <link rel="stylesheet" href="/public/css/default.css" />
            <link
                rel="stylesheet"
                href="/public/js/font-awesome/css/font-awesome.min.css"
            />
            <link rel="stylesheet" href="/public/css/bootstrap.min.css" />
            <link rel="stylesheet" href="/public/css/common.css" />
            <link rel="stylesheet" href="/public/css/font.css" />
            <link rel="stylesheet" href="/public/header.css" />
            <link rel="stylesheet" href="/public/index.css" />
            <link rel="stylesheet" href="/public/modal.css" />
            <link rel="stylesheet" href="/public/popup.css" /> */}
            {/* js */}
            {/* 기타 */}
            {/*[if lte IE 8]>
                
                
                <![endif]*/}
            <div className="minwidth">
                {/* 상단 시작 { */}
                {/* <header include-html="/public/header.html" /> */}
                {/* } 상단 끝 */}
                {/*  팝업시작 { */}
                {/* <div include-html="/public/popup.html" /> */}
                {/*  } 팝업시작  */}
                {/* 시장정보 확인하기 Modal { */}
                {/* <div include-html="/public/modal.html" /> */}
                {/* } 시장정보 확인하기 Modal */}
                <hr />
                {/* 콘텐츠 시작 { */}
                <div id="mymain" className="main ">
                <div
                    id="topNav"
                    className="d-flex justify-content-start align-items-end pb-2 "
                >
                    <h1 className="h1 m-0 font-weight-bold">회사소개</h1>
                    <ul id="topNavList" className="d-flex">
                    <li className="active">
                        <a href="/public/intro/intro.html">회사소개</a>
                    </li>
                    <li className>
                        <Link to="/map/">오시는길</Link>
                    </li>
                    </ul>
                </div>
                <div id="intro1_01" className="subWrap">
                    {" "}
                    {/* #sub */}
                    <div className="basicWrap intro">
                    <span className="sound_only">회사소개</span>
                    <section className="mt40">
                        <div className="mb100 intro1">
                        <img
                            className="t_img"
                            src={icon01}
                            alt="tec"
                        />
                        <h1 className="h1">
                            TEC은 세계적으로 검증된 기술사업화 교육 프로그램입니다.
                        </h1>
                        <div className="row container">
                            <section className="w-100 mb60">
                            <h3 className="h3 mb40">
                                미국 리서치 트라이앵글파크(RTP)의 노스캐롤라이나
                                주립대(NCSU)에서 개발
                            </h3>
                            <img
                                className="w60"
                                src={intro01_01}
                                alt
                            />
                            </section>
                            <section className="w-100">
                            <h3 className="h3 mb40">
                                하이테크 기업에게 필수적인 일련의 과정을 모듈화 하여 학습
                            </h3>
                            <img src={intro01_02} alt />
                            </section>
                        </div>
                        </div>
                        <div className="mb100 intro2">
                        <img
                            className="t_img"
                            src={icon02}
                            alt="tec"
                        />
                        <h1 className="h1">
                            TEC을 경험하면 사업과 시장을 보는 관점이 달라집니다.
                        </h1>
                        <div className="row container mb60">
                            <section className="col-6 px-0 d-flex align-items-center flex-column justify-content-center">
                            <h3 className="h3">TEC 교육과정 및 목표</h3>
                            <p>
                                비즈니스 계획 준비 기반 세우기 및 문제 제기를 통해 이해도와
                                사고력 제고
                            </p>
                            </section>
                            <section className="col">
                            <img
                                style={{ width: "100%" }}
                                src={intro02_01}
                                alt
                            />
                            </section>
                        </div>
                        <div className="row container">
                            <section className="col">
                            <img
                                style={{ width: "100%" }}
                                className="mb-4"
                                src={intro02_02}
                                alt
                            />
                            <img
                                style={{ width: "100%" }}
                                src={intro02_03}
                                alt
                            />
                            </section>
                            <section className="col-6 px-0 d-flex align-items-center flex-column justify-content-center">
                            <h3 className="h3">TEC 알고리즘</h3>
                            <p>
                                기술창업사업화, 이상(성공)과 현실(냉엄한 시장)의
                                괴리(실패)에 다리를 놓아주어 치명적 결함 제거
                            </p>
                            <p>
                                역량(Capability)-제품(Product)-시장(Markets), 3가지 요소
                                유기적 연결, 창업 실수를 줄이고 가능성을 높이는데 초점
                            </p>
                            </section>
                        </div>
                        </div>
                        <div className="mb100 intro3">
                        <img
                            className="t_img"
                            src={icon03}
                            alt="tec"
                        />
                        <h1 className="h1">
                            (주)에이치이노베이션은 TEC을 국내에 독점 공급하는 최고 전문가
                            그룹 입니다.
                        </h1>
                        <div className="row container">
                            <section className="w-100 mb60">
                            <h3 className="h3 mb40">
                                한밭대학교 창업경영대학원 및 창업학과 교육과정에 15년간
                                적용한 TEC프로그램 DB보유, 국내 독점 공급사
                            </h3>
                            <img
                                className="w60"
                                src={intro03_01}
                                alt="TEC의 독보적인 알고리즘을 디지털화"
                            />
                            <h2 className="text-center">
                                TEC의 독보적인 알고리즘을 디지털화
                                <br />
                                사업 성공률을 획기적으로 높이는 &apos;비즈니스 모델 수립&apos; 교육
                                가능
                            </h2>
                            </section>
                            <section className="w-100">
                            <h3 className="h3 mb40">
                                현장감이 살아있는 교육 프로그램 운영
                            </h3>
                            <div className="row container program">
                                <div className="w-25">
                                <img
                                    src={intro03_02}
                                    alt="다년간 창업 및 기술사업화 경험을 바탕으로 국내 환경에 맞춘 워크시트"
                                />
                                <p>
                                    다년간 창업 및 기술사업화 경험을 바탕으로 국내 환경에
                                    맞춘 워크시트
                                </p>
                                </div>
                                <div className="w-25">
                                <img
                                    src={intro03_03}
                                    alt="TEC 알고리즘 전문 서적"
                                />
                                <p>
                                    TEC 알고리즘 전문 서적 &apos;Tracersing the Valley of Death&apos;
                                    번역 및 출판(최종인(2016), 기술사업화: 죽음의 계곡을
                                    건너다, 한경사)
                                </p>
                                </div>
                                <div className="w-25">
                                <img
                                    src={intro03_04}
                                    alt="제품 아이디어 도출부터 사업화 과정까지 일련의 과정을 알고리즘(Algorithm)을 통해 진행"
                                />
                                <p>
                                    제품 아이디어 도출부터 사업화 과정까지 일련의 과정을
                                    알고리즘(Algorithm)을 통해 진행
                                </p>
                                </div>
                                <div className="w-25">
                                <img
                                    src={intro03_05}
                                    alt="제품 아이디어 도출부터 사업화 과정까지 일련의 과정을 알고리즘(Algorithm)을 통해 진행"
                                />
                                <p>
                                    제품 아이디어 도출부터 사업화 과정까지 일련의 과정을
                                    알고리즘(Algorithm)을 통해 진행
                                </p>
                                </div>
                            </div>
                            </section>
                        </div>
                        </div>
                    </section>
                    </div>
                </div>
                {/* #sub */}
                </div>
                {/* } 콘텐츠 끝 */}
                <hr />
                {/* 하단 시작 { */}
                {/* <footer include-html="/public/footer.html" /> */}
                {/* } 하단 시작  */}
            </div>
        </div>

    )
}

export default Intro