const setCookie = function (name, value, day) {
  if (!day) {
    document.cookie = name + "=" + value + ";path=/";
    return;
  }

  var date = new Date();
  date.setTime(date.getTime() + day * 60 * 60 * 24 * 1000);
  document.cookie =
    name + "=" + value + ";expires=" + date.toUTCString() + ";path=/";
};

const getCookie = function (name) {
  var value = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return value ? value[2] : null;
};

const deleteCookie = function (name) {
  var date = new Date();
  document.cookie =
    name + "= " + "; expires=" + date.toUTCString() + "; path=/";
};

const CookieUtil = { setCookie, getCookie, deleteCookie };

export default CookieUtil;
