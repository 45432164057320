import { Link } from "react-router-dom";
import "feature/qna/qna.css";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useMemo } from "react";

import firebase from "firebase/app";
import "firebase/functions";

const Write = () => {
  const functions = useMemo(() => firebase.functions());

  return (
    <Formik
      validate={(values) => {
        const errors = {};

        if (!values.email) {
          errors.email = "이메일이 필요합니다";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        ) {
          errors.email = "잘못된 이메일 주소입니다";
        }
        return errors;
      }}
      initialValues={{
        name: "",
        email: "",
        phoneNumber: "",
        title: "",
        content: "",
      }}
      va
      onSubmit={(values) => {
        const sendEmail = functions.httpsCallable("sendEmail");
        sendEmail(values).then((result) => alert("전송 완료"));
      }}
    >
      <div>
        <div className="minwidth">
          {/* 상단 시작 { */}
          {/* <header include-html="/public/header.html" /> */}
          {/* } 상단 끝 */}
          {/* 시장정보 확인하기 Modal { */}
          {/* <div include-html="/public/modal.html" /> */}
          {/* } 시장정보 확인하기 Modal */}
          <hr />
          {/* 콘텐츠 시작 { */}
          <div className="resizeWrap">
            <div className="basicWrap">
              {/* basicWrap */}
              <div
                id="topNav"
                className="d-flex justify-content-start align-items-end pb-2 mb40"
              >
                <h1 className="h1 m-0 font-weight-bold">문의하기</h1>
                <ul id="topNavList" className="d-flex">
                  <li className>
                    <Link to="/notice/list/">공지사항</Link>
                  </li>
                  <li className="active">
                    <a href="/qna/write/">문의사항</a>
                  </li>
                </ul>
              </div>
              <section id="bo_w">
                <h2 className="sound_only">문의하기 </h2>
                {/* 게시물 작성/수정 시작 { */}
                <Form
                  name="fwrite"
                  id="fwrite"
                  autoComplete="off"
                  style={{ width: "100%" }}
                >
                  <div className="bo_w_info write_div">
                    <label htmlFor="wr_name" className="sound_only">
                      이름
                    </label>
                    <Field
                      type="text"
                      name="name"
                      id="wr_name"
                      className="frm_input full_input name required"
                      required
                      placeholder="이름"
                    />
                    <label htmlFor="wr_2" className="sound_only">
                      이메일
                    </label>

                    <Field
                      type="text"
                      name="email"
                      id="wr_2"
                      className="frm_input full_input email required"
                      required
                      placeholder="E-mail"
                    >
                      
                    </Field>
                    <ErrorMessage
                      name="email"
                      component="span"
                      style={{
                        color: "red",
                      }}
                    />
                    <label htmlFor="wr_tel" className="sound_only">
                      전화번호
                    </label>
                    <Field
                      type="text"
                      name="phoneNumber"
                      id="wr_1"
                      className="frm_input full_input tel required"
                      required
                      placeholder="phone"
                    />
                  </div>
                  <div className="bo_w_tit write_div">
                    <label htmlFor="wr_subject" className="sound_only">
                      제목<strong>필수</strong>
                    </label>
                    <div id="autosave_wrapper" className="write_div">
                      <Field
                        type="text"
                        name="title"
                        id="wr_subject"
                        required
                        className="frm_input full_input required"
                        size={50}
                        maxLength={255}
                        placeholder="Title"
                      />
                    </div>
                  </div>
                  <div className="write_div">
                    <label htmlFor="wr_content" className="sound_only">
                      내용<strong>필수</strong>
                    </label>
                    <div className="wr_content ">
                      <Field
                        as="textarea"
                        id="wr_content"
                        name="content"
                        className="required"
                        maxLength={10000}
                        required
                        style={{ width: "100%", height: 300 }}
                      />
                    </div>
                  </div>
                  <div className="text-center mt-4">
                    <p className="p">
                      * E-Mail으로 문의하신 답장을 보내드리겠습니다. 정확한 입력
                      부탁드립니다.
                    </p>
                    <button
                      type="submit"
                      id="btn_submit"
                      className="btn btn_sm squear-btn-basic mt-4"
                    >
                      문의보내기
                    </button>
                  </div>
                </Form>
              </section>
              {/* } 게시물 작성/수정 끝 */}
            </div>
            {/* container_wr / basicWrap */}
          </div>
          {/* } 콘텐츠 끝 */}
          <hr />
          {/* 하단 시작 { */}
          {/* <footer include-html="/public/footer.html" /> */}
          {/* } 하단 시작  */}
        </div>
      </div>
    </Formik>
  );
};

export default Write;
