import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import getDateNow from "util/getDateNow";

export const sliceAdminNotice = createSlice({
  name: "admin/notice",
  initialState: { noticeList: [] },
  reducers: {
    setNotices: (state, action) => {
      const payload = action.payload;
      if (!Array.isArray(payload) || payload?.length <= 0) {
        state.noticeList = [];
        return;
      }
      state.noticeList = action.payload;
    },
    sortNotice: (state, action) => {
      state.noticeList = state.noticeList.sort((back, front) => {
        const bDate = Number.parseInt(back.date.replace(/-|T|\.|:/g, ""));
        const fDate = Number.parseInt(front.date.replace(/-|T|\.|:/g, ""));
        return fDate - bDate;
      });
    },
    noticeSubmit: (state, action) => {},
  },
});
// Action Creator
export const {
  setNotices,
  noticeSubmit,
  sortNotice,
} = sliceAdminNotice.actions;
// Thunk Function
export const requestGetNotices = () => (dispatch, getState) => {
  const db = firebase.firestore();
  const auth = firebase.auth();

  db.collection("adminNotice")
    .get()
    .then((snapshot) => {
      const notices = [];
      for (const doc of snapshot.docs) {
        notices.push({ ...doc.data(), id: doc.id });
      }
      dispatch(setNotices(notices));
      dispatch(sortNotice());
    });
};

export const requestCreateNotice = (submitData, history) => (
  dispatch,
  getState
) => {
  const db = firebase.firestore();
  const auth = firebase.firestore();

  db.collection("adminNotice")
    .add({
      title: submitData.title,
      content: submitData.content,
      date: getDateNow(),
      author: "관리자",
    })
    .then(() => history.replace("/admin/notice"));
};

export const requestUpdateNotice = (submitData, id, history) => (
  dispatch,
  getState
) => {
  const db = firebase.firestore();

  db.collection("adminNotice")
    .doc(id)
    .update({
      title: submitData.title,
      content: submitData.content,
      date: getDateNow(),
      author: "관리자",
    })
    .then(() => {
      history.replace("/admin/notice");
    });
};

export default sliceAdminNotice.reducer;
