import { Link } from "react-router-dom";
import mapImg from "../../image/sub/map.jpg";

const MapComponent = () => {
  return (
    <div>
      <div className="minwidth">
        {/* 상단 시작 { */}
        <header include-html="/public/header.html" />
        {/* } 상단 끝 */}
        {/*  팝업시작 { */}
        <div include-html="/public/popup.html" />
        {/*  } 팝업시작  */}
        {/* 시장정보 확인하기 Modal { */}
        <div include-html="/public/modal.html" />
        {/* } 시장정보 확인하기 Modal */}
        <hr />
        {/* 콘텐츠 시작 { */}
        <div id="mymain" className="main ">
          <div
            id="topNav"
            className="d-flex justify-content-start align-items-end pb-2 "
          >
            <h1 className="h1 m-0 font-weight-bold">회사소개</h1>
            <ul id="topNavList" className="d-flex">
              <li className>
                <Link to="/intro/">회사소개</Link>
              </li>
              <li className="active">
                <Link to="/map/">오시는길</Link>
              </li>
            </ul>
          </div>
          <div id="intro1_02" className="subWrap">
            {" "}
            {/* #sub */}
            <div className="basicWrap pt40">
              <span className="sound_only">오시는길</span>
              <section>
                <p className="text-center mb40">
                  <i
                    className="fa fa-map"
                    style={{ fontSize: 48, color: "#033887" }}
                  />
                </p>
                <h3 className="h3 text-center">
                  34518 대전광역시 유성구 동서대로 125(덕명동), S9동 307호
                </h3>
                <h3 className="h3 text-center">
                  34158, 3F-307, S9, 125 Dongseodaero, Yuseong-gu, Daejeon,
                  Korea
                </h3>
                <div id="map" className="mt40">
                  {/* <img src="https://via.placeholder.com/1200x450?text=googlemap?+navermap?+and_id/pw" alt=""> */}
                  <img src={mapImg} alt="googlemap" />
                  {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25707.344798324768!2d127.28498085134589!3d36.35065042286719!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35654cb3d076704d%3A0xc291d366787ff1bb!2z64yA7KCE6rSR7Jet7IucIOycoOyEseq1rCDsmKjsspwx64-ZIOuPmeyEnOuMgOuhnCAxMjUgczk!5e0!3m2!1sko!2skr!4v1603189498313!5m2!1sko!2skr" width="1200" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> */}
                </div>
              </section>
            </div>
          </div>
        </div>
        {/* } 콘텐츠 끝 */}
        <hr />
        {/* 하단 시작 { */}
        {/* } 하단 시작  */}
      </div>
    </div>
  );
};

export default MapComponent;
