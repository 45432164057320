import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

export const slice = createSlice({
  name: "notice",
  initialState: { noticeList: [] },
  reducers: {
    setNotices: (state, action) => {
      const payload = action.payload;
      if (!Array.isArray(payload) || payload?.length <= 0) {
        state.noticeList = [];
        return;
      }
      state.noticeList = action.payload;
    },
    addNotice: (state, action) => {
      let payload = action.payload;
      if (!Array.isArray(payload) || payload?.length <= 0) {
        return;
      }
      state.noticeList.push(payload);
    },
    sortNotice: (state, action) => {
      state.noticeList = state.noticeList.sort((back, front) => {
        const bDate = Number.parseInt(back.date.replace(/-|T|\.|:/g, ""));
        const fDate = Number.parseInt(front.date.replace(/-|T|\.|:/g, ""));
        return fDate - bDate;
      });
    },
  },
});
export const { setNotices, sortNotice } = slice.actions;
// Thunk Function
export const fetchNotices = () => (dispatch, getState) => {
  const db = firebase.firestore();
  const auth = firebase.auth();

  // 서버에서 데이터를 가져왔다고 가정
  db.collection("adminNotice")
    .get()
    .then((snapshot) => {
      const notices = [];
      for (const doc of snapshot.docs) {
        notices.push({ ...doc.data(), id: doc.id });
      }
      dispatch(setNotices(notices));
      dispatch(sortNotice());
    });
  // dispatch(
  //   setNotices([
  //     {
  //       date: "2020-12-12",
  //       title: "공지1",
  //       content: "내용1",
  //       author: "작성자1",
  //     },
  //     {
  //       date: "2020-12-30",
  //       title: "공지2",
  //       content: "내용2",
  //       author: "작성자2",
  //     },
  //     {
  //       date: "2020-12-31",
  //       title: "공지3",
  //       content: "내용3",
  //       author: "작성자3",
  //     },
  //   ])
  // );
};

export default slice.reducer;
