import { Link, useHistory, useRouteMatch } from "react-router-dom";
import "../Worksheet.css";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { useEffect, useRef, useState } from "react";

import * as docx from "docx";
import { saveAs } from "file-saver";
import extractPdf from "util/extractPdf";
import CommentCheck from "util/CommentCheck";

const Worksheet4 = ({ listeners, userType }) => {
  const [answers, setAnswers] = useState({});
  const db = useRef(firebase.firestore()).current;
  const auth = useRef(firebase.auth()).current;
  const match = useRouteMatch();
  const history = useHistory();
  const [worksheetId, setWorksheetId] = useState();
  const [commentVisible, setCommentVisible] = useState(true);
  const [initialAnswers, setInitialAnswers] = useState({});
  const htmlRef = useRef();
  //
  const [dataArray, setDataArray] = useState([1]);
  //
  const radioChecked = listeners.radioChecked(answers);
  const radioListener = listeners.radioListener(answers, setAnswers);
  const checkboxChecked = listeners.checkboxChecked(answers);
  const checkboxListener = listeners.checkboxListener(answers, setAnswers);
  const textareaValue = listeners.textareaValue(answers);
  const textareaListener = listeners.textareaListener(answers, setAnswers);
  const commentValue = listeners.commentValue(answers);
  const commentListener = listeners.commentListener(answers, setAnswers);

  const saveWorksheet = (e) => {
    if (userType === "admin") {
      alert("어드민은 워크시트를 저장할 수 없습니다!");
      return;
    }

    let finalSubmit = false;
    let date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, -1);

    if (worksheetId && worksheetId != "new") {
      const accountInfo =
        userType == "mento"
          ? {
              mento: auth.currentUser.uid,
            }
          : {
              mentee: auth.currentUser.uid,
            };

      db.collection("worksheets")
        .doc(worksheetId)
        .update({
          ...accountInfo,
          questionAnswers: answers,
          page: 4,
          finalSubmit,
          date,
        })
        .then(() => {
          alert("저장 성공!");
          listeners.storeAnswersListener(answers);
        })
        .catch((e) => alert(e));
      return;
    }
    console.log("\nanswers", {
      questionAnswers: {
        ...answers,
      },
      mentee: auth.currentUser.uid,
      mento: "",
    });
    db.collection("worksheets")
      .add({
        questionAnswers: answers,
        mentee: auth.currentUser.uid,
        page:4,
        finalSubmit,
        date,
      })
      .then((doc) => {
        setWorksheetId(doc.id);
        alert("저장 성공!");
        listeners.storeAnswersListener(answers);
        history.replace(`/worksheets/${doc.id || "new"}/worksheet4/`);
      })
      .catch((e) => alert(e));
  };

  useEffect(() => {
    console.log(userType);
    // if (userType == "mento") {
    //   setCommentVisible(true);
    // }
  }, [userType]);

  useEffect(() => {
    //
    setWorksheetId(match.params.id);

    db.collection("worksheets")
      .doc(match.params.id)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          return;
        }
        if (doc.data().questionAnswers["b_1_2_1"]) {
          setDataArray([1, 2]);
        }
        if (doc.data().questionAnswers["b_1_3_1"]) {
          setDataArray([1, 2, 3]);
        }
        if (doc.data().questionAnswers["b_1_4_1"]) {
          setDataArray([1, 2, 3, 4]);
        }
        if (doc.data().questionAnswers["b_1_5_1"]) {
          setDataArray([1, 2, 3, 4, 5]);
        }
        listeners.storeAnswersListener(doc.data().questionAnswers);
        setAnswers(doc.data().questionAnswers);
      });
  }, []);

  const extractDocx = () => {
    const doc = new docx.Document();

    const marginOption = {
      margins: {
        left: 70,
      },
    };
    const centerOption = {
      verticalAlign: docx.VerticalAlign.CENTER,
    };

    const table = new docx.Table({
      width: {
        size: 100,
        type: docx.WidthType.PERCENTAGE,
      },
      rows: dataArray
        .map((item) => {
          return [
            new docx.TableRow({
              children: [
                new docx.TableCell({
                  ...marginOption,
                  ...centerOption,
                  columnSpan: 3,
                  children: [new docx.Paragraph(`${item}번 항목`)],
                }),
              ],
            }),
            new docx.TableRow({
              children: [
                new docx.TableCell({
                  ...marginOption,
                  ...centerOption,
                  children: [new docx.Paragraph("목표 고객명")],
                }),
                new docx.TableCell({
                  ...marginOption,
                  ...centerOption,
                  children: [new docx.Paragraph("고객수")],
                }),
                new docx.TableCell({
                  ...marginOption,
                  ...centerOption,
                  children: [new docx.Paragraph("출처")],
                }),
              ],
            }),
            new docx.TableRow({
              height: {
                height: 500,
                rule: docx.HeightRule.ATLEAST,
              },
              children: [
                new docx.TableCell({
                  ...marginOption,
                  ...centerOption,
                  children: [new docx.Paragraph(answers["b_1_" + item + "_1"])],
                }),
                new docx.TableCell({
                  ...marginOption,
                  ...centerOption,
                  children: [new docx.Paragraph(answers["b_1_" + item + "_2"])],
                }),
                new docx.TableCell({
                  ...marginOption,
                  ...centerOption,
                  children: [new docx.Paragraph(answers["b_1_" + item + "_3"])],
                }),
              ],
            }),
            new docx.TableRow({
              children: [
                new docx.TableCell({
                  ...marginOption,
                  ...centerOption,
                  columnSpan: 3,
                  children: [
                    new docx.Paragraph("기존의 문제점 (고객의 요구사항)"),
                  ],
                }),
              ],
            }),
            new docx.TableRow({
              height: {
                height: 500,
                rule: docx.HeightRule.ATLEAST,
              },
              children: [
                new docx.TableCell({
                  ...marginOption,
                  ...centerOption,
                  columnSpan: 1,
                  children: [new docx.Paragraph("1")],
                }),
                new docx.TableCell({
                  ...marginOption,
                  ...centerOption,
                  columnSpan: 2,
                  children: [new docx.Paragraph(answers["b_1_" + item + "_4"])],
                }),
              ],
            }),
            new docx.TableRow({
              height: {
                height: 500,
                rule: docx.HeightRule.ATLEAST,
              },
              children: [
                new docx.TableCell({
                  ...marginOption,
                  ...centerOption,
                  columnSpan: 1,
                  children: [new docx.Paragraph("2")],
                }),
                new docx.TableCell({
                  ...marginOption,
                  ...centerOption,
                  columnSpan: 2,
                  children: [new docx.Paragraph(answers["b_1_" + item + "_5"])],
                }),
              ],
            }),
            new docx.TableRow({
              height: {
                height: 500,
                rule: docx.HeightRule.ATLEAST,
              },
              children: [
                new docx.TableCell({
                  ...marginOption,
                  ...centerOption,
                  columnSpan: 1,
                  children: [new docx.Paragraph("3")],
                }),
                new docx.TableCell({
                  ...marginOption,
                  ...centerOption,
                  columnSpan: 2,
                  children: [new docx.Paragraph(answers["b_1_" + item + "_6"])],
                }),
              ],
            }),
          ];
        })
        .reduce((acc, cur) => acc.concat(cur), []),
    });

    doc.addSection({
      properties: {},
      children: [table],
    });
    docx.Packer.toBlob(doc).then((blob) => {
      saveAs(blob);
    });
  };

  const worksheet4Table = (item) => {
    return (
      <table className="table stepTable mb-0" key={item}>
        <colgroup>
          <col width="4%" />
          <col width="30%" />
          <col width="33%" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <td colSpan={2} className="bd-right">
              <input
                className="inputForm required"
                type="text"
                name={"b_1_" + item}
                placeholder="목표 고객명"
                value={textareaValue("b_1_" + item + "_1", 1)}
                onChange={textareaListener("b_1_" + item + "_1", 1)}
              />
            </td>
            <td className="bd-right">
              <input
                className="inputForm required"
                type="text"
                name={"b_1_" + item}
                placeholder="고객수"
                value={textareaValue("b_1_" + item + "_2", 1)}
                onChange={textareaListener("b_1_" + item + "_2", 1)}
              />
            </td>
            <td>
              <input
                className="inputForm required"
                type="text"
                name={"b_1_" + item}
                placeholder="출처"
                value={textareaValue("b_1_" + item + "_3", 1)}
                onChange={textareaListener("b_1_" + item + "_3", 1)}
              />
            </td>
          </tr>
          <tr>
            <td className="bg center middle py-3">분류</td>
            <td colSpan={3} className="bg center middle py-3">
              기존의 문제점 ( 고객의 요구사항 )
            </td>
          </tr>
          <tr>
            <td className="bg center middle">1</td>
            <td colSpan={3}>
              <textarea
                onKeyUp={(e) => {
                  e.currentTarget.style.height = "auto";
                  e.currentTarget.style.height =
                    e.currentTarget.scrollHeight + 2 + "px";
                }}
                className="textareaForm required"
                name={"b_1_" + item}
                id
                cols={30}
                rows={3}
                placeholder="문제점1"
                value={textareaValue("b_1_" + item + "_4", 1)}
                onChange={textareaListener("b_1_" + item + "_4", 1)}
              />
            </td>
          </tr>
          <tr>
            <td className="bg center middle">2</td>
            <td colSpan={3}>
              <textarea
                onKeyUp={(e) => {
                  e.currentTarget.style.height = "auto";
                  e.currentTarget.style.height =
                    e.currentTarget.scrollHeight + 2 + "px";
                }}
                className="textareaForm required"
                name={"b_1_" + item}
                id
                cols={30}
                rows={3}
                placeholder="문제점2"
                value={textareaValue("b_1_" + item + "_5", 1)}
                onChange={textareaListener("b_1_" + item + "_5", 1)}
              />
            </td>
          </tr>
          <tr>
            <td className="bg center middle">3</td>
            <td colSpan={3}>
              <textarea
                onKeyUp={(e) => {
                  e.currentTarget.style.height = "auto";
                  e.currentTarget.style.height =
                    e.currentTarget.scrollHeight + 2 + "px";
                }}
                className="textareaForm required"
                name={"b_1_" + item}
                id
                cols={30}
                rows={3}
                placeholder="문제점3"
                value={textareaValue("b_1_" + item + "_6", 1)}
                onChange={textareaListener("b_1_" + item + "_6", 1)}
              />
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <div>
      <div className="minwidth">
        <div
          className="modal fade main_modal pb-4 memtoListPopup"
          id="exampleModal1"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header" style={{ border: 0 }}>
                <h5 className="modal-title" id="exampleModalLabel" />
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row text-center">
                  <div className="col-12">
                    <h4 className="h4">
                      저장해야 다음으로 넘어갈 수 있습니다.
                    </h4>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {/* <button
                  type="button"
                  className="btn btn-sm squear-btn-border-basic"
                  data-dismiss="modal"
                >
                  취소
                </button> */}
                <button
                  type="button"
                  className="btn btn-sm squear-btn-basic"
                  id="applyFinish"
                  data-dismiss="modal"
                  onClick={() => {
                    let modal = document.getElementById("exampleModal1");
                    function removeClass(element, className) {
                      var check = new RegExp("(\\s|^)" + className + "(\\s|$)");
                      element.className = element.className
                        .replace(check, " ")
                        .trim();
                    }
                    removeClass(modal, "show");
                  }}
                >
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*  콘텐츠 시작 { */}
        <div id="worksheet">
          {/* 상단 시작 { */}
          {/* } nav 끝 */}
          <div className="basicWrap">
            <section className="d-flex justify-content-between youtube align-items-center px-5">
              <h2 className="h2 font-weight-normal">
                작성하는 법을 잘 모르겠다면? youtube 시청 후 작성하기
              </h2>
              <a
                className="btn btn-sm font-weight-normal squear-btn-basic radius pr-4 pl-4"
                href
              >
                과정설명 영상 보러가기
              </a>
            </section>
            <div style={{ marginTop: 22, borderBottom: "1px solid #aaa" }}>
              <span style={{ fontSize: 12, color: "#aaa", marginTop: 22 }}>
                STEP2. 가치제안서 {">"} 고객조사 워크시트
              </span>
            </div>
            <form action className="dataForm mt40 " ref={htmlRef}>
              <div className="d-flex justify-content-end mb-1 addBtn">
                {/* <div id="step2_1_Btn">+추가</div> */}
                <div
                  onClick={() => {
                    let array = [...dataArray];
                    array.push(array[array.length - 1] + 1);
                    return setDataArray(array);
                  }}
                >
                  +추가
                </div>
              </div>
              {/* {console.log(dataArray)} */}
              {dataArray.map((item) => {
                console.log(item);
                return worksheet4Table(item);
              })}
              {/* <table className="table stepTable mb-0">
                <colgroup>
                  <col width="4%" />
                  <col width="30%" />
                  <col width="33%" />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <td colSpan={2} className="bd-right">
                      <input
                        className="inputForm required"
                        type="text"
                        name="b_1_1"
                        placeholder="목표 고객명"
                        value={textareaValue("b_1_1", 1)}
                        onChange={textareaListener("b_1_1", 1)}
                      />
                    </td>
                    <td className="bd-right">
                      <input
                        className="inputForm required"
                        type="text"
                        name="b_1_1"
                        placeholder="고객수"
                        value={textareaValue("b_1_1", 2)}
                        onChange={textareaListener("b_1_1", 2)}
                      />
                    </td>
                    <td>
                      <input
                        className="inputForm required"
                        type="text"
                        name="b_1_1"
                        placeholder="출처"
                        value={textareaValue("b_1_1", 3)}
                        onChange={textareaListener("b_1_1", 3)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="bg center middle py-3">분류</td>
                    <td colSpan={3} className="bg center middle py-3">
                      기존의 문제점 ( 고객의 요구사항 )
                    </td>
                  </tr>
                  <tr>
                    <td className="bg center middle">1</td>
                    <td colSpan={3}>
                      <textarea 
onKeyUp={(e) => {
                        e.currentTarget.style.height = "auto"
                        e.currentTarget.style.height = e.currentTarget.scrollHeight + 2 + "px";
                      }}
                        className="textareaForm required"
                        name="b_1_1"
                        id
                        cols={30}
                        rows={3}
                        placeholder="문제점1"
                        value={textareaValue("b_1_1", 4)}
                        onChange={textareaListener("b_1_1", 4)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="bg center middle">2</td>
                    <td colSpan={3}>
                      <textarea 
onKeyUp={(e) => {
                        e.currentTarget.style.height = "auto"
                        e.currentTarget.style.height = e.currentTarget.scrollHeight + 2 + "px";
                      }}
                        className="textareaForm required"
                        name="b_1_1"
                        id
                        cols={30}
                        rows={3}
                        placeholder="문제점2"
                        value={textareaValue("b_1_1", 5)}
                        onChange={textareaListener("b_1_1", 5)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="bg center middle">3</td>
                    <td colSpan={3}>
                      <textarea 
onKeyUp={(e) => {
                        e.currentTarget.style.height = "auto"
                        e.currentTarget.style.height = e.currentTarget.scrollHeight + 2 + "px";
                      }}
                        className="textareaForm required"
                        name="b_1_1"
                        id
                        cols={30}
                        rows={3}
                        placeholder="문제점3"
                        value={textareaValue("b_1_1", 6)}
                        onChange={textareaListener("b_1_1", 6)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table> */}
              <div id="create" className="mb40" />
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_1", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_1_comment"
                    id="b_1_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == 'mento' ? false:true}
                    value={commentValue("b_1", 1)}
                    onChange={commentListener("b_1", 1)}
                  />
                </div>
              </section>
            </form>
            <section className="btnArea d-flex align-items-center justify-content-end">
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={() => extractPdf(htmlRef.current)}
              >
                PDF
              </button>
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={extractDocx}
              >
                다운로드
              </button>
              <div className="btn btn-sm squear-btn-border-basic mr-2">
                <Link to={`/worksheets/${worksheetId || "new"}/worksheet3/`}>
                  이전으로
                </Link>
              </div>
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={saveWorksheet}
              >
                저장하기
              </button>
              {/* <div className="btn btn-sm squear-btn-basic">
                <Link to={`/worksheets/${(worksheetId || "new")}/worksheet5/`}>다음으로</Link>
              </div> */}
              <div
                className="btn btn-sm squear-btn-basic "
                // data-toggle="modal"
                // data-target="#exampleModal1"
                onClick={() => {
                  // setSelectMento(mento);
                  // 변경된 값
                  // console.log(answers)
                  // // 처음 값
                  // console.log(initialAnswers)
                  // console.log(typeof answers)
                  // console.log(typeof initialAnswers)
                  // console.log(JSON.stringify(initialAnswers) === JSON.stringify(answers))

                  // if(JSON.stringify(initialAnswers) === JSON.stringify(answers)){
                  //   history.push(`/worksheets/${worksheetId || "new"}/worksheet5/`)
                  //   console.log('같음')
                  // }else{
                  //   console.log('다름')
                  //   let modal = document.getElementById('exampleModal1')
                  //   function addClass(element, className) { element.className += " " + className; };
                  //   addClass(modal, "show")
                  // }
                  console.log(dataArray);
                  let keyArray = [];
                  dataArray.map((item, index) => {
                    keyArray.push("b_1_" + item + "_1");
                    keyArray.push("b_1_" + item + "_2");
                    keyArray.push("b_1_" + item + "_3");
                    keyArray.push("b_1_" + item + "_4");
                    keyArray.push("b_1_" + item + "_5");
                    keyArray.push("b_1_" + item + "_6");
                  });
                  // let keyArray = ['b_1_1_1', 'b_1_1_2', 'b_1_1_3', 'b_1_1_4', 'b_1_1_5', 'b_1_1_6']
                  console.log(keyArray);
                  listeners.checkValue(keyArray, answers, worksheetId, 5);
                }}
              >
                <Link>다음으로</Link>
              </div>
            </section>
          </div>
        </div>
        {/* } 콘텐츠 끝 */}
        <hr />
        {/* 하단 시작 { */}
        {/* <footer include-html="/public/worksheets/worksheet_footer.html" /> */}
        {/* } 하단 시작  */}
      </div>
    </div>
  );
};

export default Worksheet4;
