import { Link, useHistory, useRouteMatch } from "react-router-dom";
import "../Worksheet.css";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { useEffect, useMemo, useRef, useState } from "react";
//
import * as docx from "docx";
import { saveAs } from "file-saver";
import questions from "../questions.json";
import createAnswerDocxRows from "util/docx/createAnswerDocxRows";
import extractPdf from "util/extractPdf";
import resizeTextarea from "util/resizeTextarea";
import CommentCheck from "util/CommentCheck";

const Worksheet3 = ({ listeners, userType }) => {
  const [answers, setAnswers] = useState({});
  const db = useRef(firebase.firestore()).current;
  const auth = useRef(firebase.auth()).current;
  const storage = useMemo(() => firebase.storage().ref(), []);
  const match = useRouteMatch();
  const history = useHistory();
  const [worksheetId, setWorksheetId] = useState();
  const [commentVisible, setCommentVisible] = useState(true);
  const [initialAnswers, setInitialAnswers] = useState({});
  const htmlRef = useRef();

  const radioChecked = listeners.radioChecked(answers);
  const radioListener = listeners.radioListener(answers, setAnswers);
  const checkboxChecked = listeners.checkboxChecked(answers);
  const checkboxListener = listeners.checkboxListener(answers, setAnswers);
  const textareaValue = listeners.textareaValue(answers);
  const textareaListener = listeners.textareaListener(answers, setAnswers);
  const commentValue = listeners.commentValue(answers);
  const commentListener = listeners.commentListener(answers, setAnswers);
  const fileValue = listeners.fileValue(answers);
  const fileListener = listeners.fileListener(answers, setAnswers);
  const fileDeleteListener = listeners.fileDeleteListener(answers, setAnswers);

  useEffect(() => {
    console.log(userType);
    // if (userType == "mento") {
      // setCommentVisible(true);
    // }
  }, [userType]);

  useEffect(() => {
    //
    setWorksheetId(match.params.id);

    db.collection("worksheets")
      .doc(match.params.id)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          return;
        }
        setInitialAnswers(doc.data().questionAnswers);
        setAnswers(doc.data().questionAnswers);
      });
  }, []);

  const saveWorksheet = (isFinal) => (e) => {
    if (userType === "admin") {
      alert("어드민은 워크시트를 저장할 수 없습니다!");
      return;
    }

    let finalSubmit = false;
    let date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, -1);

    if (isFinal) {
      finalSubmit = true;
    }
    if (worksheetId && worksheetId != "new") {
      const accountInfo =
        userType == "mento"
          ? {
              mento: auth.currentUser.uid,
            }
          : {
              mentee: auth.currentUser.uid,
            };

      db.collection("worksheets")
        .doc(worksheetId)
        .get()
        .then((doc) =>
          db
            .collection("worksheets")
            .doc(worksheetId)
            .update({
              ...accountInfo,
              questionAnswers: answers,
              page: 3,
              finalSubmit,
              date,
            })
        )
        .then(() => {
          alert("저장 성공!");
          listeners.storeAnswersListener(answers);
        })
        .catch((e) => alert(e));

      return;
    }
    console.log("\nanswers", {
      questionAnswers: {
        ...answers,
      },
      mentee: auth.currentUser.uid,
      mento: "",
    });
    db.collection("worksheets")
      .add({
        questionAnswers: answers,
        mentee: auth.currentUser.uid,
        page:3,
        finalSubmit,
        date,
      })
      .then((doc) => {
        setWorksheetId(doc.id);
        alert("저장 성공!");
        listeners.storeAnswersListener(answers);
        history.replace(`/worksheets/${doc.id || "new"}/worksheet3/`);
      })
      .catch((e) => alert(e));
  };
  //
  const extractDocx = () => {
    const doc = new docx.Document();

    const marginOption = {
      margins: {
        left: 70,
      },
    };
    const centerOption = {
      verticalAlign: docx.VerticalAlign.CENTER,
    };
    //
    let dataArray = [
      createAnswerDocxRows(
        questions[0]["a_1_9"],
        answers?.["a_1_9"] ? answers["a_1_9"] : "",
        1
      )[0],
      createAnswerDocxRows(
        questions[0]["a_1_9"],
        answers?.["a_1_9"] ? answers["a_1_9"] : "",
        1,
        "checkbox"
      )[1],
    ];
    //
    Object.keys(questions[2]).map((item, index) => {
      dataArray.push(
        createAnswerDocxRows(
          item.toUpperCase() + ". " + questions[2][item],
          answers?.[item] ? answers[item] : "",
          1
        )[0]
      );
      dataArray.push(
        createAnswerDocxRows(
          item.toUpperCase() + ". " + questions[2][item],
          answers?.[item] ? answers[item] : "",
          1,
          "textarea"
        )[1]
      );
    });
    console.log(dataArray);
    const table = new docx.Table({
      width: {
        size: 100,
        type: docx.WidthType.PERCENTAGE,
      },
      rows: dataArray,
      // .reduce((acc, cur) => acc.concat(cur), []),
    });

    doc.addSection({
      children: [table],
    });
    docx.Packer.toBlob(doc).then((blob) => {
      saveAs(blob);
    });
  };
  //

  return (
    <div>
      <div className="minwidth">
        <div
          className="modal fade main_modal pb-4 memtoListPopup"
          id="exampleModal1"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header" style={{ border: 0 }}>
                <h5 className="modal-title" id="exampleModalLabel" />
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row text-center">
                  <div className="col-12">
                    <h4 className="h4">
                      저장해야 다음으로 넘어갈 수 있습니다.
                    </h4>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {/* <button
                  type="button"
                  className="btn btn-sm squear-btn-border-basic"
                  data-dismiss="modal"
                >
                  취소
                </button> */}
                <button
                  type="button"
                  className="btn btn-sm squear-btn-basic"
                  id="applyFinish"
                  data-dismiss="modal"
                  onClick={() => {
                    let modal = document.getElementById("exampleModal1");
                    function removeClass(element, className) {
                      var check = new RegExp("(\\s|^)" + className + "(\\s|$)");
                      element.className = element.className
                        .replace(check, " ")
                        .trim();
                    }
                    removeClass(modal, "show");
                  }}
                >
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*  콘텐츠 시작 { */}
        <div id="worksheet">
          {/* 상단 시작 { */}
          {/* } nav 끝 */}
          <div className="basicWrap">
            <section className="d-flex justify-content-between youtube align-items-center px-5">
              <h2 className="h2 font-weight-normal">
                작성하는 법을 잘 모르겠다면? youtube 시청 후 작성하기
              </h2>
              <a
                className="btn btn-sm font-weight-normal squear-btn-basic radius pr-4 pl-4"
                href
              >
                과정설명 영상 보러가기
              </a>
            </section>
            <div style={{ marginTop: 22, borderBottom: "1px solid #aaa" }}>
              <span style={{ fontSize: 12, color: "#aaa", marginTop: 22 }}>
                STEP1. 아이디어 설명 {">"} 믿을만한 워크시트
              </span>
            </div>
            <div ref={htmlRef}>
              <div className="beforeDateForm mt40 disabled p-4">
                <section className="section" style={{ cursor: "inherit" }}>
                  <div className="question" style={{ cursor: "inherit" }}>
                    Q. 어떤 자원을 보유하고 계십니까? (Q.a_1_9)
                  </div>
                  <div className="answer" style={{ cursor: "inherit" }}>
                    <ul className="row" style={{ cursor: "inherit" }}>
                      <li className="col-2" style={{ cursor: "inherit" }}>
                        <label
                          htmlFor="a_1_9_1"
                          className="a_1_9_1 checkbox"
                          style={{ cursor: "inherit" }}
                        >
                          <span
                            style={{
                              cursor: "inherit",
                              backgroundColor: answers["a_1_9"]?.includes(
                                "1.기술"
                              )
                                ? "#033887"
                                : "white",
                            }}
                          />
                          1.기술
                        </label>
                      </li>
                      <li className="col-2" style={{ cursor: "inherit" }}>
                        <label
                          htmlFor="a_1_9_2"
                          className="a_1_9_2 checkbox"
                          style={{ cursor: "inherit" }}
                        >
                          <span
                            style={{
                              cursor: "inherit",
                              backgroundColor: answers["a_1_9"]?.includes(
                                "2.자금"
                              )
                                ? "#033887"
                                : "white",
                            }}
                          />
                          2.자금
                        </label>
                      </li>
                      <li className="col-2" style={{ cursor: "inherit" }}>
                        <label
                          htmlFor="a_1_9_3"
                          className="a_1_9_3 checkbox"
                          style={{ cursor: "inherit" }}
                        >
                          <span
                            style={{
                              cursor: "inherit",
                              backgroundColor: answers["a_1_9"]?.includes(
                                "3.인적자원"
                              )
                                ? "#033887"
                                : "white",
                            }}
                          />
                          3.인적자원
                        </label>
                      </li>
                      <li className="col-2" style={{ cursor: "inherit" }}>
                        <label
                          htmlFor="a_1_9_4"
                          className="a_1_9_4 checkbox"
                          style={{ cursor: "inherit" }}
                        >
                          <span
                            style={{
                              cursor: "inherit",
                              backgroundColor: answers["a_1_9"]?.includes(
                                "4.생산"
                              )
                                ? "#033887"
                                : "white",
                            }}
                          />
                          4.생산
                        </label>
                      </li>
                      <li className="col-2" style={{ cursor: "inherit" }}>
                        <label
                          htmlFor="a_1_9_5"
                          className="a_1_9_5 checkbox"
                          style={{ cursor: "inherit" }}
                        >
                          <span
                            style={{
                              cursor: "inherit",
                              backgroundColor: answers["a_1_9"]?.includes(
                                "5.마케팅"
                              )
                                ? "#033887"
                                : "white",
                            }}
                          />
                          5.마케팅
                        </label>
                      </li>
                      <li className="col-2" style={{ cursor: "inherit" }}>
                        <label
                          htmlFor="a_1_9_6"
                          className="a_1_9_6 checkbox"
                          style={{ cursor: "inherit" }}
                        >
                          <span
                            style={{
                              cursor: "inherit",
                              backgroundColor: answers["a_1_9"]?.includes(
                                "6.유통"
                              )
                                ? "#033887"
                                : "white",
                            }}
                          />
                          6.유통
                        </label>
                      </li>
                    </ul>
                  </div>
                </section>
              </div>
              <form action className="dataForm mt40">
                <section className="section">
                  <div className="question">
                    A-5-1. 기술<span className="text-danger">*</span>
                  </div>
                  <div className="answer">
                    <textarea
                      onKeyUp={(e) => {
                        e.currentTarget.style.height = "auto";
                        e.currentTarget.style.height =
                          e.currentTarget.scrollHeight + 2 + "px";
                      }}
                      name="a_5_1"
                      id="a_5_1"
                      className="textareaForm"
                      value={textareaValue("a_5_1", 1)}
                      onChange={textareaListener("a_5_1", 1)}
                    />
                    <div className="d-flex">
                      <input
                        type="file"
                        id="file"
                        className="d-none"
                        onChange={fileListener("a_5_1", 1)}
                      />
                      <label
                        htmlFor="file"
                        className="w-25 text-center fileupload"
                      >
                        + 파일업로드
                      </label>
                      <div
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        &nbsp;&nbsp;
                        {fileValue("a_5_1", 1) ? (
                          <>
                            <a href={fileValue("a_5_1", 1)}>첨부파일</a>&nbsp;
                            <div
                              onClick={fileDeleteListener("a_5_1", 1)}
                              className="btn btn-sm squear-btn-basic pr-2 pl-2 p-1"
                            >
                              삭제
                            </div>
                          </>
                        ) : (
                          <div>파일이 없습니다</div>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  className="section comment"
                  style={CommentCheck(userType, commentValue("a_5_1", 1))}
                >
                  <div className="question font-weight-bold">Comment</div>
                  <div className="answer">
                    <textarea
                      onKeyUp={(e) => {
                        e.currentTarget.style.height = "auto";
                        e.currentTarget.style.height =
                          e.currentTarget.scrollHeight + 2 + "px";
                      }}
                      name="a_5_1_comment"
                      id="a_5_1_comment"
                      className="textareaForm"
                      placeholder="멘토만 작성할 수 있는 구역입니다."
                      disabled={userType == 'mento' ? false:true}
                      value={commentValue("a_5_1", 1)}
                      onChange={commentListener("a_5_1", 1)}
                    />
                  </div>
                </section>
                <section className="section">
                  <div className="question">
                    A-5-2. 생산능력<span className="text-danger">*</span>
                  </div>
                  <div className="answer">
                    <textarea
                      onKeyUp={(e) => {
                        e.currentTarget.style.height = "auto";
                        e.currentTarget.style.height =
                          e.currentTarget.scrollHeight + 2 + "px";
                      }}
                      name="a_5_2"
                      id="a_5_2"
                      className="textareaForm"
                      value={textareaValue("a_5_2", 1)}
                      onChange={textareaListener("a_5_2", 1)}
                    />
                  </div>
                </section>
                <section
                  className="section comment"
                  style={CommentCheck(userType, commentValue("a_5_2", 1))}
                >
                  <div className="question font-weight-bold">Comment</div>
                  <div className="answer">
                    <textarea
                      onKeyUp={(e) => {
                        e.currentTarget.style.height = "auto";
                        e.currentTarget.style.height =
                          e.currentTarget.scrollHeight + 2 + "px";
                      }}
                      name="a_5_2_comment"
                      id="a_5_2_comment"
                      className="textareaForm"
                      placeholder="멘토만 작성할 수 있는 구역입니다."
                      disabled={userType == 'mento' ? false:true}
                      value={commentValue("a_5_2", 1)}
                      onChange={commentListener("a_5_2", 1)}
                    />
                  </div>
                </section>
                <section className="section">
                  <div className="question">
                    A-5-3. 팀역량(내부역량)
                    <span className="text-danger">*</span>
                  </div>
                  <div className="answer">
                    <textarea
                      onKeyUp={(e) => {
                        e.currentTarget.style.height = "auto";
                        e.currentTarget.style.height =
                          e.currentTarget.scrollHeight + 2 + "px";
                      }}
                      name="a_5_3"
                      id="a_5_3"
                      className="textareaForm"
                      value={textareaValue("a_5_3", 1)}
                      onChange={textareaListener("a_5_3", 1)}
                    />
                  </div>
                </section>
                <section
                  className="section comment"
                  style={CommentCheck(userType, commentValue("a_5_3", 1))}
                >
                  <div className="question font-weight-bold">Comment</div>
                  <div className="answer">
                    <textarea
                      onKeyUp={(e) => {
                        e.currentTarget.style.height = "auto";
                        e.currentTarget.style.height =
                          e.currentTarget.scrollHeight + 2 + "px";
                      }}
                      name="a_5_3_comment"
                      id="a_5_3_comment"
                      className="textareaForm"
                      placeholder="멘토만 작성할 수 있는 구역입니다."
                      disabled={userType == 'mento' ? false:true}
                      value={commentValue("a_5_3", 1)}
                      onChange={commentListener("a_5_3", 1)}
                    />
                  </div>
                </section>
                <section className="section">
                  <div className="question">
                    A-5-4. 파트너 역량(외부역량)
                    <span className="text-danger">*</span>
                  </div>
                  <div className="answer">
                    <textarea
                      onKeyUp={(e) => {
                        e.currentTarget.style.height = "auto";
                        e.currentTarget.style.height =
                          e.currentTarget.scrollHeight + 2 + "px";
                      }}
                      name="a_5_4"
                      id="a_5_3"
                      className="textareaForm"
                      value={textareaValue("a_5_4", 1)}
                      onChange={textareaListener("a_5_4", 1)}
                    />
                  </div>
                </section>
                <section
                  className="section comment"
                  style={CommentCheck(userType, commentValue("a_5_4", 1))}
                >
                  <div className="question font-weight-bold">Comment</div>
                  <div className="answer">
                    <textarea
                      onKeyUp={(e) => {
                        e.currentTarget.style.height = "auto";
                        e.currentTarget.style.height =
                          e.currentTarget.scrollHeight + 2 + "px";
                      }}
                      name="a_5_4_comment"
                      id="a_5_3_comment"
                      className="textareaForm"
                      placeholder="멘토만 작성할 수 있는 구역입니다."
                      disabled={userType == 'mento' ? false:true}
                      value={commentValue("a_5_4", 1)}
                      onChange={commentListener("a_5_4", 1)}
                    />
                  </div>
                </section>
                <section className="section">
                  <div className="question">
                    A-5-5. 공급자<span className="text-danger">*</span>
                  </div>
                  <div className="answer">
                    <textarea
                      onKeyUp={(e) => {
                        e.currentTarget.style.height = "auto";
                        e.currentTarget.style.height =
                          e.currentTarget.scrollHeight + 2 + "px";
                      }}
                      name="a_5_5"
                      id="a_5_4"
                      className="textareaForm"
                      value={textareaValue("a_5_5", 1)}
                      onChange={textareaListener("a_5_5", 1)}
                    />
                  </div>
                </section>
                <section
                  className="section comment"
                  style={CommentCheck(userType, commentValue("a_5_5", 1))}
                >
                  <div className="question font-weight-bold">Comment</div>
                  <div className="answer">
                    <textarea
                      onKeyUp={(e) => {
                        e.currentTarget.style.height = "auto";
                        e.currentTarget.style.height =
                          e.currentTarget.scrollHeight + 2 + "px";
                      }}
                      name="a_5_5_comment"
                      id="a_5_4_comment"
                      className="textareaForm"
                      placeholder="멘토만 작성할 수 있는 구역입니다."
                      disabled={userType == 'mento' ? false:true}
                      value={commentValue("a_5_5", 1)}
                      onChange={commentListener("a_5_5", 1)}
                    />
                  </div>
                </section>
                <section className="section">
                  <div className="question">
                    A-5-6. 유통<span className="text-danger">*</span>
                  </div>
                  <div className="answer">
                    <textarea
                      onKeyUp={(e) => {
                        e.currentTarget.style.height = "auto";
                        e.currentTarget.style.height =
                          e.currentTarget.scrollHeight + 2 + "px";
                      }}
                      name="a_5_5"
                      id="a_5_5"
                      className="textareaForm"
                      value={textareaValue("a_5_6", 1)}
                      onChange={textareaListener("a_5_6", 1)}
                    />
                  </div>
                </section>
                <section
                  className="section comment"
                  style={CommentCheck(userType, commentValue("a_5_6", 1))}
                >
                  <div className="question font-weight-bold">Comment</div>
                  <div className="answer">
                    <textarea
                      onKeyUp={(e) => {
                        e.currentTarget.style.height = "auto";
                        e.currentTarget.style.height =
                          e.currentTarget.scrollHeight + 2 + "px";
                      }}
                      name="a_5_6_comment"
                      id="a_5_5_comment"
                      className="textareaForm"
                      placeholder="멘토만 작성할 수 있는 구역입니다."
                      disabled={userType == 'mento' ? false:true}
                      value={commentValue("a_5_6", 1)}
                      onChange={commentListener("a_5_6", 1)}
                    />
                  </div>
                </section>
              </form>
            </div>

            <section className="btnArea d-flex align-items-center justify-content-end">
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={() => extractPdf(htmlRef.current)}
              >
                PDF
              </button>
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={extractDocx}
              >
                다운로드
              </button>
              <div className="btn btn-sm squear-btn-border-basic mr-2">
                <Link to={`/worksheets/${worksheetId || "new"}/worksheet2/`}>
                  이전으로
                </Link>
              </div>
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={saveWorksheet(false)}
              >
                저장하기
              </button>
              <div
                className="btn btn-sm squear-btn-basic "
                onClick={() => {
                  let keyArray = [
                    "a_5_1",
                    "a_5_2",
                    "a_5_3",
                    "a_5_4",
                    "a_5_5",
                    "a_5_6",
                  ];
                  listeners.checkValue(keyArray, answers, worksheetId, 4);
                }}
              >
                <Link>다음으로</Link>
              </div>
            </section>
          </div>
        </div>
        {/* } 콘텐츠 끝 */}
        <hr />
      </div>
    </div>
  );
};

export default Worksheet3;
