import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

import "feature/mypage/mypage.css";
import getDateNow from "util/getDateNow";

const MemberApply = () => {
  const db = useRef(firebase.firestore()).current;
  const [mentos, setMentos] = useState([]);
  const [selectMento, setSelectMento] = useState({});

  const storage = useMemo(() => firebase.storage().ref(), []);

  const match = useRouteMatch();

  //
  useEffect(() => {
    //
    // firebase.auth().onAuthStateChanged((user) => {
    //     console.log(user.uid)
    //     db.collection("worksheets")
    //     .get()
    //     .then((querySnapshot) => {
    //         let worksheetArray=[]
    //         querySnapshot.forEach((doc) => {
    //             // TODO: 이렇게 뽑아도 되는게 맞는지...
    //             let data = doc.data()
    //             if(data.mentee == user.uid){
    //                 data.id=doc.id
    //                 worksheetArray.push(data)
    //             }
    //         });
    //         setWorksheet(worksheetArray)
    //     });
    // });
    //
  }, []);

  useEffect(() => {
    firebase
      .firestore()
      .collection("mento")
      .get()
      .then(async (list) => {
        const clone = [];
        for (const mentoDoc of list.docs) {
          const userDoc = await firebase
            .firestore()
            .collection("users")
            .doc(mentoDoc.id)
            .get();
          clone.push({
            ...mentoDoc.data(),
            ...userDoc.data(),
            id: mentoDoc.id,
          });
        }
        console.log("clone", clone);
        setMentos(
          clone
            .filter((e) => {
              return e.agree;
            })
            .filter((e) => {
              return e.useOrNot;
            })
        );
        // setMentos(clone);
      });
  }, []);

  const MentoList = () => {
    const [mentoUrl, setMentoUrl] = useState({});
    useEffect(() => {
      mentos.forEach((mento) => {
        const mentoStorage = storage.child("image/user/" + mento.id);
        mentoStorage.getDownloadURL().then((url) => {
          const clone = { ...mentoUrl };
          clone[mento.id] = url;
          setMentoUrl(clone);
        });
      });
    }, [mentos]);

    return mentos.map((mento, i) => {
      return (
        <div className="w-25" key={mento.id}>
          <img
            height={200}
            width={200}
            src={
              mentoUrl[mento.id]
                ? mentoUrl[mento.id]
                : "https://via.placeholder.com/200x200?text=pic"
            }
            alt
          />
          <h3 className="h3 mt-4">{mento.name}</h3>
          <p className="mt-4 text-truncate">{mento.job}</p>
          <div className="d-flex justify-content-center mt-4">
            <div className="btn btn-sm squear-btn-border-basic mr-2 radius">
              <Link
                to={`/admin/member/${mento.id}/worksheets/`}
                className="detailView "
              >
                상세보기
              </Link>
            </div>
            <div
              className="btn btn-sm squear-btn-basic radius applyClick"
              data-toggle="modal"
              data-target="#exampleModal1"
              onClick={() => {
                setSelectMento(mento);
              }}
            >
              신청하기
            </div>
          </div>
        </div>
      );
    });
  };

  const setMentoAccept = (accept) => {
    if (!match.params.worksheetId || match.params.worksheetId == "") return;

    firebase
      .firestore()
      .collection("worksheets")
      .doc(match.params.worksheetId)
      .get()
      .then((doc) => {
        if (!doc.exists) throw new Error("해당 ID의 워크시트가 존재하지 않음");
        // if (
        //   doc.data().mento &&
        //   doc.data().mento != "" &&
        //   doc.data().mentoAccept
        // ) {
        //   throw new Error("신청한 멘토가 이미 있음");
        // }
      })
      .then(() => {
        firebase
          .firestore()
          .collection("worksheets")
          .doc(match.params.worksheetId)
          .update({
            mentoAccept: accept,
            pendingDate: getDateNow(),
            mento: selectMento.id,
          });
      })
      .then(() => {
        alert("신청 완료!");
        window.history.back();
      })
      .catch((e) => alert(e));
  };

  return (
    <div>
      <div className="minwidth">
        <hr />
        <div id="mymain" className="main ">
          <br />
          <br />
          <br />
          {/* <div className="position-fixed fixed-top text-center">
                      멘티 : 워크시트 작성 전
                  </div> */}
          <div id="mypage" className="subWrap">
            <div className="basicWrap pt40">
              {/*  */}
              <div className="user_2 ">
                {/* <div>
                    <h1 className="h1">내 멘토가 없습니다.</h1>
                    <h1 className="h1">멘토를 선택해주세요! :)</h1>
                  </div> */}
                <section className="mt80 text-center">
                  <h2 className="h2 font-weight-bold pb-4">멘토 목록</h2>
                  <div className="mt-4 d-inline-flex justify-content-flex-start memtoList">
                    <MentoList />
                  </div>
                </section>
                <div
                  className="modal fade main_modal pb-4 memtoListPopup"
                  id="exampleModal1"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header" style={{ border: 0 }}>
                        <h5 className="modal-title" id="exampleModalLabel" />
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="row text-center">
                          <div className="col-12">
                            <h4 className="h4">
                              {selectMento.name}님에게 멘토 신청을 하시겠습니까?
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-sm squear-btn-border-basic"
                          data-dismiss="modal"
                        >
                          취소
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm squear-btn-basic"
                          id="applyFinish"
                          data-dismiss="modal"
                          onClick={() => setMentoAccept("accept")}
                        >
                          신청하기
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          {/* #sub */}
        </div>
        {/* } 콘텐츠 끝 */}
        <hr />
        {/* 하단 시작 { */}
        {/* <footer include-html="/public/footer.html" /> */}
        {/* } 하단 시작  */}
      </div>
    </div>
  );
};

export default MemberApply;
