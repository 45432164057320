import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import firebase from "firebase";

const Pmc = ({ userType, listeners }) => {
  const htmlRef = useRef();
  const db = useRef(firebase.firestore()).current;
  const auth = useRef(firebase.auth()).current;
  let match = useRouteMatch();
  const [worksheetId, setWorksheetId] = useState();
  const [commentVisible, setCommentVisible] = useState(false);
  const [worksheetTitle, setWorksheetTitle] = useState("");
  const history = useHistory();

  useEffect(() => {
    console.log(userType);
    if (userType == "mento") {
      setCommentVisible(true);
    }
  }, [userType]);

  //
  useEffect(() => {
    setWorksheetId(match.params.id);

    db.collection("worksheets")
      .doc(match.params.id)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          return;
        }
        listeners.storeAnswersListener(doc.data().questionAnswers);
        setWorksheetTitle(doc.data().title);
      });
  }, []);

  const saveWorksheet = (value) => {
    if (userType === "admin") {
      alert("어드민은 워크시트를 저장할 수 없습니다!");
      return;
    }

    const resultAnswers = {
      ...(listeners.storeAnswersValue() || {}),
      ...value,
    };
    const accountInfo =
      userType == "mento"
        ? {
            mento: auth.currentUser.uid,
          }
        : {
            mentee: auth.currentUser.uid,
          };
    if (worksheetId && worksheetId != "new") {
      db.collection("worksheets")
        .doc(worksheetId)
        .update({
          ...accountInfo,
          questionAnswers: resultAnswers,
        })
        .then(() => {
          alert("저장 성공!");
          listeners.storeAnswersListener(resultAnswers);
        })
        .catch((e) => alert(e));
      return;
    }
    console.log("\nanswers", {
      questionAnswers: {
        ...resultAnswers,
      },
      mentee: auth.currentUser.uid,
      mento: "",
      page: 1,
      endPage: 1,
    });
    db.collection("worksheets")
      .add({
        questionAnswers: resultAnswers,
        mentee: auth.currentUser.uid,
        page: 1,
      })
      .then((doc) => {
        setWorksheetId(doc.id);
        alert("저장 성공!");
        listeners.storeAnswersListener(resultAnswers);
        history.replace(`/worksheets/${doc.id}/pamm/`);
      })
      .catch((e) => alert(e));
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...listeners.storeAnswersValue(),
      }}
      onSubmit={(values, { setSubmitting }) => {
        saveWorksheet(values);
      }}
    >
      <Form>
        <div>
          <div className="minwidth">
            <div id="worksheet">
              <div className="basicWrap">
                <section className="d-flex justify-content-between youtube align-items-center px-5">
                  <h2 className="h2 font-weight-normal">
                    작성하는 법을 잘 모르겠다면? youtube 시청 후 작성하기
                  </h2>
                  <Link
                    className="btn btn-sm font-weight-normal squear-btn-basic radius pr-4 pl-4"
                    to=""
                  >
                    과정설명 영상 보러가기
                  </Link>
                </section>
                <div style={{ marginTop: 22, borderBottom: "1px solid #aaa" }}>
                  <span style={{ fontSize: 12, color: "#aaa", marginTop: 22 }}>
                    PMC (제품-시장-역량) 워크시트
                  </span>
                </div>
                <form action className="dataForm mt40 " ref={htmlRef}>
                  {/*  */}
                  <table className="table stepTable mb-0">
                    {/* 헤더 */}
                    <tr>
                      <td className="bg center middle tt" colSpan="1">
                        제품명
                      </td>
                      <td className="bg center middle tt" colSpan="1">
                        고객명
                      </td>
                      <td className="bg center middle tt" colSpan="1">
                        세분시장
                      </td>
                      <td className="bg center middle tt" colSpan="1">
                        고객니즈
                      </td>
                      <td className="bg center middle tt" colSpan="1">
                        제품속성
                      </td>
                      <td className="bg center middle tt" colSpan="1">
                        역량
                      </td>
                    </tr>
                    {/* 1 행 */}
                    <tr>
                      <td className="tt " rowSpan="10">
                        <Field
                          as="textarea"
                          className="textareaForm scroll center middle"
                          name={`pmc_0_0`}
                          cols={30}
                          rows={35}
                          value={worksheetTitle}
                          // placeholder={tec}
                          // value
                        />
                      </td>
                      <TableInputTds curRow={1} numCols={5} />
                    </tr>
                    {/* 2~9 행 */}
                    {new Array(9).fill(1).map((_, i) => {
                      return (
                        <tr key={i}>
                          <TableInputTds curRow={2 + i} numCols={5} />
                        </tr>
                      );
                    })}
                  </table>
                </form>
                <section className="btnArea d-flex align-items-center justify-content-end mt-2">
                  <button
                    type="submit"
                    className="btn btn-sm squear-btn-border-basic"
                  >
                    저장하기
                  </button>
                </section>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

const TableInputTds = ({ curRow, numCols }) => {
  return (
    <>
      {new Array(numCols).fill(1).map((e, i) => {
        const name = `pmc_${curRow}_${i + 1}`;
        return (
          <td className="tt " key={name}>
            <Field
              as="textarea"
              className="textareaForm scroll center middle"
              name={name}
              cols={30}
            />
          </td>
        );
      })}
    </>
  );
};

export default Pmc;
