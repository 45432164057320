import { useEffect, useMemo, useRef, useState } from "react";
import "./Join.css";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import userEvent from "@testing-library/user-event";
import getDateNow from "util/getDateNow";
import $ from 'jquery';

const Join = () => {
 return(
     <div className="joinWrap">
        {/* <h2 className="joinTitle"><span>INNOVATION</span>에 가입해주세요</h2> */}
        <h2 className="joinTitle">회원가입</h2>
        <p>회원구분에 따라 가입절차에 차이가 있으니 해당하는 회원으로 가입해주세요.</p>
        <div className="join">
            <Link className="big-btn" to="/register/mentee">
                <div className="user">
                    멘티회원
                </div>
                <p>회원가입 후 워크시트를 작성해보세요.</p>
                <div className="btn btn01">회원가입</div>
            </Link>
            
            <Link className="big-btn" to="/register/mento">
                <div className="user">
                    멘토회원
                </div>
                <p>멘티회원에게 멘토가 되어주세요.</p>
                <div className="btn btn02">회원가입</div>
            </Link>
        </div>
     </div>
 )
};

export default Join;