import { Link, useHistory, useRouteMatch } from "react-router-dom";
import "../Worksheet.css";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { useEffect, useLayoutEffect, useRef, useState } from "react";

import * as docx from "docx";
import { saveAs } from "file-saver";
import createAnswerDocxRows from "util/docx/createAnswerDocxRows";
import questions from "feature/worksheets/questions.json";
import extractPdf from "util/extractPdf";
import CommentCheck from "util/CommentCheck";

const QUESTIONS = ["a_1_1", "a_1_2", "a_1_3", "a_1_6", "a_1_8", "a_1_9"];

const Worksheet13 = ({ userType, listeners }) => {
  const [answers, setAnswers] = useState({});
  const db = useRef(firebase.firestore()).current;
  const auth = useRef(firebase.auth()).current;
  const [commentVisible, setCommentVisible] = useState(true);
  const match = useRouteMatch();
  const [worksheetId, setWorksheetId] = useState();
  const htmlRef = useRef();

  const [initialAnswers, setInitialAnswers] = useState({});
  const [prevent, setPrevent] = useState(false);

  const history = useHistory();

  const radioDefaultChecked = listeners.radioDefaultChecked(
    answers,
    setAnswers
  );
  const radioChecked = listeners.radioChecked(answers);
  const radioListener = listeners.radioListener(answers, setAnswers);
  const checkboxChecked = listeners.checkboxChecked(answers);
  const checkboxListener = listeners.checkboxListener(answers, setAnswers);
  const textareaValue = listeners.textareaValue(answers);
  const textareaListener = listeners.textareaListener(answers, setAnswers);
  const commentValue = listeners.commentValue(answers);
  const commentListener = listeners.commentListener(answers, setAnswers);
  const fileValue = listeners.fileValue(answers);
  const fileListener = listeners.fileListener(answers, setAnswers);

  useEffect(() => {
    //
    setWorksheetId(match.params.id);

    db.collection("worksheets")
      .doc(match.params.id)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          return;
        }
        setInitialAnswers(doc.data().questionAnswers);
        setAnswers(doc.data().questionAnswers);
      });
  }, []);

  useEffect(() => {
    console.log(userType);
    // if (userType == "mento") {
    //   setCommentVisible(true);
    // }
  }, [userType]);

  const saveWorksheet = (e) => {
    if (userType === "admin") {
      alert("어드민은 워크시트를 저장할 수 없습니다!");
      return;
    }

    let finalSubmit = false;
    let date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, -1);

    if (worksheetId && worksheetId != "new") {
      const accountInfo =
        userType == "mento"
          ? {
              mento: auth.currentUser.uid,
            }
          : {
              mentee: auth.currentUser.uid,
            };

      db.collection("worksheets")
        .doc(worksheetId)
        .update({
          ...accountInfo,
          questionAnswers: answers,
          page: 14,
          finalSubmit,
          date,
        })
        .then(() => {
          alert("저장 성공!");
          listeners.storeAnswersListener(answers);
        })
        .catch((e) => alert(e));
      return;
    }
    console.log("\nanswers", {
      questionAnswers: {
        ...answers,
      },
      mentee: auth.currentUser.uid,
      mento: "",
    });
    db.collection("worksheets")
      .add({
        questionAnswers: answers,
        mentee: auth.currentUser.uid,
        page: 14,
        finalSubmit,
        date,
      })
      .then((doc) => {
        setWorksheetId(doc.id);
        alert("저장 성공!");
        listeners.storeAnswersListener(answers);
        history.replace(`/worksheets/${doc.id || "new"}/worksheet14/`);
      })
      .catch((e) => alert(e));
  };

  const extractDocx = () => {
    const cellOp = {
      verticalAlign: docx.VerticalAlign.TOP,
      margins: {
        left: 80,
        top: 80,
      },
    };
    const rowOp = {
      height: {
        height: 2000,
        rule: docx.HeightRule.ATLEAST,
      },
    };
    const table = new docx.Table({
      width: {
        size: 100,
        type: docx.WidthType.PERCENTAGE,
      },
      rows: [
        new docx.TableRow({
          children: [
            // 핵심파트너
            new docx.TableCell({
              ...cellOp,
              rowSpan: 2,
              children: [
                new docx.Paragraph("핵심파트너"),
                new docx.Paragraph(answers["w14-핵심파트너"] || ""),
              ],
            }),
            // 핵심활동
            new docx.TableCell({
              ...cellOp,
              children: [
                new docx.Paragraph("핵심활동"),
                new docx.Paragraph(answers["w14-핵심활동"] || ""),
              ],
            }),
            // 가치제안
            new docx.TableCell({
              ...cellOp,
              columnSpan: 2,
              rowSpan: 2,
              children: [
                new docx.Paragraph("가치제안"),
                new docx.Paragraph(answers["w14-가치제안"] || ""),
              ],
            }),
            // 고객관계
            new docx.TableCell({
              ...cellOp,
              children: [
                new docx.Paragraph("고객관계"),
                new docx.Paragraph(answers["w14-고객관계"] || ""),
              ],
            }),
            // 고객
            new docx.TableCell({
              ...cellOp,
              rowSpan: 2,
              children: [
                new docx.Paragraph("고객"),
                new docx.Paragraph(answers["w14-고객"] || ""),
              ],
            }),
          ],
        }),
        new docx.TableRow({
          children: [
            // 핵심자원
            new docx.TableCell({
              ...cellOp,
              children: [
                new docx.Paragraph("핵심자원"),
                new docx.Paragraph(answers["w14-핵심자원"] || ""),
              ],
            }),
            // 채널
            new docx.TableCell({
              ...cellOp,
              children: [
                new docx.Paragraph("채널"),
                new docx.Paragraph(answers["w14-채널"] || ""),
              ],
            }),
          ],
        }),
        new docx.TableRow({
          children: [
            // 비용
            new docx.TableCell({
              ...cellOp,
              columnSpan: 3,
              children: [
                new docx.Paragraph("비용"),
                new docx.Paragraph(answers["w14-비용"] || ""),
              ],
            }),
            // 수익
            new docx.TableCell({
              ...cellOp,
              columnSpan: 3,
              children: [
                new docx.Paragraph("수익"),
                new docx.Paragraph(answers["w14-수익"] || ""),
              ],
            }),
          ],
        }),
      ],
    });

    const doc = new docx.Document();

    doc.addSection({
      properties: {},
      children: [table],
    });
    docx.Packer.toBlob(doc).then((blob) => {
      saveAs(blob);
    });
  };

  return (
    <div>
      <div className="minwidth">
        {/*  콘텐츠 시작 { */}
        <div id="worksheet">
          {/* 상단 시작 { */}
          {/* <header
                    className="header"
                    include-html="/public/worksheets/worksheet_header.html"
                /> */}
          {/* } 상단 끝 */}
          {/* nav 시작 { */}
          {/* <nav
                    className="d-flex flex-column position-fixed fixed-left"
                    id="worksheetNav"
                    include-html="/public/worksheets/worksheet_nav.html"
                /> */}
          {/* } nav 끝 */}
          <div className="basicWrap">
            <section className="d-flex justify-content-between youtube align-items-center px-5">
              <h2 className="h2 font-weight-normal">
                작성하는 법을 잘 모르겠다면? youtube 시청 후 작성하기
              </h2>
              <Link
                className="btn btn-sm font-weight-normal squear-btn-basic radius pr-4 pl-4"
                to=""
              >
                과정설명 영상 보러가기
              </Link>
            </section>
            <div style={{ marginTop: 22, borderBottom: "1px solid #aaa" }}>
              <span style={{ fontSize: 12, color: "#aaa", marginTop: 22 }}>
                STEP3. 비즈니스 모델 캔버스 {">"} 비즈니스 모델 캔버스
              </span>
            </div>
            <form action className="dataForm mt40" ref={htmlRef}>
              <table className="table stepTable mb40 canvas">
                <colgroup>
                  <col width="20%" />
                  <col width="20%" />
                  <col width="10%" />
                  <col width="10%" />
                  <col width="20%" />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <td rowSpan={2}>
                      <span>핵심파트너</span>
                      <div className="d-flex align-items-stretch">
                        <textarea
                          className="textareaForm"
                          rows={10}
                          defaultValue={""}
                          name="w14-핵심파트너"
                          value={textareaValue("w14-핵심파트너", 1)}
                          onChange={textareaListener("w14-핵심파트너", 1)}
                        />
                      </div>
                    </td>
                    <td>
                      <span>핵심활동</span>
                      <textarea
                        className="textareaForm"
                        rows={4}
                        defaultValue={""}
                        name="w14-핵심활동"
                        value={textareaValue("w14-핵심활동", 1)}
                        onChange={textareaListener("w14-핵심활동", 1)}
                      />
                    </td>
                    <td colSpan={2} rowSpan={2}>
                      <span>가치제안</span>
                      <textarea
                        className="textareaForm"
                        rows={10}
                        defaultValue={""}
                        name="w14-가치제안"
                        value={textareaValue("w14-가치제안", 1)}
                        onChange={textareaListener("w14-가치제안", 1)}
                      />
                    </td>
                    <td>
                      <span>고객관계</span>
                      <textarea
                        className="textareaForm"
                        rows={4}
                        defaultValue={""}
                        name="w14-고객관계"
                        value={textareaValue("w14-고객관계", 1)}
                        onChange={textareaListener("w14-고객관계", 1)}
                      />
                    </td>
                    <td rowSpan={2}>
                      <span>고객</span>
                      <textarea
                        className="textareaForm"
                        rows={10}
                        defaultValue={""}
                        name="w14-고객"
                        value={textareaValue("w14-고객", 1)}
                        onChange={textareaListener("w14-고객", 1)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>핵심자원</span>
                      <textarea
                        className="textareaForm"
                        rows={4}
                        defaultValue={""}
                        name="w14-핵심자원"
                        value={textareaValue("w14-핵심자원", 1)}
                        onChange={textareaListener("w14-핵심자원", 1)}
                      />
                    </td>
                    <td>
                      <span>채널</span>
                      <textarea
                        className="textareaForm"
                        rows={4}
                        defaultValue={""}
                        name="w14-채널"
                        value={textareaValue("w14-채널", 1)}
                        onChange={textareaListener("w14-채널", 1)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <span>비용</span>
                      <textarea
                        className="textareaForm"
                        rows={4}
                        defaultValue={""}
                        name="w14-비용"
                        value={textareaValue("w14-비용", 1)}
                        onChange={textareaListener("w14-비용", 1)}
                      />
                    </td>
                    <td colSpan={3}>
                      <span>수익</span>
                      <textarea
                        className="textareaForm"
                        rows={4}
                        defaultValue={""}
                        name="w14-수익"
                        value={textareaValue("w14-수익", 1)}
                        onChange={textareaListener("w14-수익", 1)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("a_2_comment", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    name="w_comment"
                    id="w_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    defaultValue={""}
                    value={commentValue("w_comment", 1)}
                    onChange={commentListener("w_comment", 1)}
                  />
                </div>
              </section>
            </form>
            <section className="btnArea d-flex align-items-center justify-content-end">
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={() => extractPdf(htmlRef.current)}
              >
                PDF
              </button>
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={extractDocx}
              >
                다운로드
              </button>
              <div className="btn btn-sm squear-btn-border-basic mr-2">
                <Link to={`/worksheets/${worksheetId || "new"}/worksheet12/`}>
                  이전으로
                </Link>
              </div>
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={saveWorksheet}
              >
                저장하기
              </button>
              <div className="btn btn-sm squear-btn-basic">
                <Link to={`/worksheets/${worksheetId || "new"}/worksheet14/`}>
                  다음으로
                </Link>
              </div>
            </section>
          </div>
        </div>
        {/* } 콘텐츠 끝 */}
        <hr />
        {/* 하단 시작 { */}
        {/* <footer include-html="/public/worksheets/worksheet_footer.html" /> */}
        {/* } 하단 시작  */}
      </div>
    </div>
  );
};

export default Worksheet13;
