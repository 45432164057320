import { Link, useHistory, useRouteMatch } from "react-router-dom";
import "../Worksheet.css";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { useEffect, useRef, useState } from "react";
//
import * as docx from "docx";
import { saveAs } from "file-saver";
import extractPdf from "util/extractPdf";
import CommentCheck from "util/CommentCheck";
//
const Worksheet5 = ({ listeners, userType }) => {
  const [answers, setAnswers] = useState({});
  const db = useRef(firebase.firestore()).current;
  const auth = useRef(firebase.auth()).current;
  const match = useRouteMatch();
  const history = useHistory();
  const [worksheetId, setWorksheetId] = useState();
  const [commentVisible, setCommentVisible] = useState(true);
  const [initialAnswers, setInitialAnswers] = useState({});
  const htmlRef = useRef();
  //
  const [countArray, setCountArray] = useState([]);
  const [sums, setSums] = useState({});
  //
  const radioChecked = listeners.radioChecked(answers);
  const radioListener = listeners.radioListener(answers, setAnswers);
  const checkboxChecked = listeners.checkboxChecked(answers);
  const checkboxListener = listeners.checkboxListener(answers, setAnswers);
  const textareaValue = listeners.textareaValue(answers);
  const textareaListener = listeners.textareaListener(answers, setAnswers);
  const commentValue = listeners.commentValue(answers);
  const commentListener = listeners.commentListener(answers, setAnswers);
  //
  const extractDocx = () => {
    const doc = new docx.Document();

    const marginOption = {
      margins: {
        left: 70,
      },
    };
    const centerOption = {
      verticalAlign: docx.VerticalAlign.CENTER,
    };
    //
    let dataArray = [];
    //
    console.log(countArray);
    //
    let headerArray = [
      new docx.TableCell({
        columnSpan: 1,
        children: [new docx.Paragraph("구분")],
      }),
    ];
    countArray.map((item, index) => {
      headerArray.push(
        new docx.TableCell({
          columnSpan: 1,
          children: [
            new docx.Paragraph(
              answers?.["b_1_" + item + "_1"]
                ? answers["b_1_" + item + "_1"]
                : "대상" + item
            ),
          ],
        })
      );
    });
    //
    dataArray.push(
      new docx.TableRow({
        children: headerArray,
      })
    );
    //
    // 카운트 1개에 3줄씩
    let count = [1, 2, 3];
    countArray.map((bItem, index) => {
      count.map((item, index) => {
        let innerArray = [
          new docx.TableCell({
            columnSpan: 1,
            children: [
              new docx.Paragraph(
                answers?.["b_1_" + bItem + "_" + (3 + item)]
                  ? answers["b_1_" + bItem + "_" + (3 + item)]
                  : "기존의 문제점1"
              ),
            ],
          }),
        ];
        //
        countArray.map((inItem, idx) => {
          innerArray.push(
            new docx.TableCell({
              columnSpan: 1,
              children: [
                new docx.Paragraph(
                  answers?.["b_2_" + inItem + "_" + (3 * (bItem - 1) + item)]
                    ? answers["b_2_" + inItem + "_" + (3 * (bItem - 1) + item)]
                    : ""
                ),
              ],
              // children: [new docx.Paragraph("b_2_"+(inItem)+"_"+(3*(bItem-1)+(item)))],
            })
          );
        });
        //
        dataArray.push(
          new docx.TableRow({
            children: innerArray,
          })
        );
      });
    });

    let sumArray = [
      new docx.TableCell({
        columnSpan: 1,
        children: [new docx.Paragraph("총점")],
      }),
    ];

    countArray.map((item) => {
      console.log(item);
      console.log("b_2_" + item);
      console.log(answers["b_2_" + item]);
      sumArray.push(
        new docx.TableCell({
          columnSpan: 1,
          children: [
            new docx.Paragraph(
              "" + (answers?.["b_2_" + item] ? answers["b_2_" + item] : "")
            ),
          ],
        })
      );
    });

    //
    console.log(sumArray);

    dataArray.push(
      new docx.TableRow({
        children: sumArray,
      })
    );

    //
    // countArray.map(item=>{

    // })
    // Object.keys(questions[1]).map((item,index)=>{
    //   if(item.includes('a_2_5') || item.includes('a_2_6')){
    //     dataArray.push(createAnswerDocxRows(item.toUpperCase()+'. '+questions[1][item], answers[item], 1)[0])
    //     dataArray.push(createAnswerDocxRows(item.toUpperCase()+'. '+questions[1][item], answers[item], 1, 'radio')[1])
    //   }else{
    //     dataArray.push(createAnswerDocxRows(item.toUpperCase()+'. '+questions[1][item], answers[item], 1)[0])
    //     dataArray.push(createAnswerDocxRows(item.toUpperCase()+'. '+questions[1][item], answers[item], 1, 'textarea')[1])
    //   }
    // })
    console.log(dataArray);
    const table = new docx.Table({
      width: {
        size: 100,
        type: docx.WidthType.PERCENTAGE,
      },
      rows: dataArray,
    });

    doc.addSection({
      children: [table],
    });
    docx.Packer.toBlob(doc).then((blob) => {
      saveAs(blob);
    });
  };
  //
  const saveWorksheet = (e) => {
    if (userType === "admin") {
      alert("어드민은 워크시트를 저장할 수 없습니다!");
      return;
    }
    let finalSubmit = false;
    let date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, -1);

    console.log(answers);
    const clone = { ...answers };
    countArray.map((item) => {
      let sum = 0;
      let valLength = document.getElementsByClassName("b_2_" + item).length;
      for (let i = 0; i < valLength; i++) {
        sum += document.getElementsByClassName("b_2_" + item)[i].value * 1;
      }
      //
      let qId = "b_2_" + item;
      // let n = item+1
      clone[qId] = clone[qId] || {};
      clone[qId] = clone[qId] || {};
      //
      clone[qId] = sum;
      console.log(clone);
      setAnswers(clone);
    });

    //
    if (worksheetId && worksheetId != "new") {
      const accountInfo =
        userType == "mento"
          ? {
              mento: auth.currentUser.uid,
            }
          : {
              mentee: auth.currentUser.uid,
            };

      db.collection("worksheets")
        .doc(worksheetId)
        .update({
          // questionAnswers: answers,
          questionAnswers: clone,
          page: 5,
          ...accountInfo,
          finalSubmit,
          date,
        })
        .then(() => {
          alert("저장 성공!");
          listeners.storeAnswersListener(clone);
        })
        .catch((e) => alert(e));
      return;
    }
    console.log("\nanswers", {
      questionAnswers: {
        ...answers,
      },
      mentee: auth.currentUser.uid,
      mento: "",
    });
    db.collection("worksheets")
      .add({
        // questionAnswers: answers,
        questionAnswers: clone,
        mentee: auth.currentUser.uid,
        page:5,
        finalSubmit,
        date,
      })
      .then((doc) => {
        setWorksheetId(doc.id);
        alert("저장 성공!");
        listeners.storeAnswersListener(clone);
        history.replace(`/worksheets/${doc.id || "new"}/worksheet5/`);
      })
      .catch((e) => alert(e));
  };

  useEffect(() => {
    console.log(userType);
    // if (userType == "mento") {
    //   setCommentVisible(true);
    // }
  }, [userType]);

  useEffect(() => {
    //
    setWorksheetId(match.params.id);

    db.collection("worksheets")
      .doc(match.params.id)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          return;
        }
        listeners.storeAnswersListener(doc.data().questionAnswers);
        setAnswers(doc.data().questionAnswers);
        console.log(doc.data().questionAnswers["b_1_2"]);
        let answerArray = [2, 3, 4, 5];
        let resultArray = [1];
        answerArray.map((item) => {
          if (doc.data().questionAnswers["b_1_" + item + "_1"] != undefined) {
            resultArray.push(item);
          }
        });
        console.log(resultArray);

        setCountArray(resultArray);
      });
  }, []);

  useEffect(() => {
    countArray.map((item) => {
      let sum = 0;
      let valLength = document.getElementsByClassName("b_2_" + item).length;
      for (let i = 0; i < valLength; i++) {
        sum += document.getElementsByClassName("b_2_" + item)[i].value * 1;
      }
      console.log(sum.toFixed(1));
      // setAnswers()
      document.getElementById("b_2_" + item).value = sum.toFixed(1);
    });
  });
  //

  return (
    <div>
      <div className="minwidth">
        <div
          className="modal fade main_modal pb-4 memtoListPopup"
          id="exampleModal1"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header" style={{ border: 0 }}>
                <h5 className="modal-title" id="exampleModalLabel" />
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row text-center">
                  <div className="col-12">
                    <h4 className="h4">
                      저장해야 다음으로 넘어갈 수 있습니다.
                    </h4>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {/* <button
                  type="button"
                  className="btn btn-sm squear-btn-border-basic"
                  data-dismiss="modal"
                >
                  취소
                </button> */}
                <button
                  type="button"
                  className="btn btn-sm squear-btn-basic"
                  id="applyFinish"
                  data-dismiss="modal"
                  onClick={() => {
                    let modal = document.getElementById("exampleModal1");
                    function removeClass(element, className) {
                      var check = new RegExp("(\\s|^)" + className + "(\\s|$)");
                      element.className = element.className
                        .replace(check, " ")
                        .trim();
                    }
                    removeClass(modal, "show");
                  }}
                >
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*  콘텐츠 시작 { */}
        <div id="worksheet" className="worksheet5">
          {/* 상단 시작 { */}
          {/* } nav 끝 */}
          <div className="basicWrap">
            <section className="d-flex justify-content-between youtube align-items-center px-5">
              <h2 className="h2 font-weight-normal">
                작성하는 법을 잘 모르겠다면? youtube 시청 후 작성하기
              </h2>
              <a
                className="btn btn-sm font-weight-normal squear-btn-basic radius pr-4 pl-4"
                href
              >
                과정설명 영상 보러가기
              </a>
            </section>
            <div style={{ marginTop: 22, borderBottom: "1px solid #aaa" }}>
              <span style={{ fontSize: 12, color: "#aaa", marginTop: 22 }}>
                STEP2. 가치제안서 {">"} 세분시장별 고객문제 워크시트
              </span>
            </div>
            <form action className="dataForm mt40 " ref={htmlRef}>
              <table className="table stepTable" style={{ marginBottom: 0 }}>
                <colgroup>
                  <col width="50%"/>
                </colgroup>
                <thead>
                  <td className="bg center middle">구분</td>
                  {countArray.map((item) => {
                    return (
                      <td key={item} className="disabled center middle ">
                        {answers?.["b_1_" + item + "_1"]
                          ? answers["b_1_" + item + "_1"]
                          : "대상" + item}
                      </td>
                    );
                  })}
                </thead>
                {countArray.map((item) => {
                  console.log(item);
                  return (
                    <tbody key={item}>
                      <td className="ddd">
                        {[1, 2, 3].map((sItem) => {
                          // 1-1의 4,5,6 또는 1-2의 4,5,6
                          return (
                            <div className="no-padd" key={sItem}>
                              <div className="center middle disabled no_padd">
                                {answers?.["b_1_" + item + "_" + (3 + sItem)]
                                  ? answers["b_1_" + item + "_" + (3 + sItem)]
                                  : "기존의 문제점1"}
                                {/* <input
                                      type="text"
                                      className="inputForm center"
                                      id={"b_1_"+item + ', '+(3+sItem)}
                                      // value={"b_1_1_"+(3*(item-1)+(sItem))}
                                      value={answers?.["b_1_"+item+"_"+(3+(sItem))] ? answers["b_1_"+item+"_"+(3+(sItem))]: "기존의 문제점1"}
                                    /> */}
                              </div>
                            </div>
                          );
                        })}
                      </td>
                      {/*  */}
                      {countArray.map((inItem) => {
                        return (
                          <td key={item}>
                            {[1, 2, 3].map((sItem) => {
                              return (
                                <div className="no_padd" key={sItem}>
                                  <input
                                    type="text"
                                    className={
                                      "inputForm center " + "b_2_" + inItem
                                    }
                                    placeholder="0 ~ 1"
                                    id={
                                      "b_2_" +
                                      inItem +
                                      "_" +
                                      (3 * (item - 1) + sItem)
                                    }
                                    // placeholder={"b_2_"+(inItem)+"_"+(3*(item-1)+sItem)}
                                    name={
                                      "b_2_" +
                                      inItem +
                                      "_" +
                                      (3 * (item - 1) + sItem)
                                    }
                                    value={textareaValue(
                                      "b_2_" +
                                        inItem +
                                        "_" +
                                        (3 * (item - 1) + sItem),
                                      1
                                    )}
                                    onChange={textareaListener(
                                      "b_2_" +
                                        inItem +
                                        "_" +
                                        (3 * (item - 1) + sItem),
                                      1
                                    )}
                                  />
                                </div>
                              );
                            })}
                          </td>
                        );
                      })}
                    </tbody>
                  );
                })}
                <tbody>
                  <td>총점</td>
                  {countArray.map((item) => {
                    let sum = 0;
                    let valLength = document.getElementsByClassName(
                      "b_2_" + item
                    ).length;
                    console.log(valLength);
                    for (let i = 0; i < valLength; i++) {
                      console.log(
                        document.getElementsByClassName("b_2_" + item)[i]
                          .value * 1
                      );
                      sum +=
                        document.getElementsByClassName("b_2_" + item)[i]
                          .value * 1;
                    }
                    console.log("b_2_8_" + item, sum);
                    // setAnswers()

                    return (
                      <td key={item}>
                        <input
                          type="text"
                          disabled
                          className={"inputForm center"}
                          // placeholder="0 ~ 1"
                          placeholder={"b_2_" + item}
                          name={"b_2_" + item}
                          id={"b_2_" + item}
                          // value={sumFnc(item)}
                          value={textareaValue("b_2_" + item, 1)}
                          onChange={textareaListener("b_2_" + item, 1)}
                          // onChange={e=>console.log(e)}
                        />
                      </td>
                    );
                  })}
                </tbody>
              </table>
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_2", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    onKeyUp={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.style.height =
                        e.currentTarget.scrollHeight + 2 + "px";
                    }}
                    name="b_2_comment"
                    id="b_2_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == 'mento' ? false:true}
                    value={commentValue("b_2", 1)}
                    onChange={commentListener("b_2", 1)}
                  />
                </div>
              </section>
            </form>
            <section className="btnArea d-flex align-items-center justify-content-end">
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={() => extractPdf(htmlRef.current)}
              >
                PDF
              </button>
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={extractDocx}
              >
                다운로드
              </button>
              <div className="btn btn-sm squear-btn-border-basic mr-2">
                <Link to={`/worksheets/${worksheetId || "new"}/worksheet4/`}>
                  이전으로
                </Link>
              </div>
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={saveWorksheet}
              >
                저장하기
              </button>
              {/* <div className="btn btn-sm squear-btn-basic">
                <Link to={`/worksheets/${(worksheetId || "new")}/worksheet6/`}>다음으로</Link>
              </div> */}
              <div
                className="btn btn-sm squear-btn-basic "
                // data-toggle="modal"
                // data-target="#exampleModal1"
                onClick={() => {
                  console.log(countArray);
                  let tt = [1, 2, 3];
                  // tt.map((sItem, sIndex) => {
                  //   countArray.map((inItem, inIndex) => {
                  //     // console.log("b_1_"+(item+1)+"_"+(3*(inItem-1)+(sItem)))
                  //     // console.log("b_1_"+(inItem+1)+"_")
                  //     // console.log(3*(inItem-1))
                  //   })

                  // })
                  let keyArray = [];
                  countArray.map((item) => {
                    countArray.map((count) => {
                      tt.map((inItem) => {
                        // console.log("b_2_"+item+"_"+((3*(count-1))+inItem))
                        keyArray.push(
                          "b_2_" + item + "_" + (3 * (count - 1) + inItem)
                        );
                      });
                    });
                  });

                  console.log(keyArray);
                  listeners.checkValue(keyArray, answers, worksheetId, 6);
                }}
              >
                <Link>다음으로</Link>
              </div>
            </section>
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default Worksheet5;