// import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import Header from "./feature/Header";
import Home from "./feature/Home";
import Modal from "./feature/Modal";
import Login from "./feature/login/Login";
import MapComponent from "./feature/map/MapComponent";
import Register from "./feature/register/Register";
import Join from "./feature/register/Join";
import RegMentee from "./feature/register/Mentee";
import RegMento from "./feature/register/Mento";
import WorksheetInfo from "./feature/worksheets/WorksheetInfo";
import Mento from "./feature/mypage/mento/Mento";
import Mentee from "./feature/mypage/mentee/Mentee";
import Intro from "./feature/intro/Intro";
import NoticeList from "./feature/notice/NoticeList";
import NoticeView from "./feature/notice/NoticeView";
import { useEffect, useRef, useState } from "react";
import QnaWrite from "./feature/qna/QnaWrite";
import QnaView from "./feature/qna/QnaView";
import WorksheetHome from "./feature/worksheets/WorksheetHome";
import WorksheetListDeatil from "./feature/mypage/mentee/detail/WorksheetListDetail";

import MentoApply from "feature/mypage/mentee/apply/Apply";
import Admin from "feature/admin/Admin";
import MentoDetail from "feature/mypage/mento/MentoDetailBack";
import InfoModifyMentee from 'feature/mypage/mentee/info/InfoModify'
import InfoModifyMento from "feature/mypage/mento/info/infoModify";

function App() {
  const [user, setUser] = useState(null);
  const [userType, setUserType] = useState(null);
  const db = useRef(firebase.firestore()).current;

  const path = require("path");

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      // console.log(user)
      setUser(user);
    });
  }, []);
  useEffect(() => {
    if (!user) {
      // setUserType('no');
      return;
    }

    db.collection("mentee")
      .doc(user.uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setUserType("mentee");
        }
      })
      .catch((e) => console.log("err", e));
    db.collection("mento")
      .doc(user.uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          if(!doc.data().agree){
            // alert('관리자에게 승인 받지 못한 멘토입니다.')
            // firebase.auth().signOut();
            // setUser(null)
            // setUserType(null)
          }
          setUserType("mento");
        }
      })
      .catch((e) => console.log("err", e));
    db.collection("admin")
      .doc(user.uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setUserType("admin");
        }
      })
      .catch((e) => console.log("err", e));
  }, [user, db]);

  useEffect(() => console.log("userType", userType), [userType]);

  return (
    <Router>
      <Switch>
        {userType === "admin" ? (
          <Route path="/admin">
            <Admin />
          </Route>
        ) : null}
        <Route path="/worksheets/:id">
          <WorksheetHome userType={userType} />
        </Route>

        <Route>
          <div className="App">
            <Header user={user} userType={userType} />
            {/* 시장정보 확인하기 Modal { */}
            <Modal />
            <Switch>
              <Route path="/mypage/mentee/:worksheetId/apply">
                <MentoApply />
              </Route>
              <Route path="/mypage/mentee/detail">
                <WorksheetListDeatil />
              </Route>
              <Route path="/qna/write">
                <QnaWrite />
              </Route>
              <Route path="/qna/view">
                <QnaView />
              </Route>
              <Route path="/notice/list">
                <NoticeList />
              </Route>
              <Route path="/notice/:id">
                <NoticeView />
              </Route>
              <Route path="/Intro">
                <Intro />
              </Route>
              {userType == "mento" ? (
                <Route path="/mypage/info/:id?">
                  <InfoModifyMento />
                </Route>
              ):(
                <Route path="/mypage/info/:id?">
                  <InfoModifyMentee />
                </Route>
              )}
              {userType === "mento" ? (
                <Route path="/mypage/detail">
                  <MentoDetail />
                </Route>
              ) : null}
              {userType === "mentee" ? (
                <Route path="/mypage/">
                  <Mentee user={user} />
                </Route>
              ) : null}
              {userType === "mento" ? (
                <Route path="/mypage/">
                  <Mento user={user} />
                </Route>
              ) : null}
              <Route path="/worksheet_info">
                <WorksheetInfo />
              </Route>

              <Route path="/map">
                <MapComponent />
              </Route>
              <Route path="/intro"></Route>
              <Route path="/join">
                <Join/>
              </Route>
              <Route path="/register/mentee/:id?">
                <RegMentee/>
              </Route>
              <Route path="/register/mento/:id?">
                <RegMento/>
              </Route>
              <Route path="/register/:id?">
                <Register />
              </Route>
              <Route path="/login/">
                <Login />
              </Route>

              <Route path="/">
                <Home user={user} userType={userType} />
              </Route>
            </Switch>
          </div>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
