import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { Provider } from "react-redux";
import store from "store";
import CookieUtil from "util/CookieUtil";

const firebaseConfig = {
  apiKey: "AIzaSyD9u-mEJuTm-Ccmsaa_78I4FIuBAfLgVFI",
  authDomain: "hinnovation-worksheet.firebaseapp.com",
  databaseURL: "https://hinnovation-worksheet.firebaseio.com",
  projectId: "hinnovation-worksheet",
  storageBucket: "hinnovation-worksheet.appspot.com",
  messagingSenderId: "178164610153",
  appId: "1:178164610153:web:d49f2a4635cea7e3655ef8",
  measurementId: "G-518R374TTV",
};

firebase.initializeApp(firebaseConfig);

// 주소가 localhost 일 때 firestore 테스트모드
var db = firebase.firestore();
var auth = firebase.auth();
if (window.location.hostname === "localhost") {
  console.log("INFO - running in localhost");
  firebase.auth().useEmulator("http://localhost:9099/");
  firebase.firestore().useEmulator("localhost", 8080);
  firebase.functions().useEmulator("localhost", 5001);
  // db.settings({
  //   host: "localhost:8080",
  //   ssl: false
  // });
}
auth
  .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  .then(() => {
    const loginFlag = CookieUtil.getCookie("loginFlag");
    if (!loginFlag) {
      auth.signOut();
    }
  })
  .then(() => {
    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      document.getElementById("root")
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
