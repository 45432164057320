import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reqDeletePopup, reqUpdatePopup } from "./sliceAdminPopup";

const AdminPopupRow = ({ idx }) => {
  const dispatch = useDispatch();

  const popupId = idx.toString();
  const popup = useSelector((state) =>
    state.admin.popup.popups.find((e) => e.id === popupId)
  );

  const [linkUrl, setLinkUrl] = useState(popup?.linkUrl);
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(popup?.imageUrl);

  return (
    <tr>
      <td>
        <div className="d-flex justify-content-center align-items-center">
          {idx}
        </div>
      </td>
      <td>
        <span className="frm_info">
          팝업클릭시 이동하는 주소입니다.
          <br />
          ex: https://example.com
        </span>
        <input
          type="text"
          name="linkUrl"
          size={80}
          id="bn_url1"
          className="frm_input popup_input"
          required
          onChange={(e) => {
            setLinkUrl(e.target.value);
          }}
          value={linkUrl}
        />
      </td>
      <td>
        <input
          type="file"
          accept=".jpeg,.png"
          name="popup_image1"
          id="popup_image_file1"
          onChange={(e) => {
            setImageFile(e.target.files[0]);
            setImageUrl(URL.createObjectURL(e.target.files[0]));
          }}
          required
        />
        <br />
        <img
          alt="잘못된 URL"
          src={imageUrl}
          style={{
            display: imageUrl ? "flex" : "none",
          }}
          id="popup_file1"
          className="popup_file"
        />
      </td>
      <td>
        <div className="btnArea popbtn d-flex justify-content-center align-items-center">
          <button
            className="btn btn-sm squear-basic-btn mr-1"
            onClick={() => {
              if (!(popupId && imageFile && linkUrl)) {
                return alert("현재 행의 모든 항목을 입력해주세요!");
              }
              alert("저장되었습니다");
              dispatch(
                reqUpdatePopup({
                  id: popupId,
                  file: imageFile,
                  linkUrl: linkUrl,
                })
              );
            }}
          >
            저장
          </button>
          {popup && (
            <button
              className="btn btn-sm squear-basic-btn gray"
              onClick={() => dispatch(reqDeletePopup(popupId))}
            >
              삭제
            </button>
          )}
        </div>
      </td>
    </tr>
  );
};

export default AdminPopupRow;
