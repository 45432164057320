import { Field, Form, Formik } from "formik";
import { useEffect, useState, useRef } from "react";

import { Link, useHistory, useRouteMatch } from "react-router-dom";
import firebase from "firebase";

const MAX_TD = 7;
const COL_LEN = 7;

const IndustryMap = ({ userType, listeners }) => {
  const db = useRef(firebase.firestore()).current;
  let match = useRouteMatch();
  const [worksheetId, setWorksheetId] = useState();
  const auth = useRef(firebase.auth()).current;
  const [commentVisible, setCommentVisible] = useState(false);
  const history = useHistory();
  const [columnCount, setColumnCount] = useState(7);

  useEffect(() => {
    console.log(userType);
    if (userType == "mento") {
      setCommentVisible(true);
    }
  }, [userType]);

  //
  useEffect(() => {
    setWorksheetId(match.params.id);

    db.collection("worksheets")
      .doc(match.params.id)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          return;
        }
        listeners.storeAnswersListener(doc.data().questionAnswers);
      });
  }, []);

  const saveWorksheet = (value) => {
    const resultAnswers = {
      ...(listeners.storeAnswersValue() || {}),
      ...value,
    };
    const accountInfo =
      userType == "mento"
        ? {
            mento: auth.currentUser.uid,
          }
        : {
            mentee: auth.currentUser.uid,
          };
    if (worksheetId && worksheetId != "new") {
      db.collection("worksheets")
        .doc(worksheetId)
        .update({
          ...accountInfo,
          questionAnswers: resultAnswers,
        })
        .then(() => {
          alert("저장 성공!");
          listeners.storeAnswersListener(resultAnswers);
        })
        .catch((e) => alert(e));
      return;
    }
    console.log("\nanswers", {
      questionAnswers: {
        ...resultAnswers,
      },
      mentee: auth.currentUser.uid,
      mento: "",
      page: 1,
      endPage: 1,
    });
    db.collection("worksheets")
      .add({
        questionAnswers: resultAnswers,
        mentee: auth.currentUser.uid,
        page: 1,
      })
      .then((doc) => {
        setWorksheetId(doc.id);
        alert("저장 성공!");
        listeners.storeAnswersListener(resultAnswers);
        history.replace(`/worksheets/${doc.id}/industrymap/`);
      })
      .catch((e) => alert(e));
  };

  const [cateLengths, setCateLengths] = useState(new Array(COL_LEN).fill(1));

  const renderedTds = cateLengths.map((colLen, colIdx) => {
    return new Array(colLen).fill(0).map((v, tdIdx) => {
      return (
        <Field
          type="text"
          name={`industrymap_cate${1 + colIdx}_${1 + tdIdx}`}
          key={tdIdx}
        ></Field>
      );
    });
  });

  console.log(renderedTds);

  const addCateLength = (cate) => (diff) => {
    const clone = [...cateLengths];

    if (clone[cate] >= MAX_TD) return;
    clone[cate] = clone[cate] + diff;
    setCateLengths(clone);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        industrymap_cate1:
          listeners.storeAnswersValue().industrymap_cate1 || "원료",
        industrymap_cate2:
          listeners.storeAnswersValue().industrymap_cate2 || "조립",
        industrymap_cate3:
          listeners.storeAnswersValue().industrymap_cate3 || "제조",
        industrymap_cate4:
          listeners.storeAnswersValue().industrymap_cate4 || "유통",
        industrymap_cate5:
          listeners.storeAnswersValue().industrymap_cate5 || "생산",
        industrymap_cate6:
          listeners.storeAnswersValue().industrymap_cate6 || "물류",
        industrymap_cate7:
          listeners.storeAnswersValue().industrymap_cate7 || "판매",
        // td1_1
        // td1_2 ...
      }}
      onSubmit={(values, { setSubmitting }) => {
        saveWorksheet(values);
      }}
    >
      {({ values, isSubmitting, submitForm }) => (
        <Form>
          <div className="minwidth">
            {/*  콘텐츠 시작 { */}
            <div id="worksheet">
              {/* } nav 끝 */}
              <div className="basicWrap industrymap">
                <div className="d-flex justify-content-around mb-4">
                  {columnCount > 0 ? (
                    <div id="cate1" className="d-flex flex-column">
                      <div className="th">
                        <Field
                          type="text"
                          name="industrymap_cate1"
                          id="cate1"
                        />
                      </div>
                      <div className="box">{renderedTds[0]}</div>
                      <button
                        type="button"
                        className="btn btn-sm squear-btn-border-basic"
                        onClick={() => {
                          addCateLength(0)(1);
                        }}
                      >
                        추가
                      </button>
                    </div>
                  ) : null}

                  {columnCount > 1 ? (
                    <div id="cate2" className="d-flex flex-column">
                      <div className="th">
                        <Field
                          type="text"
                          name="industrymap_cate2"
                          id="cate2"
                        />
                      </div>
                      <div className="box">{renderedTds[1]}</div>
                      <button
                        type="button"
                        className="btn btn-sm squear-btn-border-basic"
                        onClick={() => {
                          addCateLength(1)(1);
                        }}
                      >
                        추가
                      </button>
                    </div>
                  ) : null}

                  {columnCount > 2 ? (
                    <div id="cate3" className="d-flex flex-column">
                      <div className="th">
                        <Field
                          type="text"
                          name="industrymap_cate3"
                          id="cate3"
                        />
                      </div>
                      <div className="box">{renderedTds[2]}</div>
                      <button
                        type="button"
                        className="btn btn-sm squear-btn-border-basic"
                        onClick={() => {
                          addCateLength(2)(1);
                        }}
                      >
                        추가
                      </button>
                    </div>
                  ) : null}

                  {columnCount > 3 ? (
                    <div id="cate4" className="d-flex flex-column">
                      <div className="th">
                        <Field
                          type="text"
                          name="industrymap_cate4"
                          id="cate4"
                        />
                      </div>
                      <div className="box">{renderedTds[3]}</div>
                      <button
                        type="button"
                        className="btn btn-sm squear-btn-border-basic"
                        onClick={() => {
                          addCateLength(3)(1);
                        }}
                      >
                        추가
                      </button>
                    </div>
                  ) : null}

                  {columnCount > 4 ? (
                    <div id="cate5" className="d-flex flex-column">
                      <div className="th">
                        <Field
                          type="text"
                          name="industrymap_cate5"
                          id="cate5"
                        />
                      </div>
                      <div className="box">{renderedTds[4]}</div>
                      <button
                        type="button"
                        className="btn btn-sm squear-btn-border-basic"
                        onClick={() => {
                          addCateLength(4)(1);
                        }}
                      >
                        추가
                      </button>
                    </div>
                  ) : null}

                  {columnCount > 5 ? (
                    <div id="cate6" className="d-flex flex-column">
                      <div className="th">
                        <Field
                          type="text"
                          name="industrymap_cate6"
                          id="cate6"
                        />
                      </div>
                      <div className="box">{renderedTds[5]}</div>
                      <button
                        type="button"
                        className="btn btn-sm squear-btn-border-basic"
                        onClick={() => {
                          addCateLength(5)(1);
                        }}
                      >
                        추가
                      </button>
                    </div>
                  ) : null}

                  {columnCount > 6 ? (
                    <div id="cate7" className="d-flex flex-column">
                      <div className="th">
                        <Field
                          type="text"
                          name="industrymap_cate7"
                          id="cate7"
                        />
                      </div>
                      <div className="box">{renderedTds[6]}</div>
                      <button
                        type="button"
                        className="btn btn-sm squear-btn-border-basic"
                        onClick={() => {
                          addCateLength(6)(1);
                        }}
                      >
                        추가
                      </button>
                    </div>
                  ) : null}
                </div>
                <div className="worksheetForm">
                  <section
                    className="section comment"
                    style={commentVisible ? null : { display: "none" }}
                  >
                    <div className="question font-weight-bold">Comment</div>
                    <div className="answer">
                      <Field
                        name="industrymap_comment"
                        id="industrymap_comment"
                        as="textarea"
                        className="textareaForm"
                        placeholder="멘토만 작성할 수 있는 구역입니다."
                      />
                    </div>
                  </section>
                </div>
                <section className="btnArea d-flex align-items-center justify-content-end mt-2">
                  <button
                    type="button"
                    className="btn btn-sm squear-btn-border-basic flex-grow-4"
                    onClick={submitForm}
                  >
                    저장하기
                  </button>
                  {/* <button
                    type="button"
                    className="btn btn-sm squear-btn-border-basic flex-grow-1"
                    onClick={() => setColumnCount(state => setColumnCount(Math.min(state+1, 7)))}
                  >
                    열추가
                  </button> */}
                </section>
              </div>
            </div>
            {/* } 콘텐츠 끝 */}
            <hr />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default IndustryMap;
