import "./Worksheet.css";

const WorksheetFooter = () => {
  return (
    <div id="worksheet">
      <div>
        <div className="d-none">
          Icons made by
          <a href="https://www.flaticon.com/authors/freepik" title="Freepik">
            Freepik
          </a>
          from{" "}
          <a href="https://www.flaticon.com/" title="Flaticon">
            www.flaticon.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default WorksheetFooter;
