import { Link } from "react-router-dom";
import '../Worksheet.css'

const Worksheet14 = () => {
    return(
        <div>
            <div className="minwidth">
                <div id="worksheet">
                <div className="basicWrap">
                    <section className="d-flex align-items-center justify-content-center last-step text-center">
                    <Link
                        className="d-flex align-items-center justify-content-center flex-column"
                        to=''
                    >
                        {/* <img src="/public/image/sub/teacher.svg" alt="멘토신청" /> */}
                        멘토신청 하러가기
                    </Link>
                    <Link
                        className="d-flex align-items-center justify-content-center flex-column"
                        to="javascript:alert('준비중입니다.')"
                    >
                        {/* <img src="/public/image/sub/business-report.svg" alt="제출하기" /> */}
                        제출하기
                    </Link>
                    </section>
                </div>
                </div>
                <hr />
            </div>
        </div>
    )
}

export default Worksheet14;