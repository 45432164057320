import * as docx from "docx";

const questionOption = {
  margins: {
    left: 80,
  },
  verticalAlign: docx.VerticalAlign.CENTER,
};
const radioOption = {
  margins: {
    left: 80,
  },
  verticalAlign: docx.VerticalAlign.CENTER,
};
const checkboxOption = {
  margins: {
    left: 80,
  },
  verticalAlign: docx.VerticalAlign.CENTER,
};
const textareaOption = {
  margins: {
    left: 80,
  },
  verticalAlign: docx.VerticalAlign.CENTER,
  rowSpan: 1,
};

const createAnswerDocxRows = (q, a, columnSpan = 3, option) => {
  let op = {};
  let rowOp = {};
  switch (option) {
    case "radio":
      op = radioOption;
      break;
    case "checkbox":
      op = checkboxOption;
      break;
    case "textarea":
      op = textareaOption;
      rowOp = {
        height:{
          height:4000,
          rule:docx.HeightRule.ATLEAST
        }
      }
      break;
    default:
  }

  return [
    new docx.TableRow({
      children: [
        new docx.TableCell({
          ...questionOption,
          columnSpan: columnSpan,
          children: [new docx.Paragraph(q.toString())],
        }),
      ],
    }),
    new docx.TableRow({
      children: [
        new docx.TableCell({
          ...op,
          columnSpan: columnSpan,
          children: [new docx.Paragraph(a.toString())],
        }),
      ],
      ...rowOp,
    }),
  ];
};

export default createAnswerDocxRows;
