import { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";

const AdminHeader = () => {
  const history = useHistory();
  const auth = useMemo(() => firebase.auth(), []);

  return (
    <div>
      <h1>WOKRSHEET</h1>
      <div id="adm_top">
        <button type="button" id="btn_gnb" className="btn_gnb_close">
          <i className="fa fa-bars" style={{ fontSize: 20 }} />
        </button>
        <div id="admlogo">
          <Link to="/admin/home" className="white">
            ADMIN
          </Link>
        </div>
        <div id="tnb">
          <ul>
            <li className="tnb_li">
              <Link to="/" title="홈페이지 바로가기">
                홈페이지
              </Link>
            </li>
            <li id="tnb_logout">
              <a
                onClick={() => {
                  auth.signOut().then(() => {
                    history.go(1);
                    history.push("/");
                  });
                }}
              >
                로그아웃
              </a>
            </li>
          </ul>
        </div>
      </div>
      <nav id="gnb" className="gnb_large ">
        <h2>관리자 주메뉴</h2>
        <ul className="gnb_ul">
          <li className="gnb_li gnb_li1">
            <Link
              to="/admin/banner"
              className=""
            >
              <button
                type="button"
                className="btn_op menu-100 menu-order-1"
                title="환경설정"
              >
                <i className="fa fa-cog" style={{ fontSize: 20 }} />
                <span className="sound_only">
                  
                    환경설정
                  
                </span>
              </button>
            </Link>
            <div className="gnb_oparea_wr">
              <div className="gnb_oparea">
                <h3>환경설정</h3>
                <ul>
                  <li>
                    <Link
                      to="/admin/banner"
                      className="gnb_2da gnb_grp_style gnb_grp_div"
                    >
                      배너관리
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/popup"
                      className="gnb_2da gnb_grp_div"
                    >
                      팝업관리
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li className="gnb_li gnb_li2">
            <Link
              to="/admin/member"
              className=""
            >
              <button
                type="button"
                className="btn_op menu-200 menu-order-2"
                title="회원관리"
              >
                <i className="fa fa-user" style={{ fontSize: 20 }} />
                <span className="sound_only">회원관리</span>
              </button>
            </Link>
            <div className="gnb_oparea_wr">
              <div className="gnb_oparea">
                <h3>회원관리</h3>
                <ul>
                  <li>
                    <Link to="/admin/member" className="gnb_2da">
                      회원관리
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/admin/member?selectType=mentee"
                      className="gnb_2da"
                    >
                      멘티관리
                    </Link>
                    <li>
                      <Link
                        to="/admin/member?selectType=mentee&sucType=ing"
                        className="gnb_2da"
                      >
                        -멘티관리(작성중)
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/admin/member?selectType=mentee&sucType=end"
                        className="gnb_2da"
                      >
                        -멘티관리(완료)
                      </Link>
                    </li>
                  </li>
                  <li>
                    <Link to="/admin/member?selectType=mento" className="gnb_2da">
                      멘토관리
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li className="gnb_li gnb_li3">
            <Link
              to="/admin/notice"
              className=""
            >
              <button
                type="button"
                className="btn_op menu-200 menu-order-2"
                title="게시판관리"
              >
                <i className="fa fa-volume-up" style={{ fontSize: 20 }} />
                <span className="sound_only">게시판관리</span>
              </button>
            </Link>
            <div className="gnb_oparea_wr">
              <div className="gnb_oparea">
                <h3>게시판관리</h3>
                <ul>
                  <li>
                    <Link to="/admin/notice"
                      className="gnb_2da"
                    >
                      공지사항관리
                    </Link>
                  </li>
                  <li>
                    <a
                      className="gnb_2da"
                    >
                      문의사항관리
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          {/* <li className="gnb_li gnb_li4">

            <button
              type="button"
              className="btn_op menu-600 menu-order-5"
              title="정산관리"
            >
              <i className="fa fa-list" style={{ fontSize: 18 }} />
              <span className="sound_only">정산관리</span>
            </button>

            <div className="gnb_oparea_wr">
              <div className="gnb_oparea">
                <h3>정산관리</h3>
                <ul>
                  <li>
                    <a
                      className="gnb_2da gnb_grp_style gnb_grp_div"
                    >
                      정산관리
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li> */}
        </ul>
      </nav>
    </div>
  );
};

export default AdminHeader;
