import { Link } from 'react-router-dom'
import 'feature/qna/qna.css'

const QnaView = () => {
    return(
        <div>
            <div className="minwidth">
                {/* 상단 시작 { */}
                {/* <header include-html="/public/header.html" /> */}
                {/* } 상단 끝 */}
                {/* 시장정보 확인하기 Modal { */}
                {/* <div include-html="/public/modal.html" /> */}
                {/* } 시장정보 확인하기 Modal */}
                <hr />
                {/* 콘텐츠 시작 { */}
                <div className="resizeWrap">
                <div className="basicWrap">
                    {/* basicWrap */}
                    <div
                    id="topNav"
                    className="d-flex justify-content-start align-items-end pb-2 mb40"
                    >
                    <h1 className="h1 m-0 font-weight-bold">문의하기 </h1>
                    <ul id="topNavList" className="d-flex">
                        <li className>
                        <Link to="/notice/list/">공지사항</Link>
                        </li>
                        <li className="active">
                        <Link to="/qna/write/">문의사항</Link>
                        </li>
                    </ul>
                    </div>
                    {/* 게시물 읽기 시작 { */}
                    <article id="bo_v" style={{ width: "100%" }}>
                    <section id="bo_v_atc">
                        <div className="row">
                        <div className="col-3 text-center">등록날짜</div>
                        <p className="bo_v_name col">20-11-02 16:48</p>
                        </div>
                        <div className="row">
                        <div className="col-3 text-center">이름</div>
                        <p className="bo_v_name col">
                            <span className="sv_guest">namyeji</span>
                        </p>
                        </div>
                        <div className="row">
                        <div className="col-3 text-center">이메일</div>
                        <p className="bo_v_email col">dkdle@naver.com</p>
                        </div>
                        <div className="row">
                        <div className="col-3 text-center">전화번호</div>
                        <p className="bo_v_tel col">01085000111</p>
                        </div>
                        <div className="row">
                        <div className="col-3 text-center">제목</div>
                        <p className="bo_v_tit col">titledlqslek</p>
                        </div>
                        {/* 본문 내용 시작 { */}
                        <div id="bo_v_con" className="row">
                        fljkhstleikj
                        </div>
                        {/* } 본문 내용 끝 */}
                    </section>
                    <article>{/* } 게시판 읽기 끝 */}</article>
                    </article>
                </div>
                {/* container_wr / basicWrap */}
                </div>
                {/* } 콘텐츠 끝 */}
                <hr />
                {/* 하단 시작 { */}
                {/* <footer include-html="/public/footer.html" /> */}
                {/* } 하단 시작  */}
            </div>
            </div>

    )
}

export default QnaView