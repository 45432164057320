import { createSlice } from "@reduxjs/toolkit";
import firebase from "firebase";

const sliceAdminPopup = createSlice({
  name: "admin/popup",
  initialState: {
    /*
    {
        id: null,
        imageUrl: null,
        linkUrl: null,
      },
    */
    popups: [],
  },
  reducers: {
    setPopups: (state, action) => {
      state.popups = action.payload;
    },
    removePopups: (state, action) => {
      state.popups = null;
    },
  },
});

export default sliceAdminPopup;

export const selectPopup = (state) => state.admin.popup;

export const { setPopups, removePopups } = sliceAdminPopup.actions;

export const reqGetPopups = () => async (dispatch) => {
  const storage = firebase.storage();
  const db = firebase.firestore();

  // const popup1 = await (await db.collection("popup").doc("popup1").get()).data();
  const popups = [];
  const popupsSnap = await db.collection("popup").get();
  for (const popupDoc of popupsSnap.docs) {
    popups.push({
      ...popupDoc.data(),
      id: popupDoc.id,
    });
  }

  dispatch(setPopups(popups));
};

export const reqPostPopups = (popupsData) => async (dispatch, getState) => {
  const storage = firebase.storage();
  const db = firebase.firestore();

  const popups = [];
  for (const popupData of popupsData) {
    const ref = storage.ref("admin/popup/" + popupData.id);
    await ref.put(popupData.file);
    const imageUrl = await ref.getDownloadURL();
    popups.push({
      imageUrl: imageUrl,
      linkUrl: popupData.linkUrl,
      id: popupData.id,
    });

    await db.collection("popup").doc(popupData.id).set({
      imageUrl: imageUrl,
      linkUrl: popupData.linkUrl,
      id: popupData.id,
    });
  }

  dispatch(reqGetPopups());
};

export const reqUpdatePopup = (popupData) => async (dispatch, getState) => {
  const storage = firebase.storage();
  const db = firebase.firestore();

  const ref = storage.ref("admin/popup/" + popupData.id);
  await ref.put(popupData.file);
  const imageUrl = await ref.getDownloadURL();
  await db.collection("popup").doc(popupData.id).set({
    imageUrl: imageUrl,
    linkUrl: popupData.linkUrl,
    id: popupData.linkUrl,
  });

  dispatch(reqGetPopups());
};

export const reqDeletePopup = (popupId) => async (dispatch, getState) => {
  const storage = firebase.storage();
  const db = firebase.firestore();

  const ref = storage.ref("admin/popup/" + popupId);
  await ref.delete();
  await db.collection("popup").doc(popupId).delete();

  dispatch(reqGetPopups());
};
