import "./Modal.css";
import React from 'react';

const Modal = () => {
  return (
    <div
      className="modal fade main_modal pb-4"
      id="exampleModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            {/* <h5 className="modal-title" id="exampleModalLabel" /> */}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row text-center">
              <div className="col-12">
                <h4 className="h4">시장조사 사이트</h4>
              </div>
              <div className="col-12 mb-1">
                <div className="card">
                  <a
                    href="http://kostat.go.kr/portal/korea/index.action"
                  >
                    <div className="card-body">
                      <h6 className="card-title">통계청</h6>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-12 mb-1">
                <div className="card">
                  <a href="http://dart.fss.or.kr/">
                    <div className="card-body">
                      <h6 className="card-title">전자공시시스템 - DART</h6>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-12">
                <div className="card">
                  <a href="http://sg.sbiz.or.kr/main.sg#/main">
                    <div className="card-body">
                      <h6 className="card-title">상권정보시스템</h6>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="row text-center mt40">
              <div className="col-12">
                <h4 className="h4">특허정보 사이트</h4>
              </div>
              <div className="col">
                <div className="card">
                  <a
                    href="http://www.kipris.or.kr/khome/main.jsp"
                  >
                    <div className="card-body">
                      <h6 className="card-title">특허정보넷 키프리스</h6>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer d-none">
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button type="button" className="btn btn-sm btn-primary">
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
