import { Link, useHistory } from "react-router-dom";
import "./Login.css";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { useRef, useState } from "react";
import CookieUtil from "util/CookieUtil";

const Login = () => {
  const auth = firebase.auth();

  const [loginId, setLoginId] = useState("");
  const [loginPw, setLoginPw] = useState("");

  const [resetpwEmail, setResetpwEmail] = useState();
  const history = useHistory();
  const db = useRef(firebase.firestore()).current;

  return (
    <div>
      <div className="minwidth">
        <hr />
        {/* 콘텐츠 시작 { */}
        <div id="wrapper" className="resizeWrap">
          {/* resizeWrap */}
          <div id="container_wr" className="resize">
            {/* container_wr */}
            {/* 로그인 시작 { */}
            <div id="mb_login" className="mbskin">
              <div className="mbskin_box">
                <strong className="bold h3 text-left font-weight-bold">
                  로그인
                </strong>
                <form
                  name="flogin"
                  action
                  // onSubmit="return flogin_submit(this);"
                  onSubmit={(e) => {
                    e.preventDefault();
                    let id = loginId;
                    let pw = loginPw;
                    if (loginId == "admin") {
                      id = "admin@gmail.com";
                    }
                    if (loginPw == "admin") {
                      pw = "admin123";
                    }
                    firebase
                      .auth()
                      // .signInWithEmailAndPassword(loginId, loginPw)
                      .signInWithEmailAndPassword(id, pw)
                      .then((userData) => {
                        // 로그인시에도 조건 필요...
                        db.collection("users")
                          .doc(userData.user.uid)
                          .get()
                          .then((doc) => {
                            if (doc.exists) {
                              console.log(doc.data());
                              console.log(doc.data().useOrNot);
                              if (doc.data().useOrNot) {
                                history.push("/");
                              } else {
                                alert("로그인이 불가한 계정입니다.");
                                firebase.auth().signOut();
                                window.location.reload();
                                // auth 로그아웃 필요
                              }
                            } else {
                              // console.log(doc)
                              // console.log(doc.id)
                              db.collection("admin")
                                .doc(doc.id)
                                .get()
                                .then((adminDoc) => {
                                  if (adminDoc.exists) {
                                    console.log(adminDoc.data());
                                    history.push("/");
                                  }
                                });
                            }
                          });

                        CookieUtil.setCookie("loginFlag", "true");
                      })
                      .catch((error) => {
                        console.log(error);
                        let msg = "";
                        if (error.code == "auth/invalid-email") {
                          msg = "이메일을 확인해주세요.";
                        } else if (error.code == "auth/user-not-found") {
                          msg = "존재하지 않는 계정입니다. 회원가입해주세요.";
                        } else if (error.code == "auth/wrong-password") {
                          msg = "비밀번호를 확인해주세요.";
                        } else if (error.code == "auth/too-many-request") {
                          msg =
                            "비밀번호 입력횟수를 초과했습니다. 정보찾기를 통해 비밀번호를 확인해주세요.";
                        } else {
                          msg = error.code;
                        }
                        alert(msg);
                      });
                  }}
                  method="post"
                >
                  <input type="hidden" name="url" defaultValue />
                  <fieldset id="login_fs">
                    <label htmlFor="login_id" className="sound_only">
                      회원아이디<strong className="sound_only">필수</strong>
                    </label>
                    <input
                      type="text"
                      name="mb_id"
                      id="login_id"
                      required
                      className="frm_input required"
                      // size={20}
                      // maxLength={20}
                      placeholder="아이디"
                      onChange={(e) => {
                        setLoginId(e.target.value);
                      }}
                    />
                    <label htmlFor="login_pw" className="sound_only">
                      비밀번호<strong className="sound_only">필수</strong>
                    </label>
                    <input
                      type="password"
                      name="mb_password"
                      id="login_pw"
                      required
                      className="frm_input required"
                      // size={20}
                      // maxLength={20}
                      placeholder="비밀번호"
                      onChange={(e) => {
                        setLoginPw(e.target.value);
                      }}
                    />
                    <button
                      type="submit"
                      className="btn btn-sm squear-btn-basic d-block login_btn"
                    >
                      로그인
                    </button>
                    <div id="login_info">
                      <div className="login_if_lpl">
                        {/* <a class="btn btn-sm btn-outline-dark"
                                      href=""
                                      target="_blank" id="login_password_lost" 
                                      data-toggle="modal"
                                      data-target="#loseLoinInfoModal"
                                      >정보찾기</a> */}
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-dark  "
                          data-toggle="modal"
                          data-target="#loseLoinInfoModal"
                        >
                          정보찾기
                        </button>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
              <div
                id="joinLink"
                className="d-flex justify-content-between align-items-center pt-4"
              >
                <p>아직 회원이 아니신가요?</p>
                <div>
                  <Link className="btn btn-sm btn-outline-dark" to="/join">
                    회원가입 하러가기
                  </Link>
                </div>
              </div>
            </div>
            {/* } 로그인 끝 */}
          </div>
          {/* container_wr / basicWrap */}
        </div>
        {/* } 콘텐츠 끝 */}
        {/* 정보찾기 팝업 { */}
        <div
          className="modal fade pb-4 main_modal"
          id="loseLoinInfoModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-modal="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  로그인 정보 찾기
                </h5>
              </div>
              <div className="modal-body">
                <div className="row text-center p-4 d-flex flex-column">
                  <p className="py-1">
                    회원가입 시 등록하신 이메일 주소를 입력해 주세요.
                  </p>
                  <p className="pb-3">
                    해당 이메일로 아이디와 비밀번호 정보를 보내드립니다
                  </p>
                  <input
                    type="text"
                    className="frm_input"
                    placeholder="E-mail 주소"
                    onChange={(e) => {
                      setResetpwEmail(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-sm btn-secondary squear-btn-border-basic "
                  data-dismiss="modal"
                >
                  창닫기
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-primary squear-btn-basic "
                  data-dismiss="modal"
                  onClick={() => {
                    auth
                      .sendPasswordResetEmail(resetpwEmail)
                      .then(() => {
                        alert("비밀번호 복구 메일이 발송되었습니다.");
                      })
                      .catch((reason) => {
                        alert(reason);
                      });
                  }}
                >
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* } 정보찾기 팝업 끝 */}
        <hr />
      </div>
    </div>
  );
};

export default Login;
