import { useEffect, useRef, useState } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import "../Worksheet.css";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import * as docx from "docx";
import { saveAs } from "file-saver";
import createAnswerDocxRows from "util/docx/createAnswerDocxRows";
import questions from "feature/worksheets/questions.json";
import extractPdf from "util/extractPdf";
import CommentCheck from "util/CommentCheck";

const Worksheet9 = ({ listeners, userType }) => {
  const [answers, setAnswers] = useState({});
  const db = useRef(firebase.firestore()).current;
  const auth = useRef(firebase.auth()).current;
  const match = useRouteMatch();
  const history = useHistory();
  const [worksheetId, setWorksheetId] = useState();
  const [commentVisible, setCommentVisible] = useState(true);
  const [initialAnswers, setInitialAnswers] = useState({});
  const htmlRef = useRef();

  const radioDefaultChecked = listeners.radioDefaultChecked(
    answers,
    setAnswers
  );
  const radioChecked = listeners.radioChecked(answers);
  const radioListener = listeners.radioListener(answers, setAnswers);
  const checkboxChecked = listeners.checkboxChecked(answers);
  const checkboxListener = listeners.checkboxListener(answers, setAnswers);
  const textareaValue = listeners.textareaValue(answers);
  const textareaListener = listeners.textareaListener(answers, setAnswers);
  const commentValue = listeners.commentValue(answers);
  const commentListener = listeners.commentListener(answers, setAnswers);

  const saveWorksheet = (e) => {
    if (userType === "admin") {
      alert("어드민은 워크시트를 저장할 수 없습니다!");
      return;
    }

    let finalSubmit = false;
    let date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, -1);

    if (worksheetId && worksheetId != "new") {
      const accountInfo =
        userType == "mento"
          ? {
              mento: auth.currentUser.uid,
            }
          : {
              mentee: auth.currentUser.uid,
            };

      db.collection("worksheets")
        .doc(worksheetId)
        .update({
          questionAnswers: answers,
          page: 9,
          ...accountInfo,
          finalSubmit,
          date,
        })
        .then(() => {
          alert("저장 성공!");
          listeners.storeAnswersListener(answers);
        })
        .catch((e) => alert(e));
      return;
    }
    console.log("\nanswers", {
      questionAnswers: {
        ...answers,
      },
      mentee: auth.currentUser.uid,
      mento: "",
    });
    db.collection("worksheets")
      .add({
        questionAnswers: answers,
        mentee: auth.currentUser.uid,
        page:9,
        finalSubmit,
        date,
      })
      .then((doc) => {
        setWorksheetId(doc.id);
        alert("저장 성공!");
        listeners.storeAnswersListener(answers);
        history.replace(`/worksheets/${doc.id || "new"}/worksheet9/`);
      })
      .catch((e) => alert(e));
  };

  useEffect(() => {
    console.log(userType);
    // if (userType == "mento") {
    //   setCommentVisible(true);
    // }
  }, [userType]);

  useEffect(() => {
    //
    setWorksheetId(match.params.id);

    db.collection("worksheets")
      .doc(match.params.id)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          return;
        }
        setAnswers(doc.data().questionAnswers);
        listeners.storeAnswersListener(doc.data().questionAnswers);
      });
  }, []);

  const extractDocx = () => {
    const cellOp = {
      verticalAlign: docx.VerticalAlign.CENTER,
      margins: {
        left: 80,
        top: 80,
      },
    };
    const bodyCellOp = {
      rowSpan: 1,
    };
    const rowOp = {
      height: {
        height: 2000,
        rule: docx.HeightRule.ATLEAST,
      },
    };
    const tablerows = [
      // 헤더
      new docx.TableRow({
        children: [
          new docx.TableCell({
            children: [new docx.Paragraph("분류")],
          }),
          new docx.TableCell({
            children: [new docx.Paragraph("세부기능")],
          }),
          new docx.TableCell({
            children: [new docx.Paragraph("스펙")],
          }),
          new docx.TableCell({
            children: [new docx.Paragraph("적용기술")],
          }),
        ],
      }),

      // a_2_7
      new docx.TableRow({
        ...rowOp,
        children: [
          new docx.TableCell({
            rowSpan: 5,
            children: [new docx.Paragraph(answers["a_2_7"] || "")],
            ...cellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_1_1"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_1_2"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_1_3"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
        ],
      }),
      new docx.TableRow({
        ...rowOp,
        children: [
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_1_4"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_1_5"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_1_6"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
        ],
      }),
      new docx.TableRow({
        ...rowOp,
        children: [
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_1_7"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_1_8"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_1_9"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
        ],
      }),
      new docx.TableRow({
        ...rowOp,
        children: [
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_1_10"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_1_11"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_1_12"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
        ],
      }),
      new docx.TableRow({
        ...rowOp,
        children: [
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_1_13"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_1_14"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_1_15"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
        ],
      }),

      // a_2_8
      new docx.TableRow({
        ...rowOp,
        ...rowOp,
        children: [
          new docx.TableCell({
            rowSpan: 5,
            children: [new docx.Paragraph(answers["a_2_8"] || "")],
            ...cellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_2_1"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_2_2"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_2_3"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
        ],
      }),
      new docx.TableRow({
        ...rowOp,
        children: [
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_2_4"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_2_5"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_2_6"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
        ],
      }),
      new docx.TableRow({
        ...rowOp,
        children: [
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_2_7"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_2_8"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_2_9"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
        ],
      }),
      new docx.TableRow({
        ...rowOp,
        children: [
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_2_10"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_2_11"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_2_12"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
        ],
      }),
      new docx.TableRow({
        ...rowOp,
        children: [
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_2_13"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_2_14"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_2_15"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
        ],
      }),

      // a_2_9
      new docx.TableRow({
        ...rowOp,
        ...rowOp,
        children: [
          new docx.TableCell({
            rowSpan: 5,
            children: [new docx.Paragraph(answers["a_2_9"] || "")],
            ...cellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_3_1"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_3_2"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_3_3"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
        ],
      }),
      new docx.TableRow({
        ...rowOp,
        children: [
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_3_4"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_3_5"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_3_6"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
        ],
      }),
      new docx.TableRow({
        ...rowOp,
        children: [
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_3_7"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_3_8"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_3_9"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
        ],
      }),
      new docx.TableRow({
        ...rowOp,
        children: [
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_3_10"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_3_11"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_3_12"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
        ],
      }),
      new docx.TableRow({
        ...rowOp,
        children: [
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_3_13"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_3_14"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
          new docx.TableCell({
            children: [new docx.Paragraph(answers["b_6_3_15"] || "")],
            ...cellOp,
            ...bodyCellOp,
          }),
        ],
      }),
    ];

    const table = new docx.Table({
      rows: tablerows,
      width: {
        size: 100,
        type: docx.WidthType.PERCENTAGE,
      },
    });

    const doc = new docx.Document();
    doc.addSection({
      children: [table],
    });
    docx.Packer.toBlob(doc).then((blob) => {
      saveAs(blob);
    });
  };

  return (
    <div>
      <div className="minwidth">
        <div id="worksheet">
          <div className="basicWrap">
            <section className="d-flex justify-content-between youtube align-items-center px-5">
              <h2 className="h2 font-weight-normal">
                작성하는 법을 잘 모르겠다면? youtube 시청 후 작성하기
              </h2>
              <Link
                className="btn btn-sm font-weight-normal squear-btn-basic radius pr-4 pl-4"
                to=""
              >
                과정설명 영상 보러가기
              </Link>
            </section>
            <div style={{ marginTop: 22, borderBottom: "1px solid #aaa" }}>
              <span style={{ fontSize: 12, color: "#aaa", marginTop: 22 }}>
                STEP2. 가치제안서 {">"} 기능분석 워크시트
              </span>
            </div>
            <form action className="dataForm mt40 " ref={htmlRef}>
              {/*  */}
              <div className="table stepTable mb-0">
                <div className="uu">
                  <div className="bg center middle tt">분류</div>
                  <div className="bg center middle tt">세부기능</div>
                  <div className="bg center middle tt">스펙</div>
                  <div className="bg center middle tt">적용기술</div>
                </div>
                {["a_2_7", "a_2_8", "a_2_9"].map((value, valIdx) => {
                  return (
                    <div className="uu" key={value + valIdx}>
                      <div className="disabled center tt zz">
                        {answers?.[value]
                          ? answers[value]
                          : "핵심기능" + (valIdx + 1)}
                      </div>
                      {["세부기능", "스펙", "적용기술"].map((tec, idx) => {
                        return (
                          <div className="tt " key={tec + idx}>
                            {[1, 2, 3, 4, 5].map((item, index) => {
                              return (
                                <div
                                  key={
                                    "b_6_" +
                                    (valIdx + 1) +
                                    "_" +
                                    (3 * (item - 1) + (idx + 1))
                                  }
                                >
                                  <textarea
                                    key={index}
                                    className={
                                      "textareaForm scroll" +
                                      (index == 0 ? "required" : "")
                                    }
                                    name={
                                      "b_6_" +
                                      (valIdx + 1) +
                                      "_" +
                                      (3 * (item - 1) + (idx + 1))
                                    }
                                    id
                                    cols={30}
                                    // placeholder={tec}
                                    placeholder={
                                      "b_6_" +
                                      (valIdx + 1) +
                                      "_" +
                                      (3 * (item - 1) + (idx + 1))
                                    }
                                    value={textareaValue(
                                      "b_6_" +
                                        (valIdx + 1) +
                                        "_" +
                                        (3 * (item - 1) + (idx + 1)),
                                      1
                                    )}
                                    onChange={textareaListener(
                                      "b_6_" +
                                        (valIdx + 1) +
                                        "_" +
                                        (3 * (item - 1) + (idx + 1)),
                                      1
                                    )}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
                {/* <div className="uu">
                            <div className="disabled center tt zz">
                                {answers?.["a_2_7"]?.multiAnswers[1] ? answers?.["a_2_7"]?.multiAnswers[1]: "핵심기능1"}
                            </div>
                            {
                                ["세부기능", "스펙", "적용기술"].map((tec,idx)=>{
                                    return(
                                        <div className="tt " key={tec+idx}>
                                            {
                                                [1,2,3,4,5].map((item,index) => {
                                                    console.log(item)
                                                    return(
                                                        <textarea
                                                            key={index}
                                                            className={"textareaForm "+(index == 0?"required":"")}
                                                            name={"b_6_1_"+(idx+1)}
                                                            id
                                                            cols={30}
                                                            // placeholder={tec}
                                                            placeholder={"b_6_1_"+(idx+1)+'. '+item}
                                                            value={textareaValue("b_6_1_"+(idx+1), item)}
                                                            onChange={textareaListener("b_6_1_"+(idx+1), item)}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div> */}
                {/* <div className="uu">
                            <div className="disabled center tt zz">
                                {answers?.["a_2_7"]?.multiAnswers[1] ? answers?.["a_2_7"]?.multiAnswers[1]: "핵심기능1"}
                            </div>
                            <div className="tt ">
                                <textarea
                                    className="textareaForm required"
                                    name="b_6_1"
                                    id
                                    cols={30}
                                    placeholder="세부기능"
                                    value={textareaValue("b_6_1", 1)}
                                    onChange={textareaListener("b_6_1", 1)}
                                />
                                <textarea
                                    className="textareaForm"
                                    name="b_6_1"
                                    id
                                    cols={30}
                                    placeholder="세부기능"
                                    value={textareaValue("b_6_1", 2)}
                                    onChange={textareaListener("b_6_1", 2)}
                                />
                                <textarea
                                    className="textareaForm"
                                    name="b_6_1"
                                    id
                                    cols={30}
                                    placeholder="세부기능"
                                    value={textareaValue("b_6_1", 3)}
                                    onChange={textareaListener("b_6_1", 3)}
                                />
                                <textarea
                                    className="textareaForm"
                                    name="b_6_1"
                                    id
                                    cols={30}
                                    placeholder="세부기능"
                                    value={textareaValue("b_6_1", 4)}
                                    onChange={textareaListener("b_6_1", 4)}
                                />
                                <textarea
                                    className="textareaForm"
                                    name="b_6_1"
                                    id
                                    cols={30}
                                    placeholder="세부기능"
                                    value={textareaValue("b_6_1", 5)}
                                    onChange={textareaListener("b_6_1", 5)}
                                />
                            </div>
                            <div className="tt ">
                                <textarea
                                    className="textareaForm required"
                                    name="b_6_1"
                                    id
                                    cols={30}
                                    placeholder="세부기능"
                                    value={textareaValue("b_6_1", 1)}
                                    onChange={textareaListener("b_6_1", 1)}
                                />
                                <textarea
                                    className="textareaForm"
                                    name="b_6_1"
                                    id
                                    cols={30}
                                    placeholder="세부기능"
                                    value={textareaValue("b_6_1", 2)}
                                    onChange={textareaListener("b_6_1", 2)}
                                />
                                <textarea
                                    className="textareaForm"
                                    name="b_6_1"
                                    id
                                    cols={30}
                                    placeholder="세부기능"
                                    value={textareaValue("b_6_1", 3)}
                                    onChange={textareaListener("b_6_1", 3)}
                                />
                            </div>
                            <div className="tt ">
                                <textarea
                                    className="textareaForm required"
                                    name="b_6_1"
                                    id
                                    cols={30}
                                    placeholder="세부기능"
                                    value={textareaValue("b_6_1", 1)}
                                    onChange={textareaListener("b_6_1", 1)}
                                />
                                <textarea
                                    className="textareaForm"
                                    name="b_6_1"
                                    id
                                    cols={30}
                                    placeholder="세부기능"
                                    value={textareaValue("b_6_1", 2)}
                                    onChange={textareaListener("b_6_1", 2)}
                                />
                                <textarea
                                    className="textareaForm"
                                    name="b_6_1"
                                    id
                                    cols={30}
                                    placeholder="세부기능"
                                    value={textareaValue("b_6_1", 3)}
                                    onChange={textareaListener("b_6_1", 3)}
                                />
                            </div>
                        </div> */}
              </div>
              {/*  */}
              {/* <table className="table stepTable mb-0">
                        <colgroup>
                        <col width="25%" />
                        <col width="25%" />
                        <col width="25%" />
                        <col />
                        </colgroup>
                        <tbody>
                        <tr>
                            <td className="bg center middle">분류</td>
                            <td className="bg center middle">세부기능</td>
                            <td className="bg center middle">스펙</td>
                            <td className="bg center middle">적용기술</td>
                        </tr>
                        <tr>
                            <td className="disabled center middle">
                            {answers?.["a_2_7"]?.multiAnswers[1] ? answers?.["a_2_7"]?.multiAnswers[1]: "핵심기능1"}{" "}
                            </td>
                            <td>
                            <textarea
                                className="textareaForm required"
                                name="b_6_1"
                                id
                                cols={30}
                                placeholder="세부기능"
                                value={textareaValue("b_6_1", 1)}
                                onChange={textareaListener("b_6_1", 1)}
                            />
                            <textarea
                                className="textareaForm"
                                name="b_6_1"
                                id
                                cols={30}
                                placeholder="세부기능"
                                value={textareaValue("b_6_1", 2)}
                                onChange={textareaListener("b_6_1", 2)}
                            />
                            <textarea
                                className="textareaForm"
                                name="b_6_1"
                                id
                                cols={30}
                                placeholder="세부기능"
                                value={textareaValue("b_6_1", 3)}
                                onChange={textareaListener("b_6_1", 3)}
                            />
                            </td>
                            <td>
                            <textarea
                                className="textareaForm required"
                                name="b_6_1_1"
                                id
                                cols={30}
                                placeholder="스펙"
                                value={textareaValue("b_6_1_1", 1)}
                                onChange={textareaListener("b_6_1_1", 1)}
                            />
                            <textarea
                                className="textareaForm"
                                name="b_6_1_1"
                                id
                                cols={30}
                                placeholder="스펙"
                                value={textareaValue("b_6_1_1", 2)}
                                onChange={textareaListener("b_6_1_1", 2)}
                            />
                            <textarea
                                className="textareaForm"
                                name="b_6_1_1"
                                id
                                cols={30}
                                placeholder="스펙"
                                value={textareaValue("b_6_1_1", 3)}
                                onChange={textareaListener("b_6_1_1", 3)}
                            />
                            </td>
                            <td>
                            <textarea
                                className="textareaForm required"
                                name="b_6_1_2"
                                id
                                cols={30}
                                placeholder="적용기술"
                                value={textareaValue("b_6_1_2", 1)}
                                onChange={textareaListener("b_6_1_2", 1)}
                            />
                            <textarea
                                className="textareaForm"
                                name="b_6_1_2"
                                id
                                cols={30}
                                placeholder="적용기술"
                                value={textareaValue("b_6_1_2", 2)}
                                onChange={textareaListener("b_6_1_2", 2)}
                            />
                            <textarea
                                className="textareaForm"
                                name="b_6_1_2"
                                id
                                cols={30}
                                placeholder="적용기술"
                                value={textareaValue("b_6_1_2", 3)}
                                onChange={textareaListener("b_6_1_2", 3)}
                            />
                            </td>
                        </tr>
                        
                        <tr>
                            <td className="disabled center middle">
                            {answers?.["a_2_8"]?.multiAnswers[1] ? answers?.["a_2_8"]?.multiAnswers[1]: "핵심기능2"}{" "}
                            </td>
                            <td>
                            <textarea
                                className="textareaForm required"
                                name="b_6_2"
                                id
                                cols={30}
                                rows={3}
                                placeholder="세부기능"
                                value={textareaValue("b_6_2", 1)}
                                onChange={textareaListener("b_6_2", 1)}
                            />
                            </td>
                            <td>
                            <textarea
                                className="textareaForm required"
                                name="b_6_2_1"
                                id
                                cols={30}
                                rows={3}
                                placeholder="스펙"
                                value={textareaValue("b_6_2_1", 1)}
                                onChange={textareaListener("b_6_2_1", 1)}
                            />
                            </td>
                            <td>
                            <textarea
                                className="textareaForm required"
                                name="b_6_2_2"
                                id
                                cols={30}
                                rows={3}
                                placeholder="적용기술"
                                value={textareaValue("b_6_2_2", 1)}
                                onChange={textareaListener("b_6_2_2", 1)}
                            />
                            </td>
                        </tr>
                        <tr>
                            <td className="disabled center middle">
                            {answers?.["a_2_9"]?.multiAnswers[1] ? answers?.["a_2_9"]?.multiAnswers[1]: "핵심기능3"}{" "}
                            </td>
                            <td>
                            <textarea
                                className="textareaForm required"
                                name="b_6_3"
                                id
                                cols={30}
                                rows={3}
                                placeholder="세부기능"
                                value={textareaValue("b_6_3", 1)}
                                onChange={textareaListener("b_6_3", 1)}
                            />
                            </td>
                            <td>
                            <textarea
                                className="textareaForm required"
                                name="b_6_3_1"
                                id
                                cols={30}
                                rows={3}
                                placeholder="스펙"
                                value={textareaValue("b_6_3_1", 1)}
                                onChange={textareaListener("b_6_3_1", 1)}
                            />
                            </td>
                            <td>
                            <textarea
                                className="textareaForm required"
                                name="b_6_3_2"
                                id
                                cols={30}
                                rows={3}
                                placeholder="적용기술"
                                value={textareaValue("b_6_3_2", 1)}
                                onChange={textareaListener("b_6_3_2", 1)}
                            />
                            </td>
                        </tr>
                        </tbody>
                    </table> */}
              <div id="create" className="mb40" />
              <section
                className="section comment"
                style={CommentCheck(userType, commentValue("b_6_3", 1))}
              >
                <div className="question font-weight-bold">Comment</div>
                <div className="answer">
                  <textarea
                    name="b_6_3_comment"
                    id="b_6_3_comment"
                    className="textareaForm"
                    placeholder="멘토만 작성할 수 있는 구역입니다."
                    disabled={userType == 'mento' ? false:true}
                    value={commentValue("b_6_3", 1)}
                    onChange={commentListener("b_6_3", 1)}
                  />
                </div>
              </section>
            </form>
            <section className="btnArea d-flex align-items-center justify-content-end">
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={() => extractPdf(htmlRef.current)}
              >
                PDF
              </button>
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={extractDocx}
              >
                다운로드
              </button>
              <div className="btn btn-sm squear-btn-border-basic mr-2">
                <Link to={`/worksheets/${worksheetId || "new"}/worksheet8/`}>
                  이전으로
                </Link>
              </div>
              <button
                className="btn btn-sm squear-btn-border-basic mr-2"
                onClick={saveWorksheet}
              >
                저장하기
              </button>
              <div
                className="btn btn-sm squear-btn-basic "
                onClick={() => {
                  let keyArray = [];
                  for (let i = 1; i <= 3; i++) {
                    for (let j = 1; j <= 15; j++) {
                      console.log("b_6_" + i + "_" + j);
                      keyArray.push("b_6_" + i + "_" + j);
                    }
                  }
                  // let keyArray = ['b_5_1', 'b_5_2', 'b_5_3', 'b_5_4_a', 'b_5_4_b', 'b_5_5', 'b_5_6']
                  listeners.checkValue(keyArray, answers, worksheetId, 10);
                }}
              >
                <Link>다음으로</Link>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Worksheet9;
